function GenerateValue(max) {
  let aResult = [];
  for (let i = 1; i <= max; i++) {
    aResult.push(i);
  }
  return aResult;
}

export const NIVEAU_ETUDE = [
  "T1",
  "T2",
  "T3",
  "T4",
  "T5",
  "T6",
  "T7",
  "T8",
  "T9",
  "T10",
  "T11",
  "T12",
  "Analphabète",
  "Universitaire",
];

export const ListActivites = [
  "Agriculture",
  "Elevage",
  "Exploitation foret/mines",
  "Pêche",
  "Commerce",
  "Transport",
  "Salariat agricole",
  "Artisanat",
  "Employé du secteur privé",
  "Service public",
  "Charbon/bois de chauffe",
  "Sans emploi",
  "Autres",
].sort();

export const ListBienAvoir = [
  "Terre cultivée (ha)",
  "Terre Pâturage (ha)",
  "Terre (ha)",
  "Bâtiment (pièces)",
  "Autres terres (ha)",
  "Charrues/herses(nombre)",
  "Charretes (nombres)",
  "Moto/Pirogue",
  "Radio (nombre)",
  "Zébus (nombre/facultatif)",
  "Assiètte(nombre)",
  "Ovins (nombre)",
  "Marmitte (nombre)",
  "Caprins (nombre)",
  "Poulets et Volailles (nombre)",
  "Pirogue (nombre)",
  "Boeufs de trait (nombre)",
  "Sans bien",
].sort();

export const getFiliation = (value) => {
  switch (value) {
    case "CF":
      return "Chef de famille";
    case "ENFANT":
      return "Enfant";
    case "PETITENFANT":
      return "Petit-enfant";
    case "GRANDMERE":
      return "Grand-mère";
    case "GRANDPERE":
      return "Grand-père";
    case "MERE":
      return "Mère";
    case "AUTRE":
      return "Autre";
    default:
      return "";
  }
};

export const getRole = (value) => {
  switch (value) {
    case "superadmin":
      return "Super admin";
    case "adminadd":
      return "Gestionnaire";
    case "adminconsult":
      return "Consultation";
    case "adminedit":
      return "Chef de site";
    case "admines":
      return "Responsable Entrée/Sortie";
    default:
      return "";
  }
};
export const CommuneData = [
  { id: 122, cood: "109.23 343.33" },
  { id: 68, cood: "686.88 253.29" },
  { id: 110, cood: "176.84 463.52" },
  { id: 75, cood: "693.92 355.64" },
  { id: 108, cood: "264.45 425.89" },
  { id: 65, cood: "619.83 415.56" },
  { id: 119, cood: "196.28 310.35" },
  { id: 60, cood: "680.95 429.11" },
  { id: 64, cood: "753.37 276.47" },
  { id: 63, cood: "646.87 221.38" },
  { id: 69, cood: "731.88 318.56" },
  { id: 76, cood: "586.12 243.31" },
  { id: 120, cood: "341.68 249.9" },
  { id: 62, cood: "592.23 355.28" },
  { id: 78, cood: "864.23 311.05" },
  { id: 70, cood: "599.45 291.97" },
  { id: 95, cood: "844.5 401.27" },
  { id: 142, cood: "103.3 173.22" },
  { id: 111, cood: "240.92 382.91" },
  { id: 115, cood: "174.61 259.17" },
  { id: 77, cood: "742.82 409.3" },
  { id: 140, cood: "343.9 176.96" },
  { id: 107, cood: "331.31 386.83" },
  { id: 66, cood: "781.16 319.79" },
  { id: 104, cood: "885.3 254.18" },
  { id: 80, cood: "858.77 278.61" },
  { id: 97, cood: "897.11 314.09" },
  { id: 71, cood: "641.51 324.78" },
  { id: 128, cood: "245.75 145.58" },
  { id: 137, cood: "216.67 174.83" },
  { id: 153, cood: "157.41 98.69" },
  { id: 89, cood: "775.43 381.13" },
  { id: 144, cood: "196.86 214.77" },
  { id: 121, cood: "290.39 284.49" },
  { id: 129, cood: "252.42 58.03" },
  { id: 127, cood: "127.4 153.43" },
  { id: 124, cood: "268.72 255.96" },
  { id: 114, cood: "301.69 313.55" },
  { id: 117, cood: "248.54 300.54" },
  { id: 134, cood: "325.59 103.05" },
  { id: 146, cood: "254.66 173.23" },
  { id: 84, cood: "726.17 454.24" },
  { id: 92, cood: "874.9 364.91" },
  { id: 151, cood: "162.97 136.49" },
  { id: 96, cood: "783.58 453.7" },
  { id: 59, cood: "674.67 472.24" },
  { id: 86, cood: "838.77 341.19" },
  { id: 118, cood: "277.24 227.07" },
  { id: 113, cood: "272.06 345.29" },
  { id: 132, cood: "167.04 189.62" },
  { id: 154, cood: "166.3 58.03" },
  { id: 73, cood: "696.6 490.25" },
  { id: 141, cood: "241.12 93.69" },
  { id: 130, cood: "202.78 130.6" },
  { id: 112, cood: "338.92 329.95" },
  { id: 88, cood: "798.77 363.47" },
  { id: 123, cood: "312.61 345.64" },
  { id: 136, cood: "192.78 87.27" },
  { id: 93, cood: "803.58 436.05" },
  { id: 100, cood: "801.95 341.73" },
  { id: 74, cood: "555.75 273.26" },
  { id: 149, cood: "285.93 198.37" },
  { id: 94, cood: "795.6 412.17" },
  { id: 82, cood: "723.56 489.37" },
  { id: 126, cood: "223.33 75.16" },
  { id: 83, cood: "765.79 461.92" },
  { id: 87, cood: "825.25 372.59" },
  { id: 101, cood: "741.95 471.19" },
  { id: 98, cood: "779.51 427.15" },
  { id: 79, cood: "823.59 445.69" },
  { id: 138, cood: "211.87 104.05" },
  { id: 99, cood: "844.15 442.48" },
  { id: 72, cood: "659.49 493.12" },
  { id: 90, cood: "803.78 393.26" },
  { id: 81, cood: "753.21 494.54" },
  { id: 139, cood: "254.64 119.91" },
  { id: 150, cood: "314.47 186.24" },
  { id: 143, cood: "298.91 181.43" },
  { id: 145, cood: "306.09 98.69" },
  { id: 125, cood: "198.52 193.37" },
  { id: 102, cood: "773.8 482.05" },
  { id: 103, cood: "805.1 458.68" },
  { id: 147, cood: "268.72 105.75" },
  { id: 91, cood: "817.57 469.29" },
  { id: 152, cood: "278.73 123.46" },
  { id: 131, cood: "287.62 105.78" },
  { id: 105, cood: "818.79 308.38" },
  { id: 85, cood: "753.18 484.73" },
  { id: 109, cood: "117.89 424.82" },
  { id: 106, cood: "321.07 225.83" },
  { id: 116, cood: "280.51 313.21" },
  { id: 155, cood: "332.42 161.82" },
  { id: 148, cood: "293.71 148.45" },
  { id: 135, cood: "277.67 168.46" },
  { id: 133, cood: "267.79 141.49" },
  //{id:135, cood:"263.34 211.03"},
  { id: 67, cood: "645.21 367.22" },
  { id: 61, cood: "678 494.26" },
  { id: 9, cood: "489.22 371.5" },
  { id: 49, cood: "331.28 532.52" },
  { id: 13, cood: "516.12 419.82" },
  { id: 47, cood: "263.49 493.28" },
  { id: 6, cood: "616.28 475.45" },
  { id: 1, cood: "582.2 445.5" },
  { id: 3, cood: "562.37 456.01" },
  { id: 15, cood: "544.82 317.46" },
  { id: 16, cood: "463.51 426.06" },
  { id: 31, cood: "507.41 261.12" },
  { id: 29, cood: "408.12 353.84" },
  { id: 45, cood: "394.05 502.19" },
  { id: 56, cood: "403.86 572.81" },
  { id: 44, cood: "360.15 490.07" },
  { id: 58, cood: "449.79 516.28" },
  { id: 43, cood: "397.56 439.61" },
  { id: 22, cood: "407.19 228.13" },
  { id: 46, cood: "337.18 446.03" },
  { id: 48, cood: "369.03 397.71" },
  { id: 11, cood: "464.05 468.33" },
  { id: 30, cood: "459.24 337.62" },
  { id: 36, cood: "426.08 191.23" },
  { id: 33, cood: "376.26 325.68" },
  { id: 38, cood: "466.46 304.28" },
  { id: 53, cood: "425.17 501.32" },
  { id: 20, cood: "537.03 493.29" },
  { id: 41, cood: "478.51 231.53" },
  { id: 8, cood: "566.99 490.97" },
  { id: 51, cood: "477.02 501.85" },
  { id: 7, cood: "512.16 349.57" },
  { id: 54, cood: "457.75 576.74" },
  { id: 10, cood: "534.62 366.16" },
  { id: 24, cood: "499.43 312.66" },
  { id: 57, cood: "482.2 558.72" },
  { id: 5, cood: "552.91 517.71" },
  { id: 28, cood: "435.34 276.28" },
  { id: 42, cood: "410.33 404.48" },
  { id: 34, cood: "437.18 375.59" },
  { id: 23, cood: "442 236.51" },
  { id: 2, cood: "551.23 540" },
  { id: 52, cood: "519.23 548.02" },
  { id: 35, cood: "434.77 298.39" },
  { id: 55, cood: "518.3 530.19" },
  { id: 27, cood: "370.31 291.98" },
  { id: 4, cood: "569.2 514.33" },
  { id: 50, cood: "497.93 572.46" },
  { id: 17, cood: "632.02 500.78" },
  { id: 39, cood: "396.6 305.54" },
  { id: 32, cood: "419.38 265.23" },
  { id: 37, cood: "384.19 259.89" },
  { id: 19, cood: "511.25 491.33" },
  { id: 26, cood: "396.97 270.77" },
  { id: 14, cood: "574.4 526.65" },
  { id: 40, cood: "469.76 281.47" },
  { id: 12, cood: "634.79 473.33" },
  { id: 25, cood: "410.68 295.38" },
  { id: 21, cood: "590.15 514.17" },
  { id: 18, cood: "642.57 495.26" },
];

export const getTypeCRNM = [
  { key: "CRENI", value: "CRENI" },
  { key: "CRENAS", value: "CRENAS" },
  { key: "CRENAM", value: "CRENAM" },
];

export const GET_NIVEAU_INSECURITE = [
  { key: "En sécurité", value: "En sécurité" },
  { key: "En alerte", value: "En alerte" },
  { key: "Zone rouge", value: "Zone rouge" },
];

export const GET_MALADIE_HERIDITAIRE = [
  { key: "Diabete", value: "Diabete" },
  { key: "Tension", value: "Tension" },
  { key: "Cancer", value: "Cancer" },
  { key: "Bilharziose", value: "Bilharziose" },
];

export const GET_SOURCE_EAU = [
  { key: "Puits", value: "Puits" },
  { key: "Pompe", value: "Pompe" },
  { key: "Fleuve", value: "Fleuve" },
  { key: "Autre", value: "Autre" },
];

export const DISTANCE = [
  { key: "-1 km", value: "-1 km" },
  { key: "1 à 5 km", value: "1 à 5 km" },
  { key: "5 à 10 km", value: "5 à 10 km" },
  { key: "+10 km", value: "+10 km" },
];

export const REVENU = [
  { key: "0 à 100 000 Ar", value: "0 à 100 000 Ar" },
  { key: "100 000 à 200 000 Ar", value: "100 000 à 200 000 Ar" },
  { key: "200 000 à 300 000 Ar", value: "200 000 à 300 000 Ar" },
  { key: "300 000 à 400 000 Ar", value: "300 000 à 400 000 Ar" },
  { key: "plus de 400 000 Ar", value: "plus de 400 000 Ar" },
];

export default GenerateValue;
