import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  Modal,
  Row,
  Table,
  Container,
} from "reactstrap";
import { Col, Form } from "react-bootstrap";
import Select from "react-select";
import {
  PEUT_ENCORE_ACCUEILLIR,
  PLEIN,
  DEPASSE_LA_CAPACITE_D_ACCUEIL,
  DEPLACE,
  FERME,
} from "data/constant/data-type";
import SiteService from "services/SiteService";
import ZonnageService from "services/ZonnageService";
import SiteTypeService from "services/SiteTypeService";
import NotificationModal from "../components/NotificationModal";
import PageSpinner from "../components/PageSpinner";
import { OUVERT } from "data/constant/data-type";

class SiteDetails extends React.Component {
  options = [
    { value: "08:00", label: "08:00" },
    { value: "09:00", label: "09:00" },
    { value: "10:00", label: "10:00" },
    { value: "11:00", label: "11:00" },
    { value: "12:00", label: "12:00" },
    { value: "13:00", label: "13:00" },
    { value: "14:00", label: "14:00" },
    { value: "15:00", label: "15:00" },
    { value: "16:00", label: "16:00" },
    { value: "17:00", label: "17:00" },
    { value: "18:00", label: "18:00" },
    { value: "19:00", label: "19:00" },
    { value: "20:00", label: "20:00" },
  ];

  state = {
    showNotification: false,
    isLoading: false,
    title: "",
    site: {
      accommodationCapacity: 0,
      agentSecurityCount: 0,
      availableHotMeal: false,
      bathroomCount: 0,
      bedCount: 0,
      chiefContact: "",
      chiefName: "",
      cleanOrganisation: false,
      containBathroom: false,
      distributionTime: [],
      educationManagerContact: "",
      educationManagerName: "",
      electricityManagerName: "",
      electrictyAcces: false,
      electrityManagerContact: "",
      foodManagerContact: "",
      foodManagerName: "",
      healthManagerContact: "",
      healthManagerName: "",
      id: 0,
      latitude: 0,
      longitude: 0,
      mealFrequence: 0,
      medicalConsultation: false,
      nom_site: "",
      presenceFds: false,
      presenceGroup: false,
      presidentFokontanyContact: "",
      presidentFokontanyName: "",
      reference: "",
      securityManager: "",
      siteType: {
        id: -1,
      },
      socialDistance: false,
      soilMoisture: false,
      state: "",
      typeSite: "",
      waterAcces: false,
      waterHygieneSanitationContact: "",
      waterHygieneSanitationManager: "",
      zodiacCount: 0,
      zonage: {
        commune: {
          id: -1,
          nomCommune: "",
        },
        districte: {
          id: -1,
          nom_districte: "",
        },
        fokotany: {
          id: -1,
          nom_fokotany: "",
        },
        region: {
          id: -1,
          nom_region: "",
        },
      },
    },
    selection: {
      fokotany: "",
      commune: "",
      district: "",
      region: "",
    },
    listFokotany: [],
    listCommune: [],
    listDistrict: [],
    listRegion: [],
    displayListDistrict: [],
    displayListCommune: [],
    displayListFokotany: [],
    displayListRegion: [],
    required: {
      zonage: false,
    },
    isError: false,
    errorMessage: "",
    errorTitle: "",
    isUpdate: false,
    isEmptyReference: false,
    isSiteTypeEmpty: false,
    isRegionEmpty: false,
    isDistrictEmpty: false,
    isCommuneEmpty: false,
    isFokontanyEmpty: false,
    selectedTimeDistribution: null,
  };

  closeErrorNotification = () => {
    this.setState({
      ...this.state,
      isError: false,
    });
  };

  closeNotification = () => {
    this.setState({
      showNotification: false,
    });
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  removeZonage = (evt, id) => {
    evt.preventDefault();
    let zonage = [...this.state.site.zonage];
    zonage = zonage.filter((elt, idx) => idx !== id);
    this.setState({
      site: {
        ...this.state.site,
        zonage,
      },
    });
  };

  componentDidMount() {
    this.initialisation();
    this.setState({
      isUpdate: this.props.location.state?.isUpdate,
    });
    this.props.location.state &&
      !this.props.location.state.isUpdate &&
      this.setState({
        title: "AJOUT D'UN NOUVEAU SITE",
      });
    this.props.location.state?.isUpdate &&
      this.props.match.params.id &&
      this.setDefaultData(this.props.match.params.id);
  }

  initialisation = () => {
    this.getRegion();
    this.getDistrict();
    this.getCommune();
    this.getFokotany();
    this.getSiteType();
  };

  setDefaultData = (id) => {
    this.setState({
      isLoading: true,
    });
    SiteService.get(id)
      .then(
        (response) => {
          this.setState({
            site: response.data?.data,
            title: "MODIFICATION D'UN SITE",
            isLoading: false,
            isUpdate: true,
            selectedTimeDistribution:
              response.data.data.distributionTime &&
              response.data.data.distributionTime.length > 0 &&
              this.options.filter((option) =>
                response.data.data.distributionTime.includes(option["value"])
              ),
          });
        },
        (error) => {
          this.setState({
            isLoading: false,
          });
        }
      )
      .catch((exception) => {
        this.setState({
          isLoading: false,
          isError: true,
          errorMessage: exception.errorCode
            ? exception.errorMessage
            : "Contactez votre administrateur",
          errorTitle: "Erreur à la recherche d'un site",
        });
      });
  };

  getCommune = () => {
    ZonnageService.getListCommune(
      this.state.selection.commune,
      this.state.selection.district,
      this.state.selection.region
    )
      .then((res) => res.data)
      .then((result) => {
        this.setState({
          listCommune: result.data,
          displayListCommune: result.data,
        });
      });
  };

  getFokotany = () => {
    this.setState({
      isLoading: true,
    });

    ZonnageService.getListFokotany(
      this.state.selection.fokotany,
      this.state.selection.commune,
      this.state.selection.district,
      this.state.selection.region
    )
      .then((res) => {
        return res.data;
      })
      .then((result) => {
        this.setState({
          listFokotany: result.data,
          displayListFokotany: result.data,
          isLoading: false,
        });
      });
  };

  getDistrict = () => {
    ZonnageService.getListDistrict(
      this.state.selection.district,
      this.state.selection.region
    )
      .then((res) => res.data)
      .then((result) => {
        this.setState({
          listDistrict: result.data,
          displayListDistrict: result.data,
        });
      });
  };

  getRegion = () => {
    ZonnageService.getListRegion(this.state.selection.region)
      .then((res) => res.data)
      .then((result) => {
        this.setState({
          listRegion: result.data,
          displayListRegion: result.data,
        });
      });
  };

  getSiteType = () => {
    SiteTypeService.getAllSiteType()
      .then((res) => res.data)
      .then((result) => {
        this.setState({
          siteTypeList: result.data,
        });
      });
  };

  handleInput = (event) => {
    const { site } = this.state;
    site[event.target.name] = event.target.value;
    this.setState({ site });
  };

  handleCheckbox = (event) => {
    const { site } = this.state;
    site[event.target.name] = event.target.checked;
    this.setState({ site });
  };

  handleChange = (evt) => {
    const { name, value } = evt.target;
    const { site } = this.state;
    site[name] = value;
    this.setState({ site }, () => this.formValid());
  };

  handleChangeSiteType = (evt) => {
    const { value } = evt.target;
    const { site } = this.state;
    site.siteType.id = value;
    this.setState(
      {
        site,
      },
      () => this.formValid()
    );
  };

  handleChangeMultipleSelect = (event, selectState) => {
    console.log(event);
    const { site } = this.state;
    site.distributionTime = event.map(function (item) {
      return item["value"];
    });
    this.setState({ site, selectedTimeDistribution: event });
  };

  getLocalisationBis = (name, value) => {
    const { listCommune, listDistrict, listFokotany } = this.state;
    let { displayListDistrict, displayListCommune, displayListFokotany } =
      this.state;
    let { site } = this.state;
    const situation = {
      region: "",
      district: "",
      commune: "-1",
      fokontany: "",
    };

    if (name === "region") {
      situation.region = value === "" ? -1 : parseInt(value);
      situation.district = -1;
      situation.commune = -1;
      situation.fokontany = -1;

      displayListDistrict =
        situation.region !== -1
          ? listDistrict &&
            listDistrict.filter((r) => r.regionid === situation.region)
          : listDistrict;

      displayListCommune =
        situation.region !== -1
          ? listCommune &&
            listCommune.filter((r) => r.regionid === situation.region)
          : listCommune;

      displayListFokotany =
        situation.region !== -1
          ? listFokotany &&
            listFokotany.filter((r) => r.regionid === situation.region)
          : listFokotany;
    } else if (name === "district") {
      situation.district = value === "" ? -1 : parseInt(value);
      situation.commune = -1;
      situation.fokontany = -1;
      const district =
        listDistrict &&
        listDistrict.filter((district) => district.id === situation.district);
      situation.region = district.length > 0 ? district[0].regionid : -1;

      displayListDistrict =
        situation.region !== -1
          ? listDistrict.filter((r) => r.regionid === situation.region)
          : listDistrict;

      displayListCommune =
        situation.district !== -1
          ? listCommune.filter((r) => r.dictricteid === situation.district)
          : listCommune;

      displayListFokotany =
        situation.district !== -1
          ? listFokotany &&
            listFokotany.filter((r) => r.communeid === situation.commune)
          : listFokotany;
    } else if (name === "commune") {
      situation.commune = value === "" ? -1 : parseInt(value);
      situation.fokontany = -1;
      const commune =
        listCommune &&
        listCommune.filter((commune) => commune.id === situation.commune);
      situation.region = commune.length > 0 ? commune[0].regionid : -1;
      situation.district = commune.length > 0 ? commune[0].dictricteid : -1;

      displayListDistrict =
        situation.region !== -1
          ? listDistrict.filter((r) => r.regionid === situation.region)
          : listDistrict;

      displayListCommune =
        situation.district !== -1
          ? listCommune.filter((r) => r.dictricteid === situation.district)
          : listCommune;

      displayListFokotany =
        situation.district !== -1
          ? listFokotany &&
            listFokotany.filter((r) => r.communeid === situation.commune)
          : listFokotany;
    } else if (name === "fokotany") {
      situation.fokontany = value;
      const fokontany =
        listFokotany &&
        listFokotany.filter((fokontany) => fokontany.id === parseInt(value));
      console.log(fokontany);
      situation.region = fokontany.length > 0 ? fokontany[0].regionid : -1;
      situation.district = fokontany.length > 0 ? fokontany[0].dictricteid : -1;
      situation.commune = fokontany.length > 0 ? fokontany[0].communeid : -1;

      displayListDistrict =
        situation.region !== -1
          ? listDistrict.filter((r) => r.regionid === situation.region)
          : listDistrict;

      displayListCommune =
        situation.district !== -1
          ? listCommune.filter((r) => r.dictricteid === situation.district)
          : listCommune;

      displayListFokotany =
        situation.district !== -1
          ? listFokotany &&
            listFokotany.filter((r) => r.communeid === situation.commune)
          : listFokotany;
    }

    site.zonage.region.id = situation.region;
    site.zonage.districte.id = situation.district;
    site.zonage.commune.id = situation.commune;
    site.zonage.fokotany.id = situation.fokontany;

    this.setState(
      {
        displayListDistrict,
        displayListCommune,
        displayListFokotany,
        site,
      },
      () => this.formValid()
    );
  };

  handleChangeZonage = (event) => {
    event.preventDefault();
    let { value, name } = event.target;
    this.getLocalisationBis(name, value);
  };

  formValid = () => {
    const { site } = this.state;
    let {
      isEmptyReference,
      isSiteTypeEmpty,
      isRegionEmpty,
      isDistrictEmpty,
      isCommuneEmpty,
      isFokontanyEmpty,
    } = this.state;
    isEmptyReference = site.reference == "";
    isSiteTypeEmpty = site.siteType.id == -1;
    isRegionEmpty = site.zonage.region.id == -1;
    isDistrictEmpty = site.zonage.districte.id == -1;
    isCommuneEmpty = site.zonage.commune.id == -1;
    isFokontanyEmpty = site.zonage.fokotany.id == -1;
    this.setState({
      isEmptyReference,
      isSiteTypeEmpty,
      isRegionEmpty,
      isDistrictEmpty,
      isCommuneEmpty,
      isFokontanyEmpty,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.formValid();
    const { site } = this.state;
    if (
      site.reference != "" &&
      site.siteType.id != -1 &&
      site.zonage.region.id != -1 &&
      site.zonage.districte.id != -1 &&
      site.zonage.commune.id != -1 &&
      site.zonage.fokotany.id != -1
    ) {
      this.state.isUpdate ? this.processUpdate() : this.processSave();
    }
  };

  processSave = () => {
    this.setState({
      isLoading: true,
    });
    const { site } = this.state;
    SiteService.create(site)
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        if (response.data.code && response.data.code.toString() === "200") {
          // this.props.onSave();
          this.props.history.push("/admin/sites");
        } else if (
          response.data.code &&
          response.data.code.toString() === "400" &&
          response.data.message.toString() === "SITE ALREADY EXIST"
        ) {
          this.notificationMessage = "Le site exite déjà";
          this.notificationTitle = "Création site impossible";
          this.setState({
            showNotification: true,
            isLoading: false,
          });
        }
      })
      .catch((exception) => {
        this.setState({
          isLoading: false,
          isError: true,
          errorMessage: exception.errorCode
            ? exception.errorMessage
            : "Contactez votre administrateur",
          errorTitle: "Erreur à l'ajout d'un site",
        });
      });
  };
  processUpdate = () => {
    this.setState({
      isLoading: true,
    });

    SiteService.update(this.state.site)
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        if (response.data.code && response.data.code.toString() === "200") {
          // this.props.onSave();
          this.props.history.push("/admin/sites");
        } else if (
          response.data.code &&
          response.data.code.toString() === "400" &&
          response.data.message.toString() === "SITE ALREADY EXIST"
        ) {
          this.notificationMessage = "Le site exite déjà";
          this.notificationTitle = "Création site impossible";
          this.setState({
            showNotification: true,
            isLoading: false,
          });
        }
      })
      .catch((exception) => {
        this.setState({
          isLoading: false,
          isError: true,
          errorMessage: exception.errorCode
            ? exception.errorMessage
            : "Contactez votre administrateur",
          errorTitle: "Erreur à la modification d'un site",
        });
      });
  };

  handleRemoveSite = (id) => {
    SiteService.delete(id).catch((exception) => {
      this.setState({
        isLoading: false,
        isError: true,
        errorMessage: exception.errorCode
          ? exception.errorMessage
          : "Contactez votre administrateur",
        errorTitle: "Erreur à la modification d'un site",
      });
    });
  };

  render() {
    const { site } = this.state;

    const listeRegion =
      this.state.displayListRegion &&
      this.state.displayListRegion.map((elt, idx) => (
        <option key={elt.id} value={elt.id}>
          {elt.nom_region}
        </option>
      ));

    const listeCommnue =
      this.state.displayListCommune &&
      this.state.displayListCommune.map((elt, idx) => (
        <option key={elt.id} value={elt.id}>
          {elt.nomCommune}
        </option>
      ));

    const listeFokotany =
      this.state.displayListFokotany &&
      this.state.displayListFokotany.map((elt, idx) => (
        <option key={elt.id} value={elt.id}>
          {elt.nom_fokotany}
        </option>
      ));

    const listeDistrict =
      this.state.displayListDistrict &&
      this.state.displayListDistrict.map((elt, idx) => (
        <option key={elt.id} value={elt.id}>
          {elt.nom_districte}
        </option>
      ));

    const siteTypeList =
      this.state.siteTypeList &&
      this.state.siteTypeList.map((elt) => (
        <option key={elt.id} value={elt.id}>
          {elt.name}
        </option>
      ));
    return (
      <div className="main-container">
        <div className="header bg-gradient-primary postition-absolute"></div>
        {/* Page content */}
        <Container className="mt-5 custom-card-container" fluid >
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardBody className="border-0">
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username">
                              Référence site *
                            </label>
                            <Input
                              className={
                                this.state.isEmptyReference
                                  ? "form-control-alternative-error"
                                  : "form-control-alternative"
                              }
                              id="input-username"
                              disabled={this.state.isUpdate}
                              placeholder=""
                              type="text"
                              name="reference"
                              value={this.state.site.reference}
                              onChange={this.handleChange}
                            />
                            {this.state.isEmptyReference && (
                              <small className="text-danger">
                                Champ obligatoire
                              </small>
                            )}
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username">
                              Nom du site
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              placeholder=""
                              type="text"
                              name="nom_site"
                              value={this.state.site.nom_site}
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username">
                              Type site *
                            </label>
                            <Input
                              type="select"
                              className={
                                this.state.isSiteTypeEmpty
                                  ? "form-control-alternative-error mdb-select md-form form-control"
                                  : "mdb-select md-form form-control"
                              }
                              onChange={this.handleChangeSiteType}
                              name="typeSite"
                              value={this.state.site.siteType.id}>
                              <option value={-1}></option>
                              {siteTypeList}
                            </Input>
                            {this.state.isSiteTypeEmpty && (
                              <small className="text-danger">
                                Champ obligatoire
                              </small>
                            )}
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-latitude">
                              Latitude
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-latitude"
                              placeholder=""
                              type="number"
                              name="latitude"
                              value={this.state.site.latitude}
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-longitude">
                              Longitude
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-longitude"
                              placeholder=""
                              type="number"
                              name="longitude"
                              value={this.state.site.longitude}
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-accommodationCapacity">
                              Capacité d'accueil
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-accommodationCapacity"
                              placeholder=""
                              type="number"
                              name="accommodationCapacity"
                              value={this.state.site.accommodationCapacity}
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-longitude">
                              Nombre de lits
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-bedCount"
                              placeholder=""
                              type="number"
                              name="bedCount"
                              value={this.state.site.bedCount}
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <div className="custom-control custom-checkbox mb-3">
                              <input
                                className="custom-control-input form-control"
                                id="customCheck1"
                                type="checkbox"
                                name="containBathroom"
                                checked={this.state.site?.containBathroom}
                                onChange={this.handleCheckbox}
                              />
                              <label
                                className="custom-control-label text-bold"
                                htmlFor="customCheck1">
                                Existence de toilette
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-bathroomCount">
                              Nombre de toilette{" "}
                              {this.state.site.containBathroom ? "*" : ""}
                            </label>

                            <Input
                              className={
                                this.state.isEmptybathroomCount
                                  ? "form-control-alternative-error"
                                  : "form-control-alternative"
                              }
                              id="input-bathroomCount"
                              placeholder=""
                              type="number"
                              name="bathroomCount"
                              value={this.state.site.bathroomCount || "0"}
                              onChange={this.handleChange}
                              disabled={!this.state.site.containBathroom}
                            />
                            {this.state.isEmptybathroomCount && (
                              <small className="text-danger">
                                Champ obligatoire
                              </small>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <div className="custom-control custom-checkbox mb-3">
                              <input
                                className="custom-control-input form-control"
                                id="customCheck2"
                                type="checkbox"
                                name="soilMoisture"
                                defaultChecked={this.state.site.soilMoisture}
                                checked={this.state.site.soilMoisture}
                                onChange={this.handleCheckbox}
                              />
                              <label
                                className="custom-control-label text-bold"
                                htmlFor="customCheck2">
                                Sol humide
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <div className="custom-control custom-checkbox mb-3">
                              <input
                                className="custom-control-input form-control"
                                id="customCheck3"
                                type="checkbox"
                                name="waterAcces"
                                defaultChecked={this.state.site.waterAcces}
                                checked={this.state.site.waterAcces}
                                onChange={this.handleCheckbox}
                              />
                              <label
                                className="custom-control-label text-bold"
                                htmlFor="customCheck3">
                                Accès Eau
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <div className="custom-control custom-checkbox mb-3">
                              <input
                                className="custom-control-input form-control"
                                id="customCheck4"
                                type="checkbox"
                                name="cleanOrganisation"
                                defaultChecked={
                                  this.state.site.cleanOrganisation
                                }
                                checked={this.state.site.cleanOrganisation}
                                onChange={this.handleCheckbox}
                              />
                              <label
                                className="custom-control-label text-bold"
                                htmlFor="customCheck4">
                                Organisation de nettoyage
                              </label>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup>
                            <div className="custom-control custom-checkbox mb-3">
                              <input
                                className="custom-control-input form-control"
                                id="customCheck5"
                                type="checkbox"
                                name="medicalConsultation"
                                defaultChecked={
                                  this.state.site.medicalConsultation
                                }
                                checked={this.state.site.medicalConsultation}
                                onChange={this.handleCheckbox}
                              />
                              <label
                                className="custom-control-label text-bold"
                                htmlFor="customCheck5">
                                Consultation médical
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <div className="custom-control custom-checkbox mb-3">
                              <input
                                className="custom-control-input form-control"
                                id="customCheck6"
                                type="checkbox"
                                name="socialDistance"
                                defaultChecked={this.state.site.socialDistance}
                                checked={this.state.site.socialDistance}
                                onChange={this.handleCheckbox}
                              />
                              <label
                                className="custom-control-label text-bold"
                                htmlFor="customCheck6">
                                Distanciation social
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Row>
                          <Col>
                            <FormGroup>
                              <div className="custom-control custom-checkbox mb-3">
                                <input
                                  className="custom-control-input form-control"
                                  id="customCheck7"
                                  type="checkbox"
                                  name="availableHotMeal"
                                  defaultChecked={
                                    this.state.site.availableHotMeal
                                  }
                                  checked={this.state.site.availableHotMeal}
                                  onChange={this.handleCheckbox}
                                />
                                <label
                                  className="custom-control-label text-bold"
                                  htmlFor="customCheck7">
                                  Disponibilité de repas chaud
                                </label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-mealFrequence">
                                Fréquence{" "}
                                {this.state.site.availableHotMeal ? "*" : ""}
                              </label>

                              <Input
                                className={
                                  this.state.isEmptymealFrequence
                                    ? "form-control-alternative-error"
                                    : "form-control-alternative"
                                }
                                id="input-mealFrequence"
                                placeholder=""
                                type="number"
                                name="mealFrequence"
                                value={this.state.site?.mealFrequence}
                                onChange={this.handleInput}
                                disabled={!this.state.site.availableHotMeal}
                              />
                              {this.state.isEmptymealFrequence && (
                                <small className="text-danger">
                                  Champ obligatoire
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                          <Col>
                            <Form.Group
                              as={Col}
                              controlId="my_multiselect_field">
                              <label
                                className="form-control-label"
                                htmlFor="input-mealFrequence">
                                Distribution{" "}
                                {this.state.site.availableHotMeal ? "*" : ""}
                              </label>
                              <Select
                                isMulti
                                options={this.options}
                                isSearchable
                                name="distributionTime"
                                value={this.state.selectedTimeDistribution}
                                onChange={this.handleChangeMultipleSelect}
                                disabled={this.state.site.availableHotMeal}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Col>
                          <FormGroup>
                            <div className="custom-control custom-checkbox mb-3">
                              <input
                                className="custom-control-input form-control"
                                id="customCheck8"
                                type="checkbox"
                                name="presenceFds"
                                defaultChecked={this.state.site.presenceFds}
                                checked={this.state.site.presenceFds}
                                onChange={this.handleCheckbox}
                              />
                              <label
                                className="custom-control-label text-bold"
                                htmlFor="customCheck8">
                                Présence FDS
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <div className="custom-control custom-checkbox mb-3">
                              <input
                                className="custom-control-input form-control"
                                id="customCheck9"
                                type="checkbox"
                                name="presenceGroup"
                                defaultChecked={this.state.site.presenceGroup}
                                checked={this.state.site.presenceGroup}
                                onChange={this.handleCheckbox}
                              />
                              <label
                                className="custom-control-label text-bold"
                                htmlFor="customCheck9">
                                Présence Groupe
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-zodiacCount">
                                Nombre de zodiac *
                              </label>

                              <Input
                                className={
                                  this.state.isEmptyzodiacCount
                                    ? "form-control-alternative-error"
                                    : "form-control-alternative"
                                }
                                id="input-zodiacCount"
                                placeholder=""
                                type="number"
                                name="zodiacCount"
                                value={this.state.site.zodiacCount || ""}
                                onChange={this.handleChange}
                              />
                              {this.state.isEmptyzodiacCount && (
                                <small className="text-danger">
                                  Champ obligatoire
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-security">
                                Nombre agent de sécurité *
                              </label>

                              <Input
                                className={
                                  this.state.isEmptyNbMedecin
                                    ? "form-control-alternative-error"
                                    : "form-control-alternative"
                                }
                                id="input-security"
                                placeholder=""
                                type="number"
                                name="nb_medecin"
                                value={this.state.site.nb_medecin || ""}
                                onChange={this.handleChange}
                              />
                              {this.state.isEmptyNbMedecin && (
                                <small className="text-danger">
                                  Champ obligatoire
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            {this.props.isUpdate && (
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-siteType">
                                  Etat *
                                </label>

                                <select
                                  className={
                                    this.state.isEmptysiteType
                                      ? "form-control-alternative-error mdb-select md-form form-control"
                                      : "mdb-select md-form form-control"
                                  }
                                  onChange={this.handleChange}
                                  name="state"
                                  value={this.state.site.state}>
                                  <option value=""></option>
                                  <option value={OUVERT}>Ouvert</option>
                                  <option value={PEUT_ENCORE_ACCUEILLIR}>
                                    Peut encore accueillir
                                  </option>
                                  <option value={PLEIN}>Plein</option>
                                  <option value={DEPASSE_LA_CAPACITE_D_ACCUEIL}>
                                    Dépasse la capacité d'accueil
                                  </option>
                                  <option value={DEPLACE}>Déplacé</option>
                                  <option value={FERME}>Fermé</option>
                                </select>
                              </FormGroup>
                            )}
                          </Col>
                        </Row>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-chiefName">
                              Nom du Chef
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-chiefName"
                              placeholder=""
                              type="text"
                              name="chiefName"
                              value={this.state.site.chiefName}
                              onChange={this.handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-chiefContact">
                              Contact du Chef
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-chiefContact"
                              placeholder=""
                              type="text"
                              name="chiefContact"
                              value={this.state.site.chiefContact}
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-presidentFokontanyName">
                              Nom du Chef fokontany
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-presidentFokontanyName"
                              placeholder=""
                              type="text"
                              name="presidentFokontanyName"
                              value={this.state.site.presidentFokontanyName}
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-presidentFokontanyContact">
                              Contact du Chef fokontany
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-presidentFokontanyName"
                              placeholder=""
                              type="text"
                              name="presidentFokontanyContact"
                              value={this.state.site.presidentFokontanyContact}
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-educationManagerName">
                              Nom du Responsable éducation
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-educationManagerName"
                              placeholder=""
                              type="text"
                              name="educationManagerName"
                              value={this.state.site.educationManagerName}
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-educationManagerContact">
                              Contact du Responsable éducation
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-educationManagerContact"
                              placeholder=""
                              type="text"
                              name="educationManagerContact"
                              value={this.state.site.educationManagerContact}
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-educationManagerName">
                              Nom du Responsable électricité
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-electricityManagerName"
                              placeholder=""
                              type="text"
                              name="electricityManagerName"
                              value={this.state.site.electricityManagerName}
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-electrityManagerContact">
                              Contact du Responsable électricité
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-electrityManagerContact"
                              placeholder=""
                              type="text"
                              name="electrityManagerContact"
                              value={this.state.site.electrityManagerContact}
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-healthManagerName">
                              Nom du Responsable santé
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-healthManagerName"
                              placeholder=""
                              type="text"
                              name="healthManagerName"
                              value={this.state.site.healthManagerName}
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-healthManagerContact">
                              Contact du Responsable santé
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-healthManagerContact"
                              placeholder=""
                              type="text"
                              name="healthManagerContact"
                              value={this.state.site.healthManagerContact}
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-waterHygieneSanitationManager">
                              Nom du Responsable BNGRC
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-waterHygieneSanitationManager"
                              placeholder=""
                              type="text"
                              name="waterHygieneSanitationManager"
                              value={
                                this.state.site.waterHygieneSanitationManager
                              }
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-waterHygieneSanitationContact">
                              Contact du Responsable BNGRC
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-waterHygieneSanitationContact"
                              placeholder=""
                              type="text"
                              name="waterHygieneSanitationContact"
                              value={
                                this.state.site.waterHygieneSanitationContact
                              }
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username">
                              Région *
                            </label>
                            <Input
                              type="select"
                              className={
                                this.state.isRegionEmpty
                                  ? "form-control-alternative-error mdb-select md-form form-control"
                                  : "mdb-select md-form form-control"
                              }
                              onChange={(event) =>
                                this.handleChangeZonage(event, "region")
                              }
                              name="region"
                              value={this.state.site?.zonage?.region?.id}>
                              <option value={-1}>
                                Afficher toutes les régions
                              </option>
                              {listeRegion}
                            </Input>
                            {this.state.isRegionEmpty && (
                              <small className="text-danger">
                                Champ obligatoire
                              </small>
                            )}
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username">
                              District *
                            </label>
                            <Input
                              type="select"
                              className={
                                this.state.isDistrictEmpty
                                  ? "form-control-alternative-error mdb-select md-form form-control"
                                  : "mdb-select md-form form-control"
                              }
                              onChange={(event) =>
                                this.handleChangeZonage(event, "district")
                              }
                              name="district"
                              value={this.state.site?.zonage?.districte?.id}>
                              <option value={-1}>
                                Afficher tous les districts
                              </option>
                              {listeDistrict}
                            </Input>
                            {this.state.isDistrictEmpty && (
                              <small className="text-danger">
                                Champ obligatoire
                              </small>
                            )}
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username">
                              Commune *
                            </label>
                            <Input
                              type="select"
                              className={
                                this.state.isCommuneEmpty
                                  ? "form-control-alternative-error mdb-select md-form form-control"
                                  : "mdb-select md-form form-control"
                              }
                              onChange={(event) =>
                                this.handleChangeZonage(event, "commune")
                              }
                              name="commune"
                              value={this.state.site?.zonage?.commune?.id}>
                              <option value={-1}>
                                Afficher toutes les communes
                              </option>
                              {listeCommnue}
                            </Input>
                            {this.state.isCommuneEmpty && (
                              <small className="text-danger">
                                Champ obligatoire
                              </small>
                            )}
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username">
                              Fokontany *
                            </label>
                            <Input
                              type="select"
                              className={
                                this.state.isFokontanyEmpty
                                  ? "form-control-alternative-error mdb-select md-form form-control"
                                  : "mdb-select md-form form-control"
                              }
                              onChange={(event) =>
                                this.handleChangeZonage(event, "fokotany")
                              }
                              name="fokotany"
                              value={this.state.site?.zonage?.fokotany?.id}>
                              <option value="">
                                Afficher tous les Fokontany
                              </option>
                              {listeFokotany}
                            </Input>
                            {this.state.isFokontanyEmpty && (
                              <small className="text-danger">
                                Champ obligatoire
                              </small>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    {!this.props.location?.state?.isCheckInfo && (
                      <hr className="m-0" />
                    )}
                    {!this.props.location?.state?.isCheckInfo && (
                      <div className="modal-footer">
                        {this.state.isError && <span>(*) </span>}
                        <Button type="button" onClick={this.props.onCancel}>
                          Annuler
                        </Button>
                        <Button
                          type="button"
                          className="btn-primary"
                          onClick={this.handleSubmit}>
                          <span>Enregistrer</span>
                        </Button>
                      </div>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        <PageSpinner show={this.state.isLoading} />
      </div>
    );
  }
}

export default SiteDetails;
