import axios from 'axios';
import { BACK_URL } from 'data/constant/urls';
import authHeader from './AuthHeader';

// const API_URL = process.env.REACT_APP_URL;
const API_URL = BACK_URL;

class MenageService {

  getList() {
    return axios.get(API_URL + "api/menage/findAll", {
      headers: authHeader(),
    });
  }
  getTraitementById(id) {
    return axios.get(API_URL + "api/traitement/findtraitement?id="+id, {
      headers: authHeader(),
    });
  }

  post(data) {
    return axios.post(API_URL + "api/traitement/addtraitement", data, {
      headers: authHeader(),
    });
  }

  delete(id){
    return axios.delete(API_URL + "api/traitement/deletetraitement?id="+id, {
      headers: authHeader(),
    });
  }

  update(data) {
    return axios.put(API_URL + "api/traitement/updatetraitement", data, {
      headers: authHeader(),
    });
  }

  getListByPagination(pageNo, pageSize, nom){
    return axios.get(API_URL + "api/traitement/findAllPagination?pageNo="+pageNo+'&pageSize='+
    pageSize+"&individu="+nom,
    {
      headers: authHeader(),
    });
  }

}

export default new MenageService();