import React from "react";
import ReactDatetime from "react-datetime";
import {
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import moment from "moment";

class Enqueteur extends React.Component {
  constructor(props) {
    super(props);
    this.id = "";
  }
  state = {
    enqueteInfo: {
      enqueteur: "",
      dateEnquete: moment().format("YYYY-MM-DDTHH:mm:ss"),
      displaydDateEnquete: moment().format("DD/MM/YYYY HH:mm"),
    },
    requiredFields: {
      enqueteur: "",
    },
  };

  componentWillReceiveProps(props) {
    if (props.requiredFieldsError) {
      this.setState({ requiredFields: props.requiredFieldsError });
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    let requiredFields = "";
    switch (name) {
      case "enqueteur":
        requiredFields = value.length <= 0 ? "Ne doit pas être vide" : "";
        // if (value.length > 0) {
        //   return "";
        // }
        break;
      default:
    }
    this.setState(
      {
        enqueteInfo: {
          ...this.state.enqueteInfo,
          [name]: value,
        },
        requiredFields: {
          [name]: requiredFields,
        },
      },
      () => {
        this.props.data(this.state.enqueteInfo);
      }
    );
  };
  handleChangeDate = (date) => {
    let value = "";
    if (date.isValid) {
      value = date.format("YYYY-MM-DDTHH:mm:ss");
    }

    this.setState(
      {
        enqueteInfo: {
          ...this.state.enqueteInfo,
          dateEnquete: value + "000Z",
          displaydDateEnquete: moment(value).format("DD/MM/YYYY HH:mm"),
        },
      },
      () => {
        this.props.data(this.state.enqueteInfo);
      }
    );
  };
  render() {
    return (
      <>
        <Row>
          <Col>
            <FormGroup>
              <div className="h3 text-primary">Enquêteur</div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label className="form-control-label" htmlFor="input-enqueteur">
                Nom de l'enquêteur*
              </label>
              <Input
                className="form-control-alternative"
                id="input-enqueteur"
                placeholder=""
                type="text"
                value={this.state.enqueteInfo.enqueteur}
                onChange={this.handleChange}
                name="enqueteur"
              />
              {this.state.requiredFields &&
                this.state.requiredFields.enqueteur &&
                this.state.requiredFields.enqueteur.length > 0 && (
                  <small className="text-danger">champ obligatoire</small>
                )}
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-date-enquete"
              >
                Date et heure de l'enquête
              </label>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  // inputProps={{
                  //   placeholder: this.state.enqueteInfo?.dateEnquete !== "" ?
                  //     moment(this.state.enqueteInfo?.dateEnquete).format("DD/MM/YYYY") : "DD/MM/YYYY" ,
                  //   onKeyDown: (e) => e.preventDefault(),
                  // }}
                  // format={"DD/MM/YYYY HH:MM:SS"}
                  closeOnSelect={true}
                  timeInputLabel="Time:"
                  showTimeInput
                  dateFormat={"DD/MM/YYYY"}
                  timeFormat={"HH:mm"}
                  onChange={this.handleChangeDate}
                  name="dateEnquete"
                  // selected={this.state.enqueteInfo.dateEnquete}
                  value={this.state.enqueteInfo.displaydDateEnquete}
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }
}

export default Enqueteur;
