import axios from 'axios';
import { BACK_URL } from 'data/constant/urls';
import authHeader from './AuthHeader';

// const API_URL = process.env.REACT_APP_URL;
const API_URL = BACK_URL;

class ZonnageService {

  getListRegion(region) {
    return axios.get(API_URL + `api/region/findAllRegion?nomRegion=${region}`, {
      headers: authHeader(),
    });
  }

  getListDistrict(districte, region) {
    return axios.get(API_URL + `api/districte/findAllDistricte?nomDistricte=${districte}&nomRegion=${region}
    `, {
      headers: authHeader(),
    });
  }

  getListCommune(commune,districte, region) {
    return axios.get(API_URL + `api/commune/findAllCommuneByRegionDistricte?nomCommune=${commune}&nomDistricte=${districte}&nomRegion=${region}`, {
      headers: authHeader(),
    });
  }

  getListFokotany(fokotany,commune,districte, region) {
    return axios.get(API_URL + `api/fokotany/findAllfokotany?nomFokotany=${fokotany}&nomCommune=${commune}&nomDistricte=${districte}&nomRegion=${region}`, {
      headers: authHeader(),
    });
  }

  getList() {
    return axios.get(API_URL + `api/commune/findAllCommune`, {
      headers: authHeader(),
    });
  }

}

export default new ZonnageService();