import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
const NewArticleModal = (props) => {
    const save = () => {
        if (props.selectedUnity && props.selectedUnity.id && props.selectedUnity.id > 0) {
            props.update();
            props.toggle();
        } else {
            props.save();
            props.toggle();
        }
    }

    return (
        <Modal isOpen={props.showModal} toggle={props.toggle} className='modal-check' fade={true}>
            <ModalHeader toggle={props.toggle}>Ajout de nouvelle unité</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label for="unity">Nom de l'unité</Label>
                    <Input
                        type="text"
                        name="unity"
                        id="unity"
                        placeholder="Nom de l'unité"
                        value={(props.selectedUnity && props.selectedUnity.libelle) || ''}
                        onChange={props.handleChange}
                    />
                </FormGroup>

            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={save}>Enregistrer</Button>{' '}
                <Button color="secondary" onClick={props.toggle}>Annuler</Button>
            </ModalFooter>
        </Modal >
    )
}

export default NewArticleModal;

