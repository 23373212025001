import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  UncontrolledDropdown,
  Badge,
} from "reactstrap";
import PageSpinner from "views/components/PageSpinner";
import { user_editor } from "data/constant/user.rights";
import EnrolementEnregistrementServices from "services/EnrolementEnregistrementServices";
import ExitFromSiteModal from "views/components/ExitFromSiteModal";
import SiteService from "services/SiteService";
import MenageService from "services/MenageService";
import MoveToOtherSiteModal from "views/components/MoveToOtherSiteModal";
import EnrolementDetailsModals from "views/components/EnrolementDetailsModals";
import Zonnage from "views/components/Zonnage";
// import { user_gestionnaire } from "data/constant/user.rights";
import { user_gest_respes } from "data/constant/user.rights";
import moment from "moment";
import VoinaService from "services/VoinaService";
import ZonnageService from "services/ZonnageService";
import InformationModal from "views/components/InformationModal";

class EnrolementPage extends React.Component {
  constructor(props) {
    super(props);
    this.nextPage.bind(this);
    this.previousPage.bind(this);
  }

  state = {
    content: [],
    sites: [],
    enrolement: {},
    siteName: "",
    chefMenage: "",
    menageId: "",
    selectedEnrolement: {},
    filtre: {
      siteId: -1,
      voina: "",
      nomChefFiltre: "",
      avecDateSortie: -1,
      region: "",
      district: "",
      commune: "",
      fokotany: "",
      regionId: -1,
      districtId: -1,
      communeId: -1,
      fokotanyId: -1,
    },
    page: 0,
    currentPage: 0,
    totalPages: 1,
    items: 0,
    totalItems: 0,
    pageSize: 10,
    showExit: false,
    isLoading: false,
    showMoveToOtherSite: false,
    showDetailEnrolement: false,
    selectedLineIndex: null,
    currentMenage: null,
    showFicheMenage: false,
    isInformation: false,
    isInit: true,
  };

  componentDidMount() {
    this.initialisation();
  }

  initialisation() {
    this.getSiteListFromWs();
    this.getVoinaListFromWs();
    // this.getList();
  }

  getList() {
    this.getListEnrolement();

    MenageService.getListByPagination(null, null, this.state.filtre).then(
      (response) => {
        response &&
          response.data &&
          this.setState({ menages: response.data.data, isLoading: false });
      }
    );
  }
  handlePage = (event) => {
    this.setState({ [event.target.id]: event.target.value }, () =>
      this.getListEnrolement()
    );
  };

  getListEnrolement() {
    this.setState(
      {
        isLoading: true,
        filtre: {
          ...this.state.filtre,
          siteId: parseInt(this.state.filtre.siteId),
          avecDateSortie: parseInt(this.state.filtre.avecDateSortie),
        },
      },
      () => {
        const pageSize = parseInt(this.state.pageSize);
        const page = parseInt(this.state.page);

        EnrolementEnregistrementServices.getList(
          page,
          pageSize,
          this.state.filtre
        ).then(
          (response) => {
            this.setState({
              content: response.data.data,
              totalPages: response.data.totalPages,
              items: response.data.data.length,
              totalItems: response.data.totalItems,
              isLoading: false,
            });
          },
          (error) => {
            this.setState({
              isLoading: false,
            });
          }
        );
      }
    );
  }

  handleChangeZonage = (situation) => {
    const { filtre } = this.state;
    filtre.communeId = situation?.commune;
    filtre.districtId = situation?.district;
    filtre.regionId = situation?.region;
    filtre.fokotanyId = situation?.fokontany;
    this.setState({ filtre });
  };

  onSearch = () => {
    this.getListEnrolement();
  };

  nextPage = (e) => {
    e.preventDefault();
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => this.getListEnrolement()
    );
  };

  previousPage = () => {
    const page = parseInt(this.state.pageSize);
    this.setState(
      {
        page: this.state.page - 1,
      },
      () => this.getListEnrolement()
    );
  };

  moveToOtherSite = (enrolement) => {
    const siteName = enrolement.site.nom_site;
    const chefMenage = enrolement.menage.nomChef;
    this.setState({
      showMoveToOtherSite: true,
      selectedEnrolement: enrolement,
      siteName: siteName,
      chefMenage: chefMenage,
    });
  };
  detailEnrolement = (enrolement) => {
    const siteName = enrolement.site.nom_site;
    const chefMenage = enrolement.menage.nomChef;
    this.setState({
      showDetailEnrolement: true,
      selectedEnrolement: enrolement,
      siteName: siteName,
      chefMenage: chefMenage,
    });
  };
  showDetailEnrolement;

  exitFromSite = (enrolement) => {
    const siteName = enrolement.site.nom_site;
    const chefMenage = enrolement.menage.nomChef;
    this.setState({
      showExit: true,
      selectedEnrolement: enrolement,
      siteName: siteName,
      chefMenage: chefMenage,
    });
  };

  onHideFormModal = () => {
    this.setState({
      showMoveToOtherSite: false,
      showExit: false,
      showDetailEnrolement: false,
    });
  };

  onSave = () => {
    this.getList();
    this.setState({
      showExit: false,
      showMoveToOtherSite: false,
      showDetailEnrolement: false,
    });
  };

  handleChangeInputFilter = (event) => {
    this.setState(
      {
        filtre: {
          ...this.state.filtre,
          [event.target.name]: event.target.value,
        },
      },
      () => {
        // this.getListEnrolement();
      }
    );
  };

  getSiteListFromWs = () => {
    this.setState({
      isLoading: true,
    });
    SiteService.getAllSite()
      .then((response) => response.data)
      .then((result) => {
        this.setState({
          siteList: result.data || [],
          isLoading: false,
        });
      })
      .catch(() =>
        this.setState({
          isLoading: false,
        })
      );
  };

  getVoinaListFromWs = () => {
    VoinaService.getAllVoina()
      .then((response) => response.data)
      .then((result) => {
        this.setState(
          {
            voinaList: result.data || [],
            isLoading: false,
          },
          () => this.setDefaultVoina()
        );
      });
    // .catch(() => {
    //   this.getList();
    //   this.setState({
    //     isLoading: false,
    //   });
    // });
  };

  setDefaultVoina() {
    //recuperation du voina par defaut ..
    VoinaService.getDefaultVoina().then((result) => {
      if (result.data.data) {
        this.setState(
          {
            filtre: {
              ...this.state.filtre,
              voina: result.data.data.libelle,
            },
          },
          () => {
            this.onSearch();
          }
        );
      } else this.getList();
    });
  }

  showInformation = (menage) => {
    this.setState({
      isInformation: true,
      currentMenage: menage,
    });
  };

  hideInformation = () => {
    this.setState({
      isInformation: false,
      currentMenage: {},
    });
  };

  render() {
    const siteOptions =
      this.state.siteList &&
      this.state.siteList.map((elt, idx) => (
        <option key={elt.id} value={elt.id}>
          {elt.nom_site}
        </option>
      ));
    const voinaOptions =
      this.state.voinaList &&
      this.state.voinaList.map((elt, idx) => (
        <option key={elt.id} value={elt.libelle}>
          {elt.libelle}
        </option>
      ));
    const tableData =
      this.state.content &&
      this.state.content.map((enrolement, index) => {
        return (
          <tr
            key={index}
            className={this.state.selectedLineIndex == index ? "selected" : ""}>
            <td className="sticky-col sticky-left p-1">
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light m-0"
                  href="#"
                  role="button"
                  size="sm"
                  color=""
                  outline={true}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ selectedLineIndex: index });
                  }}>
                  <i className="fas fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-menu-arrow"
                  container="body"
                  positionFixed={true}
                  right>
                  <React.Fragment>
                    {enrolement.dateSortie === null &&
                      (user_gest_respes.includes(
                        localStorage.getItem("roleName") || ""
                      ) ||
                        user_editor.includes(
                          localStorage.getItem("roleName") || ""
                        )) && (
                        <DropdownItem
                          href="#"
                          onClick={() => this.moveToOtherSite(enrolement)}>
                          Déplacer sur un autre site
                        </DropdownItem>
                      )}

                    {enrolement.dateSortie === null &&
                      (user_gest_respes.includes(
                        localStorage.getItem("roleName") || ""
                      ) ||
                        user_editor.includes(
                          localStorage.getItem("roleName") || ""
                        )) && (
                        <DropdownItem
                          href="#"
                          onClick={() => this.exitFromSite(enrolement)}>
                          Enregistrer la sortie du site
                        </DropdownItem>
                      )}

                    <DropdownItem
                      href="#"
                      onClick={() => this.detailEnrolement(enrolement)}>
                      Voir les détails de l'enrolement
                    </DropdownItem>
                  </React.Fragment>
                </DropdownMenu>
              </UncontrolledDropdown>
            </td>
            <td
              className="clickable"
              onClick={() => this.showInformation(enrolement.menage)}
              // onCancel={this.hideFicheMenage}
            >
              {enrolement.menage.nomChef}
            </td>
            <td>
              {moment(enrolement.dateEntree, "YYYY-MM-DDTHH:mm:ss").format(
                "DD/MM/YYYY HH:mm"
              )}
            </td>
            <td>
              {enrolement.dateSortie
                ? moment(enrolement.dateSortie, "YYYY-MM-DDTHH:mm:ss").format(
                    "DD/MM/YYYY HH:mm"
                  )
                : "-----------------"}
            </td>
            <td>{enrolement.site.nom_site}</td>
            {/* <td></td>
            <td></td>
            <td></td>
            <td></td> */}
            <td>{enrolement.voina.libelle}</td>
            <td className="col-badges">
              <div className="badges-container">
                <Badge color={"secondary"}>{enrolement.don.join("-")}</Badge>
              </div>
            </td>
            <td>{enrolement.nombreDeplaces}</td>
          </tr>
        );
      });

    return (
      <>
        {this.state.showMoveToOtherSite && (
          <MoveToOtherSiteModal
            enrolement={this.state.selectedEnrolement}
            siteName={this.state.siteName}
            siteList={this.state.siteList}
            chefMenage={this.state.chefMenage}
            onCancel={this.onHideFormModal}
            onSave={this.onSave}
            // isUpdate={this.state.isUpdate}
            // articleList={this.state.articleList}
            // siteList={this.state.siteList}
          />
        )}
        {this.state.showExit && (
          <ExitFromSiteModal
            enrolement={this.state.selectedEnrolement}
            siteName={this.state.siteName}
            chefMenage={this.state.chefMenage}
            onCancel={this.onHideFormModal}
            onSave={this.onSave}
            // isUpdate={this.state.isUpdate}
            // articleList={this.state.articleList}
            // siteList={this.state.siteList}
          />
        )}
        {this.state.showDetailEnrolement && (
          <EnrolementDetailsModals
            enrolement={this.state.selectedEnrolement}
            siteName={this.state.siteName}
            chefMenage={this.state.chefMenage}
            onCancel={this.onHideFormModal}
          />
        )}
        {this.state.isInformation && (
          <InformationModal
            menage={this.state.currentMenage}
            close={this.hideInformation}
          />
        )}
        <div className="header bg-gradient-primary postition-absolute">
          <Container fluid>
            <div className="header-body">{/* Card stats */}</div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt-5 custom-card-container" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                    <Col lg="3">
                      <FormGroup className="mb-3 mb-xl-1">
                        <label
                          className="form-control-label"
                          htmlFor="input-username">
                          Site
                        </label>
                        <div className="input-group-alternative input-group">
                          <Input
                            type="select"
                            className="mdb-select md-form form-control"
                            onChange={this.handleChangeInputFilter}
                            name="siteId"
                            value={this.state.filtre.siteId}>
                            <option value={-1}>Tous les sites</option>
                            {siteOptions}
                          </Input>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col lg="3">
                      <FormGroup className="mb-3 mb-xl-1">
                        <label
                          className="form-control-label"
                          htmlFor="input-username">
                          Voina
                        </label>
                        <div className="input-group-alternative input-group">
                          <Input
                            type="select"
                            className="mdb-select md-form form-control"
                            onChange={this.handleChangeInputFilter}
                            name="voina"
                            value={this.state.filtre.voina}>
                            <option value={""}>Tous les voina</option>
                            {voinaOptions}
                          </Input>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col lg="3">
                      <FormGroup className="mb-3 mb-xl-1">
                        <label
                          className="form-control-label"
                          htmlFor="input-username">
                          Nom du chef
                        </label>
                        <div className="input-group-alternative input-group">
                          <input
                            placeholder="Nom du chef"
                            type="text"
                            className="form-control"
                            value={this.state.filtre.nomChefFiltre}
                            onChange={this.handleChangeInputFilter}
                            name="nomChefFiltre"
                            // onKeyDown={this.onPressEnter}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col lg="3">
                      <FormGroup className="mb-3 mb-xl-1">
                        <label
                          className="form-control-label"
                          htmlFor="input-username">
                          Date de sortie
                        </label>
                        <div className="input-group-alternative input-group">
                          <Input
                            type="select"
                            className="mdb-select md-form form-control"
                            onChange={this.handleChangeInputFilter}
                            name="avecDateSortie"
                            value={this.state.avecDateSortie}>
                            <option value={-1}>Tout Afficher...</option>
                            <option value={1}>Avec</option>
                            <option value={0}>Sans</option>
                          </Input>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Zonnage
                    handleChangeZonage={this.handleChangeZonage}
                    filter={this.state.filtre}
                  />

                  <Row>
                    <Col>
                      <FormGroup>
                        <Button
                          style={{ width: "100%", fontSize: 14 }}
                          className="btn"
                          color="primary"
                          width="100%"
                          onClick={this.onSearch}>
                          Rechercher
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <Table
                  className="align-items-center table-flush table table-sticky"
                  responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" className="p-1" />
                      <th scope="col">Chef de menage</th>
                      <th scope="col">Date et heure d'arrivée</th>
                      <th scope="col">Date sortie</th>
                      <th scope="col">Nom du site</th>
                      {/* <th scope="col">Region</th>
                      <th scope="col">District</th>
                      <th scope="col">Commune</th>
                      <th scope="col">Fokontany</th> */}
                      <th scope="col">Voina</th>
                      <th scope="col" className="col-badges">
                        Dons
                      </th>
                      <th scope="col">Nombre d'individu enrôlés</th>
                    </tr>
                  </thead>
                  <tbody>{tableData}</tbody>
                </Table>
                <CardFooter className="py-2 footer-pagination-container">
                  <label className="form-control-label">
                    <b>Total Page: </b> {this.state.totalPages}
                  </label>
                  <label className="form-control-label">
                    <b>Page courante: </b> {this.state.page + 1}
                  </label>
                  <label className="form-control-label">
                    {this.state.items} <b>enrollements sur </b>
                    {this.state.totalItems}
                  </label>

                  <div className="pagination-navigation-container">
                    <label className="form-control-label">
                      <b>Nombre de lignes</b>
                    </label>
                    <Input
                      id="pageSize"
                      type="select"
                      width={50}
                      style={{
                        display: "inline-block",
                        width: 80,
                        marginLeft: 10,
                        height: 40,
                      }}
                      value={this.state.pageSize}
                      onChange={this.handlePage}>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                    </Input>

                    <nav aria-label="...">
                      <Pagination
                        className="pagination pagination-lg justify-content-end mb-0"
                        listClassName="justify-content-end mb-0 pagination-lg">
                        <PaginationItem>
                          {this.state.page > 0 && (
                            <PaginationLink
                              href="#pablo"
                              onClick={this.previousPage}
                              tabIndex="-1">
                              <i className="fas fa-angle-left" />
                              <span className="sr-only">Previous</span>
                            </PaginationLink>
                          )}
                        </PaginationItem>
                        <PaginationItem
                          className={
                            this.state.page + 1 < this.state.totalPages
                              ? "active"
                              : "disabled"
                          }>
                          <PaginationLink href="#pablo" onClick={this.nextPage}>
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </nav>
                  </div>
                  {/* <div className="content-export-menage">
                    <Button onClick={this.exportData}>Exporter</Button>
                  </div> */}
                </CardFooter>
              </Card>
            </div>
          </Row>
          <PageSpinner show={this.state.isLoading} />
        </Container>
      </>
    );
  }
}

export default EnrolementPage;
