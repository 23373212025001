import axios from 'axios';
import { BACK_URL } from 'data/constant/urls';
import authHeader from './AuthHeader';

// const API_URL = process.env.REACT_APP_URL;
const API_URL = BACK_URL;

class MouvementTypeService {

    getAllMouvementType() {
        return axios.get(API_URL + `api/mouvementType/findAll`, {
            headers: authHeader(),
        });
    }

    findMouvementTypeByValue = (value) => {
        return axios.get(API_URL + `api/mouvementType/findMouvementTypeByValue?value=${value}`, {
            headers: authHeader(),
        });
    }

}

export default new MouvementTypeService();