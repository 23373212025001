import axios from 'axios';
import { BACK_URL } from 'data/constant/urls';
import authHeader from './AuthHeader';

// const API_URL = process.env.REACT_APP_URL;
const API_URL = BACK_URL;

class ExcelService {

    importExcel(data) {
        return axios.get(API_URL + "/api/menage/import", data, {
            headers: authHeader(),
        });
    }

    uploadFile(file, voinaId) {
        const formData = new FormData();
        formData.append("file", file);
        return axios.post(API_URL + `api/import/importbngrc?voinaId=${voinaId}`, formData, {
            headers: authHeader(),

        });
    }

    uploadFileNewFormat(file, voinaId) {
        const formData = new FormData();
        formData.append("file", file);
        return axios.post(API_URL + `api/import/importNewFormat?voinaId=${voinaId}`, formData, {
            headers: authHeader(),

        });
    }
    uploadFokontany(file) {
        const formData = new FormData();
        formData.append("file", file);
        return axios.post(API_URL + "api/import/importfokotany", formData, {
            headers: authHeader(),

        });
    }
}
export default new ExcelService();