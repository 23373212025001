import Zonage from "components/menage/Zonage";
import { user_editor, user_gestionnaire } from "data/constant/user.rights";
import moment from "moment";
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import ObservationService from "services/ObservationService";
import SiteTypeService from "services/SiteTypeService";
import ConfirmationModal from "views/components/ConfirmationModal";
import CreateEditObservationModal from "views/components/CreateEditObservationModal";
import Zonnage from "views/components/Zonnage";
import PageSpinner from "views/components/PageSpinner";

class ObservationPage extends React.Component {
  constructor(props) {
    super(props);
    this.onSave.bind(this);
    this.nextPage.bind(this);
    this.previousPage.bind(this);
    this.onSearch.bind(this);
    this.showConfirmationModal = this.showConfirmationModal.bind(this);
  }

  state = {
    content: [],
    ShowObservation: false,
    showConfirmation: false,
    showMenage: false,
    showFiche: false,
    showIndividu: false,
    isLoading: false,
    isUpdate: false,
    confirmationModal: false,
    listerIndividu: false,
    observationIdtoDelete: -1,
    page: 0,
    totalPage: 1,
    items: 0,
    totalitems: 0,
    activite: "",
    chefFamille: "",
    identifiantMenage: "",
    commune: "",
    fokotany: "",
    district: "",
    region: "",
    currentIndiviu: null,
    currentMenage: null,
    cf: false,
    pageSize: "10",
    filtre: {
      libelle: "",
      siteType: -1,
      regionId: -1,
      districtId: -1,
      communeId: -1,
      fokotanyId: -1,
    },
    checked: [],
    siteTypeList: [],
    confirmationModalDeleteMenages: false,
    isQRreader: false,
    isInformation: false,
    nom: "",
    prenom: "",
    cin: "",
    selectedLineIndex: null,
  };

  componentDidMount() {
    this.getListObservation();
    this.getSiteType();
  }

  handleChangeZonage = (situation) => {
    const { filtre } = this.state;
    filtre.communeId = situation.commune;
    filtre.districtId = situation.district;
    filtre.regionId = situation.region;
    filtre.fokotanyId = situation.fokontany;
    this.setState({ filtre });
  };

  handleChangeSiteType = (evt) => {
    const { value } = evt.target;
    this.setState({
      filtre: {
        ...this.state.filtre,
        siteType: value,
      },
    });
  };

  getSiteType = () => {
    SiteTypeService.getAllSiteType()
      .then((res) => res.data)
      .then((result) => {
        this.setState({
          siteTypeList: result.data,
        });
      });
  };

  onShowConfirmation = (id) => {
    this.setState({
      observationIdtoDelete: id,
      showConfirmation: true,
      confirmationTitle: "Voulez-vous vraiment supprimer cette observation ?",
    });
  };
  cancelConfirmation = () => {
    this.setState({
      showConfirmation: false,
    });
  };

  onSearch = () => {
    this.getListObservation();
  };

  onPressEnter = (event) => {
    if (event.key === "Enter") {
      this.onSearch();
    }
  };

  showConfirmationModal = () => {
    this.setState({
      confirmationModal: true,
      individuSelected: true,
    });
  };

  saveConfirmation = () => {
    const { observationIdtoDelete } = this.state;
    if (observationIdtoDelete !== -1) this.deleteObservation();
    this.setState({ showConfirmation: false });
  };

  deleteObservation = () => {
    const { observationIdtoDelete } = this.state;
    ObservationService.delete(observationIdtoDelete)
      .then((response) => response.data)
      .then(() =>
        this.setState({ isLoading: false, observationIdtoDelete: -1 }, () =>
          this.getListObservation()
        )
      )
      .catch((exception) =>
        this.setState({
          observationIdtoDelete: -1,
          isLoading: false,
          isError: true,
          errorMessage: exception.errorCode
            ? exception.errorMessage
            : "Contactez votre administrateur",
          errorTitle: "Erreur à la suppression d'une observation",
        })
      );
  };

  getListObservation() {
    this.setState({
      isLoading: true,
    });
    const page = parseInt(this.state.pageSize);
    ObservationService.getListByPagination(
      this.state.page,
      page,
      this.state.filtre
    ).then(
      (response) => {
        console.log("reposne", response);
        this.setState({
          content: response.data?.data,
          isLoading: false,
          items: response.data?.data?.content
            ? response.data?.data?.content?.length
            : 0,
          totalPage: response.data?.data?.totalPages,
          totalitems: response.data?.data?.totalElements,
        });
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  }

  handleChange = (evt) => {
    let value = evt.target.value;
    this.setState({
      filtre: {
        ...this.state.filtre,
        [evt.target.name]: value,
      },
    });
  };

  onSave = (data) => {
    this.getListObservation();
    this.setState(
      {
        ShowObservation: false,
      }
      // () => this.onSearch()
    );
  };

  //Nouvelle observation
  onShowObservation = (observation, isUpdate) => {
    this.setState({
      ShowObservation: true,
      isUpdate: isUpdate,
      selectedObservation: observation,
    });
  };

  onCloseModal = () => {
    this.setState({
      ShowObservation: false,
    });
  };

  nextPage = (e) => {
    e.preventDefault();
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => this.getListObservation()
    );
  };

  previousPage = () => {
    const page = parseInt(this.state.pageSize);
    this.setState(
      {
        page: this.state.page - 1,
      },
      () => this.getListObservation()
    );
  };

  handlePage = (event) => {
    this.setState(
      {
        items: 0,
        [event.target.id]: parseInt(event.target.value),
      },
      () => this.getListObservation()
    );
  };

  handleMenage = (data) => {
    this.setState({
      ...this.state,
      ...data,
      filtre: {
        ...this.state.filtre,
        ...data,
      },
    });
  };

  showInformation = (menage) => {
    this.setState({
      isInformation: true,
      currentMenage: menage,
    });
  };

  hideInformation = () => {
    this.setState({
      isInformation: false,
      currentMenage: {},
    });
  };

  render() {
    const siteTypeList =
      this.state.siteTypeList &&
      this.state.siteTypeList.map((elt) => (
        <option key={elt.id} value={elt.id}>
          {elt.name}
        </option>
      ));

    const tableData =
      this.state?.content?.content &&
      this.state?.content?.content.map((observation, index) => {
        return (
          <tr
            key={index}
            className={this.state.selectedLineIndex == index ? "selected" : ""}>
            {(user_gestionnaire.includes(
              localStorage.getItem("roleName") || ""
            ) ||
              user_editor.includes(localStorage.getItem("roleName") || "")) && (
              <td className="sticky-col sticky-left p-1">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light m-0"
                    href="#"
                    role="button"
                    size="sm"
                    color=""
                    outline={true}
                    style={{ zIndex: 1 }}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ selectedLineIndex: index });
                    }}>
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu
                    className="dropdown-menu-arrow"
                    container="body"
                    positionFixed={true}
                    right>
                    <React.Fragment>
                      {(user_gestionnaire.includes(
                        localStorage.getItem("roleName") || ""
                      ) ||
                        user_editor.includes(
                          localStorage.getItem("roleName") || ""
                        )) && (
                        <DropdownItem
                          href="#"
                          className="clickable"
                          onClick={() =>
                            this.onShowObservation(observation, true)
                          }>
                          Modifier observation
                        </DropdownItem>
                      )}

                      {user_gestionnaire.includes(
                        localStorage.getItem("roleName") || ""
                      ) && (
                        <DropdownItem
                          href="#"
                          className="clickable"
                          onClick={() =>
                            this.onShowConfirmation(observation.id)
                          }>
                          Supprimer l'observation
                        </DropdownItem>
                      )}
                    </React.Fragment>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            )}
            <td>
              {moment
                .utc(observation.dateObservation)
                .format("DD/MM/YYYY HH:mm")}
            </td>
            <td>{observation.libelle}</td>
            <td>{observation.site.nom_site}</td>
            <td>{observation.site.siteType.name}</td>
            <td>{observation.site.zonage.region.nom_region}</td>
            <td>{observation.site.zonage.districte.nom_districte}</td>
            <td>{observation.site.zonage.commune.nomCommune}</td>
            <td>{observation.site.zonage.fokotany.nom_fokotany}</td>
          </tr>
        );
      });
    const { showConfirmation } = this.state;
    return (
      <>
        {showConfirmation && (
          <ConfirmationModal
            onCancel={this.cancelConfirmation}
            onSave={this.saveConfirmation}
            message={this.state.confirmationTitle}
          />
        )}
        {this.state.ShowObservation && (
          <CreateEditObservationModal
            observation={this.state.selectedObservation}
            onCancel={this.onCloseModal}
            onSave={this.onSave}
            isUpdate={this.state.isUpdate}
          />
        )}

        <div className="header bg-gradient-primary postition-absolute">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row></Row>
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt-5 custom-card-container" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                    <Col>
                      <FormGroup className="mb-3 mb-xs-1">
                        <label
                          className="form-control-label"
                          htmlFor="input-username">
                          Libellé de l'observation
                        </label>
                        <div className="input-group-alternative input-group">
                          <input
                            placeholder="Libellé"
                            type="text"
                            className="form-control"
                            value={this.state.filtre.libelle}
                            onChange={this.handleChange}
                            name="libelle"
                            // onKeyDown={this.onPressEnter}
                          />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-username">
                          Type site
                        </label>
                        <div className="input-group-alternative input-group">
                          <Input
                            type="select"
                            className="mdb-select md-form form-control"
                            onChange={this.handleChangeSiteType}
                            name="typeSite"
                            value={this.state.filtre.siteType}>
                            <option value={""}>Tout Afficher</option>
                            {siteTypeList}
                          </Input>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Zonnage
                    handleChangeZonage={this.handleChangeZonage}
                    filter={this.state.filtre}
                  />

                  <Row>
                    <Col>
                      <FormGroup>
                        <Button
                          style={{ width: "100%", fontSize: 14 }}
                          className="btn"
                          color="primary"
                          width="100%"
                          onClick={this.onSearch}>
                          Rechercher
                        </Button>
                      </FormGroup>
                    </Col>
                    {user_editor.includes(
                      localStorage.getItem("roleName") || ""
                    ) && (
                      <React.Fragment>
                        <Col>
                          <FormGroup>
                            <Button
                              style={{ width: "100%", fontSize: 14 }}
                              className="btn"
                              onClick={() =>
                                this.onShowObservation(null, false)
                              }
                              color="success">
                              Nouvelle observation
                            </Button>
                          </FormGroup>
                        </Col>
                      </React.Fragment>
                    )}
                  </Row>
                </CardHeader>
                <Table
                  className="align-items-center table-flush table table-sticky"
                  responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" className="p-1" />
                      <th scope="col">Date de l'observation</th>
                      <th scope="col">Libellé d'observation</th>
                      <th scope="col">Site concerné</th>
                      <th scope="col">Type de site</th>
                      <th scope="col">Région</th>
                      <th scope="col">District</th>
                      <th scope="col">Commune</th>
                      <th scope="col">Fokontany</th>
                    </tr>
                  </thead>

                  <tbody>{tableData}</tbody>
                </Table>
                <CardFooter className="py-2 footer-pagination-container">
                  <label
                    className="form-control-label"
                    htmlFor="input-username">
                    <b>Total Page:</b> {this.state.totalPage}
                  </label>
                  <label
                    className="form-control-label"
                    htmlFor="input-username">
                    <b>Page courante:</b> {this.state.page + 1}
                  </label>
                  <label
                    className="form-control-label"
                    htmlFor="input-username">
                    {this.state.items} <b>observations sur</b>{" "}
                    {this.state.totalitems}
                  </label>
                  <div className="pagination-navigation-container">
                    <Label className="form-control-label">
                      <b>Nombre de lignes</b>
                    </Label>
                    <Input
                      id="pageSize"
                      type="select"
                      width={50}
                      style={{
                        display: "inline-block",
                        width: 80,
                        marginLeft: 10,
                        height: 40,
                      }}
                      value={this.state.pageSize}
                      onChange={this.handlePage}>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                    </Input>
                    <nav aria-label="...">
                      <Pagination
                        className="pagination pagination-lg justify-content-end mb-0"
                        listClassName="justify-content-end mb-0 pagination-lg">
                        <PaginationItem className="active">
                          {this.state.page > 0 && (
                            <PaginationLink
                              href="#"
                              onClick={this.previousPage}
                              tabIndex="-1">
                              <i className="fas fa-angle-left" />
                              <span className="sr-only">Previous</span>
                            </PaginationLink>
                          )}
                        </PaginationItem>
                        <PaginationItem
                          className={
                            this.state.page + 1 < this.state.totalPage
                              ? "active"
                              : "disabled"
                          }>
                          <PaginationLink href="#" onClick={this.nextPage}>
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </nav>
                  </div>
                </CardFooter>
              </Card>
            </div>
          </Row>
          <PageSpinner show={this.state.isLoading} />
        </Container>
      </>
    );
  }
}
export default ObservationPage;
