import React from 'react'
import {
    CardFooter,
    Row,
    Col,
    Label,
    Container,
    PaginationLink,
    Pagination,
    PaginationItem,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from 'reactstrap';

export const Footer = (props) => {
    return (
        <CardFooter className="py-2 footer-pagination-container" style={props.style || {}}>
            <label
                className="form-control-label"
                htmlFor="input-username"
            >
                <b>Total Page:</b> {props.getTotalPage()}
            </label>
            <Label
                className="form-control-label"
                htmlFor="input-username"
            >
                <b>Page courante:</b> {props.currentPage}
            </Label>
            <nav aria-label="...">
                <Pagination
                    className="pagination pagination-lg mb-0"
                    listClassName="mb-0 pagination-lg"
                >
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>Page n°</InputGroupText>
                        </InputGroupAddon>
                        <Input
                            id='currentPage'
                            value={parseInt(props.currentPage)}
                            onChange={props.handlePage}
                            min={1}
                            max={props.getTotalPage()}
                            type='number'
                            style={{ textAlign: 'center', backgroundColor: 'transparent' }}
                        />
                    </InputGroup>
                    <PaginationItem>
                        {props.currentPage > 1 && (
                            <PaginationLink
                                href="#pablo"
                                onClick={(event) => props.handleChangePage(event, props.currentPage - 1)}
                                tabIndex="-1"
                            >
                                <i className="fas fa-angle-left" />
                                <span className="sr-only">Previous</span>
                            </PaginationLink>
                        )}
                    </PaginationItem>
                    {props.currentPage < props.getTotalPage() &&
                        <PaginationItem
                            className={
                                "active"
                            }
                        >
                            <PaginationLink href="#" onClick={(event) => props.handleChangePage(event, props.currentPage + 1)}>
                                <i className="fas fa-angle-right" />
                                <span className="sr-only">Next</span>
                            </PaginationLink>
                        </PaginationItem>}

                </Pagination>
            </nav>
            {props.children ?
            <div className='d-flex align-items-center justify-content-center mt-2 mx-auto'>
                {props.children}
            </div> : ""
            }
        </CardFooter>
    )
}

export default Footer;
