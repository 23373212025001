import React from 'react';

const SuggestionMenage = (props) => {
  const options = props.results&& props.results.length> 0 && props.results.map(r => (
    <li key={r.id} className='list-group-item'>
      {r.adresse}
    </li>
  ))
  return props.show && <div className="list-group parent-div"> <ul className="child-div">{options}</ul></div>
}

export default SuggestionMenage;