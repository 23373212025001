import InformationGenerale from "components/enrolement/InformationGenerale";
import Enqueteur from "components/enrolement/Enqueteur";
import React from "react";
// reactstrap components
import { Button, Card, CardBody, Container, Row, Col } from "reactstrap";
import EnrolementEnregistrementServices from "services/EnrolementEnregistrementServices";
import PageSpinner from "views/components/PageSpinner";
import InformationSupplementaire from "components/enrolement/InformationSupplementaire";
import NotificationModal from "./NotificationModal";
import CardHeader from "reactstrap/lib/CardHeader";

class EnrolementEnregistrement extends React.Component {
  state = {
    enrolement: {
      hasJob: false,
      enqueteInfo: {
        enqueteur: "",
      },
      nombreDeplaces: 0,
      isInit: true,
    },
    requiredFields: {
      site: "",
      voina: "",
      enqueteur: "",
    },
    showNotification: false,
    isLoading: false,
    isError: false,
    errorMessage: "",
    errorTitle: "",
  };

  componentDidMount() {}

  formValid = () => {
    return false;
  };

  checkValidation = () => {
    const enqueteur =
      this.state.enrolement && this.state.enrolement.enqueteInfo.enqueteur
        ? ""
        : "champ obligatoire";
    const site =
      this.state.enrolement && this.state.enrolement.siteId != -1
        ? ""
        : "champ obligatoire";
    const voina =
      this.state.enrolement && this.state.enrolement.voinaId != -1
        ? ""
        : "champ obligatoire";
    // const district =
    //   this.state.menage && this.state.menage.district
    //     ? ""
    //     : "champ obligatoire";
    // const commune =
    //   this.state.menage && this.state.menage.commune ? "" : "champ obligatoire";
    // const fokotany =
    //   this.state.menage && this.state.menage.fokotany
    //     ? ""
    //     : "champ obligatoire";
    // const chef =
    //   this.state.menage &&
    //   this.state.menage.chef &&
    //   this.state.menage.chef.length > 0
    //     ? ""
    //     : "champ obligatoire";
    const requiredFields = {
      enqueteur,
      site,
      voina,
    };

    let result = true;
    this.setState({
      enrolement: {
        ...this.state.enrolement,
        enqueteInfo: {
          ...this.state.enrolement.enqueteInfo,
          requiredFields,
        },
      },
      requiredFields,
    });

    if (enqueteur.length > 0 || site.length > 0 || voina.length > 0) {
      result = false;
    }
    return result;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const data = this.requestDTO();
    if (this.checkValidation()) {
      this.processSave(data);
    }
  };

  requestDTO = () => {
    const data = { ...this.state.enrolement };
    console.log(">>>>>>>>>>> : ", data.dateEntree);
    const requestDTO = {
      dateEntree: data.dateEntree,
      dateSortie: data.dateSortie,
      nombreDeplaces: data.nombreDeplaces,
      siteId: data.siteId,
      hasJob: data.avoirMoyenDeSubsistance,
      enqueteInfo: {
        dateEnquete: data.enqueteInfo.dateEnquete,
        enqueteur: data.enqueteInfo.enqueteur,
      },
      voinaId: data.voinaId,
      sinistre: {
        ...data.sinistre,
      },
      menageId: data.menageId,
    };
    return requestDTO;
  };

  handleInformationGenerale = (data) => {
    this.setState(
      {
        enrolement: {
          ...this.state.enrolement,
          ...data,
        },
      },
      () => {
        if (!this.state.enrolement.isInit) this.checkValidation();
      }
    );
  };

  handleInformationSupplementaire = (data) => {
    this.setState(
      {
        enrolement: {
          ...this.state.enrolement,
          sinistre: {
            ...data,
          },
        },
      } //,
      // () => this.checkValidation()
    );
  };

  handleEnqueteur = (data) => {
    this.setState(
      {
        enrolement: {
          ...this.state.enrolement,
          enqueteInfo: {
            ...data,
          },
        },
      },
      () => this.checkValidation()
    );
  };

  closeErrorNotification = () => {
    this.setState({
      ...this.state,
      isError: false,
    });
  };

  closeNotification = () => {
    this.setState({
      showNotification: false,
    });
    // this.goToEnrolementList();
  };

  processSave = (data) => {
    this.setState({
      isLoading: true,
    });

    EnrolementEnregistrementServices.post(data).then(
      (response) => {
        if (response.data.code && response.data.code.toString() === "200") {
          this.setState({
            isLoading: false,
          });
          this.goToEnrolementList();
        } else if (
          response.data.code &&
          response.data.code.toString() === "400" &&
          response.data.message.toString() === "ENROLEMENT CREATION FAILED"
        ) {
          this.notificationMessage =
            "Le menage est déjà enregistré sur un site pour ce voina";
          this.notificationTitle = "Enrolement impossible";
          this.setState({
            showNotification: true,
            isLoading: false,
          });
        }
      },
      (error) => {
        // this.goToEnrolementList();
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  goToEnrolementList = () => {
    this.props.history.push({
      pathname: "/admin/enrolement",
    });
  };

  render() {
    return (
      <>
        <div className="header bg-gradient-primary postition-absolute"></div>
        {/* Page content */}
        <Container className="mt-5 custom-card-container mb-1" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="h2">Enrôler sur un site</CardHeader>
                <CardBody>
                  <InformationGenerale
                    {...this.props}
                    data={this.handleInformationGenerale}
                    requiredFieldsError={this.state.requiredFields}
                  />
                  <hr className="mt-0 mb-4" />
                  <Enqueteur
                    data={this.handleEnqueteur}
                    requiredFieldsError={this.state.requiredFields}
                  />
                  <hr className="mt-0 mb-4" />
                  <InformationSupplementaire
                    {...this.props}
                    nbIndividuMax={this.state.enrolement.nombreDeplaces}
                    data={this.handleInformationSupplementaire}
                  />
                </CardBody>
                <hr className="m-0" />
                <div className="modal-footer">
                  <Button
                    onClick={() =>
                      this.props.history.push(`/admin/gestion-menage`)
                    }
                  >
                    Annuler
                  </Button>
                  <Button className="btn-primary" onClick={this.handleSubmit}>
                    ENREGISTRER
                  </Button>
                </div>
              </Card>
            </div>
          </Row>
        </Container>
        <PageSpinner show={this.state.isLoading} />
        {this.state.showNotification && (
          <NotificationModal
            show={this.state.showNotification}
            message={this.notificationMessage}
            title={this.notificationTitle}
            close={this.closeNotification}
          />
        )}
        {this.state.isError && (
          <NotificationModal
            show={this.state.isError}
            message={this.state.errorMessage}
            title={this.state.errorTitle}
            close={this.closeErrorNotification}
          />
        )}
      </>
    );
  }
}

export default EnrolementEnregistrement;
