import axios from "axios";
import { BACK_URL } from "data/constant/urls";
import authHeader from "./AuthHeader";

// const API_URL = process.env.REACT_APP_URL;
const API_URL = BACK_URL;

class IndividuService {
  getList() {
    return axios.get(API_URL + "api/individu/findAll", {
      headers: authHeader(),
    });
  }

  findById(id) {
    return axios.get(API_URL + "api/individu/findIndividuById?id="+id, {
      headers: authHeader(),
    });
  }

  post(data) {
    return axios.post(API_URL + "api/individu/addindividu", data, {
      headers: authHeader(),
    });
  }

  delete(id){
    return axios.delete(API_URL + "api/individu/deleteindividu?id="+id, {
      headers: authHeader(),
    });
  }

  update(data) {
    return axios.put(API_URL + "api/individu/updateindividu", data, {
      headers: authHeader(),
    });
  }

  getListByPagination(pageNo, pageSize, nom, affiliation, etat=''){
    return axios.get(API_URL + "api/individu/findAllPagination?pageNo="+pageNo+
    '&pageSize='+pageSize+'&nom='+nom+'&affiliation='+affiliation+'&etat='+etat,
    {
      headers: authHeader(),
    });
  }

  findChefFamilleByMenageId(id){
    return axios.get(API_URL + "api/individu/findChefFamilleByMenage?id="+id,
    {
      headers: authHeader(),
    });
  }

  findIndividuByTraitementId(id){
    return axios.get(API_URL + "api/individu/findIndividuByTraitementId?id="+id,
    {
      headers: authHeader(),
    });
  }

  findFicheIndividuById(id){
    return axios.get(API_URL + "api/individu/findFicheIndividuById?id="+id,
    {
      headers: authHeader(),
    });
  }
  
  impression(id){
    const url = `${API_URL}api/individu/export?id=${id}`;
    const method = 'GET';
    const responseType= 'blob';
    return axios.request({
      url,
      method,
      responseType, 
      headers: authHeader()
    })
  }

  exportDatas(data) {
    const url = API_URL + "api/export/individus";
    const method = "POST";
    const responseType = "arraybuffer";
    return axios.request({
        url,
        method,
        responseType,
        data,
        headers: authHeader(),
    });
}
}

export default new IndividuService();
