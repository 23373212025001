export default [
  { id: "1", value: "Malagasy" },
  { id: "2", value: "Française" },
  { id: "3", value: "Americaine" },
  { id: "4", value: "Afghane" },
  { id: "5", value: "Albanaise" },
  { id: "6", value: "Algerienne" },
  { id: "7", value: "Allemande" },
  { id: "8", value: "Andorrane" },
  { id: "9", value: "Angolaise" },
  { id: "10", value: "Antiguaise et barbudienne" },
  { id: "11", value: "Argentine" },
  { id: "12", value: "Armenienne" },
  { id: "13", value: "Australienne" },
  { id: "14", value: "Autrichienne" },
  { id: "15", value: "Azerbaïdjanaise" },
  { id: "16", value: "Bahamienne" },
  { id: "17", value: "Bahreinienne" },
  { id: "18", value: "Bangladaise" },
  { id: "19", value: "Barbadienne" },
  { id: "20", value: "Belge" },
  { id: "21", value: "Belizienne" },
  { id: "22", value: "Beninoise" },
  { id: "23", value: "Bhoutanaise" },
  { id: "24", value: "Bielorusse" },
  { id: "25", value: "Birmane" },
  { id: "26", value: "Bissau Guinéenne" },
  { id: "27", value: "Bolivienne" },
  { id: "28", value: "Bosnienne" },
  { id: "29", value: "Botswanaise" },
  { id: "30", value: "Bresilienne" },
  { id: "31", value: "Britannique" },
  { id: "32", value: "Bruneienne" },
  { id: "33", value: "Bulgare" },
  { id: "34", value: "Burkinabe" },
  { id: "35", value: "Burundaise" },
  { id: "36", value: "Cambodgienne" },
  { id: "37", value: "Camerounaise" },
  { id: "38", value: "Canadienne" },
  { id: "39", value: "Cap verdienne" },
  { id: "40", value: "Centrafricaine" },
  { id: "41", value: "Chilienne" },
  { id: "42", value: "Chinoise" },
  { id: "43", value: "Chypriote" },
  { id: "44", value: "Colombienne" },
  { id: "45", value: "Comorienne" },
  { id: "46", value: "Congolaise" },
  { id: "47", value: "Costaricaine" },
  { id: "48", value: "Croate" },
  { id: "49", value: "Cubaine" },
  { id: "50", value: "Danoise" },
  { id: "51", value: "Djiboutienne" },
  { id: "52", value: "Dominicaine" },
  { id: "53", value: "Dominiquaise" },
  { id: "54", value: "Egyptienne" },
  { id: "55", value: "Emirienne" },
  { id: "56", value: "Equato guineenne" },
  { id: "57", value: "Equatorienne" },
  { id: "58", value: "Erythreenne" },
  { id: "59", value: "Espagnole" },
  { id: "60", value: "Est timoraise" },
  { id: "61", value: "Estonienne" },
  { id: "62", value: "Ethiopienne" },
  { id: "63", value: "Fidjienne" },
  { id: "64", value: "Finlandaise" },
  { id: "65", value: "Gabonaise" },
  { id: "66", value: "Gambienne" },
  { id: "67", value: "Georgienne" },
  { id: "68", value: "Ghaneenne" },
  { id: "69", value: "Grenadienne" },
  { id: "70", value: "Guatemalteque" },
  { id: "71", value: "Guineenne" },
  { id: "72", value: "Guyanienne" },
  { id: "73", value: "Haïtienne" },
  { id: "74", value: "Hellenique" },
  { id: "75", value: "Hondurienne" },
  { id: "76", value: "Hongroise" },
  { id: "77", value: "Indienne" },
  { id: "78", value: "Indonesienne" },
  { id: "79", value: "Irakienne" },
  { id: "80", value: "Irlandaise" },
  { id: "81", value: "Islandaise" },
  { id: "82", value: "Israélienne" },
  { id: "83", value: "Italienne" },
  { id: "84", value: "Ivoirienne" },
  { id: "85", value: "Jamaïcaine" },
  { id: "86", value: "Japonaise" },
  { id: "87", value: "Jordanienne" },
  { id: "88", value: "Kazakhstanaise" },
  { id: "89", value: "Kenyane" },
  { id: "90", value: "Kirghize" },
  { id: "91", value: "Kiribatienne" },
  { id: "92", value: "Kittitienne et nevicienne" },
  { id: "93", value: "Kossovienne" },
  { id: "94", value: "Koweitienne" },
  { id: "95", value: "Laotienne" },
  { id: "96", value: "Lesothane" },
  { id: "97", value: "Lettone" },
  { id: "98", value: "Libanaise" },
  { id: "99", value: "Liberienne" },
  { id: "100", value: "Libyenne" },
  { id: "101", value: "Liechtensteinoise" },
  { id: "102", value: "Lituanienne" },
  { id: "103", value: "Luxembourgeoise" },
  { id: "104", value: "Macedonienne" },
  { id: "105", value: "Malaisienne" },
  { id: "106", value: "Malawienne" },
  { id: "107", value: "Maldivienne" },
  { id: "108", value: "Malienne" },
  { id: "109", value: "Maltaise" },
  { id: "110", value: "Marocaine" },
  { id: "111", value: "Marshallaise" },
  { id: "112", value: "Mauricienne" },
  { id: "113", value: "Mauritanienne" },
  { id: "114", value: "Mexicaine" },
  { id: "115", value: "Micronesienne" },
  { id: "116", value: "Moldave" },
  { id: "117", value: "Monegasque" },
  { id: "118", value: "Mongole" },
  { id: "119", value: "Montenegrine" },
  { id: "120", value: "Mozambicaine" },
  { id: "121", value: "Namibienne" },
  { id: "122", value: "Nauruane" },
  { id: "123", value: "Neerlandaise" },
  { id: "124", value: "Neo zelandaise" },
  { id: "125", value: "Nepalaise" },
  { id: "126", value: "Nicaraguayenne" },
  { id: "127", value: "Nigeriane" },
  { id: "128", value: "Nigerienne" },
  { id: "129", value: "Nord coréenne" },
  { id: "130", value: "Norvegienne" },
  { id: "131", value: "Omanaise" },
  { id: "132", value: "Ougandaise" },
  { id: "133", value: "Ouzbeke" },
  { id: "134", value: "Pakistanaise" },
  { id: "135", value: "Palau" },
  { id: "136", value: "Palestinienne" },
  { id: "137", value: "Panameenne" },
  { id: "138", value: "Papouane neoguineenne" },
  { id: "139", value: "Paraguayenne" },
  { id: "140", value: "Peruvienne" },
  { id: "141", value: "Philippine" },
  { id: "142", value: "Polonaise" },
  { id: "143", value: "Portoricaine" },
  { id: "144", value: "Portugaise" },
  { id: "145", value: "Qatarienne" },
  { id: "146", value: "Roumaine" },
  { id: "147", value: "Russe" },
  { id: "148", value: "Rwandaise" },
  { id: "149", value: "Saint lucienne" },
  { id: "150", value: "Saint marinaise" },
  { id: "151", value: "Saint vincentaise et grenadine" },
  { id: "152", value: "Salomonaise" },
  { id: "153", value: "Salvadorienne" },
  { id: "154", value: "Samoane" },
  { id: "155", value: "Santomeenne" },
  { id: "156", value: "Saoudienne" },
  { id: "157", value: "Senegalaise" },
  { id: "158", value: "Serbe" },
  { id: "159", value: "Seychelloise" },
  { id: "160", value: "Sierra leonaise" },
  { id: "161", value: "Singapourienne" },
  { id: "162", value: "Slovaque" },
  { id: "163", value: "Slovene" },
  { id: "164", value: "Somalienne" },
  { id: "165", value: "Soudanaise" },
  { id: "166", value: "Sri lankaise" },
  { id: "167", value: "Sud africaine" },
  { id: "168", value: "Sud coréenne" },
  { id: "169", value: "Suedoise" },
  { id: "170", value: "Suisse" },
  { id: "171", value: "Surinamaise" },
  { id: "172", value: "Swazie" },
  { id: "173", value: "Syrienne" },
  { id: "174", value: "Tadjike" },
  { id: "175", value: "Taiwanaise" },
  { id: "176", value: "Tanzanienne" },
  { id: "177", value: "Tchadienne" },
  { id: "178", value: "Tcheque" },
  { id: "179", value: "Thaïlandaise" },
  { id: "180", value: "Togolaise" },
  { id: "181", value: "Tonguienne" },
  { id: "182", value: "Trinidadienne" },
  { id: "183", value: "Tunisienne" },
  { id: "184", value: "Turkmene" },
  { id: "185", value: "Turque" },
  { id: "186", value: "Tuvaluane" },
  { id: "187", value: "Ukrainienne" },
  { id: "188", value: "Uruguayenne" },
  { id: "189", value: "Vanuatuane" },
  { id: "190", value: "Venezuelienne" },
  { id: "191", value: "Vietnamienne" },
  { id: "192", value: "Yemenite" },
  { id: "193", value: "Zambienne" },
  { id: "194", value: "Zimbabweenne" }
];