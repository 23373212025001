import axios from "axios";
import { BACK_URL } from "data/constant/urls";
import authHeader from "./AuthHeader";

// const API_URL = process.env.REACT_APP_URL;
const API_URL = BACK_URL;

class SiteTypeService {
  getAllSiteType() {
    return axios.get(API_URL + `param/sitetype/findAll`, {
      headers: authHeader(),
    });
  }

  getAllUnitiesPagination() {
    return axios.get(API_URL + `api/unite/findAllPagination`, {
      headers: authHeader(),
    });
  }

  getUnityByLibelle(libelle) {
    return axios.get(API_URL + `api/unite/findByLibelle?libelle=${libelle}`, {
      headers: authHeader(),
    });
  }

  createUnity = (unity) => {
    return axios.post(API_URL + `api/unite/addUnite`, unity, {
      headers: authHeader(),
    });
  };

  updateUnity = (unity) => {
    return axios.put(API_URL + `api/unite/updateunite`, unity, {
      headers: authHeader(),
    });
  };

  deleteUnity = (id) => {
    return axios.delete(API_URL + `api/unite/deleteUnite?id=${id}`, {
      headers: authHeader(),
    });
  };
}

export default new SiteTypeService();
