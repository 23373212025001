import axios from "axios";
import { BACK_URL } from "data/constant/urls";
import authHeader from "./AuthHeader";
import { filterAndPageable } from "common/RequestUtils";
// const API_URL = process.env.REACT_APP_URL;
const API_URL = BACK_URL;

class ObservationService {
  create(data) {
    return new Promise(async (success, error) => {
      const result = await axios
        .post(`${API_URL}api/observation/addObservation`, data, {
          headers: authHeader(),
        })
        .catch((exception) => {
          error(exception.response && exception.response.data);
        });
      success(result);
    });
  }
  update(data) {
    return new Promise(async (success, error) => {
      const result = await axios
        .put(`${API_URL}api/observation/update`, data, {
          headers: authHeader(),
        })
        .catch((exception) => {
          error(exception.response && exception.response.data);
        });
      success(result);
    });
  }
  delete(id) {
    return new Promise(async (success, error) => {
      const result = await axios
        .delete(API_URL + `api/observation/delete?id=${id}`, {
          headers: authHeader(),
        })
        .catch((exception) => {
          error(exception.response && exception.response.data);
        });
      success(result);
    });
  }

  getListByPagination(page, size, filtre) {
    console.log("filtre", filtre);
    let data = [];
    filtre.libelle &&
      filtre.libelle.length > 0 &&
      data.push({ option: "libelle", value: filtre.libelle });
    filtre.siteType &&
      filtre.siteType !== -1 &&
      data.push({ option: "sitetype", value: filtre.siteType });
    filtre.regionId &&
      filtre.regionId !== -1 &&
      data.push({ option: "region", value: filtre.regionId });
    filtre.districtId &&
      filtre.districtId !== -1 &&
      data.push({ option: "district", value: filtre.districtId });
    filtre.communeId &&
      filtre.communeId !== -1 &&
      data.push({ option: "commune", value: filtre.communeId });
    filtre.fokotanyId &&
      filtre.fokotanyId !== -1 &&
      data.push({ option: "fokontany", value: filtre.fokotanyId });
    page && data.push({ option: "page", value: page });
    size && data.push({ option: "size", value: size });

    console.log("filtre",filterAndPageable(data));

    return axios.get(
      API_URL + "api/observation/findAllPagination" + filterAndPageable(data),
      {
        headers: authHeader(),
      }
    );
  }
}

export default new ObservationService();
