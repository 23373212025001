import React, { useMemo } from "react";
import ReportingContainer from "views/components/reporting/ReportingContainer";

function ReportingSinistre() {
  return (
    <>
      <ReportingContainer components={{
        nbSinistre: {
          tabTitle : "Nombre sinistrés",
          component: 
            useMemo(() => React.lazy(() => import('../components/reporting/NbSinistreChart')), [])
            // React.lazy(() => import("../components/reporting/NbSinitre"))
        }
      }} />
    </>
  );
}

export default ReportingSinistre;
