import axios from "axios";
import { authHeaderLoharano } from "./AuthHeader";
import { filterAndPageable } from "../common/RequestUtils";

const API_LOHARANO = "https://www.e-fokontany.gov.mg/api/loharano";

class eFokontanyService {
  searchByQRCode(bookNumber) {
    return axios.get(API_LOHARANO + `/households/${bookNumber}?include=address&include=citizens`, {
      headers: authHeaderLoharano()
    })
  }

  searchCitizen(filtre, page, size) {
    let data = [];
    filtre.nom && filtre.nom.length > 0 && data.push({ option: "firstName", value: filtre.nom });
    filtre.prenom && filtre.prenom.length > 0 && data.push({ option: "lastName", value: filtre.prenom });
    filtre.cin && filtre.cin.length > 0 && data.push({ option: "cni", value: filtre.cin });
    filtre.fokontanyId && filtre.fokontanyId.length > 0 && data.push({ option: "fokontanyId", value: filtre.fokontanyId });

    // champ obligatoire
    data.push({ option: "minimize", value: "false" });
    data.push({ option: "include", value: "address" });
    data.push({ option: "include", value: "household" });
    data.push({ option: "page", value: page ? page : 1 });
    data.push({ option: "size", value: size ? size : 100 });

    // return null;
    return axios.get(API_LOHARANO + "/citizens" + filterAndPageable(data), {
      headers: authHeaderLoharano(),
    });
  }
}

export default new eFokontanyService();
