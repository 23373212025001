import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  Modal,
  Label,
} from "reactstrap";
import { Form } from "react-bootstrap";
import moment from "moment";

import NotificationModal from "./NotificationModal";
import PageSpinner from "./PageSpinner";
import NeedService from "services/NeedService";

class CreateEditNeedModal extends React.Component {
  priorities = [
    {
      id: 1,
      name: "Urgent",
    },
    {
      id: 2,
      name: "Critique",
    },
    {
      id: 3,
      name: "Mineur",
    },
  ];

  states = ["NOUVEAU", "EN COURS DE TRAITEMENT", "TRAITÉ", "IMPOSSIBLE"];

  state = {
    showNotification: false,
    isLoading: false,
    title: "CREATION D'UN BESOIN",
    need: {
      articleId: -1,
      etat: "",
      id: 0,
      priorite: -1,
      qteArticle: "0",
      siteId: -1,
    },
    isError: false,
    errorMessage: "",
    errorTitle: "",
    isUpdate: false,
    isSiteEmpty: false,
    isArticleEmpty: false,
    isPriorityEmpty: false,
    isStateEmpty: false,
    isQuantityEmpty: false,
    isInit: true,
    isInProgressing: false,
    isTreated: false,
    isStillNew: false,
    isNew: false,
    stateBeforeUpdate: "",
  };

  closeErrorNotification = () => {
    this.setState({
      ...this.state,
      isError: false,
    });
  };

  closeNotification = () => {
    this.setState({
      showNotification: false,
    });
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  componentDidMount() {
    if (this.props.isUpdate) {
      this.getNeedById(this.props.need);
    } else {
      const { need } = this.state;
      need.dateSave = moment().format("DD/MM/YYYY");
      this.setState({ need });
    }
  }

  getNeedById = (id) => {
    this.setState({
      isLoading: true,
    });
    NeedService.getById(id)
      .then((response) => {
        console.log(response);
        this.setState(
          {
            need: response?.data,
            title: "MODIFICATION D'UN BESOIN",
            isLoading: false,
            isInProgressing: response.data.etat === "EN COURS DE TRAITEMENT",
            isTreated: response.data.etat === "TRAITE",
            isNew: response.data.etat === "NOUVEAU",
            stateBeforeUpdate: response.data.etat,
          },
          () => console.log(this.state.need)
        );
      })
      .catch((exception) => {
        this.setState({
          isLoading: false,
          isError: true,
          errorMessage: exception.errorCode
            ? exception.errorMessage
            : "Contactez votre administrateur",
          errorTitle: "Erreur à la recherche d'un site",
        });
      });
  };

  formValid = () => {
    const { need } = this.state;
    let {
      isSiteEmpty,
      isArticleEmpty,
      isPriorityEmpty,
      isStateEmpty,
      isQuantityEmpty,
      isStillNew,
    } = this.state;
    isSiteEmpty = need.siteId == -1;
    isArticleEmpty = need.articleId == -1;
    isPriorityEmpty = need.priorite == -1;
    isStateEmpty = need.etat == "";
    isQuantityEmpty = need.qteArticle == 0 || need.qteArticle == "";
    isStillNew =
      this.props.isUpdate &&
      need.etat === "NOUVEAU" &&
      this.state.stateBeforeUpdate === "NOUVEAU";

    if (this.props.isUpdate) {
      this.setState({
        isSiteEmpty,
        isStateEmpty,
        isPriorityEmpty,
        isQuantityEmpty,
        isArticleEmpty,
        isStillNew,
      });
    } else {
      this.setState({
        isSiteEmpty,
        isPriorityEmpty,
        isQuantityEmpty,
        isArticleEmpty,
      });
    }
    console.log("Form Validation");
  };

  handleSubmit = (event) => {
    this.formValid();
    const { need } = this.state;

    if (this.props.isUpdate) {
      if (
        need.siteId != -1 &&
        need.articleId != -1 &&
        need.priorite != -1 &&
        need.etat != "" &&
        need.qteArticle != 0 &&
        need.qteArticle != "" &&
        (need.etat !== "NOUVEAU" || this.state.stateBeforeUpdate !== "NOUVEAU")
      ) {
        console.log("process update");
        this.processUpdate();
      }
    } else {
      if (
        need.siteId != -1 &&
        need.articleId != -1 &&
        need.priorite != -1 &&
        need.qteArticle != 0 &&
        need.qteArticle != ""
      ) {
        console.log("process save");
        this.processSave();
      }
    }
  };

  processSave = () => {
    this.setState({
      isLoading: true,
    });
    const { need } = this.state;

    NeedService.create(need)
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        if (response.data.code && response.data.code.toString() === "200") {
          this.props.onSave();
        } else if (
          response.data.code &&
          response.data.code.toString() === "400" &&
          response.data.message.toString() === " ALREADY EXIST"
        ) {
          this.notificationMessage = "Le site exite déjà";
          this.notificationTitle = "Création site impossible";
          this.setState({
            showNotification: true,
            isLoading: false,
          });
        }
      })
      .catch((exception) => {
        this.setState({
          isLoading: false,
          isError: true,
          errorMessage: exception.errorCode
            ? exception.errorMessage
            : "Contactez votre administrateur",
          errorTitle: "Erreur à l'ajout d'un site",
        });
      });
  };

  processUpdate = () => {
    this.setState({
      isLoading: true,
    });

    NeedService.update(this.state.need)
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        if (response.data.code && response.data.code.toString() === "200") {
          this.props.onSave();
        } else if (
          response.data.code &&
          response.data.code.toString() === "400" &&
          response.data.message.toString() === "SITE ALREADY EXIST"
        ) {
          this.notificationMessage = "Le site exite déjà";
          this.notificationTitle = "Création site impossible";
          this.setState({
            showNotification: true,
            isLoading: false,
          });
        }
      })
      .catch((exception) => {
        this.setState({
          isLoading: false,
          isError: true,
          errorMessage: exception.errorCode
            ? exception.errorMessage
            : "Contactez votre administrateur",
          errorTitle: "Erreur à la modification d'un site",
        });
      });
  };

  handleChangeDate = (date, id) => {
    let value = "";
    if (date.isValid) {
      value = date.format("DD/MM/YYYY");
    }
    const { need } = this.state;
    need[id] = value;
    this.setState({ need });
  };

  handleChangeInput = (event) => {
    const { need } = this.state;
    need[event.target.name] = event.target.value;
    this.setState({ need }, () => this.formValid());
  };

  render() {
    const articleOptions =
      this.props.articleList &&
      this.props.articleList.map((elt, idx) => (
        <option key={elt.id} value={elt.id}>
          {elt.libelle}
        </option>
      ));

    const siteOptions =
      this.props.siteList &&
      this.props.siteList.map((elt, idx) => (
        <option key={elt.id} value={elt.id}>
          {elt.nom_site}
        </option>
      ));

    const priorityOptions = this.priorities.map((elt, idx) => (
      <option key={elt.id} value={elt.id}>
        {elt.name}
      </option>
    ));

    const stateOptions = this.states.map((elt, idx) => (
      <option
        key={idx}
        value={elt}
        disabled={this.props.isUpdate && this.state.isNew && elt === "NOUVEAU"}
      >
        {elt}
      </option>
    ));

    return (
      <>
        <PageSpinner show={this.state.isLoading} />
        <Modal
          className="modal-dialog-centered"
          isOpen={true}
          style={{ zIndex: "-100" }}
        >
          <div className="modal-body p-0">
            <Card className=" border-0">
              <CardHeader className="bg-light-secondary pb-0">
                <div className="text-muted text-center mt-2 mb-3 ">
                  <h2>{this.state.title}</h2>
                </div>
              </CardHeader>
              <CardBody className="px-lg-4 py-lg-2">
                <Form>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Site concerné *
                    </Label>
                    <div className="input-group-alternative input-group">
                      <Input
                        type="select"
                        className={
                          this.state.isSiteEmpty
                            ? "form-control-alternative-error mdb-select md-form form-control"
                            : "mdb-select md-form form-control"
                        }
                        onChange={this.handleChangeInput}
                        name="siteId"
                        value={this.state.need?.siteId}
                        disabled={
                          this.props.isUpdate &&
                          (this.state.isInProgressing || this.state.isTreated)
                        }
                      >
                        <option value={-1}></option>
                        {siteOptions}
                      </Input>
                    </div>
                    {this.state.isSiteEmpty && (
                      <small className="text-danger">Champ obligatoire</small>
                    )}
                  </FormGroup>

                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Article *
                    </Label>
                    <div className="input-group-alternative input-group">
                      <Input
                        type="select"
                        className={
                          this.state.isArticleEmpty
                            ? "form-control-alternative-error mdb-select md-form form-control"
                            : "mdb-select md-form form-control"
                        }
                        onChange={this.handleChangeInput}
                        name="articleId"
                        value={this.state.need?.articleId}
                        disabled={
                          this.props.isUpdate &&
                          (this.state.isInProgressing || this.state.isTreated)
                        }
                      >
                        <option value={-1}></option>
                        {articleOptions}
                      </Input>
                    </div>
                    {this.state.isArticleEmpty && (
                      <small className="text-danger">Champ obligatoire</small>
                    )}
                  </FormGroup>

                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Priorité *
                    </Label>
                    <div className="input-group-alternative input-group">
                      <Input
                        type="select"
                        className={
                          this.state.isPriorityEmpty
                            ? "form-control-alternative-error mdb-select md-form form-control"
                            : "mdb-select md-form form-control"
                        }
                        onChange={this.handleChangeInput}
                        name="priorite"
                        value={this.state.need?.priorite}
                        disabled={this.props.isUpdate && this.state.isTreated}
                      >
                        <option value={-1}></option>
                        {priorityOptions}
                      </Input>
                    </div>
                    {this.state.isPriorityEmpty && (
                      <small className="text-danger">Champ obligatoire</small>
                    )}
                  </FormGroup>

                  {this.props.isUpdate && (
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Etat *
                      </Label>
                      <div className="input-group-alternative input-group">
                        <Input
                          type="select"
                          className={
                            this.state.isStateEmpty ||
                            (this.props.isUpdate && this.state.isStillNew)
                              ? "form-control-alternative-error mdb-select md-form form-control"
                              : "mdb-select md-form form-control"
                          }
                          onChange={this.handleChangeInput}
                          name="etat"
                          value={this.state.need?.etat}
                          disabled={this.props.isUpdate && this.state.isTreated}
                        >
                          <option value=""></option>
                          {stateOptions}
                        </Input>
                      </div>
                      {this.state.isStateEmpty && (
                        <small className="text-danger">Champ obligatoire</small>
                      )}

                      {this.props.isUpdate && this.state.isStillNew && (
                        <small className="text-danger">
                          Vous devez choisir un autre état autre que NOUVEAU
                        </small>
                      )}
                    </FormGroup>
                  )}

                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Quantité *
                    </Label>
                    <div className="input-group-alternative input-group">
                      <Input
                        className={
                          this.state.isQuantityEmpty
                            ? "form-control-alternative-error mdb-select md-form form-control"
                            : "mdb-select md-form form-control"
                        }
                        type="number"
                        name="qteArticle"
                        id="qteArticle"
                        placeholder="Qté"
                        onChange={this.handleChangeInput}
                        value={this.state.need?.qteArticle}
                        disabled={this.props.isUpdate && this.state.isTreated}
                      />
                    </div>
                    {this.state.isQuantityEmpty && (
                      <small className="text-danger">Champ obligatoire</small>
                    )}
                  </FormGroup>
                </Form>
              </CardBody>
              <hr className="m-0" />
              <div className="modal-footer">
                {this.state.isError && <span>(*) </span>}
                <Button type="button" onClick={this.props.onCancel}>
                  Annuler
                </Button>
                {!this.state.isTreated && (
                  <Button
                    type="button"
                    color="primary"
                    onClick={this.handleSubmit}
                  >
                    <span>Enregistrer</span>
                  </Button>
                )}
              </div>
            </Card>
          </div>
        </Modal>
        {this.state.showNotification && (
          <NotificationModal
            show={this.state.showNotification}
            message={this.notificationMessage}
            title={this.notificationTitle}
            close={this.closeNotification}
          />
        )}
        {this.state.isError && (
          <NotificationModal
            show={this.state.isError}
            message={this.state.errorMessage}
            title={this.state.errorTitle}
            close={this.closeErrorNotification}
          />
        )}
      </>
    );
  }
}

export default CreateEditNeedModal;
