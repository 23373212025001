import React from "react";
import {
  Button, Modal, ModalBody, ModalFooter, ModalHeader, FormGroup,
  Input,
  Row,
  Col
} from "reactstrap";
import ExcelService from "services/ExcelService";
import VoinaService from "services/VoinaService";
import PageSpinner from "views/components/PageSpinner";

class ImportExcel extends React.Component {

  state = {
    selectedFiles: undefined,
    currentFile: undefined,
    isLoading: false,
    progress: 0,
    message: "",
    file: {},
    show: true,
    fileInfos: [],
    nbSucces: "",
    filtre: {
      voina: "",
    },
    requerdField: { voina: "" },
    unresolvedRow: [],
  };

  componentDidMount() {
    this.initialisation();
  }

  initialisation() {
    this.getVoinaListFromWs();
    // this.getList();
  }

  getVoinaListFromWs = () => {
    VoinaService.getAllVoina()
      .then((response) => response.data)
      .then((result) => {
        this.setState(
          {
            voinaList: result.data || [],
            isLoading: false,
          },
          () => this.setDefaultVoina()
        );
      });
    // .catch(() => {
    //   this.getList();
    //   this.setState({
    //     isLoading: false,
    //   });
    // });
  };

  setDefaultVoina() {
    //recuperation du voina par defaut ..
    VoinaService.getDefaultVoina().then((result) => {
      if (result.data.data) {
        this.setState(
          {
            filtre: {
              ...this.state.filtre,
              voina: result.data.data.libelle,
            },
          }
        );
      }
    });
  }

  componentWillReceiveProps() {
    this.setState({
      show: true,
      message: "",
      unresolvedRow: [],
      nbSucces: '',
    });
  }

  selectFile = (e) => {
    e.preventDefault();
    // let file = e.target.files[0]
    this.setState({
      selectedFiles: e.target.files,
      file: e.target.files[0],
    });
    const file =
      e.target.files && e.target.files.length > 0 && e.target.files[0];
    if (file) {
      const size = file.size;
      const name = file.name;
      let format = name.split(".");
      const longueur = format.length;
      format = format[longueur - 1].toLowerCase();
      let iSize = parseInt(size, 10);
      iSize = iSize / 1000;
      /** file format */
      if (this.props.data == "data") {
        if (format !== "xls") {
          this.setState({
            message: "Format xls requis !",
            selectedFiles: false,
          });
          return;
        } else {
          this.setState({
            message: "",
            selectedFiles: true,
          });
        }
      } else if (this.props.data == "dataNew") {
        if (format !== "xlsx") {
          this.setState({
            message: "Format xlsx requis !",
            selectedFiles: false,
          });
          return;
        } else {
          this.setState({
            message: "",
            selectedFiles: true,
          });
        }
      }
      /** file size */
      if (iSize > 10000) {
        this.setState({
          message: "Fichier trop volumineux! la taille acceptée est de 10 Mo",
          selectedFiles: false,
        });
      } else {
        this.setState({
          message: "",
          selectedFiles: true,
        });
      }
    }
  };

  handleUpload = async () => {
    if (this.checkValidation()) {
      let currentFileTest = this.state.file && this.state.file;
      try {
        this.setState({
          progress: 0,
          currentFile: currentFileTest,
          isLoading: true,
        });

        const res = await ExcelService.uploadFile(this.state.file, this.state.filtre.voina);

        if (res) {
          this.setState({
            isLoading: false,
            message: "importation avec succès",
            unresolvedRow: res.data.unresolvedRow ? res.data.unresolvedRow : [],
            nbSucces: res.data.nbSucces ? res.data.nbSucces : "",
            show: false,
          });
        } else {
          this.setState({
            isLoading: false,
            message: res.data.message,
            unresolvedRow: res.data.unresolvedRow ? res.data.unresolvedRow : [],
            nbSucces: res.data.nbSucces ? res.data.nbSucces : "",
            show: false,
          });
        }
      } catch (error) {
        this.setState({
          isLoading: false,
          message: error.error ? error.error : "Internal server error",
          show: false,
        });
      }
    }

  };

  handleUploadNewData = async () => {
    if (this.checkValidation()) {
      let currentFileTest = this.state.file && this.state.file;
      try {
        this.setState({
          progress: 0,
          currentFile: currentFileTest,
          isLoading: true,
        });

        const res = await ExcelService.uploadFileNewFormat(this.state.file, this.state.filtre.voina);

        if (res) {
          this.setState({
            isLoading: false,
            message: "importation avec succès",
            unresolvedRow: res.data.unresolvedRow ? res.data.unresolvedRow : [],
            nbSucces: res.data.nbSucces ? res.data.nbSucces : "",
            show: false,
          });
        } else {
          this.setState({
            isLoading: false,
            message: res.data.message,
            unresolvedRow: res.data.unresolvedRow ? res.data.unresolvedRow : [],
            nbSucces: res.data.nbSucces ? res.data.nbSucces : "",
            show: false,
          });
        }
      } catch (error) {
        this.setState({
          isLoading: false,
          message: error.error ? error.error : "Internal server error",
          show: false,
        });
      }
    }

  };

  checkValidation = () => {
    const validationVoina = this.state.filtre.voina !== "" ? "" : "Champ obligatoire";
    this.setState(
      {
        requerdField: {
          ...this.state.requerdField,
          voina: validationVoina,
        },
      }
    );
    return validationVoina == "";

  };

  handleChangeInputFilter = (event) => {
    this.setState(
      {
        filtre: {
          ...this.state.filtre,
          [event.target.name]: event.target.value,
        },
      },
      () => {
        this.checkValidation();
      }
    );
  };

  handleUploadFokontany = async () => {
    let currentFileTest = this.state.file && this.state.file;
    try {
      this.setState({
        progress: 0,
        currentFile: currentFileTest,
        isLoading: true,
      });

      const res = await ExcelService.uploadFokontany(this.state.file);

      if (res) {
        this.setState({
          isLoading: false,
          message: 'importation des "fokontany" avec succès',
        });
      } else {
        this.setState({
          isLoading: false,
          message: res.data.message,
        });
      }
    } catch (error) {

      if (error.response.data.errorCode === "ERR_FORMAT_VALUE") {
        this.setState({
          isLoading: false,
          message: error.response.data.precision,
        });
      } else if (error.response.data.error === "Internal Server Error") {
        this.setState({
          isLoading: false,
          message: "Echec de l'importation : " + error.response.data.error,
        });
      } else {
        this.setState({
          isLoading: false,
          message: error.response.data.errorMessage,
        });
      }
    }

  };

  render() {
    const {
      selectedFiles,
      currentFile,
      progress,
      message,
      fileInfos,
    } = this.state;

    const voinaOptions =
      this.state.voinaList &&
      this.state.voinaList.map((elt, idx) => (
        <option key={elt.id} value={elt.id}>
          {elt.libelle}
        </option>
      ));

    const ListeErreur = this.state.unresolvedRow.map((elt, idx) => {
      const data = elt && elt !== "" && elt.split(",");
      return (
        <tr key={idx}>
          <td>{data && data[0]}</td>
          <td>{data && data[1]}</td>
        </tr>
      );
    });
    return (
      <Modal isOpen={this.props.showModal} className="modal-check" fade={true}>
        <ModalHeader toggle={this.props.toggle}>
          Import de fichier excel
        </ModalHeader>
        <ModalBody>
          {currentFile && <PageSpinner show={this.state.isLoading} />}

          <input type="file" onChange={this.selectFile} />

          <div className="alert alert-light" role="alert">
            {message}
          </div>
          {this.state.nbSucces !== "" && (
            <>
              <label className="form-control-label" htmlFor="input-username">
                Nombre de ligne importé : {this.state.nbSucces}{" "}
                <i
                  className="ni ni-check-bold"
                  style={{ color: "green", fontSize: "20px" }}
                />
              </label>
            </>
          )}
          {this.state.unresolvedRow.length > 0 && (
            <>
              <label className="form-control-label" htmlFor="input-username">
                Liste de données malremplies sur le fichier excel :{" "}
                <i
                  className="ni ni-fat-remove"
                  style={{ color: "red", fontSize: "20px" }}
                />
              </label>
              <div className="table-error">
                <table className="table ">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-white">N° ligne</th>
                      <th className="text-white">Données à vérifier</th>
                    </tr>
                  </thead>
                  <tbody>{ListeErreur}</tbody>
                </table>
              </div>
            </>
          )}
          {(this.props.data == "data" || this.props.data == "dataNew") && (
            <>
              <Row>
                <Col>
                  <FormGroup className="mb-3 mb-xl-1">
                    <label
                      className="form-control-label"
                      htmlFor="input-username">
                      Voina
                    </label>
                    <div className="input-group-alternative input-group">
                      <Input
                        type="select"
                        className="mdb-select md-form form-control"
                        onChange={this.handleChangeInputFilter}
                        name="voina"
                        value={this.state.filtre.voina}>
                        <option value={""}>Tous les voina</option>
                        {voinaOptions}
                      </Input>
                    </div>
                    {this.state.requerdField.voina !== "" &&
                      <span className="text-danger">{this.state.requerdField.voina}</span>}
                  </FormGroup>
                </Col>
              </Row>

            </>
          )}
        </ModalBody>

        <ModalFooter>
          {/* <Button className="btn btn-primary" disabled={!selectedFiles} onClick={this.handleUpload("fokontany")}>Import fokontany</Button> */}
          {this.props.data == "data" && (
            <Button
              className="btn btn-primary"
              disabled={!selectedFiles}
              onClick={this.handleUpload}
            >

              Import données
            </Button>
          )}
          {this.props.data == "dataNew" && (
            <Button
              className="btn btn-primary"
              disabled={!selectedFiles}
              onClick={this.handleUploadNewData}
            >

              Import noveau format
            </Button>
          )}
          {this.props.fokontany == "fokontany" && (
            <Button
              className="btn btn-primary"
              disabled={!selectedFiles}
              onClick={this.handleUploadFokontany}
            >
              Import fokontany
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}
export default ImportExcel;
