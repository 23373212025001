import React, { useMemo } from "react";
import ReportingContainer from "views/components/reporting/ReportingContainer";

function ReportingSite() {
  return (
    <>
      <ReportingContainer
        components={{
          siteOccupationRate: {
            tabTitle: "Taux d'occupation",
            component: useMemo(
              () =>
                React.lazy(() =>
                  import("../components/reporting/SiteOccupationRate")
                ),
              []
            ),
            // React.lazy(() => import("../components/reporting/SiteOccupationRate")),
          },
          nbSite: {
            tabTitle: "Nombre de sites",
            component: useMemo(
              () =>
                React.lazy(() => import("../components/reporting/NombreSite")),
              []
            ),
            // React.lazy(() => import("../components/reporting/NombreSite")),
          },
          // entranceEvolution: {
          //   tabTitle: "Evolution du nombre d'entrée",
          //   component: useMemo(
          //     () =>
          //       React.lazy(() =>
          //         import("../components/reporting/EvolutionEntree")
          //       ),
          //     []
          //   ),
          //   // React.lazy(() => import("../components/reporting/NombreSite")),
          // },
        }}
      />
    </>
  );
}

export default ReportingSite;
