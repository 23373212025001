import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
const TypeSiteModal = (props) => {
    const save = () => {
        if (props.selectedTypeSite && props.selectedTypeSite.id && props.selectedTypeSite.id > 0) {
            props.update();
            props.toggle();
        } else {
            props.save();
            props.toggle();
        }
    }

    return (
        <Modal isOpen={props.showModal} toggle={props.toggle} className='modal-check' fade={true}>
            <ModalHeader toggle={props.toggle}>Ajout de nouveau type de site</ModalHeader>
            <ModalBody style={{ backgroundColor: 'transparent' }}>
                <FormGroup>

                    <Label for="typesite">Nom du type de site</Label>
                    <Input
                        type="text"
                        name="typesite"
                        id="typesite"
                        placeholder="Nom du typesite"
                        value={(props.selectedTypeSite && props.selectedTypeSite.name) || ''}
                        onChange={props.handleChange}
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={save}>Enregistrer</Button>{' '}
                <Button color="secondary" onClick={props.toggle}>Annuler</Button>
            </ModalFooter>
        </Modal >
    )
}

export default TypeSiteModal;

