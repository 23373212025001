export const user_viewer = [
  "superadmin",
  "adminadd",
  "adminconsult",
  "adminedit",
  "admines",
];

export const user_fullRights = ["superadmin"];

export const user_editor = ["superadmin", "adminedit"];

export const user_gestionnaire = ["superadmin", "adminadd"];

export const user_respes = [
  "superadmin",
  "adminedit",
  "adminadd",
  "adminconsult",
];

export const user_gest_respes = ["superadmin", "adminadd","admines"];
