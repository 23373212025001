import FooterTable from "components/Footer/Footer";
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Label,
} from "reactstrap";
import partenaireService from "services/PartenaireService";
import articleService from "services/ArticleService";
import inventoryService from "services/InventoryService";
import mouvementTypeService from "services/MouvementTypeService";
import siteMedicalService from "services/SiteService";
import StockModal from "views/components/StockModal";
import TransfertModal from "views/components/TransfertModal";
import PageSpinner from "views/components/PageSpinner";
import { TRANSFERT } from "data/constant/data-type";
import { Download } from "helper/ImpressionHelper";
import BoardService from "services/BoardService";

class ArticlesPage extends React.Component {
  state = {
    selectedArticle: {},
    stockList: [],
    currentPage: 1,
    totalPage: 1000,
    pageSize: 10,
    showModal: false,
    showModalTransfert: false,
    date: "",
    idSite: "",
    idArticle: "",
    articleList: [],
    selectedStock: {},
    selectedHistorique: {
      article: {},
      date: "",
      id: 0,
      mouvementType: {},
      partenaire: {},
      quantity: 0,
      quantityPlanned: 0,
      siteDestinataire: {},
      siteSource: {},
    },
    siteList: [],
    isSuperior: false,
    isRegister: false,
    isTransfertSuperior: false,
    isCancel: false,
    siteMedicalId: -1,
    partenaireId: -1,
    articleId: -1,
    selectedLineIndex: null,
  };

  // Function for WS ********************************
  getStockListFromWs = () => {
    this.setState({ isLoading: true });
    const { partenaireId, articleId, siteMedicalId, currentPage, pageSize } =
      this.state;
    inventoryService
      .getStockByPaginationByFilter(
        partenaireId,
        articleId,
        siteMedicalId,
        currentPage - 1,
        pageSize
      )
      .then((response) => response.data)
      .then((result) => {
        const stockList = (result.data && result.data.stockDTOs) || [];
        const totalPage = (result.data && result.data.totalPage) || 0;
        this.setState({
          stockList,
          isLoading: false,
          totalPage,
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  addInventoryByList = () => {
    this.setState({ isLoading: true });
    const { stockList } = this.state;
    inventoryService
      .addInventoryByList(stockList)
      .then((result) => result.data)
      .then(() => {
        this.setState({ isLoading: false }, () => this.getStockListFromWs());
      })
      .catch(() => this.setState({ isLoading: false }));
  };

  getArticleListFromWS = () => {
    this.setState({ isLoading: true });
    articleService
      .getAllArticles()
      .then((response) => response.data)
      .then((result) => {
        this.setState({
          isLoading: false,
          articleList: result.data || [],
        });
      })
      .catch(() => this.setState({ isLoading: false, articleList: [] }));
  };

  registerQuantityPlanned = () => {
    this.setState({ isLoading: true });
    const { selectedStock } = this.state;
    if (selectedStock.qt_real < selectedStock.qt_planned) {
      this.setState({ isSuperior: true });
    } else {
      inventoryService
        .registerQuantityPlanned(selectedStock)
        .then((response) => response.data)
        .then((result) => {
          this.setState({ isLoading: false, showModal: false });
        })
        .then(() => this.getStockListFromWs())
        .catch(() => {
          this.setState({ isLoading: false, showModal: false });
        });
    }
  };

  cancelRegistrationQuantityPlanned = () => {
    this.setState({ isLoading: true });
    const { selectedStock } = this.state;
    inventoryService
      .cancelRegistrationQuantityPlanned(selectedStock)
      .then((response) => response.data)
      .then((result) => {
        this.setState({
          isLoading: false,
          showModal: false,
          quantityPlanned: 0,
        });
      })
      .then(() => this.getStockListFromWs())
      .catch(() => {
        this.setState({ isLoading: false, showModal: false });
      });
  };

  findMouvementTypeByValue = () => {
    const { selectedHistorique } = this.state;
    mouvementTypeService
      .findMouvementTypeByValue(TRANSFERT)
      .then((response) => response.data)
      .then((result) => {
        selectedHistorique.mouvementType = result.data || {};
        this.setState({ selectedHistorique });
      });
  };

  useQuantityRegistered = () => {
    const { selectedHistorique, isTransfertSuperior } = this.state;
    if (!isTransfertSuperior)
      inventoryService
        .useQuantityRegistered(selectedHistorique)
        .then((response) => response.data)
        .then((result) => {
          this.setState({ isLoading: false, quantityPlanned: 0 });
        })
        .then(() => this.getStockListFromWs());
  };

  getListMedicalFromWS = () => {
    this.setState({
      isLoading: true,
    });

    siteMedicalService
      .getAllSite()
      .then((response) => response.data)
      .then((result) => {
        this.setState({
          siteList: result.data || [],
          isLoading: false,
        });
      })

      .catch(() =>
        this.setState({
          isLoading: false,
        })
      );
  };

  getArticleListFromWS = () => {
    this.setState({ isLoading: true });
    articleService
      .getAllArticles()
      .then((response) => response.data)
      .then((result) => {
        this.setState({
          isLoading: false,
          articleList: result.data || [],
        });
      })
      .catch(() => this.setState({ isLoading: false }));
  };

  getPartenaireListFromWS = () => {
    this.setState({ isLoading: true });
    partenaireService
      .getAllPartenaire()
      .then((response) => response.data)
      .then((result) => {
        this.setState({
          isLoading: false,
          partenaireList: result.partenaires || [],
        });
      })
      .catch(() => this.setState({ isLoading: false }));
  };

  getSiteList = () => {
    const { siteList } = this.state;
    return (
      siteList &&
      siteList.map((site, index) => (
        <option key={index} value={site.id}>
          {site.nom_site}
        </option>
      ))
    );
  };

  getPartenaireList = () => {
    const { partenaireList } = this.state;
    return (
      partenaireList &&
      partenaireList.map((partenaire, index) => (
        <option key={index} value={partenaire.id}>
          {partenaire.designation}
        </option>
      ))
    );
  };

  getArticleList = () => {
    const { articleList } = this.state;
    return (
      articleList &&
      articleList.map((article, index) => (
        <option key={index} value={article.id}>
          {" "}
          {article.libelle}
        </option>
      ))
    );
  };

  //Function for handling change data ***************************
  componentDidMount() {
    this.getStockListFromWs();
    this.findMouvementTypeByValue();
    this.getListMedicalFromWS();
    this.getArticleListFromWS();
    this.getPartenaireListFromWS();
  }

  onTransfert = (selectedStock) => {
    const { selectedHistorique } = this.state;
    selectedHistorique.article = selectedStock.article;
    selectedHistorique.siteSource = selectedStock.sitemedical;
    selectedHistorique.partenaire = selectedStock.partenaire;
    this.setState({
      selectedHistorique,
      showModalTransfert: true,
      quantityPlanned: selectedStock.qt_planned || 0,
    });
  };

  doInventory = () => {
    this.props.history.push({
      pathname: "/admin/addMouvement",
    });
  };

  goToInventoryPage = () => {
    this.props.history.push({
      pathname: "/admin/inventory",
    });
  };

  listMouvement = () => {
    this.props.history.push({
      pathname: "/admin/listMouvement",
    });
  };

  handleChange = (evt, index) => {
    const { stockList } = this.state;
    const eventId = evt.target.id;
    const eventValue = evt.target.value;

    if (eventId === "qt_real") {
      stockList[index].qt_real = eventValue;
      this.setState({ stockList });
    } else {
      this.setState({ [eventId]: eventValue }, () => {
        this.getStockListFromWs();
      });
    }
  };

  handleChangeDate = (event) => {
    this.setState({ date: event.format("DD/MM/YYYY") });
  };

  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  toggle2 = () => {
    this.setState({ showModalTransfert: !this.state.showModalTransfert });
  };

  handleChangePage = (event, index) => {
    event.preventDefault();
    if (index > 0 && index <= this.getTotalPage())
      this.setState(
        {
          currentPage: index,
        },
        () => this.getStockListFromWs()
      );
  };

  handlePage = (event) => {
    this.setState(
      {
        [event.target.id]: parseInt(event.target.value),
      },
      () => this.getStockListFromWs()
    );
  };

  getTotalPage = () => {
    const { totalPage } = this.state;
    return totalPage;
  };

  handleQuantityChange = (event) => {
    const { selectedStock, isRegister, quantityPlanned } = this.state;
    const eventId = event.target.id;
    const eventValue = event.target.value;
    selectedStock[eventId] = eventValue;
    if (isRegister) {
      this.setState({
        selectedStock,
        isSuperior: eventValue > selectedStock.qt_real,
      });
    } else {
      this.setState({
        selectedStock,
        isSuperior: eventValue > quantityPlanned,
      });
    }
  };

  onRegister = (selectedStock) => {
    this.setState({
      selectedStock,
      showModal: true,
      isRegister: true,
    });
  };

  onCancel = (selectedStock) => {
    this.setState({
      selectedStock,
      showModal: true,
      isRegister: false,
      isCancel: true,
      quantityPlanned: selectedStock.qt_planned,
    });
  };

  handleTransfertQuantity = (event) => {
    const { selectedHistorique, quantityPlanned } = this.state;
    const eventId = event.target.id;
    const eventValue = event.target.value;
    selectedHistorique[eventId] = eventValue;
    this.setState({
      selectedHistorique,
      isTransfertSuperior:
        selectedHistorique.quantityPlanned &&
        selectedHistorique.quantityPlanned > quantityPlanned,
    });
  };

  handleChangeSite = (event) => {
    const { selectedHistorique, siteList } = this.state;
    const eventValue = event.target.value;
    const siteSourceOutput =
      siteList &&
      siteList.length > 0 &&
      siteList.filter((site) => site.id === parseInt(eventValue));
    selectedHistorique.siteDestinataire =
      siteSourceOutput.length > 0 ? siteSourceOutput[0] : {};
    this.setState({ selectedHistorique });
  };

  // Function for render
  getStockList = () => {
    const { stockList } = this.state;
    return (
      stockList &&
      stockList.map((stock, index) => (
        <tr key={index}
          className={this.state.selectedLineIndex == index ? "selected" : ""}
        >
          <td className="sticky-col sticky-left p-1">
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-icon-only text-light m-0"
                href="#"
                role="button"
                size="sm"
                color=""
                outline={true}
                style={{ zIndex: 1 }}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ selectedLineIndex: index });
                }}
                >
                <i className="fas fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" container="body" positionFixed={true}  right>
                <DropdownItem
                  href="#"
                  className="clickable"
                  onClick={() => {
                    this.onRegister(stock);
                  }}>
                  Réserver quantité
                </DropdownItem>
                <DropdownItem
                  href="#"
                  className="clickable"
                  onClick={() => {
                    this.onCancel(stock);
                  }}>
                  Annuler la réservation
                </DropdownItem>

                <DropdownItem
                  href="#"
                  className="clickable"
                  onClick={() => {
                    this.onTransfert(stock);
                  }}>
                  Transférer la quantité prévue
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
          <td>{(stock.partenaire && stock.partenaire.designation) || ""}</td>
          <td>{(stock.sitemedical && stock.sitemedical.nom_site) || ""}</td>
          <td>{(stock.sitemedical && stock.sitemedical.typeSite) || ""}</td>
          <td>{(stock.article && stock.article.libelle) || ""}</td>
          <td>
            {(stock.article &&
              stock.article.categorie &&
              stock.article.categorie.libelle) ||
              ""}
          </td>
          <td>
            {(stock.article &&
              stock.article.unite &&
              stock.article.unite.libelle) ||
              ""}
          </td>
          <td>{stock.qt_planned || "0"}</td>
          <td>{stock.qt_real || "0"}</td>
          {/*<td>{stock.qt_initial || '0'}</td>*/}
          
        </tr>
      ))
    );
  };

  exportData = () => {
    const { stockList } = this.state;
    const dataToExport = {
      stockList,
    };

    BoardService.exportDataStock(dataToExport)
      .then((response) => {
        Download(response);
      })
      .catch((error) => console.log(error));
  };

  render() {
    const roleName = localStorage.getItem("roleName");
    if (roleName !== "superadmin" && roleName !== "adminadd") {
      this.props.history.push("/admin/accueil");
    }
    const {
      currentPage,
      isLoading,
      partenaireId,
      articleId,
      siteMedicalId,
      selectedStock,
      showModal,
      isSuperior,
      isRegister,
      quantityPlanned,
      isCancel,
      selectedHistorique,
      siteList,
      showModalTransfert,
      isTransfertSuperior,
      pageSize,
    } = this.state;
    return (
      <>
        <div className="header bg-gradient-primary postition-absolute">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row></Row>
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt-5 custom-card-container" fluid >
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                    <Col lg="8">
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label for="siteMedicalId">Site</Label>
                            <div className="input-group-alternative input-group">
                            <Input
                              type="select"
                              name="siteMedicalId"
                              id="siteMedicalId"
                              value={siteMedicalId || -1}
                              onChange={this.handleChange}>
                              <option value={-1}>Tous les sites</option>
                              {this.getSiteList()}
                            </Input>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label for="articleId">Article</Label>
                            <div className="input-group-alternative input-group">
                            <Input
                              type="select"
                              name="articleId"
                              id="articleId"
                              value={articleId || -1}
                              onChange={this.handleChange}>
                              <option value={-1}>Tous les articles</option>
                              {this.getArticleList()}
                            </Input>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label for="partenaireId">Partenaire</Label>
                            <div className="input-group-alternative input-group">
                            <Input
                              type="select"
                              name="partenaireId"
                              id="partenaireId"
                              value={partenaireId || -1}
                              onChange={this.handleChange}>
                              <option value={-1}>Tous les partenaires</option>
                              {this.getPartenaireList()}
                            </Input>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>

                    <Col lg="4">
                      <Container
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center ",
                          paddingRight: 0,
                        }}>
                        <Label
                          style={{
                            marginLeft: 10,
                            fontSize: 16,
                            marginTop: 6,
                          }}>
                          Nombre de lignes
                        </Label>
                        <Input
                          id="pageSize"
                          type="select"
                          width={50}
                          style={{
                            display: "inline-block",
                            width: 80,
                            marginLeft: 10,
                            height: 40,
                          }}
                          value={pageSize}
                          onChange={this.handlePage}>
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={30}>30</option>
                          <option value={40}>40</option>
                          <option value={50}>50</option>
                        </Input>
                      </Container>
                    </Col>
                  </Row>
                </CardHeader>
                <div className="table-responsive">
                  <table
                    className="table table-bordered align-items-center table-flush table table-sticky"
                    responsive="true">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" className="p-1" />
                        <th scope="col">Partenaire</th>
                        <th scope="col">Nom du Site</th>
                        <th scope="col">Type de Site</th>
                        <th scope="col">Article</th>
                        <th scope="col">Catégorie</th>
                        <th scope="col">Unité</th>
                        <th scope="col">Quantité Prévue</th>
                        <th scope="col">Quantité Disponible</th>
                        {/*<th scope="col">Quantité Entrée</th>*/}
                      </tr>
                    </thead>
                    <tbody>{this.getStockList()}</tbody>
                  </table>
                </div>
                <FooterTable
                  currentPage={currentPage}
                  handlePage={this.handlePage}
                  handleChangePage={this.handleChangePage}
                  getTotalPage={this.getTotalPage}>
                  {/* <Button className="btn" onClick={this.exportData}>
                                        Exporter
                                    </Button>  */}
                </FooterTable>
              </Card>
            </div>
          </Row>

          <PageSpinner show={isLoading} />
        </Container>
        <StockModal
          selectedStock={selectedStock}
          handleQuantityChange={this.handleQuantityChange}
          registerQuantityPlanned={this.registerQuantityPlanned}
          cancelRegistrationQuantityPlanned={
            this.cancelRegistrationQuantityPlanned
          }
          toggle={this.toggle}
          showModal={showModal}
          isSuperior={isSuperior}
          isRegister={isRegister}
          isCancel={isCancel}
          quantityPlanned={quantityPlanned}
        />
        <TransfertModal
          showModal={showModalTransfert}
          selectedHistorique={selectedHistorique}
          handleChangeSite={this.handleChangeSite}
          useQuantityRegistered={this.useQuantityRegistered}
          handleTransfertQuantity={this.handleTransfertQuantity}
          siteList={siteList}
          quantityPlanned={quantityPlanned}
          toggle={this.toggle2}
          isSuperior={isTransfertSuperior}
        />
      </>
    );
  }
}

export default ArticlesPage;
