import axios from 'axios';
import { BACK_URL } from 'data/constant/urls';
import authHeader from './AuthHeader';

// const API_URL = process.env.REACT_APP_URL;
const API_URL = BACK_URL;

class PartenaireService {

    getAllPartenaire() {
        
        return axios.get(API_URL + `api/partenaire/findAll`, {
            headers: authHeader(),
        });
    }
    getAllPartenairePagination() {
        return axios.get(API_URL + `api/unite/findAllPagination`, {
            headers: authHeader(),
        });
    }


    getPartenaireByLibelle(libelle) {
        return axios.get(API_URL + `api/unite/findByLibelle?libelle=${libelle}`, {
            headers: authHeader(),
        });
    }

    createPartenaire = (partenaire) => {
        return axios.post(API_URL + `api/partenaire/addPartenaire`, partenaire,{
            headers: authHeader(),
        });
    }

    updatePartenaire = (partenaire) => {
        return axios.put(API_URL + `api/partenaire/updatecategorie`, partenaire,{
            headers: authHeader(),
        });
    }

    deletePartenaire = (id) => {
        return axios.delete(API_URL + `api/partenaire/deletecategorie?id=${id}`, {
            headers: authHeader(),
        });
    }
}

export default new PartenaireService();