import React from "react";
import { Col, FormGroup, Input, Row } from "reactstrap";
import ZonnageService from "services/ZonnageService";
import PageSpinner from "views/components/PageSpinner";
class Zonage extends React.Component {
  state = {
    isFilter: false,
    isRequired: false,
    isLoading: false,
    zonage: {
      region: "",
      district: "",
      commune: "",
      fokotany: "",
      hameau: "",
    },
    requiredFields: {
      fokotany: "",
      region: "",
      district: "",
      commune: "",
    },
    listFokotany: [],
    listRegion: [],
    listDistrict: [],
    listCommune: [],
    displayRegion: [],
    displayCommune: [],
    displayFokotany: [],
  };

  componentDidMount() {
    this.getFokotany();
    this.getRegion();
    this.getDistrict();
    this.getCommune();
  }
  componentWillReceiveProps(props) {
    this.getData(props);
    if (props.isFilter) {
      this.setState({ isFilter: true });
    }
  }

  getData(props) {
    const data = props.menage;
    let menageDTO = {};
    menageDTO.region = data && data.region ? data.region : "";
    menageDTO.district = data && data.district ? data.district : "";
    menageDTO.fokotany = data && data.fokotany ? data.fokotany : "";
    menageDTO.hameau = data && data.hameau ? data.hameau : "";
    menageDTO.commune = data && data.commune ? data.commune : "";
    menageDTO.requiredFields =
      data && data.requiredFields ? data.requiredFields : "";
    this.setDefault(menageDTO);
  }

  setDefault(data) {
    if (data) {
      const { region, district, fokotany, commune, hameau, requiredFields } =
        data;
      this.setState({
        zonage: {
          ...this.state.zonage,
          region,
          district,
          fokotany,
          commune,
          hameau,
        },
        requiredFields,
      });
    }
  }
  getRegion = () => {
    ZonnageService.getListRegion(this.state.zonage.region)
      .then((res) => res.data)
      .then((result) => {
        this.setState({
          listRegion: result.data,
          displayRegion: result.data,
        });
      });
  };
  getCommune = () => {
    ZonnageService.getListCommune(
      this.state.zonage.commune,
      this.state.zonage.district,
      this.state.zonage.region
    )
      .then((res) => res.data)
      .then((result) => {
        this.setState({
          listCommune: result.data,
          displayCommune: result.data,
        });
      });
  };
  getFokotany = () => {
    this.setState({
      isLoading: true,
    });

    ZonnageService.getListFokotany(
      this.state.zonage.fokotany,
      this.state.zonage.commune,
      this.state.zonage.district,
      this.state.zonage.region
    )
      .then((res) => {
        return res.data;
      })
      .then((result) => {
        this.setState({
          listFokotany: result.data,
          displayFokotany: result.data,
          isLoading: false,
        });
      });
  };
  getDistrict = () => {
    ZonnageService.getListDistrict(
      this.state.zonage.district,
      this.state.zonage.region
    )
      .then((res) => res.data)
      .then((result) => {
        this.setState({
          listDistrict: result.data,
          displayDistrict: result.data,
        });
      });
  };

  handleChange = (evt) => {
    const { name, value } = evt.target;
    const useDTO = this.props?.useDTO ;
    const dataId = evt?.target[evt.target?.selectedIndex]?.getAttribute("data-id") ;

    let requiredFields = { ...this.state.requiredFields };
    let regionDTO = useDTO ? {} : ""; 
    let disctrictDTO = useDTO ? {} : ""; 
    let communeDTO = useDTO ? {} : ""; 
    let fokontanyDTO =  useDTO ? {} : ""; 
    
    switch (name) { 
      case "region":
        requiredFields.region = value === "" ? "Champ obligatoire" : "";

        regionDTO = dataId != null ? this.state.listRegion?.find((item) => item.id == dataId) : null; 

        const displayDistrict =
          value !== ""
            ? this.state.listDistrict.filter((r) => r.nom_region === value)
            : this.state.listDistrict;
        const displayCommune =
          value !== ""
            ? this.state.listCommune.filter((r) => r.nomRegion === value)
            : this.state.listCommune;
        const displayFokotany =
          value !== ""
            ? this.state.listFokotany.filter((r) => r.nomRegion === value)
            : this.state.listFokotany;

        if (!value) {
          requiredFields.district = "Champ obligatoire";
          requiredFields.commune = "Champ obligatoire";
          requiredFields.fokotany = "Champ obligatoire";
        }

        this.setState(
          {
            displayDistrict,
            displayCommune,
            displayFokotany,
            zonage: {
              ...this.state.zonage,
              district: useDTO ? {} : "",
              commune: useDTO ? {} : "",
              fokotany: useDTO ? {} : "",
              [name]: useDTO ? regionDTO : value,
            },
            requiredFields,
          },
          () => {
            this.props.data && this.props.data(this.state.zonage);
          }
        );
        break;
      case "district":
        requiredFields.district = value === "" ? "Champ obligatoire" : "";

        disctrictDTO = dataId != null ? this.state.listDistrict?.find((item) => item.id == dataId) : null ; 
        regionDTO = disctrictDTO ? this.state.listRegion?.find((item) => item.id == disctrictDTO?.regionid) : null; 
        // communeDTO = this.state.listCommune?.find((item) => item.id == dataId); 
        // fokontanyDTO = this.state.listFokotany?.find((item) => item.id == dataId); 

        const region =
          value !== "" && disctrictDTO ? 
            disctrictDTO.nom_region
            : "";
        const commune = 
          value !== ""
            ? this.state.listCommune.filter((r) => r.nomDistricte === value)
            : this.state.listCommune;
        const displayFokotany_District =
          value !== ""
            ? this.state.listFokotany.filter((r) => r.nomDistricte === value)
            : this.state.listFokotany;
        const district =
          value !== ""
            ? this.state.listDistrict.filter((r) => r.nom_region === region)
            : this.state.listDistrict;

        if (value) {
          requiredFields.region = "";
        } else {
          requiredFields.region = "Champ obligatoire";
          requiredFields.commune = "Champ obligatoire";
        }
        this.setState(
          {
            displayDistrict: district,
            displayRegion: this.state.listRegion,
            displayCommune: commune,
            displayFokotany: displayFokotany_District,
            zonage: {
              ...this.state.zonage,
              region: useDTO ? regionDTO : region,
              commune: useDTO ? {} : null,
              fokotany:useDTO ? {} : null,
              [name]: useDTO ? disctrictDTO : value,
            },
            requiredFields,
          },
          () => {
            this.props.data && this.props.data(this.state.zonage);
          }
        );
        break;
      case "commune":
        requiredFields.commune = value === "" ? "Champ obligatoire" : "";

        communeDTO = dataId != null ? this.state.listCommune?.find((item) => item.id == dataId) : null; 
        disctrictDTO = communeDTO ? this.state.listDistrict?.find((item) => item.id == communeDTO?.dictricteid) : null; 
        regionDTO = communeDTO ? this.state.listRegion?.find((item) => item.id == communeDTO?.regionid) : null; 
        // fokontanyDTO = this.state.listFokotany?.find((item) => item.id == dataId); 
        const nomRegion =
          value !== ""
            ? communeDTO?.nomRegion
            : "";
        const nomDistricte =
          value !== ""
            ? communeDTO?.nomDistricte
            : "";
        let communeList =
          value !== "" ?
            this.state.listCommune.filter((r) =>
                r.nomRegion === nomRegion && r.nomDistricte == nomDistricte
              )
            : this.state.listCommune;
        const displayFokotany_Commune =
          value !== ""
            ? this.state.listFokotany.filter((r) => r.nomCommune == value)
            : this.state.listFokotany;

        if (value) {
          requiredFields.region = "";
          requiredFields.district = "";
        } else {
          requiredFields.region = "Champ obligatoire";
          requiredFields.district = "Champ obligatoire";
        }
        this.setState(
          {
            displayCommune: communeList,
            displayRegion: this.state.listRegion,
            displayDistrict: this.state.listDistrict,
            displayFokotany: displayFokotany_Commune,
            zonage: {
              ...this.state.zonage,
              region: useDTO ? regionDTO : nomRegion,
              district: useDTO ? disctrictDTO : nomDistricte,
              fokotany: useDTO ? {} : "",
              [name]: useDTO ? communeDTO : value,
            },
            requiredFields,
          },
          () => {
            this.props.data && this.props.data(this.state.zonage);
          }
        );
        break;
      case "fokotany":
        requiredFields.fokotany = value === "" ? "Champ obligatoire" : "";

        fokontanyDTO = dataId != null ? this.state.listFokotany?.find((item) => item.id == dataId) || {} : {}; 
        communeDTO = fokontanyDTO ? this.state.listCommune?.find((item) => item.id == fokontanyDTO?.communeid) || {} : {}; 
        disctrictDTO = fokontanyDTO ? this.state.listDistrict?.find((item) => item.id == fokontanyDTO?.dictricteid) || {} : {}; 
        regionDTO = fokontanyDTO ? this.state.listRegion?.find((item) => item.id == fokontanyDTO?.regionid) || {} : {}; 

        const optionElement = evt.target.childNodes[evt.target.selectedIndex];
        const _commune = optionElement.getAttribute("data-commune");
        const _districte = optionElement.getAttribute("data-districte");

        const data_fokotany =
          value !== ""
            ? this.state.listFokotany.filter(
                (r) =>
                  r.nom_fokotany === value &&
                  r.nomCommune === _commune &&
                  r.nomDistricte === _districte
              )
            : "";
        const nomRegion_fokotany =
          data_fokotany !== "" && data_fokotany[0].nomRegion;

        const nomDistricte_fokotany =
          data_fokotany !== "" && data_fokotany[0].nomDistricte;

        const nomCommune_fokotany =
          data_fokotany !== "" && data_fokotany[0].nomCommune;
        if (value) {
          requiredFields.region = "";
          requiredFields.district = "";
          requiredFields.commune = "";
        } else {
          requiredFields.region = "Champ obligatoire";
          requiredFields.district = "Champ obligatoire";
          requiredFields.commune = "Champ obligatoire";
        }
        const tempZonageFokontany =
        useDTO && data_fokotany != "" && data_fokotany[0] ?
          {
            region: regionDTO,
            district: disctrictDTO,
            commune: communeDTO
          } : {
            region: nomRegion_fokotany,
            district: nomDistricte_fokotany,
            commune: nomCommune_fokotany,
          };

        this.setState(
          {
            zonage: {
              ...this.state.zonage,
              ...tempZonageFokontany,
              [name]: useDTO ? fokontanyDTO : value,
            },
            requiredFields,
          },
          () => {
            this.props.data && this.props.data(this.state.zonage);
          }
        );
        break;

      default:
        this.setState(
          {
            zonage: {
              ...this.state.zonage,
              [name]: value,
            },
          },
          () => {
            this.props.data && this.props.data(this.state.zonage);
          }
        );
        break;
    }
  };

  render() {
    const listeRegion =
      this.state.displayRegion &&
      this.state.displayRegion.map((elt, idx) => (
        <option key={elt.id} value={elt.nom_region} data-id={elt.id}>
          {elt.nom_region}
        </option>
      ));
    const listeCommnue =
      this.state.displayCommune &&
      this.state.displayCommune.map((elt, idx) => (
        <option key={elt.id} value={elt.nomCommune} data-id={elt.id}>
          {elt.nomCommune}
        </option>
      ));
    const listeFokotany =
      this.state.displayFokotany &&
      this.state.displayFokotany.map((elt, idx) => (
        <option
          key={elt.id}
          data-id={elt.id}
          value={elt.nom_fokotany}
          data-commune={elt.nomCommune}
          data-districte={elt.nomDistricte}
        >
          {elt.nom_fokotany}
        </option>
      ));

    const listeDistrict =
      this.state.displayDistrict &&
      this.state.displayDistrict.map((elt, idx) => (
        <option key={elt.id} value={elt.nom_districte}  data-id={elt.id}>
          {elt.nom_districte}
        </option>
      ));

    return (
      <>
        {!this.props.hideHameau && (
          <Row>
            <Col>
              <FormGroup>
                <h4 className="text-primary">Zonage</h4>
              </FormGroup>
            </Col>
          </Row>
        )}
        <Row>
          <Col xl="3" lg="4" sm="12" className="mb-3 mb-xl-1">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-first-name">
                Région {this.state.isFilter ? "" : "*"}
              </label>
              <div className="input-group-alternative input-group">
                <select
                  className="mdb-select md-form  form-control"
                  onChange={this.handleChange}
                  name="region"
                  value={ this.props?.useDTO ?  this.state.zonage?.region?.nom_region || "" : this.state.zonage?.region}
                >
                  <option value="">Afficher toutes les régions</option>
                  {listeRegion}
                </select>
              </div>
              {this.state.requiredFields &&
                this.state.requiredFields.region &&
                this.state.requiredFields.region.length > 0 && (
                  <small className="text-danger">champ obligatoire</small>
                )}
            </FormGroup>
          </Col>
          <Col xl="3" lg="4" sm="12" className="mb-3 mb-xl-1">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-first-name">
                District {this.state.isFilter ? "" : "*"}
              </label>
              <div className="input-group-alternative input-group">
                <select
                  className="mdb-select md-form  form-control"
                  onChange={this.handleChange}
                  name="district"
                  value={ this.props?.useDTO ?  this.state.zonage?.district?.nom_districte || "" : this.state.zonage?.district}
                >
                  <option value="">Afficher tous les districts</option>
                  {listeDistrict}
                </select>
              </div>
              {this.state.requiredFields &&
                this.state.requiredFields.district &&
                this.state.requiredFields.district.length > 0 && (
                  <small className="text-danger">champ obligatoire</small>
                )}
            </FormGroup>
          </Col>
          <Col xl="3" lg="4" sm="12" className="mb-3 mb-xl-1">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-first-name">
                Commune {this.state.isFilter ? "" : "*"}
              </label>
              <div className="input-group-alternative input-group">
                <select
                  className="mdb-select md-form  form-control"
                  onChange={this.handleChange}
                  name="commune"
                  value={this.props?.useDTO ?  this.state.zonage?.commune?.nomCommune || "" : this.state.zonage?.commune}
                >
                  <option value="">Afficher toutes les communes</option>
                  {listeCommnue}
                </select>
              </div>
              {this.state.requiredFields &&
                this.state.requiredFields.commune &&
                this.state.requiredFields.commune.length > 0 && (
                  <small className="text-danger">champ obligatoire</small>
                )}
            </FormGroup>
          </Col>

          <Col xl="3" lg="4" sm="12" className="mb-3 mb-xl-1">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-first-name">
                Fokontany {this.state.isFilter ? "" : "*"}
              </label>
              <div className="input-group-alternative input-group">
                <select
                  className="mdb-select md-form  form-control"
                  onChange={this.handleChange}
                  name="fokotany"
                  value={this.props?.useDTO ? this.state.zonage?.fokotany?.nom_fokotany || "" : this.state.zonage?.fokotany}
                >
                  <option value="">Afficher tous les Fokontany</option>
                  {listeFokotany}
                </select>
              </div>
              {this.state.requiredFields &&
                this.state.requiredFields.fokotany &&
                this.state.requiredFields.fokotany.length > 0 && (
                  <small className="text-danger">champ obligatoire</small>
                )}
            </FormGroup>
          </Col>
          {!this.props.hideHameau && (
            <Col xl="3" lg="4" sm="12" className="mb-3 mb-xl-1">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-first-name"
                >
                  Hameau(x)
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-email"
                  placeholder=""
                  type="text"
                  onChange={this.handleChange}
                  name="hameau"
                  value={this.state.zonage?.hameau}
                />
              </FormGroup>
            </Col>
          )}
        </Row>
        <PageSpinner show={this.state.isLoading} />
      </>
    );
  }
}

export default Zonage;
