/*eslint-disable*/
// nodejs library to set properties for components
import React, { Fragment } from "react";
import { Link, NavLink as NavLinkRRD } from "react-router-dom";
import ImportExcel from "components/Excel/ImportExcel";
// reactstrap components
import {
  Button,
  Col,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Media,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { user_fullRights } from "data/constant/user.rights";
import { user_editor } from "data/constant/user.rights";

var ps;

class Sidebar extends React.Component {
  state = {
    checkData: false,
    checkNewData: false,
    chechFokontany: false,
    collapseOpen: false,
    roleName: "",
    showModal: false,
    collapsibles: {}
  };

  isChildrenActive = (children) => {
    const pathName = window?.location?.pathname; 
    let childernIsActive = false ;
    children.forEach((c) => {
      if(pathName && pathName?.includes(c.path)) {
        childernIsActive = true;
      }
    })
    console.log('isChildrenActive',[children,pathName,childernIsActive])
    return childernIsActive
  }
  componentDidMount() {
    const roleName = localStorage.getItem("roleName");
    const { routes, routes2 } = this.props;
    const tempCollapsibles = {...this.state.collapsibles} ;
    routes.forEach(r => {
      if(r.children && r.children.length > 0){
        tempCollapsibles[r.id] = !this.isChildrenActive(r.children);
      }
    });
    routes2.forEach(r => {
      if(r.children && r.children.length > 0)
        tempCollapsibles[r.id] = !this.isChildrenActive(r.children);
    });
    this.setState({
      collapsibles: tempCollapsibles
    });
    this.setState({
      roleName,
    });
  }
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };

  toggle = () => {
    this.setState({
      checkData: true,
      checkNewData: false,
      chechFokontany: false,
      showModal: !this.state.showModal,
    });
  };
  toggleNewData = () => {
    this.setState({
      checkData: false,
      checkNewData: true,
      chechFokontany: false,
      showModal: !this.state.showModal,
    });
  };
  toggleFokontany = () => {
    this.setState({
      checkData: false,
      checkNewData: false,
      chechFokontany: true,
      showModal: !this.state.showModal,
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    });
  };

   // toggle menu collasible
   toggleCollapsible = (id) => {
    this.setState(prevState => ({
      collapsibles: {
        ...prevState.collapsibles,
        [id]: !prevState.collapsibles[id]
      }
    }));
  };

  // creates the links that appear in the left menu / Sidebar
  showLink = (data) => {
    let bShow = true;
    data.forEach((d) => {
      if (!d) {
        bShow = false;
      }
    });
    return bShow;
  };

  isAuthorized = () => {
    let bAuthorized = true;
    const roleName = this.state.roleName;
    switch (roleName) {
      case "superadmin":
        break;
      case "adminadd":
        bAuthorized = false;
        break;
      case "adminconsult":
        bAuthorized = false;
        break;
      case "adminconsult":
        bAuthorized = false;
        break;
      case "adminconsultexport":
        bAuthorized = false;
        break;
    }

    return bAuthorized;
  };

  createLinks = (routes, parent = null) => {
    return routes.map((prop, key) => {
      const login = prop.path !== "/login";

      const information =
        prop.path !== "/information" &&
        (prop.restrictionView
          ? prop.restrictionView.includes(this.state.roleName)
          : true);

      const individuList =
        prop.path !== "/individuList" &&
        (prop.restrictionView
          ? prop.restrictionView.includes(this.state.roleName)
          : true);

      const stock =
        prop.path !== "/tableau-de-bord-stock" &&
        (prop.restrictionView
          ? prop.restrictionView.includes(this.state.roleName)
          : true);

      const siteDashboard =
        prop.path !== "/dashboard/sites/:id" &&
        (prop.restrictionView
          ? prop.restrictionView.includes(this.state.roleName)
          : true);

      const siteDetails =
        prop.path !== "/siteDetails/:id" &&
        (prop.restrictionView
          ? prop.restrictionView.includes(this.state.roleName)
          : true);

      const utilisateurs =
        prop.path !== "/utilisateurs" &&
        (prop.restrictionView
          ? prop.restrictionView.includes(this.state.roleName)
          : true);

      const addMouvement =
        prop.path !== "/addMouvement" &&
        (prop.restrictionView
          ? prop.restrictionView.includes(this.state.roleName)
          : true);

      const inventory =
        prop.path !== "/inventory" &&
        (prop.restrictionView
          ? prop.restrictionView.includes(this.state.roleName)
          : true);

      const menage =
        prop.name !== "Création menage" &&
        (prop.restrictionView
          ? prop.restrictionView.includes(this.state.roleName)
          : true);

      const addEnrolement =
        prop.name !== "Enregistrement enrolement" &&
        (prop.restrictionView
          ? prop.restrictionView.includes(this.state.roleName)
          : true);

      const data = [
        menage,
        login,
        information,
        individuList,
        addMouvement,
        stock,
        addEnrolement,
        siteDashboard,
        siteDetails,
      ];

      if (prop.children && prop.children.length > 0 && parent && parent.id && !this.state.collapsibles[prop.id]) {
        this.setState( {collapsibles: {...this.state.collapsibles}, [parent.id] : false} )
      }

      return (
        this.showLink(data) && (
          prop.children && prop.children.length > 0 ?
          <Fragment key={key}>
            <NavItem key={`navitemc-${key}`} >
              <div
                className={"nav-link collapse-nav-menu " + (!this.state.collapsibles[prop.id] ? "active" : "")}
                onClick={() => this.toggleCollapsible(prop.id)}
                >
                <i className={prop.icon} />
                <span className='collapse-nav-text'>{prop.name}</span>
                <i className={`ni ${this.state.collapsibles[prop.id] ? ' ni-bold-down ' : ' ni-bold-up '} collapse-nav-icon`} />
              </div>
            </NavItem>  
            <Collapse key={`collapse-${key}`} className="collapse-nav-children" isOpen={!this.state.collapsibles[prop.id]} >
              {this.createLinks(prop.children, prop)}
            </Collapse>
          </Fragment>
          :
          <NavItem key={`navitem-0-${key}`} >
            <NavLink
              key={`navlink-0-${key}`}
              to={prop.layout + prop.path}
              tag={NavLinkRRD}
              onClick={this.closeCollapse}
              activeClassName="active">
              <i className={prop.icon} />
              <span>{prop.name}</span>
            </NavLink>
          </NavItem>
        )
      );
    });
  };

  render() {
    const { bgColor, routes, logo, routes2 } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank",
      };
    }
    return (
      <Navbar
        className="navbar-vertical fixed-left navbar-light bg-white"
        expand="md"
        id="sidenav-main">
        <Container fluid className="flex-start">
          {/* Toggler MOBILE */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleCollapse}>
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          {logo ? (
            <NavbarBrand className="pt-md-2" {...navbarBrandProps}>
              <img
                alt={logo.imgAlt}
                className="navbar-brand-img"
                src={logo.imgSrc}
              />
            </NavbarBrand>
          ) : null}
          {/* User */}
          <Nav className="align-items-center d-md-none">
            <UncontrolledDropdown nav>
              <DropdownToggle nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    {/* <img
                      alt="..."
                      src={require("assets/img/theme/team-1-800x800.jpg")}
                    /> */}
                    <i className="fa fa-user" />
                  </span>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" container="body" positionFixed={true}  right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Bienvenue!</h6>
                </DropdownItem>

                <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                  <i className="ni ni-user-run" />
                  <span>Se déconnecter</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          {/* Collapse */}
          <Collapse navbar isOpen={this.state.collapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                {logo ? (
                  <Col className="collapse-brand" xs="6">
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </Link>
                    ) : (
                      <a href={logo.outterLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </a>
                    )}
                  </Col>
                ) : null}
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.toggleCollapse}>
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* Form */}
            <Form className="mt-4 mb-3 d-md-none">
              <InputGroup className="input-group-rounded input-group-merge">
                <Input
                  aria-label="Search"
                  className="form-control-rounded form-control-prepended"
                  placeholder="Search"
                  type="search"
                />
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <span className="fa fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Form>
            {/* Navigation */}
            <Nav key={'nav-1'} navbar>{this.createLinks(routes)}</Nav>
            <hr className="my-2" />

            <Nav key={'nav-2'} navbar>{this.createLinks(routes2)}</Nav>

            <>
              <hr className="my-2" />
              {(this.state.roleName === "superadmin" ||
                this.state.roleName === "adminadd") && (
                <Nav navbar>
                  <NavItem>
                    <NavLink
                      to={"/admin/settings"}
                      tag={NavLinkRRD}
                      onClick={this.closeCollapse}
                      activeClassName="active">
                      <i className="ni ni-settings text-secondary" />
                      Paramètres
                    </NavLink>
                  </NavItem>
                </Nav>
              )}
              {this.state.roleName === "superadmin" && (
                <Nav navbar>
                  <NavItem>
                    <NavLink
                      to={"/admin/utilisateurs"}
                      tag={NavLinkRRD}
                      onClick={this.closeCollapse}
                      activeClassName="active">
                      <i className="ni ni-circle-08 text-secondary" />
                      Utilisateurs
                    </NavLink>
                  </NavItem>
                </Nav>
              )}
            </>

            { (this.state.roleName === "superadmin" ||
                this.state.roleName === "adminadd") && (
              <React.Fragment>
                <Nav navbar>
                  <NavItem>
                    <NavLink
                      onClick={this.toggle}

                      className="clickable active"
                    >
                      <i className="ni ni-folder-17 text-secondary" />
                      Import données BNGRC
                    </NavLink>
                  </NavItem>
                </Nav>
                <Nav navbar>
                  <NavItem>
                    <NavLink
                      onClick={this.toggleNewData}

                      className="clickable active"
                    >
                      <i className="ni ni-folder-17 text-secondary" />
                      Import nouveau format
                    </NavLink>
                  </NavItem>
                </Nav>
              </React.Fragment>
                )
            }

            {/* Divider */}
          </Collapse>
          {this.state.checkData && (
            <ImportExcel
              data="data"
              toggle={this.toggle}
              showModal={this.state.showModal}
            />
          )}
          {this.state.checkNewData && (
            <ImportExcel
              data="dataNew"
              toggle={this.toggleNewData}
              showModal={this.state.showModal}
            />
          )}
          {this.state.chechFokontany && (
            <ImportExcel
              fokontany="fokontany"
              toggle={this.toggleFokontany}
              showModal={this.state.showModal}
            />
          )}
        </Container>
      </Navbar>
    );
  }
}

export default Sidebar;
