import FooterTable from "components/Footer/Footer";
import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Button,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
import historiqueService from "services/HistoriqueService";
import mouvementTypeService from "services/MouvementTypeService";
import partenaireService from "services/PartenaireService";
import siteMedicalService from "services/SiteService";
import ConfirmationModalAnnuler from "views/components/ConfirmationModalAnnuler";
import PageSpinner from "views/components/PageSpinner";
import NotificationModal from "../components/NotificationModal";

class HistoriqueAddPage extends React.Component {
  state = {
    siteList: [],
    mouvementTypeList: [],
    partenaireList: [],
    currentPage: 1,
    totalPage: 0,
    pageSize: 10,
    showModal: false,
    confirmationModal: false,
    historiqueList: [],
    idSite: -1,
    idPartenaire: -1,
    idMouvement: -1,
    confirmationModal: false,
    selectedMouvement: {},
    isError: false,
    partnerDropDownSelected: "Tous les partenaires",
    siteDropDownSelected: "Tous les sites",
    mouvementTypeDropDownSelected: "Tous les types",
    selectedLineIndex: null,
  };

  showConfirmationModal = (item) => {
    this.setState({
      confirmationModal: true,
      selectedMouvement: item,
    });
  };

  handleCloseNotification = () => {
    this.setState({
      isError: false,
    });
  };
  deleteMouvement = () => {
    historiqueService
      .deleteHistorique(this.state.selectedMouvement.id)
      .then((data) => {
        this.setState({
          confirmationModal: false,
        });
        this.getHistoriqueListFromWS();
      })
      .catch((exception) => {
        this.setState({
          confirmationModal: false,
          isLoading: false,
          isError: true,
          errorMessage: exception.errorCode
            ? exception.errorMessage
            : "Contactez votre administrateur",
          errorTitle: "Erreur à la suppression d'un mouvement",
        });
        this.getHistoriqueListFromWS();
      });
  };

  // function for web service
  getListMedicalFromWS = () => {
    this.setState({
      isLoading: true,
    });

    siteMedicalService
      .getAllSite()
      .then((response) => response.data)
      .then((result) => {
        this.setState({
          siteList: result.data || [],
          isLoading: false,
        });
      })
      .catch(() =>
        this.setState({
          isLoading: false,
        })
      );
  };

  getMouvementTypeListFromWS = () => {
    this.setState({ isLoading: true });
    mouvementTypeService
      .getAllMouvementType()
      .then((response) => response.data)
      .then((result) => {
        this.setState({
          isLoading: false,
          mouvementTypeList: result.data || [],
        });
      })
      .catch(() => this.setState({ isLoading: false }));
  };

  getPartenaireListFromWS = () => {
    this.setState({ isLoading: true });
    partenaireService
      .getAllPartenaire()
      .then((response) => response.data)
      .then((result) => {
        this.setState({
          isLoading: false,
          partenaireList: result.partenaires || [],
        });
      })
      .catch(() => this.setState({ isLoading: false }));
  };

  getHistoriqueListFromWS = () => {
    this.setState({ isLoading: true });
    const { currentPage, pageSize, idSite, idPartenaire, idMouvement } =
      this.state;
    historiqueService
      .findHistorique(
        currentPage - 1,
        pageSize,
        idSite,
        idPartenaire,
        idMouvement
      )
      .then((response) => response.data)
      .then((result) => {
        const historiqueList =
          (result.data && result.data.historiqueDTOs) || [];
        const totalElements = (result.data && result.data.totalElements) || "0";
        this.setState({
          isLoading: false,
          historiqueList,
          totalPage: totalElements,
        });
      });
  };

  // function for data change
  componentDidMount() {
    this.getHistoriqueListFromWS();
    this.getListMedicalFromWS();
    this.getPartenaireListFromWS();
    this.getMouvementTypeListFromWS();
  }

  doInventory = () => {
    this.props.history.push({
      pathname: "/admin/addMouvement",
    });
  };

  handleSelectChange = (event) => {
    event.preventDefault();
    const eventId = event.target.id;
    const eventValue = event.target.value;
    this.setState({ [eventId]: eventValue }, () =>
      this.getHistoriqueListFromWS()
    );
  };

  handleChangePage = (event, index) => {
    event.preventDefault();
    if (index > 0 && index <= this.getTotalPage())
      this.setState(
        {
          currentPage: index,
        },
        () => this.getHistoriqueListFromWS()
      );
  };

  handlePage = (event) => {
    this.setState(
      {
        [event.target.id]: parseInt(event.target.value),
      },
      () => this.getHistoriqueListFromWS()
    );
  };

  //function to insert into render
  getHistoriqueList = () => {
    const { historiqueList } = this.state;
    return (
      historiqueList &&
      historiqueList.map((historique, index) => {
        const date = new Date(historique.date);
        const dateString =
          date.getDate() +
          "/" +
          (date.getMonth() + 1) +
          "/" +
          date.getFullYear();
        return (
          <tr key={index}
            className={this.state.selectedLineIndex == index ? "selected" : ""}
          >

            <td className="sticky-col sticky-left p-1">
              {historique.isCancellable && (
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light m-0"
                    href="#"
                    role="button"
                    size="sm"
                    color=""
                    outline={true}
                    style={{ zIndex: 1 }}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ selectedLineIndex: index });
                    }}
                    >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" container="body" positionFixed={true}  right>
                    <DropdownItem
                      href="#"
                      className="clickable"
                      onClick={() => {
                        this.showConfirmationModal(historique);
                      }}>
                      Annuler
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </td>
            <td>
              {(historique.partenaire && historique.partenaire.designation) ||
                "Aucun"}
            </td>
            <td>{dateString || "Aucun"}</td>
            <td>
              {(historique.mouvementType &&
                historique.mouvementType.designation) ||
                "Aucun"}
            </td>
            <td>{(historique.article && historique.article.libelle) || ""}</td>
            <td>
              {(historique.siteSource && historique.siteSource.nom_site) || ""}
            </td>
            <td>
              {(historique.siteDestinataire &&
                historique.siteDestinataire.nom_site) ||
                ""}
            </td>
            <td>{historique.quantityPlanned || 0}</td>
            <td>{historique.quantity || 0}</td>
            
          </tr>
        );
      })
    );
  };

  getSiteList = () => {
    const { siteList } = this.state;
    return (
      siteList &&
      siteList.map((site, index) => (
        <DropdownItem
          key={index}
          onClick={() => this.handleChangeDropDownMenu(1, site.id)}>
          {site.nom_site}
        </DropdownItem>
      ))
    );
  };

  handleChangeDropDownMenu = (index, value) => {
    this.setState(
      {
        idSite: index === 1 ? value : this.state.idSite,
        siteDropDownSelected:
          index === 1
            ? this.state.siteList &&
              this.state.siteList.filter((site) => site.id === value)[0]
              ? this.state.siteList.filter((site) => site.id === value)[0]
                  .nom_site
              : "Tous les sites"
            : this.state.siteDropDownSelected,
        idMouvement: index === 2 ? value : this.state.idMouvement,
        mouvementTypeDropDownSelected:
          index === 2
            ? this.state.mouvementTypeList &&
              this.state.mouvementTypeList.filter(
                (mouvement) => mouvement.id === value
              )[0]
              ? this.state.mouvementTypeList.filter(
                  (mouvement) => mouvement.id === value
                )[0].designation
              : "Tous les types"
            : this.state.mouvementTypeDropDownSelected,
        idPartenaire: index === 3 ? value : this.state.idPartenaire,
        partnerDropDownSelected:
          index === 3
            ? this.state.partenaireList &&
              this.state.partenaireList.filter(
                (partner) => partner.id === value
              )[0]
              ? this.state.partenaireList.filter(
                  (partner) => partner.id === value
                )[0].designation
              : "Tous les partenaires"
            : this.state.partnerDropDownSelected,
      },
      () => this.getHistoriqueListFromWS()
    );
  };

  getPartenaireList = () => {
    const { partenaireList } = this.state;
    return (
      partenaireList &&
      partenaireList.map((partenaire, index) => (
        <DropdownItem
          key={index}
          onClick={() => this.handleChangeDropDownMenu(3, partenaire.id)}>
          {partenaire.designation}
        </DropdownItem>
      ))
    );
  };

  getMouvementTypeList = () => {
    const { mouvementTypeList } = this.state;
    return (
      mouvementTypeList &&
      mouvementTypeList.map((mouvementType, index) => (
        <DropdownItem
          key={index}
          onClick={() => this.handleChangeDropDownMenu(2, mouvementType.id)}>
          {mouvementType.designation}
        </DropdownItem>
      ))
    );
  };

  getTotalPage = () => {
    const { totalPage } = this.state;
    return totalPage;
  };

  cancelConfirmation = () => {
    this.setState({
      confirmationModal: false,
    });
  };
  saveConfirmation = () => {
    this.deleteMouvement();
    this.setState({
      isLoading: false,
    });
  };
  render() {
    const roleName = localStorage.getItem("roleName");
    if (roleName !== "superadmin" && roleName !== "adminadd") {
      this.props.history.push("/admin/accueil");
    }
    const { currentPage, isLoading, pageSize } = this.state;
    const modifiersDropDown = {
      setMaxHeight: {
        enabled: true,
        order: 890,
        fn: (data) => {
          return {
            ...data,
            styles: {
              ...data.styles,
              overflow: "auto",
              maxHeight: "190px",
            },
          };
        },
      },
    };
    return (
      <>
        {this.state.isError && (
          <NotificationModal
            show={this.state.isError}
            message={this.state.errorMessage}
            title={this.state.errorTitle}
            close={this.handleCloseNotification}
          />
        )}
        {this.state.confirmationModal && (
          <ConfirmationModalAnnuler
            onCancel={this.cancelConfirmation}
            onSave={this.saveConfirmation}
          />
        )}
        <div className="header bg-gradient-primary postition-absolute">
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col></Col>
              </Row>
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt-5 custom-card-container" fluid >
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0 d-flex flex-wrap">
                  <div className="mb-2 d-flex flex-wrap mr-auto">
                    <FormGroup>
                      <Label for="idSite">Site</Label>
                      <br />
                      <UncontrolledDropdown>
                        <DropdownToggle caret>
                          {this.state.siteDropDownSelected}
                        </DropdownToggle>
                        <DropdownMenu
                          className="dropdown-menu-arrow"
                          down="true"
                          modifiers={{
                            ...modifiersDropDown,
                          }}>
                          <DropdownItem
                            key={-1}
                            onClick={() =>
                              this.handleChangeDropDownMenu(1, -1)
                            }>
                            Tous les sites
                          </DropdownItem>
                          {this.getSiteList()}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </FormGroup>
                  
                    <FormGroup>
                      <Label for="idMouvement">Type de mouvement</Label>
                      <br />
                      <UncontrolledDropdown>
                        <DropdownToggle caret>
                          {this.state.mouvementTypeDropDownSelected}
                        </DropdownToggle>
                        <DropdownMenu
                          className="dropdown-menu-arrow"
                          down="true"
                          modifiers={{
                            ...modifiersDropDown,
                          }}>
                          <DropdownItem
                            key={-1}
                            onClick={() =>
                              this.handleChangeDropDownMenu(2, -1)
                            }>
                            Tous les types
                          </DropdownItem>
                          {this.getMouvementTypeList()}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </FormGroup>
                  
                    <FormGroup>
                      <Label for="idPartenaire">Partenaire</Label>
                      <br />
                      <UncontrolledDropdown>
                        <DropdownToggle caret>
                          {this.state.partnerDropDownSelected}
                        </DropdownToggle>
                        <DropdownMenu
                          className="dropdown-menu-arrow"
                          down="true"
                          modifiers={{
                            ...modifiersDropDown,
                          }}>
                          <DropdownItem
                            key={-1}
                            onClick={() =>
                              this.handleChangeDropDownMenu(3, -1)
                            }>
                            Tous les partenaires
                          </DropdownItem>
                          {this.getPartenaireList()}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </FormGroup>
                  </div>
                  <div className="mb-2 d-flex flex-wrap ">
                    <FormGroup className="mt-auto mr-4 ">
                      <Button
                        color="primary"
                        onClick={this.doInventory}
                        >
                        Nouveau mouvement
                      </Button>
                    </FormGroup>
                    <FormGroup className="align-items-center d-flex flex-nowrap mt-auto ">
                      <Label className="form-control-label mb-0">
                        <b>Nombre de lignes</b>
                      </Label>
                      <Input
                        id="pageSize"
                        type="select"
                        width={50}
                        style={{
                          display: "inline-block",
                          width: 80,
                          marginLeft: 10,
                          height: 40,
                        }}
                        value={pageSize}
                        onChange={this.handlePage}>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                        <option value={40}>40</option>
                        <option value={50}>50</option>
                      </Input>
                    </FormGroup>
                  </div>
                </CardHeader>
                <div className="table-responsive">
                  <table
                    className="align-items-center table table-bordered table-flush table table-sticky"
                    responsive="true">
                    <thead className="thead-light">
                      <tr>
                        <th className="p-1" />
                        <th scope="col">Partenaire</th>
                        <th scope="col">Date</th>
                        <th scope="col">Type de mouvement</th>
                        <th scope="col">Article</th>
                        <th scope="col">Site source</th>
                        <th scope="col">Site destinataire</th>
                        <th scope="col">Quantité Prévue Transférée</th>
                        <th scope="col">Quantité Transférée</th>
                      </tr>
                    </thead>
                    <tbody>{this.getHistoriqueList()}</tbody>
                  </table>
                  </div>
                <FooterTable
                  currentPage={currentPage}
                  handlePage={this.handlePage}
                  handleChangePage={this.handleChangePage}
                  getTotalPage={this.getTotalPage}
                />
              </Card>
            </div>
          </Row>
          <PageSpinner show={isLoading} />
        </Container>
      </>
    );
  }
}

export default HistoriqueAddPage;
