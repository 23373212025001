import axios from "axios";
import { BACK_URL } from "data/constant/urls";
import authHeader from "./AuthHeader";

// const API_URL = process.env.REACT_APP_URL;
const API_URL = BACK_URL;

class NeedService {
  getList(
    pageNo = 0,
    pageSize = 10,
    siteId = "-1",
    articleId = "-1",
    priority = "",
    state = "",
    sortBy = "id"
  ) {
    return axios.get(
      API_URL +
        "param/besoin/all-pagination?pageNo=" +
        pageNo +
        "&pageSize=" +
        pageSize +
        "&siteId=" +
        siteId +
        "&articleId=" +
        articleId +
        "&priority=" +
        priority +
        "&state=" +
        state +
        "&sortBy=" +
        sortBy,
      {
        headers: authHeader(),
      }
    );
  }

  create(data) {
    return new Promise(async (success, error) => {
      const result = await axios
        .post(`${API_URL}param/besoin/add`, data, {
          headers: authHeader(),
        })
        .catch((exception) => {
          error(exception.response && exception.response.data);
        });
      success(result);
    });
  }

  delete = (id) => {
    return axios.delete(API_URL + `param/besoin/delete?id=${id}`, {
      headers: authHeader(),
    });
  };
  update(data) {
    return new Promise(async (success, error) => {
      const result = await axios
        .put(`${API_URL}param/besoin/update`, data, {
          headers: authHeader(),
        })
        .catch((exception) => {
          error(exception.response && exception.response.data);
        });
      success(result);
    });
  }

  getById(id) {
    return new Promise(async (success, error) => {
      const result = await axios
        .get(`${API_URL}param/besoin/${id}`, {
          headers: authHeader(),
        })
        .catch((exception) => {
          error(exception.response && exception.response.data);
        });
      success(result.data);
    });
  }
}

export default new NeedService();
