import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  Row
} from "reactstrap";
import UtilisateurService from "services/UtilisateurService";
import PageSpinner from "./PageSpinner";
import UserNewPasswordModal from "./UserNewPasswordModal";

class CreateEditUtilisateurModal extends React.Component {
  constructor(props) {
    super(props);
    // this.props.isUpdate = false;
  }

  state = {
    isLoading: false,
    title: "AJOUT NOUVEL UTILISATEUR",
    originalIndividu: {
      nom: "",
      pseudo: "",
      password: "",
      roleName: "",
    },
    individu: {
      nom: "",
      pseudo: "",
      password: "",
      roleName: "",
    },
    requiredFields: {
      nom: "",
      pseudo: "",
      password: "",
      roleName: "",
    },
    showChangePassword : false,
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  componentDidMount() {
    const id = this.props.utilisateur ? this.props.utilisateur.id : "";
    // this.props.isUpdate = id !== "";
    this.props.isUpdate && this.setDefaultData(id);
  }

  setDefaultData = (id) => {
    this.setState({
      isLoading: true,
      title: "MODIFICATION UTILISATEUR",
    });
    UtilisateurService.get(id).then(
      (response) => {
        const individu = response.data.data;
        individu.password = "";
        this.setState({
          originalIndividu: individu,
          individu,
          isLoading: false,
        });
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  handleChange = (evt) => {
    const { name, value } = evt.target;
    let requiredFields = { ...this.state.requiredFields };
    requiredFields[name] = value === "" ? "Champ obligatoire" : "";
    switch (name) {
      case "pseudo":
        if (value.length > 15) {
          return;
        }
        break;
      case "nom":
        if (value.length > 125) {
          return;
        }
        break;
      case "password":
        requiredFields[name] = this.passwordValidation(value);        
        break;
      default:
        break;
    }

    this.setState({
      individu: {
        ...this.state.individu,
        [name]: value,
      },
      requiredFields,
    });
  };

  passwordValidation = (value) => {
    
    if (value.length > 14) {
      return "";
    }
    if (value.length < 8) {
      return "8 charactères minimum";
    }
    const minRegex = "^(?=.*[a-z@#\$%\^\&*\)\(+=._-]).+$";
    const maxRegex = "^(?=.*[A-Z]).+$";
    if (!value.match(minRegex)) {
      return "Minuscule requis";
    }
    if (!value.match(maxRegex)) {
      return "Majuscule requis";
    }
    return ""
  }

  handleChangeRadio = (evt) => {
    const { value } = evt.target;
    let requiredFields = { ...this.state.requiredFields };
    requiredFields.roleName = value === "" ? "Champ obligatoire" : "";
    this.setState({
      individu: {
        ...this.state.individu,
        roleName: value,
      },
      requiredFields,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.isValid()) {
      this.props.isUpdate ? this.processUpdate() : this.processSave();
    }
  };

  isValid = () => {
    const individu = { ...this.state.individu };
    let requiredFields = { ...this.state.requiredFields };  
    const data = Object.entries(individu)
      .map(([key, value]) => ({ key, value }))
      .filter((d) => d.value === "");
    data.forEach((field) => {
      requiredFields[field.key] = "Champ obligatoire";
    });
    
    /** ignore password if update */
    if (this.props.isUpdate) requiredFields.password = "";

    this.setState({
      requiredFields,
    });

    const dataRequired = Object.entries(requiredFields)
    .map(([key, value]) => ({ key, value }))
    .filter((d) => d.value);

    if (dataRequired.length === 0 && (this.state.individu.password === '' && !this.props.isUpdate)) {
      return false;
    }
    const result = dataRequired.filter((elt) => 
      elt.value !== "" && (elt.key != "password" || !this.props.isUpdate)
    );
    if (result.length > 0) {
      return false;
    }
    return true;
  };

  processSave = () => {
    this.setState({
      isLoading: true,
    });
    UtilisateurService.post(this.state.individu).then(
      (data) => {
        this.setState({
          isLoading: false,
        });
        this.props.onSucces();
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
        this.props.onCancel();
      }
    );
  };

  processUpdate = () => {
    this.setState({
      isLoading: true,
    });
    let data = {
      ...this.state.individu,      
    }
    delete data.password
    UtilisateurService.update(data).then(
      (data) => {
        this.setState({
          isLoading: false,
        });
        this.props.onSucces();
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
        this.props.onCancel();
      }
    );
  };

  handleChangePassword = () => {
    this.setState({ showChangePassword : true})
  }
  handleCancelChangePassword = () => {
    this.setState({ showChangePassword : false})
  }
  render() {
    return (
      <>
        {this.state.showChangePassword &&
          <UserNewPasswordModal
            onCancel={this.handleCancelChangePassword}
            passwordValidation={this.passwordValidation}
            individu={this.state.originalIndividu} isOpen={this.state.showChangePassword}
          />
        }
        <PageSpinner show={this.state.isLoading} />
        <Modal className="modal-dialog-centered" isOpen={true} size="lg">
          <div className="modal-body p-0">
            <Card className=" border-0">
              <CardHeader className="bg-light-secondary pb-0">
                <div className="text-muted text-center mt-2 mb-3 ">
                  <h2>{this.state.title}</h2>
                </div>
              </CardHeader>
              <CardBody className="px-lg-4 py-lg-2">
                <Form>
                  <div className="pl-lg-4 pt-2">
                    <Row>
                      <Col lg="4">
                        <FormGroup >
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Pseudo *
                          </label>

                          <Input
                            className={"form-control-alternative " +(this.state.requiredFields.pseudo.length > 0 && 'border-danger ') }
                            id="input-username"
                            placeholder="Pseudo"
                            type="text"
                            name="pseudo"
                            value={this.state.individu.pseudo}
                            onChange={this.handleChange}
                          />
                          {this.state.requiredFields.pseudo.length > 0 && (
                            <small className="text-danger">
                              Champ obligatoire
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Nom et prénoms *
                          </label>

                          <Input
                           className={"form-control-alternative " +(this.state.requiredFields.nom.length > 0 && 'border-danger ') }
                            id="input-username"
                            placeholder="Nom et prénoms"
                            type="text"
                            name="nom"
                            value={this.state.individu.nom}
                            onChange={this.handleChange}
                          />
                          {this.state.requiredFields.nom.length > 0 && (
                            <small className="text-danger">
                              Champ obligatoire
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                     
                      <Col lg="4" className="d-flex align-items-end">
                      {
                        !this.props.isUpdate ?
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Mot de passe *
                          </label>

                          <Input
                           className={"form-control-alternative " +(this.state.requiredFields.password.length > 0 && 'border-danger ') }
                            id="password"
                            placeholder="Mot de passe"
                            type="password"
                            name="password"
                            value={this.state.individu.password}
                            onChange={this.handleChange}
                          />
                          {this.state.requiredFields.password.length > 0 && (
                            <small className="text-danger">
                              {this.state.requiredFields.password}
                            </small>
                          )}
                        </FormGroup>                      
                      :
                        <FormGroup>
                          <Button type="button" className="mt-auto" color="primary" onClick={this.handleChangePassword}>
                            <span>Changer le mot de passe</span>
                          </Button>  
                        </FormGroup>               
                      }
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <label className="text-bold">Role *</label>
                          <br />
                          {this.state.requiredFields.roleName.length > 0 && (
                            <small className="text-danger">
                              Champ obligatoire
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <div className="custom-control custom-radio mb-3">
                            <input
                              className="custom-control-input"
                              id="superadmin"
                              type="radio"
                              name="custom-radio-2"
                              value="superadmin"
                              onChange={this.handleChangeRadio}
                              checked={
                                this.state.individu.roleName === "superadmin"
                              }
                            />
                            <label
                              className="custom-control-label text-bold"
                              htmlFor="superadmin"
                            >
                              Super admin
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="9">
                        <FormGroup>
                          <small htmlFor="superadmin">
                            Compte d’un super admin
                          </small>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <div className="custom-control custom-radio mb-3">
                            <input
                              className="custom-control-input"
                              id="adminadd"
                              type="radio"
                              value="adminadd"
                              name="custom-radio-2"
                              checked={
                                this.state.individu.roleName === "adminadd"
                              }
                              onChange={this.handleChangeRadio}
                            />
                            <label
                              className="custom-control-label text-bold"
                              htmlFor="adminadd"
                            >
                              Gestionnaire
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="9">
                        <FormGroup>
                          <small htmlFor="adminadd">
                            Compte uniquement avec un droit de saisie
                          </small>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <div className="custom-control custom-radio mb-3">
                            <input
                              className="custom-control-input"
                              id="adminconsult"
                              type="radio"
                              value="adminconsult"
                              name="custom-radio-2"
                              checked={
                                this.state.individu.roleName === "adminconsult"
                              }
                              onChange={this.handleChangeRadio}
                            />
                            <label
                              className="custom-control-label text-bold"
                              htmlFor="adminconsult"
                            >
                              Consultation
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="9">
                        <FormGroup>
                          <small htmlFor="adminconsult">
                            Compte uniquement avec un droit de consultation
                          </small>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <div className="custom-control custom-radio mb-3">
                            <input
                              className="custom-control-input"
                              id="adminedit"
                              type="radio"
                              value="adminedit"
                              name="custom-radio-2"
                              checked={
                                this.state.individu.roleName === "adminedit"
                              }
                              onChange={this.handleChangeRadio}
                            />
                            <label
                              className="custom-control-label text-bold"
                              htmlFor="adminedit"
                            >
                              Chef de site
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="9">
                        <FormGroup>
                          <small htmlFor="adminedit">
                            Compte uniquement avec un droit de modification
                          </small>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <div className="custom-control custom-radio mb-3">
                            <input
                              className="custom-control-input"
                              id="admines"
                              type="radio"
                              value="admines"
                              name="custom-radio-2"
                              onChange={this.handleChangeRadio}
                            />
                            <label
                              className="custom-control-label text-bold"
                              htmlFor="admines"
                            >
                              Responsable Entrée/Sortie
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="9">
                        <FormGroup>
                          <small htmlFor="adminconsultexport">
                            Compte uniquement avec un droit de consultation
                            d’export de données
                          </small>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  
                </Form>
              </CardBody>
              <hr className="m-0" />
              <div className="modal-footer">
                <Button type="button" onClick={this.props.onCancel}>
                  Annuler
                </Button>
                <Button type="button" color="primary" onClick={this.handleSubmit}>
                  <span>Enregistrer</span>
                </Button>
              </div>
            </Card>
          </div>
        </Modal>
      </>
    );
  }
}

export default CreateEditUtilisateurModal;
