import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
const StockModal = (props) => {

    const save = () => {
        if (!props.isSuperior) {
            props.useQuantityRegistered()
            props.toggle();
        }
    }

    const getSiteList = () => {
        const { siteList } = props;
        return siteList && siteList.map((site, index) =>
            <option key={index} value={site.id}>{site.nom_site}</option>
        );
    }

    return (
        <Modal isOpen={props.showModal} toggle={props.toggle} className='modal-check' fade={true}>
            <ModalHeader toggle={props.toggle}>Réserver une quantité</ModalHeader>
            <ModalBody style={{ backgroundColor: 'transparent' }}>
                <FormGroup>
                    <Label for="quantityPlanned">Article</Label>
                    <Input
                        type="text"
                        name="quantity"
                        id="article"
                        placeholder="Nom de l'article"
                        value={(props.selectedHistorique && props.selectedHistorique.article && props.selectedHistorique.article.libelle) || ''}
                        disabled
                    />
                </FormGroup>

                <FormGroup>
                    <Label for="quantityPlanned">Site Source</Label>
                    <Input
                        type="text"
                        name="article"
                        id="article"
                        placeholder="Nom de l'article"
                        value={(props.selectedHistorique && props.selectedHistorique.siteSource && props.selectedHistorique.siteSource.nom_site) || ''}
                        disabled
                    />
                </FormGroup>

                <FormGroup>
                    <Label for="quantityPlanned">Site destinataire</Label>
                    <Input
                        type="select"
                        name="category"
                        id="siteDestinataire"
                        value={(props.selectedHistorique.siteDestinataire && props.selectedHistorique.siteDestinataire.id) || -1}
                        onChange={props.handleChangeSite}
                    >
                        <option value={-1}></option>
                        {getSiteList()}
                    </Input>
                </FormGroup>

                <FormGroup>
                    <Label for="quantityPlanned">Quantité réservée disponible</Label>
                    <Input
                        type="number"
                        name="quantityPlanned"
                        id="quantityPlanned"
                        placeholder="Quantité réservée disponible"
                        value={(props.quantityPlanned && props.quantityPlanned) || 0}
                        disabled
                    />
                </FormGroup>

                <FormGroup>
                    <Label for="quantityPlanned">Quantité réservée à libérer</Label>
                    <Input
                        type="number"
                        name="quantityPlanned"
                        id="quantityPlanned"
                        placeholder="Nom de catégorie"
                        value={(props.selectedHistorique && props.selectedHistorique.quantityPlanned) || ''}
                        onChange={props.handleTransfertQuantity}
                    />
                    {props.isSuperior && <Label for="quantityPlanned" style={{ color: 'red', marginTop: 10 }}>Quantité réservée supérieure quantité en stock</Label>}
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={save}>Enregistrer</Button>{' '}
                <Button color="secondary" onClick={props.toggle}>Annuler</Button>
            </ModalFooter>
        </Modal >
    )
}

export default StockModal;

