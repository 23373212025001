import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, Tooltip } from "react-leaflet";
import * as L from "leaflet";
import "./Maps.css"
import MenageService from "services/CarteService";
import markerIcon from "../../../src/assets/img/icons/maps/red-map-marker.png";
import moment from "moment";

function getIconSize(numerateur, denominateur, iconSize) {
    if (numerateur < denominateur) {
        return Math.floor((numerateur * iconSize) / denominateur);
    }
    return iconSize;
}

function generateIconSize(size = 10) {
    const markerIconType = [];

    for (let i = 0; i <= size; i++) {
        const iconSize = 16 + (i * 4);
        markerIconType[i] = L.icon({
            iconUrl: markerIcon,
            iconSize: [iconSize, iconSize],
            popupAnchor: [0,0]
        });
    }

    return markerIconType;
}

function testAlert({covid, nbIndividus, capacity}) {
    // console.log(covid, ', ', nbIndividus, ', ', capacity);
    if ((covid && covid > 0) || (capacity < nbIndividus)) {
        return 'text-red';
    }
    else return 'text-gray';
}

function SiteDetailView(props) {
    const {reference, nom_site, chiefname, chiefContact, nbIndividus, capacity, id, validationDate, covid} = props.siteDetail;
    let valueAlert = testAlert(props.siteDetail);

    return (
        <div>
            <div className={"site-detail-view-title "}>
                <a className={valueAlert} href={process.env.REACT_APP_BASE_FRONT_URL + 'admin/dashboard/sites/' + id }>
                    {(testAlert(props.siteDetail) === 'text-red')&& (<i className="fas fa-exclamation-triangle"></i>)}
                    <span className="maps-text">{(reference)&& ' ' + reference.toUpperCase() + ' : '}{nom_site}</span>
                </a>
            </div>
            <div className="site-detail-view-data">
                <div><span className="maps-text">{chiefname && chiefname}</span></div>
                <div><span className="maps-text">{chiefContact&& chiefContact}</span></div>
                <div className={(nbIndividus > capacity) ? "text-red" : ""}>{(nbIndividus > capacity) && (<i className="fas fa-exclamation-triangle"></i>)}<span className="maps-text">{nbIndividus} / {capacity}</span></div>
                <div 
                    className={(covid && covid > 0)? "text-red" : ""}
                >
                    <span className="maps-text">{(covid && covid > 0) ? (<i className="fas fa-exclamation-triangle"></i>) : ""} COVID: {covid || 0}</span>
                </div>
                <div><span className="maps-text">{validationDate&& moment(validationDate).format('DD/MM/YYYY')}</span></div>
            </div>
        </div>
    )
}

function MarkerView(props) {

    const {latitude, longitude, nbIndividus, capacity} = props.siteDetail;
    const { markerIconType } = props;
    
    return(
        <>
        <Marker position={[latitude, longitude]} icon={markerIconType}>
            {(nbIndividus >= capacity)&& (
                <Tooltip direction="top" opacity={1} permanent>{nbIndividus} / {capacity}</Tooltip>
            )}
        </Marker>
        <Marker position={[latitude, longitude]} icon={markerIconType}>
            <Popup>
                <SiteDetailView siteDetail={props.siteDetail} />
            </Popup>
            <Tooltip direction="top">
                <SiteDetailView siteDetail={props.siteDetail} />
            </Tooltip>
        </Marker>
        </>
        )
}

function verifyAllData(params) {
    const expectedData = {
        "id": { type:"number", isNecessary: true },
        "nom_site": { type: "string", isNecessary: true },
        "capacity": { type:"number", isNecessary: true },
        "chiefContact": { type: "string", isNecessary: false },
        "chiefname": { type: "string", isNecessary: false },
        "latitude": { type: "number", isNecessary: true },
        "longitude": { type: "number", isNecessary: true },
        "nbIndividus": { type: "number", isNecessary: true },
        "reference": { type: "string", isNecessary: false },
        "covid": { type: "number", isNecessary: false},
        "validationDate": {type: "string", isNecessary: false}
    };
    let isDataOk = true;
        
    for (const [key, value] of Object.entries(expectedData)) {
        if (typeof(params[key]) !== value.type && value.isNecessary) {
            isDataOk = false;
            break;
        }
    }
    
    return isDataOk;
}

function MarkersList(props) {
    const {datasRes} = props;
    const iconSize = 10;
    const markerIconType = generateIconSize(iconSize);
    
    return (
        datasRes.map((siteDetail) => (
            (verifyAllData(siteDetail))? (
                <MarkerView 
                    key={`CarteOpenStreetMap-${siteDetail.id}`} 
                    siteDetail={siteDetail} 
                    iconSize={iconSize} 
                    markerIconType={markerIconType[getIconSize(siteDetail.nbIndividus || 0, siteDetail.capacity || 0, iconSize)]}
                 />
            ) : null
        ))
    )
}

function MapSideBar(props) {
    const {datasRes, map} = props;

    return (
        datasRes.sort((a,b) => (a.nom_site.toLowerCase() > b.nom_site.toLowerCase()) ? 1 : ((b.nom_site.toLowerCase() > a.nom_site.toLowerCase()) ? -1 : 0)).map((siteDetail) => (
            (verifyAllData(siteDetail))? (
                <div 
                    key={`CarteOpenStreetMap-${siteDetail.id}`} 
                    className="maps-sidebar-items" 
                    onClick={() => {map.flyTo([siteDetail.latitude, siteDetail.longitude], 18);}}
                >
                    <SiteDetailView siteDetail={siteDetail} />
                </div>
            ) : null
        ))
    );
}

function OpenStreetMap() {
    const position = [-18.910054415137566, 47.53095390598203];
    const [datasRes, updateDatasRes] = useState([]);
    const [map, setMap] = useState(null);

    useEffect(() => {
        try {
            MenageService.getListSite()
            .then((res) => {
                if (res.data.data && res.data.data.length > 0) {
                    updateDatasRes(res.data.data);
                }
            })
        } catch (error) {
            console.error(error);
        }
    }, []);

    return (
        <div className="carte-maps">
            <MapContainer 
                className="carte-maps-container" 
                center={position} zoom={13} 
                minZoom={6} 
                maxZoom={18} 
                scrollWheelZoom={true} 
                whenCreated={map => setMap(map)}
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {(datasRes && datasRes.length > 0)&& <MarkersList datasRes={datasRes} />}
            </MapContainer>
            
            <div className="carte-maps-container-aside bg-white pt-2">
                {(datasRes && datasRes.length > 0)&& <MapSideBar datasRes={datasRes} map={map} />}
            </div>
        </div>
    );
}

export default OpenStreetMap;
