import React from "react";
import ReactApexChart from "react-apexcharts";
import VoinaService from "services/VoinaService";
import Colors from "assets/scss/custom-variables.scss";
import {
  Container,
  Row,
  Input,
  Col,
  FormGroup,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import PageSpinner from "../PageSpinner";
import ReportingService from "services/ReportingService";
import Zonnage from "../Zonnage";
import { barOptions } from "data/constant/chartOptions";

class FokotanyMostAffected extends React.Component {
  state = {
    isLoading: true,
    options: {
      ...barOptions,
      series: [
        {
          name: "Nombre de menages sinistrés",
          data: [],
        },
      ],

      xaxis: {
        ...barOptions.xaxis,
        min: 0,
        max: 100,
        decimalsInFloat: 0,
        categories: [],
      },
    },

    filtre: {
      voinaId: -1,
      region: "",
      district: "",
      commune: "",
      fokotany: "",
      regionId: -1,
      districtId: -1,
      communeId: -1,
      fokotanyId: -1,
    },
  };

  componentDidMount() {
    this.getVoinaListFromWs();
  }

  handleChangeInputFilter = (event) => {
    this.setState(
      {
        filtre: {
          ...this.state.filtre,
          [event.target.name]: parseInt(event.target.value),
        },
      },
      () => {
        this.getReportingDataFokotany();
      }
    );
  };

  getVoinaListFromWs = () => {
    VoinaService.getAllVoina()
      .then((response) => response.data)
      .then((result) => {
        this.setState(
          {
            voinaList: result.data || [],
            isLoading: false,
          },
          () => this.setDefaultVoina()
        );
      });
    // .catch(() => {
    //   this.getList();
    //   this.setState({
    //     isLoading: false,
    //   });
    // });
  };

  setDefaultVoina() {
    //recuperation du voina par defaut ..
    VoinaService.getDefaultVoina().then((result) => {
      if (result.data.data) {
        this.setState(
          {
            filtre: {
              ...this.state.filtre,
              voinaId: parseInt(result.data.data.id),
            },
          },
          () => {
            this.getReportingDataFokotany();
          }
        );
      } else this.getReportingDataFokotany();
    });
  }

  getReportingDataFokotany() {
    ReportingService.getMostAffectedFokotany(this.state.filtre)
      .then((res) => {
        let tempNbMenage = [];
        let tempNomFokotany = [];
        for (const fkt of res?.data?.nombreFokotanyDTOS) {
          tempNomFokotany.push(fkt.nomFokotany);
          tempNbMenage.push(fkt.nbMenage);
        }

        let stateTemp = JSON.parse(JSON.stringify(this.state));

        stateTemp.capacites = tempNbMenage;
        stateTemp.options.series = [
          {
            name: "Nombre de menages sinistrés",
            data: tempNbMenage.map((x) => 0),
          },
        ];
        stateTemp.options.chart.height =
          tempNomFokotany?.length && tempNomFokotany?.length > 0
            ? tempNomFokotany?.length * 60 + 100
            : 240;
        stateTemp.options.xaxis.categories = tempNomFokotany;
        const max = Math.max(...tempNbMenage);
        stateTemp.options.xaxis.max = max < 5 ? 5 : max + (5 - (max % 5));

        this.setState(stateTemp, () => {
          stateTemp.options.series = [
            {
              name: "Nombre de menages sinistrés",
              data: tempNbMenage,
            },
          ];

          stateTemp.isLoading = false;
          this.setState(stateTemp);
        });
      })
      .catch((error) => console.error(error));
  }

  onSearch = () => {
    this.getReportingDataFokotany();
  };

  handleChangeZonage = (situation) => {
    const { filtre } = this.state;
    filtre.communeId = situation?.commune;
    filtre.districtId = situation?.district;
    filtre.regionId = situation?.region;
    filtre.fokotanyId = situation?.fokontany;
    this.setState({ filtre }, () => {
      this.onSearch();
    });
  };

  render() {
    const voinaOptions =
      this.state.voinaList &&
      this.state.voinaList.map((elt, idx) => (
        <option key={elt.id} value={elt.id}>
          {elt.libelle}
        </option>
      ));
    return (
      <>
        <div className="header bg-gradient-primary postition-absolute">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row></Row>
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt-5" fluid>
          <Card className="shadow" style={{ minHeight: 375 }}>
            <CardHeader className="border-0">
              <div className="h3 reporting-title text-primary">
                Les Fokontany les plus touchées
              </div>
            </CardHeader>
            <CardBody>
              <Row>
                <Col lg="12">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Voina
                    </label>
                    <div className="input-group-alternative input-group">
                      <Input
                        type="select"
                        className="mdb-select md-form form-control"
                        onChange={this.handleChangeInputFilter}
                        name="voinaId"
                        value={this.state.filtre.voinaId}
                      >
                        <option value={-1}>Tous les voina</option>
                        {voinaOptions}
                      </Input>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              {/* <FokotanyMostAffectedFunction /> */}
              <Zonnage
                handleChangeZonage={this.handleChangeZonage}
                filter={this.state.filtre}
              />
              <ReactApexChart
                options={this.state.options}
                series={this.state.options?.series}
                type="bar"
                height={this.state.options?.chart?.height}
              />
            </CardBody>
          </Card>
        </Container>
        <PageSpinner show={this.state.isLoading} />
      </>
    );
  }
}

export default FokotanyMostAffected;
