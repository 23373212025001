import AdminLayout from "layouts/Admin.js";
import React from 'react';
import { Redirect, Route } from "react-router-dom";
import AuthService from "services/AuthService";
//import AuthService from "services/AuthService";

const PrivateRoute =({component: Component, ...rest})=>{
  const isLoggedIn = () => {
    return AuthService.isLoggedIn();
  }

  return(
      <Route
      {...rest}
      render={props =>
        isLoggedIn() ? (
          <Route path="/admin" render={props => <AdminLayout {...props} />} />
        ) : (
          <Redirect to={{ pathname: '/auth', state: { from: props.location } }} />
        )
      }
    />
  )
}

export default PrivateRoute;