import React from "react";

import {
  ModalBody,
  ModalHeader,
  Modal
} from "reactstrap";

// import QrReader from 'react-qr-reader'
import QrReader from 'modern-react-qr-reader'

class QRContainer extends React.Component {

  handleScan = data => {
    if (data) {
      // console.log('🚀 ~~~~~~~~~~~~~~~~ 🚀 data', data);
      // data = "Veuillez utiliser l'application officielle Loharano Fokontany pour exploiter les données | 0052722101253825";
      const bookNumber = data.split("| ").pop();
      this.props.onSearch(bookNumber);
    }
  }
  handleError = err => {
    console.error(err)
  }

  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={true}
          size="lg"
        >
          <ModalHeader toggle={this.props.close}>
            QR code Loharano
          </ModalHeader>
          <QrReader
            facingMode={"environment"}
            delay={300}
            onError={this.handleError}
            onScan={this.handleScan}
            style={{ width: '50%', alignSelf: 'center', }}
          />
        </Modal>
      </>
    );
  }
}

export default QRContainer;
