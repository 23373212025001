import React from 'react';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
const NewArticleModal = (props) => {
    const unityOptionList = () => props.unityList && props.unityList.map((unity, index) => <option key={index} value={unity.id}>{unity.libelle}</option>);
    const categoryOptionlist = () => props.categoryList && props.categoryList.map((category, index) => <option key={index} value={category.id}>{category.libelle}</option>);
    const save = () => {
        if (props.selectedArticle && props.selectedArticle.id && props.selectedArticle.id > 0) {
            props.update() && props.toggle()
        } else {
            props.save() && props.toggle()
        }
    }
    return (
        <Modal isOpen={props.showModal} toggle={props.toggle} className='modal-check' fade={true}>
            <ModalHeader toggle={props.toggle}>Ajout de nouveau article</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label for="id">Identifiant</Label>
                    <Input
                        type="text"
                        name="articleId"
                        id="articleId"
                        placeholder="Identifiant de l'article"
                        value={(props.selectedArticle && props.selectedArticle.articleId) || ''}
                        onChange={props.handleChange}
                    />
                    {props.checkerField && props.checkerField.isArticleIdEmpty && <Label for="id" style={{ color: 'red' }}>Champ obligatoire</Label>}
                </FormGroup>
                <FormGroup>
                    <Label for="designation">Désignation</Label>
                    <Input
                        type="text"
                        name="designation"
                        id="libelle"
                        placeholder="Désignation de l'article"
                        value={(props.selectedArticle && props.selectedArticle.libelle) || ''}
                        onChange={props.handleChange}
                    />
                    {props.checkerField && props.checkerField.isDesignationEmpty && <Label for="id" style={{ color: 'red' }}>Champ obligatoire</Label>}
                </FormGroup>
                <FormGroup>
                    <Label for="category">Catégorie</Label>
                    <Input
                        type="select"
                        name="category"
                        id="category"
                        value={(props.selectedArticle && props.selectedArticle.categorie && props.selectedArticle.categorie.id) || -1}
                        onChange={props.handleChange}
                    >
                        <option value={-1}>Pas de catégorie</option>
                        {categoryOptionlist()}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="unity">Unité de gestion</Label>
                    <Input
                        type="select"
                        name="unity"
                        id="unity"
                        value={(props.selectedArticle && props.selectedArticle.unite && props.selectedArticle.unite.id) || -1}
                        onChange={props.handleChange}
                    >
                        <option value={-1}>Pas d'unité</option>
                        {unityOptionList()}
                    </Input>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={save}>Enregistrer</Button>{' '}
                <Button color="secondary" onClick={props.cancel}>Annuler</Button>
            </ModalFooter>
        </Modal >
    )
}

export default NewArticleModal;

