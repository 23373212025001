import React from "react";
import ReactDatetime from "react-datetime";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  UncontrolledTooltip
} from "reactstrap";
import TetheredDateTime from "views/components/TetheredDateTime";

class TraitementIndividu extends React.Component {
  constructor(props) {
    super(props);
    this.traitementAdulte = [
      "Nom",
      "Prénoms",
      "CIN",
      "Date de naissance",
      "Lieu de naissance"
    ];
  }
  state = {
    individues: [],
    removedIndividuIds: []
  };

  componentWillReceiveProps(props) {
    if (props.data) {
      this.getData(props);
    }
    if (props.removedIndividuIds) {
      this.setState({ removedIndividuIds: props.removedIndividuIds })
    }
  }

  getData(props) {
    const individues = props && props.individues ? props.individues : [];
    const menage = props && props.menage ? props.menage : {};
    const chef = {
      nom: menage.nom,
      prenom: menage.prenom,
      cin: menage.cin,
      dateNaissance: menage.dateNaissance,
      lieuNaissance: menage.lieuNaissance
    };

    if (individues.length === 0) {
      individues.push(chef);
    } else {
      individues[0] = { ...menage }
    }
    this.setDefault(individues);
  }

  setDefault(data) {
    if (data) {
      this.setState({
        individues: data,
      });
    }
  }

  addTraitement = (evt) => {
    evt.preventDefault();
    const nom = "";
    const prenom = "";
    const dateNaissance = "";
    const lieuNaissance = "";
    const cin = "";
    const chef = "non";
    const menageid = this.props.menage ? parseInt(this.props.menage.id) : ""

    const individu = { nom, prenom, dateNaissance, lieuNaissance, cin, chef, menageid };
    const data = { ...individu };
    let individues = [...this.state.individues];
    individues.push(data);
    this.setState(
      {
        individues,
      },
      () => {
        this.props.data(this.state.individues);
      }
    );
  };

  removeTraitement = (evt, id) => {
    evt.preventDefault();
    let individues = [...this.state.individues];

    individues = individues.filter((elt, idx) => idx !== id);
    let { removedIndividuIds } = this.state;
    const individu = individues.find((item, index) => index === id);
    individu && removedIndividuIds.push(individu.id);

    this.setState(
      {
        individues,
        removedIndividuIds
      },
      () => {
        this.props.data(this.state.individues);
        this.props.removeIndividu(removedIndividuIds);
      }
    );
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(
      {
        mendateNaissance: {
          ...this.state.mendateNaissance,
          [name]: value,
        },
      },
      () => {
        this.props.data(this.state.individues);
      }
    );
  };

  handleChangeTraitement = (event, idx, num, type) => {
    let value = "";
    if (num === "dateNaissance") {
      if (event.isValid) {
        value = event.format("YYYY");
      }
    } else {
      event.preventDefault();
      value = event.target.value;
    }

    let individues = [...this.state.individues];
    const data = individues.filter((elt, id) => id === idx)[0];


    switch (num) {
      case "age":
        data[num] = value;
        data["dateNaissance"] = (new Date().getFullYear() - value).toString();
        break;
      case "dateNaissance":
        data[num] = value;
        data["age"] = new Date().getFullYear() - value;
        break;
      default:
        data[num] = value;
        break;
    }

    individues = individues.map((elt, id) => {
      if (id === idx) {
        elt = data;
      }
      return elt;
    });

    this.setState(
      {
        individues,
      },
      () => {
        this.props.data(this.state.individues);
      }
    );
  };


  getAge(data) {
    if (data.age) {
      return data.age;
    }
    else if (data.dateNaissance) {
      return data.dateNaissance.toString().includes("/") ? new Date().getFullYear() - (data.dateNaissance.split("/").pop()) : new Date().getFullYear() - data.dateNaissance;
    }
    else { return "" }
  }

  getDateNaissance(data) {
    if (data.dateNaissance) {
      return data.dateNaissance.toString().includes("/") ? data.dateNaissance.split("/").pop() : data.dateNaissance
    }
    else if (data.age) {
      return new Date().getFullYear() - data.age;
    }
    else { return "" }
  }


  render() {
    const { readOnly } = this.props;
    const individuBody = this.state.individues.map((elt, index) => {
      return (
        <tr key={index}>
          {!readOnly && <td className="col-action">
            {index > 0 && (
              <FormGroup>
                <button
                  className="btn m-0"
                  id='stooltip6112347451'                  
                  type="button"
                  onClick={(event) => this.removeTraitement(event, index)}
                >
                  <i className="fa fa-trash-alt" />
                </button>
                <UncontrolledTooltip
                  delay={0}
                  placement="top"
                  target="stooltip6112347451"
                >
                  Supprimer
                </UncontrolledTooltip>
              </FormGroup>
            )}
          </td>}

          <td>
            <FormGroup>
              <Input
                className="form-control-alternative"
                placeholder=""
                type="text"
                name="nom"
                onChange={(event) =>
                  this.handleChangeTraitement(event, index, "nom", "individu")
                }
                value={elt.nom}
                disabled={index === 0}
              />
            </FormGroup>
          </td>

          {/* <td>
            <FormGroup>
              <Input
                className="form-control-alternative"
                placeholder=""
                type="text"
                name="prenom"
                onChange={(event) =>
                  this.handleChangeTraitement(event, index, "prenom", "individu")
                }
                value={elt.prenom}
                disabled={index === 0}
              />
            </FormGroup>
          </td> */}

          <td>
            <FormGroup>
              <Input
                className="form-control-alternative"
                placeholder=""
                type="number"
                // min={1}
                name="cin"
                onChange={(event) =>
                  this.handleChangeTraitement(event, index, "cin", "traitement")
                }
                value={elt.cin}
                disabled={index === 0}
              />
            </FormGroup>
          </td>

          <td className="col-mini">
            <FormGroup>
              <Input
                className="form-control-alternative"
                placeholder=""
                type="text"
                type="number"
                min={0}
                onChange={(event) =>
                  this.handleChangeTraitement(event, index, "age", "traitement")
                }
                name="age"
                value={this.getAge(elt)}
                disabled={index === 0}
              />
            </FormGroup>
          </td>

          <td>
            <Form autoComplete="off">
              <FormGroup autoComplete="off">
                <InputGroup
                  className="input-group-alternative  flex-nowrap"
                  autoComplete="off"
                >
                  <InputGroupAddon addonType="prepend" autoComplete="off">
                    <InputGroupText autoComplete="off">
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <TetheredDateTime
                    inputProps={{
                      placeholder: "",
                      onKeyDown: (e) => e.preventDefault(),
                      disabled: index === 0
                    }}
                    disableOnClickOutside={false}
                    dateFormat="YYYY"
                    timeFormat={false}
                    closeOnSelect={true}
                    autocomplete="off"
                    onChange={(event) =>
                      this.handleChangeTraitement(
                        event,
                        index,
                        "dateNaissance",
                        "individu"
                      )
                    }
                    name="dateNaissance"
                    selected={elt.dateNaissance}
                    value={this.getDateNaissance(elt)}
                    disabled={index === 0}
                  />
                </InputGroup>
              </FormGroup>
            </Form>
          </td>

          <td>
            <FormGroup>
              <Input
                className="form-control-alternative"
                placeholder=""
                type="text"
                name="lieuNaissance"
                onChange={(event) =>
                  this.handleChangeTraitement(event, index, "lieuNaissance", "individu")
                }
                value={elt.lieuNaissance}
                disabled={index === 0}
              />
            </FormGroup>
          </td>
        </tr>
      );
    });

    return (
      <>
        <Row>
          <Col>
            <FormGroup className={ !readOnly ? "m-0" : ""}>
              <div className="h3 text-primary d-flex justify-content-between align-items-center">
                <span>Liste individus</span>
                {!readOnly && <div>
                  <Button
                    className="m-2"
                    color="primary" type="button"
                    onClick={this.addTraitement}
                    id="tooltip611234744"
                    >
                    <i className="fa fa-plus" />
                  </Button>
                  <UncontrolledTooltip
                    delay={0}
                    placement="top"
                    target="tooltip611234744"
                  >                
                    Ajouter
                  </UncontrolledTooltip>
                  </div>
                }
              </div>
            </FormGroup>
          </Col>
            
        </Row>
        <Row>
          <Col lg="12">
            <div className="table-responsive table-secondary">
              <table className="traitement table-form" cellSpacing="0" cellPadding="0">
                <thead>
                  <tr>
                    {!readOnly && (
                      <th className="col-action">
                        <small className="text-bold">Action</small>
                      </th>
                    )}
                    <th className="">
                      <small className="text-bold">Nom et Prénom</small>
                    </th>
                    {/* <th className="">
                      <small className="text-bold">Prénoms</small>
                    </th> */}
                    <th className="">
                      <small className="text-bold">CIN</small>
                    </th>
                    <th className="col-mini">
                      <small className="text-bold">Age</small>
                    </th>
                    <th className="">
                      <small className="text-bold">Date de naissance</small>
                    </th>
                    <th className="">
                      <small className="text-bold">Lieu de naissance</small>
                    </th>
                  </tr>
                </thead>
                <tbody>{individuBody}</tbody>
              </table>
            </div>
            
          </Col>
        </Row>
      </>
    );
  }
}

export default TraitementIndividu;
