import axios from "axios";
import { BACK_URL } from "data/constant/urls";
import authHeader from "./AuthHeader";
import { filterAndPageable } from "../common/RequestUtils";

// const API_URL = process.env.REACT_APP_URL;
const API_URL = BACK_URL;

class MenageService {
  getList() {
    return axios.get(API_URL + "api/menage/findAll", {
      headers: authHeader(),
    });
  }
  getIndividusByMenageId(id) {
    return axios.get(API_URL + "api/individu/findIndividuByMenage?id=" + id, {
      headers: authHeader(),
    });
  }
  getFicheMenageById(id) {
    return axios.get(API_URL + "api/menage/findFichemenage?id=" + id, {
      headers: authHeader(),
    });
  }

  post(data) {
    return axios.post(API_URL + "traboina/tbd/addMenageIndividu", data, {
      headers: authHeader(),
    });
  }

  postMenageIndividu(data) {
    return axios.post(API_URL + "api/menage/addMenageIndividu", data, {
      headers: authHeader(),
    });
  }

  updateMenageIndividu(data) {
    return axios.put(API_URL + "api/menage/updateMenageindividu", data, {
      headers: authHeader(),
    });
  }

  delete(id) {
    return axios.delete(API_URL + "sinistre/deletemenage?id=" + id, {
      headers: authHeader(),
    });
  }

  update(data) {
    return axios.put(API_URL + "api/menage/updatemenage", data, {
      headers: authHeader(),
    });
  }

  getMenageById(id) {
    return new Promise(async (success, error) => {
      const result = axios
        .get(API_URL + `sinistre/findmenage?id=${id}`, {
          headers: authHeader(),
        })
        .catch((exception) => {
          error(exception.response && exception.response.data);
        });
      success(result);
    });
  }

  getById(id) {
    return new Promise(async (success, error) => {
      const result = axios
        .get(API_URL + `sinistre/${id}`, {
          headers: authHeader(),
        })
        .catch((exception) => {
          error(exception.response && exception.response.data);
        });
      success(result);
    });
  }

  getListByPagination(page, size, filtre) {
    let data = [];
    filtre.nom &&
      filtre.nom.length > 0 &&
      data.push({ option: "nom", value: filtre.nom });
    filtre.prenom &&
      filtre.prenom.length > 0 &&
      data.push({ option: "prenom", value: filtre.prenom });
    filtre.cin &&
      filtre.cin.length > 0 &&
      data.push({ option: "cin", value: filtre.cin });
    filtre.region &&
      filtre.region.length > 0 &&
      data.push({ option: "region", value: filtre.region });
    filtre.district &&
      filtre.district.length > 0 &&
      data.push({ option: "district", value: filtre.district });
    filtre.fokotany &&
      filtre.fokotany.length > 0 &&
      data.push({ option: "fokotany", value: filtre.fokotany });
    filtre.commune &&
      filtre.commune.length > 0 &&
      data.push({ option: "commune", value: filtre.commune });
    page && data.push({ option: "pageNo", value: page });
    size && data.push({ option: "pageSize", value: size });

    return axios.get(
      API_URL + "sinistre/findAllPagination" + filterAndPageable(data),
      {
        headers: authHeader(),
      }
    );
  }

  impression(id) {
    const url = `${API_URL}api/menage/export?id=${id}`;
    const method = "GET";
    const responseType = "blob";
    return axios.request({
      url,
      method,
      responseType,
      headers: authHeader(),
    });
  }

  deleteMenages(ids) {
    return axios.delete(API_URL + "sinistre/deletemenagelot?" + ids, {
      headers: authHeader(),
    });
  }

  exportDatas(data) {
    const url = API_URL + "api/export/menage";
    const method = "POST";
    const responseType = "arraybuffer";
    return axios.request({
      url,
      method,
      responseType,
      data,
      headers: authHeader(),
    });
  }
}

export default new MenageService();
