import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
const StockModal = (props) => {

    const save = () => {
        if (props.isRegister) {
            props.registerQuantityPlanned()
        } else {
            props.cancelRegistrationQuantityPlanned()
        }

    }
    return (
        <Modal isOpen={props.showModal} toggle={props.toggle} className='modal-check' fade={true}>
            <ModalHeader toggle={props.toggle}>Réserver une quantité</ModalHeader>
            <ModalBody style={{ backgroundColor: 'transparent' }}>
                <FormGroup>
                    <Label for="quantityPlanned">Article</Label>
                    <Input
                        type="text"
                        name="quantity"
                        id="article"
                        placeholder="Nom de l'article"
                        value={(props.selectedStock && props.selectedStock.article && props.selectedStock.article.libelle) || ''}
                        disabled
                    />
                </FormGroup>
                {props.isRegister && (
                    <FormGroup>
                        <Label for="quantityPlanned">Quantité disponible</Label>
                        <Input
                            type="number"
                            name="quantity"
                            id="qt_real"
                            placeholder="Quantité disponible"
                            value={(props.selectedStock && props.selectedStock.qt_real) || 0}
                            disabled
                        />
                    </FormGroup>
                )}

                {!props.isRegister && <FormGroup>
                    <Label for="quantityPlanned">Quantité réservée disponible</Label>
                    <Input
                        type="number"
                        name="quantityPlanned"
                        id="quantityPlanned"
                        placeholder="Quanit"
                        value={(props.quantityPlanned && props.quantityPlanned) || 0}
                        disabled={!props.isRegister}
                    />
                    {props.isSuperior && props.isRegister && <Label for="quantityPlanned" style={{ color: 'red', marginTop: 10 }}>Quantité réservée supérieure quantité en stock</Label>}
                </FormGroup>}

                <FormGroup>
                    <Label for="quantityPlanned">{props.isRegister ? 'Quantité à réserver' : 'Quantité réservée à libérer'}</Label>
                    <Input
                        type="number"
                        name="qt_planned"
                        id="qt_planned"
                        placeholder="Nom de catégorie"
                        value={(props.selectedStock && props.selectedStock.qt_planned) || ''}
                        onChange={props.handleQuantityChange}
                        disabled={props.isRegister ? false : !props.isCancel}
                    />
                    {props.isSuperior && !props.isRegister && <Label for="quantityPlanned" style={{ color: 'red', marginTop: 10 }}>Quantité à libérier supérieure à la quantité réservée disponible</Label>}
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={save}>Enregistrer</Button>{' '}
                <Button color="secondary" onClick={props.toggle}>Annuler</Button>
            </ModalFooter>
        </Modal >
    )
}

export default StockModal;

