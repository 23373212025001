import React from "react";
import { Nav, NavLink, NavItem, TabContent, TabPane, Container, Card, CardBody } from "reactstrap";
import {
  NavLink as RouterNavLink,
  Route,
  Switch,
  Redirect,
  useLocation,
  useRouteMatch
} from "react-router-dom";

function ReportingContainer({components}) {
  /** components example */
  // {
  //   xxx: { tabTitle : "xxx", component: React.lazy(() => import("./xxx"))},
  //   xxxxx: { tabTitle : "xxx xxxx", component: React.lazy(() => import("./xxx xxxx"))}
  // };
  let { path, url } = useRouteMatch();
  const { pathname } = useLocation();
  const getRoute = (route) => `/${route}`;

  const renderNavItems = () =>
    Object.entries(components).map((comp) => (      
      <NavItem key={`${comp[0]}-nav-item`} >
        <NavLink tag={RouterNavLink} to={`${url}${getRoute(comp[0])}`} exact className="px-3 py-2">
          {comp[1].tabTitle}
        </NavLink>
      </NavItem>
  ));

  const renderRoutes = () =>
    Object.entries(components).map(([route, Component]) => (
      <Route key={`${route}-route`} 
        path={`${path}${getRoute(route)}`}>
        <TabPane tabId={getRoute(route)}>
          <Component.component />
        </TabPane>
      </Route>
  ));

  const lastPath = pathname.split('/');
  return (
    <>
      <>
      <div className="header bg-gradient-primary postition-absolute"></div>
      {/* Page content */}
        <Container className="mt-5" fluid>
          <Card className="shadow" style={{ minHeight: 375 }}>
            <CardBody>
            <Nav tabs>{renderNavItems()}</Nav>
            <TabContent activeTab={'/'+lastPath[lastPath.length - 1]} className="p-3">
              <React.Suspense fallback={<div className="h4 text-center text-secondary p-6">Loading...</div>}>
                <Switch>
                  {renderRoutes()}
                  <Redirect from="/" to={`${pathname}/${Object.keys(components)[0]}`} />
                </Switch>                
              </React.Suspense>
            </TabContent>
              {/* {props.children} */}
            </CardBody>
          </Card>
        </Container>
      </>
    </>
  );
}

export default ReportingContainer;
