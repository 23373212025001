import React from "react";
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Form,
  UncontrolledTooltip,
} from "reactstrap";
import TetheredDateTime from "views/components/TetheredDateTime";
import CustomInput from "./input";
import nationalites from "common/nationalites";

export const individuList = ({
  menageIndividu,
  requiredFields,
  readOnly,
  getBirthYear,
  handleChangeInputIndividu,
  handleChangeDateIndividu,
  removeIndividu,
  handleDateCin
}) =>
  menageIndividu?.individu &&
  menageIndividu?.individu.map((elt, index) => {
    return (
      <tr key={index}>
        {!readOnly && (
          <td className="col-action">
            {index > 0 && (
              <FormGroup>
                <button
                  className="btn m-0"
                  id="stooltip6112347451"
                  type="button"
                  onClick={() => removeIndividu(elt, index)}>
                  <i className="fa fa-trash-alt" />
                </button>
                <UncontrolledTooltip
                  delay={0}
                  placement="top"
                  target="stooltip6112347451">
                  Supprimer
                </UncontrolledTooltip>
              </FormGroup>
            )}
          </td>
        )}

        <td>
          <CustomInput
            name="nom"
            handleChangeInputIndividu={(event) =>
              handleChangeInputIndividu(event, index)
            }
            value={elt.nom}
            isRequired={
              index === 0 &&
              requiredFields &&
              requiredFields?.nom &&
              requiredFields?.nom?.length > 0
            }
            fieldRequiredMessage={requiredFields?.nom}
          />
        </td>

        <td>
          <CustomInput
            name="prenom"
            handleChangeInputIndividu={(event) =>
              handleChangeInputIndividu(event, index)
            }
            value={elt.prenom}
            isRequired={
              index === 0 &&
              requiredFields &&
              requiredFields?.prenom &&
              requiredFields?.prenom?.length > 0
            }
            fieldRequiredMessage={requiredFields.prenom}
          />
        </td>
        <td>
          <CustomInput
            name="cin"
            handleChangeInputIndividu={(event) =>
              handleChangeInputIndividu(event, index)
            }
            value={elt.cin || ""}
            isRequired={
              index === 0 &&
              requiredFields &&
              requiredFields?.cin &&
              requiredFields?.cin?.length > 0
            }
            fieldRequiredMessage={requiredFields.cin}
            maxLength={12}
          />
        </td>
        <td>
          <FormGroup>
            <InputGroup className="input-group-alternative flex-nowrap">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-calendar-grid-58" />
                </InputGroupText>
              </InputGroupAddon>
              <TetheredDateTime
                inputProps={{
                  placeholder: "Choisir date",
                  onKeyDown: (e) => e.preventDefault(),
                }}
                viewMode={"years"}
                format={"DD/MM/YYYY"}
                closeOnSelect={true}
                timeFormat={false}
                onChange={(event) => handleDateCin(event, index)}
                name="dateCin"
                selected={elt.dateCin}
                value={elt.dateCin}
              />
            </InputGroup>
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <Input
              className="form-control-alternative"
              id="input-lieuCin"
              placeholder=""
              type="text"
              name="lieuCin"
              value={elt?.lieuCin || ""}
              onChange={(event) => handleChangeInputIndividu(event, index)}
              //   onFocus={  onFocus}
              //   onBlur={  onBlur}
            />
            {index === 0 &&
              requiredFields &&
              requiredFields?.lieuCin &&
              requiredFields?.lieuCin?.length > 0 && (
                <small className="text-danger"> {requiredFields.lieuCin}</small>
              )}
          </FormGroup>
        </td>
        <td className="col-mini">
          <FormGroup>
            <Input
              className="form-control-alternative"
              placeholder=""
              type="number"
              min={0}
              onChange={(event) => handleChangeInputIndividu(event, index)}
              name="age"
              value={elt?.age || ""}
            />
          </FormGroup>
        </td>

        <td>
          <Form autoComplete="off">
            <FormGroup autoComplete="off">
              <InputGroup
                className="input-group-alternative  flex-nowrap"
                autoComplete="off">
                <InputGroupAddon addonType="prepend" autoComplete="off">
                  <InputGroupText autoComplete="off">
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <TetheredDateTime
                  inputProps={{
                    placeholder: "",
                    onKeyDown: (e) => e.preventDefault(),
                  }}
                  disableOnClickOutside={false}
                  dateFormat="YYYY"
                  timeFormat={false}
                  closeOnSelect={true}
                  autocomplete="off"
                  placeholder="Choisir l'année"
                  onChange={(event) => handleChangeDateIndividu(event, index)}
                  name="dateNaissance"
                  selected={elt.dateNaissance}
                  value={getBirthYear(elt.dateNaissance)}
                />
              </InputGroup>
            </FormGroup>
          </Form>
        </td>

        <td>
          <FormGroup>
            <Input
              className="form-control-alternative"
              placeholder=""
              type="text"
              name="lieuNaissance"
              onChange={(event) => handleChangeInputIndividu(event, index)}
              value={elt?.lieuNaissance || ""}
            />
            {index === 0 && requiredFields && requiredFields.lieuNaissance && (
              <small className="text-danger">
                {requiredFields.lieuNaissance}
              </small>
            )}
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <div className="input-group-alternative input-group">
              <select
                className="mdb-select md-form  form-control"
                onChange={(event) => handleChangeInputIndividu(event, index)}
                name="sexe"
                value={elt?.sexe}>
                <option value=""></option>
                <option value="Masculin">Masculin</option>
                <option value="Feminin">Feminin</option>
              </select>
            </div>
            {index === 0 && requiredFields && requiredFields.gender && (
              <small className="text-danger">{requiredFields.gender}</small>
            )}
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <div className="input-group-alternative input-group">
              <select
                className="mdb-select md-form  form-control"
                onChange={(event) => handleChangeInputIndividu(event, index)}
                name="nationalite">
                <option></option>
                {nationalites.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.value}
                  </option>
                ))}
              </select>
            </div>
            {index === 0 && requiredFields && requiredFields.nationalite && (
              <small className="text-danger">
                {requiredFields.nationalite}
              </small>
            )}
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <Input
              className="form-control-alternative"
              id="input-pere"
              placeholder=""
              type="text"
              name="pere"
              value={elt?.pere || ""}
              onChange={(event) => handleChangeInputIndividu(event, index)}
              //   onFocus={  onFocus}
              //   onBlur={  onBlur}
            />
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <Input
              className="form-control-alternative"
              id="input-mentionPere"
              placeholder=""
              type="text"
              name="mentionPere"
              value={elt?.mentionPere || ""}
              onChange={(event) => handleChangeInputIndividu(event, index)}
              //   onFocus={  onFocus}
              //   onBlur={  onBlur}
            />
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <Input
              className="form-control-alternative"
              id="input-mere"
              placeholder=""
              type="text"
              name="mere"
              value={elt?.mere || ""}
              onChange={(event) => handleChangeInputIndividu(event, index)}
              //   onFocus={  onFocus}
              //   onBlur={  onBlur}
            />
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <Input
              className="form-control-alternative"
              id="input-mentionMere"
              placeholder=""
              type="text"
              name="mentionMere"
              value={elt?.mentionMere || ""}
              onChange={(event) => handleChangeInputIndividu(event, index)}
              //   onFocus={  onFocus}
              //   onBlur={  onBlur}
            />
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <Input
              className="form-control-alternative"
              placeholder=""
              type="text"
              value={elt?.telephone || ""}
              maxLength={10}
              minLength={10}
              onChange={(event) => handleChangeInputIndividu(event, index)}
              name="telephone"
            />
            {elt?.telephone?.length > 0 &&
              requiredFields &&
              requiredFields?.telephone &&
              requiredFields?.telephone?.length > 0 && (
                <small className="text-danger">
                  {" "}
                  {requiredFields.telephone}
                </small>
              )}
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <Input
              className="form-control-alternative"
              placeholder=""
              type="text"
              value={elt.situationMatrimoniale || ""}
              onChange={(event) => handleChangeInputIndividu(event, index)}
              name="situationMatrimoniale"
            />
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <Input
              className="form-control-alternative"
              placeholder=""
              type="text"
              value={elt?.activite || ""}
              onChange={(event) => handleChangeInputIndividu(event, index)}
              name="activite"
            />
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <Input
              className="form-control-alternative"
              placeholder=""
              type="text"
              value={elt?.observation || ""}
              onChange={(event) => handleChangeInputIndividu(event, index)}
              name="observation"
            />
          </FormGroup>
        </td>
        <td>
          {" "}
          <FormGroup>
            <div className="input-group-alternative input-group">
              <select
                className="mdb-select md-form  form-control"
                onChange={(event) => handleChangeInputIndividu(event, index)}
                name="handicape"
                value={elt?.handicape || false}>
                <option value="false">Non</option>
                <option value="true">Oui</option>
              </select>
            </div>
          </FormGroup>
        </td>
      </tr>
    );
  });

export default individuList;
