import React from "react";
import DonService from "services/DonService";
import moment from "moment";

import {
  Button,
  Modal,
  CardHeader,
  Card,
  ModalFooter,
  FormGroup,
  Table,
  Label,
  CardBody,
} from "reactstrap";

const DonListModal = (props) => {
  const [allDon, setAllDon] = React.useState([]);

  React.useEffect(() => {
    DonService.findDonByMenageId(props?.menage?.id).then((res) => {
      if (res?.data?.data) {
        setAllDon(res.data.data);
      }
    });
  }, [props.menage.id]);

  return (
    <Modal
      centered={true}
      className="modal-dialog-centered modal-check"
      size="lg"
      isOpen={props.showModal}
      toggle={props.toggle}
      fade={true}
    >
      <Card className="shadow">
        <CardHeader className="pb-0">
          <div className="text-muted text-center mt-2 mb-3 ">
            <h2>Liste des dons pour le menage :</h2>
          </div>
        </CardHeader>
        <CardBody style={{ backgroundColor: "transparent" }}>
          <FormGroup>
            <div>
              <Label className="ml-1" style={{ fontSize: 16, marginTop: 6 }}>
                <b>Id Menage : </b>
              </Label>{" "}
              <span>{props?.menage?.id || ""}</span>
            </div>
            <div>
              <Label className="ml-1" style={{ fontSize: 16, marginTop: 6 }}>
                <b>Chef de famille : </b>{" "}
              </Label>{" "}
              <span>
                {props?.menage?.nomChef ? props?.menage?.nomChef : ""}{" "}
                {props?.menage?.prenomChef &&
                props?.menage?.prenomChef != null &&
                props?.menage?.prenomChef !== "null"
                  ? props?.menage?.prenomChef
                  : ""}
              </span>
            </div>
          </FormGroup>
          <Table className="align-items-center table-flush table" responsive>
            <thead className="thead-light">
              <tr>
                {/* <th scope="col">id</th> */}
                <th scope="col">Date du don</th>
                <th scope="col">Menage</th>
                <th scope="col">Type du don</th>
                <th scope="col">Voina</th>
              </tr>
            </thead>

            <tbody>
              {allDon &&
                Array.isArray(allDon) &&
                allDon.map((don, index) => {
                  return (
                    <tr key={index}>
                      {/* <td>{don.id || ""}</td> */}
                      <td>
                        {don.dateDon
                          ? moment.utc(don.dateDon).format("DD/MM/YYYY HH:mm")
                          : ""}
                      </td>
                      <td>{don.menage?.id}</td>
                      <td>{don.typeDon?.name || ""}</td>
                      <td>{don.voina?.libelle || ""}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <ModalFooter>
        <Button color="secondary" onClick={props.toggle}>
          Fermer
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DonListModal;
