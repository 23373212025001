import React from "react";
import ReactDatetime from "react-datetime";
import {
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import moment from "moment";

class ReadEnqueteur extends React.Component {
  constructor(props) {
    super(props);
    this.id = "";
  }
  state = {
    enqueteInfo: {
      enqueteur: "",
      dateEnquete: "",
    },
    requiredFields: {
      enqueteur: "",
    },
  };
  componentWillReceiveProps = (props) => {
    this.setState({
      enqueteInfo: props.data.enqueteInfo,
    });
  };
  render() {
    return (
      <>
        <Row>
          <Col>
            <FormGroup>
              <h4 className="text-primary">Enqueteur</h4>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label className="form-control-label" htmlFor="input-enqueteur">
                Nom de l'enquêteur
              </label>
              <Input
                className="form-control-alternative"
                id="input-enqueteur"
                placeholder=""
                type="text"
                name="enqueteur"
                defaultValue={this.state.enqueteInfo?.enqueteur}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-date-enquete"
              >
                Date et heure de l'enquête
              </label>
              <Input
                className="form-control-alternative"
                id="input-dateEnquete"
                placeholder=""
                type="text"
                name="dateEnquete"
                value={moment.utc(this.state.enqueteInfo?.dateEnquete).format('DD/MM/YYYY HH:mm')}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }
}

export default ReadEnqueteur;
