import axios from "axios";
import { BACK_URL } from "data/constant/urls";
import authHeader from "./AuthHeader";

// const API_URL = process.env.REACT_APP_URL;
const API_URL = BACK_URL;

class SiteMedicaleService {
  getList(
    pageNo = 0,
    pageSize = 10,
    nom_site = "",
    reference = "",
    state = "",
    filtreZonage
  ) {
    let zonage = "";

    if (filtreZonage) {
      if (filtreZonage.regionId && filtreZonage.regionId !== -1)
        zonage += "&region=" + filtreZonage.regionId;
      if (filtreZonage.districtId && filtreZonage.districtId !== -1)
        zonage += "&district=" + filtreZonage.districtId;
      if (filtreZonage.communeId && filtreZonage.communeId !== -1)
        zonage += "&commune=" + filtreZonage.communeId;
      if (filtreZonage.fokotanyId && filtreZonage.fokotanyId !== -1)
        zonage += "&fokotany=" + filtreZonage.fokotanyId;
      if (filtreZonage.siteType) zonage += "&typeSite=" + filtreZonage.siteType;
    }

    return axios.get(
      API_URL +
        "api/sites/findAllPagination?pageNo=" +
        pageNo +
        "&pageSize=" +
        pageSize +
        "&nomsite=" +
        nom_site +
        "&reference=" +
        reference +
        "&state=" +
        state +
        zonage,
      {
        headers: authHeader(),
      }
    );
  }

  getAllSite() {
    return axios.get(API_URL + "api/sites/findAllView", {
      headers: authHeader(),
    });
  }

  get(id) {
    return new Promise(async (success, error) => {
      const result = axios
        .get(API_URL + `api/sites/findSiteById?id=${id}`, {
          headers: authHeader(),
        })
        .catch((exception) => {
          error(exception.response && exception.response.data);
        });
      success(result);
    });
  }

  getSiteTBById(id) {
    return new Promise(async (success, error) => {
      const result = axios
        .get(API_URL + `api/sites/${id}`, {
          headers: authHeader(),
        })
        .catch((exception) => {
          error(exception.response && exception.response.data);
        });
      success(result);
    });
  }

  getSiteById(id) {
    return new Promise(async (success, error) => {
      const result = axios
        .get(API_URL + `api/sites/findSiteById?id=${id}`, {
          headers: authHeader(),
        })
        .catch((exception) => {
          error(exception.response && exception.response.data);
        });
      success(result);
    });
  }

  delete(id) {
    return new Promise(async (success, error) => {
      const result = await axios
        .delete(API_URL + `api/sites/delete?id=${id}`, {
          headers: authHeader(),
        })
        .catch((exception) => {
          error(exception.response && exception.response.data);
        });
      success(result);
    });
  }

  update(data) {
    return new Promise(async (success, error) => {
      const result = await axios
        .put(`${API_URL}api/sites/update`, data, {
          headers: authHeader(),
        })
        .catch((exception) => {
          error(exception.response && exception.response.data);
        });
      success(result);
    });
  }

  reopen(id) {
    return new Promise(async (success, error) => {
      const result = await axios
        .put(`${API_URL}api/sites/${id}/reopen`, null, {
          headers: authHeader(),
        })
        .catch((exception) => {
          error(exception.response && exception.response.data);
        });
      success(result);
    });
  }

  close(id) {
    return new Promise(async (success, error) => {
      const result = await axios
        .put(`${API_URL}api/sites/${id}/close`, null, {
          headers: authHeader(),
        })
        .catch((exception) => {
          error(exception.response && exception.response.data);
        });
      success(result);
    });
  }

  create(data) {
    return new Promise(async (success, error) => {
      const result = await axios
        .post(`${API_URL}api/sites/add`, data, {
          headers: authHeader(),
        })
        .catch((exception) => {
          error(exception.response && exception.response.data);
        });
      success(result);
    });
  }
}

export default new SiteMedicaleService();
