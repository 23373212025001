import InformationGenerale from "components/enrolement/InformationGenerale";
import Enqueteur from "components/enrolement/Enqueteur";
import React from "react";
// reactstrap components// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Container,
  Input,
  Modal,
  Row,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { Col, Form } from "react-bootstrap";

import EnrolementEnregistrementServices from "services/EnrolementEnregistrementServices";
import PageSpinner from "views/components/PageSpinner";
import ReadInformationSupplementaire from "components/enrolement/ReadInformationSupplementaire";
import NotificationModal from "./NotificationModal";
import ReadInformationGenerale from "../../components/enrolement/ReadInformationGenerale";
import ReadEnqueteur from "components/enrolement/ReadEnqueteur";

class EnrolementDetailsModals extends React.Component {
  state = {
    enrolement: {
      enqueteInfo: {
        enqueteur: "",
      },
    },
    requiredFields: {
      site: "",
      voina: "",
      enqueteur: "",
    },
    showNotification: false,
    isReadOnly: true,
    isLoading: false,
    isError: false,
    title: "DETAILS DE L'ENROLEMENT",
    errorMessage: "",
    errorTitle: "",
  };

  componentWillReceiveProps = (props) => {
    this.setState({
      enrolement: this.props.enrolement,
    });
  };
  componentDidMount() {}

  formValid = () => {
    return false;
  };

  checkValidation = () => {
    const enqueteur =
      this.state.enrolement && this.state.enrolement.enqueteInfo.enqueteur
        ? ""
        : "champ obligatoire";
    const site =
      this.state.enrolement && this.state.enrolement.selectedSite
        ? ""
        : "champ obligatoire";
    const voina =
      this.state.enrolement && this.state.enrolement.selectedVoina
        ? ""
        : "champ obligatoire";
    // const district =
    //   this.state.menage && this.state.menage.district
    //     ? ""
    //     : "champ obligatoire";
    // const commune =
    //   this.state.menage && this.state.menage.commune ? "" : "champ obligatoire";
    // const fokotany =
    //   this.state.menage && this.state.menage.fokotany
    //     ? ""
    //     : "champ obligatoire";
    // const chef =
    //   this.state.menage &&
    //   this.state.menage.chef &&
    //   this.state.menage.chef.length > 0
    //     ? ""
    //     : "champ obligatoire";
    const requiredFields = {
      enqueteur,
      site,
      voina,
    };

    let result = true;
    this.setState({
      enrolement: {
        ...this.state.enrolement,
        enqueteInfo: {
          ...this.state.enrolement.enqueteInfo,
          requiredFields,
        },
      },
      requiredFields,
    });

    if (enqueteur.length > 0 || site.length > 0 || voina.length > 0) {
      result = false;
    }
    return result;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const data = this.requestDTO();
    if (this.checkValidation()) {
      this.processSave(data);
    }
  };

  requestDTO = () => {
    const data = { ...this.state.enrolement };
    const requestDTO = {
      dateEntree: data.dateEntree,
      dateSortie: data.dateSortie,
      nombreDeplaces: data.nombreDeplaces,
      siteId: data.siteId,
      enqueteInfo: {
        dateEnquete: data.enqueteInfo.dateEnquete,
        enqueteur: data.enqueteInfo.enqueteur,
      },
      voinaId: data.voinaId,
      sinistre: {
        ...data.sinistre,
      },
      menageId: data.menageId,
    };
    return requestDTO;
  };

  handleInformationGenerale = (data) => {
    this.setState(
      {
        enrolement: {
          ...this.state.enrolement,
          ...data,
        },
      },
      () => this.checkValidation()
    );
  };

  handleInformationSupplementaire = (data) => {
    this.setState(
      {
        enrolement: {
          ...this.state.enrolement,
          sinistre: {
            ...data,
          },
        },
      } //,
      // () => this.checkValidation()
    );
  };

  handleEnqueteur = (data) => {
    this.setState(
      {
        enrolement: {
          ...this.state.enrolement,
          enqueteInfo: {
            ...data,
          },
        },
      },
      () => this.checkValidation()
    );
  };

  closeErrorNotification = () => {
    this.setState({
      ...this.state,
      isError: false,
    });
  };

  closeNotification = () => {
    this.setState({
      showNotification: false,
    });
    this.goToEnrolementList();
  };

  processSave = (data) => {
    this.setState({
      isLoading: true,
    });

    EnrolementEnregistrementServices.post(data).then(
      (response) => {
        if (response.data.code && response.data.code.toString() === "200") {
          this.setState({
            isLoading: false,
          });
          this.goToEnrolementList();
        } else if (
          response.data.code &&
          response.data.code.toString() === "400" &&
          response.data.message.toString() === "ENROLEMENT CREATION FAILED"
        ) {
          this.notificationMessage =
            "Le menage est déjà enregistré sur un site pour ce voina";
          this.notificationTitle = "Enrolement impossible";
          this.setState({
            showNotification: true,
            isLoading: false,
          });
        }
      },
      (error) => {
        // this.goToEnrolementList();
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  goToEnrolementList = () => {
    this.props.history.push({
      pathname: "/admin/enrolement",
    });
  };

  render() {
    return (
      <>
        <PageSpinner show={this.state.isLoading} />
        <Modal
          className="modal-dialog-centered"
          isOpen={true}
          size="lg"
          // style={{ zIndex: "-100", maxWidth: "1000px", width: "100%" }}
        >
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="bg-light-secondary pb-0">
                  <div className="text-muted text-center mt-2 mb-3 ">
                    <h2>{this.state.title}</h2>
                  </div>
                </CardHeader>
                <CardBody>
                  <div>
                    <ReadInformationGenerale data={this.state.enrolement} />
                    <hr className="primary " />
                    <ReadEnqueteur data={this.state.enrolement} />
                    <hr className="primary " />
                    <ReadInformationSupplementaire
                      data={this.state.enrolement}
                    />
                    
                  </div>
                </CardBody>
                <hr className="m-0 " />
                <div className="modal-footer">
                  <Button
                    type="button"
                    onClick={() => this.props.onCancel()}
                  >
                    Fermer
                  </Button>
                </div>
              </Card>
            </div>
          </Row>
          <PageSpinner show={this.state.isLoading} />
          {this.state.showNotification && (
            <NotificationModal
              show={this.state.showNotification}
              message={this.notificationMessage}
              title={this.notificationTitle}
              close={this.closeNotification}
            />
          )}
          {this.state.isError && (
            <NotificationModal
              show={this.state.isError}
              message={this.state.errorMessage}
              title={this.state.errorTitle}
              close={this.closeErrorNotification}
            />
          )}
        </Modal>
      </>
    );
  }
}

export default EnrolementDetailsModals;
