import axios from "axios";
import { BACK_URL } from "data/constant/urls";

// const API_URL = process.env.REACT_APP_URL;
const API_URL = BACK_URL;

class AuthService {
  //login
  login(username, password) {
    return axios
      .post(
        API_URL + "signin",
        {
          pseudo: username,
          password,
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .catch((error) => {
        console.log("Error", error);
      })
      .then((response) => {
        if (response.data) {
          let user = { ...response.data.data };
          delete user.password;
          user.token = response.data.token;
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("username", user.nom);
          localStorage.setItem("roleName", user.roleName);
        }
        return response.data;
      });
  }

  signin() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ pseudo: "admin", password: "test" }),
    };

    return fetch(API_URL + "signin", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log("okk****");
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  isLoggedIn() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user ? user.token : null;
  }
}

export default new AuthService();
