
export function filterAndPageable(filter) {
    let filtre = "";
    let isEdit = false;

    if (filter && filter.length > 0) {
        filter.forEach(element => {
            filtre = isEdit ? filtre + "&" + element.option + "=" + element.value : "?" + element.option + "=" + element.value;
            isEdit = true;
        });
    }

    if (!isEdit) {
        filtre = ""
    }

    return filtre;
}

export const generateGetOptions = (dataOptions) => {
    let s = "";
    Object.entries(dataOptions).forEach((item, index) => {
      s += ( item[1] && item[1] != "" ?  (s.length > 0 ? "&" : "" ) + item[0] + "=" + item[1] : "" );
    });
    return s.length > 0 ? "?" + s : "";
};

export const getParameters = query => {
    let parameter = ''
    if (!query) {
      return '';
    }
    Object.keys(query).forEach(key=>{
        const value = query[key];
        if (value !== null && value !== undefined && value !== "" && value !== []) {
            parameter += `${!parameter ? '?' : '&'}${key}=${query[key]}`
        }
    })
    return parameter;
}