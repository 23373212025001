import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { DEMO, ECONOMY, HEALTH, MENAGE, NUTRITION } from "data/constant/data-type";
import React from "react";
import {
  Button,





  Container, Modal,

  ModalBody,
  ModalFooter, ModalHeader,


  Row
} from "reactstrap";

const PopupCheck = (props) => {
  const getCheckBoxList = () => {
    switch (
      props.location &&
      props.location.state &&
      props.location.state.data
    ) {
      case HEALTH:
        return (
          <Container>
            <Row>
              <FormControlLabel
                className="col"
                control={
                  <Checkbox
                    color="primary"
                    id="showName"
                    checked={props.checked && props.checked.showName}
                    onChange={props.handleChange}
                    name="gilad"
                  />
                }
                label="Nom et Prénoms"
              />
            </Row>
            <Row>
              <FormControlLabel
                className="col"
                control={
                  <Checkbox
                    color="primary"
                    id="showSex"
                    checked={props.checked && props.checked.showSex}
                    onChange={props.handleChange}
                    name="jason"
                  />
                }
                label="Sexe"
              />
            </Row>
            <Row>
              <FormControlLabel
                className="col"
                control={
                  <Checkbox
                    color="primary"
                    id="showAge"
                    checked={props.checked && props.checked.showAge}
                    onChange={props.handleChange}
                    name="antoine"
                  />
                }
                label="Age"
              />
            </Row>
            <Row>
              <FormControlLabel
                className="col"
                control={
                  <Checkbox
                    color="primary"
                    id="showHandicap"
                    checked={props.checked && props.checked.showHandicap}
                    onChange={props.handleChange}
                    name="antoine"
                  />
                }
                label="Handicap"
              />
            </Row>
            <Row>
              <FormControlLabel
                className="col"
                control={
                  <Checkbox
                    color="primary"
                    id="showTaille"
                    checked={props.checked && props.checked.showTaille}
                    onChange={props.handleChange}
                    name="antoine"
                  />
                }
                label="Taille"
              />
            </Row>
            <Row>
              <FormControlLabel
                className="col"
                control={
                  <Checkbox
                    color="primary"
                    id="showTel"
                    checked={props.checked && props.checked.showTel}
                    onChange={props.handleChange}
                    name="antoine"
                  />
                }
                label="Téléphone"
              />
            </Row>
            <Row>
              <FormControlLabel
                className="col"
                control={
                  <Checkbox
                    color="primary"
                    id="showPoids"
                    checked={props.checked && props.checked.showPoids}
                    onChange={props.handleChange}
                    name="antoine"
                  />
                }
                label="Poids"
              />
            </Row>
            <Row>
              <FormControlLabel
                className="col"
                control={
                  <Checkbox
                    color="primary"
                    id="showPB"
                    checked={props.checked && props.checked.showPB}
                    onChange={props.handleChange}
                    name="antoine"
                  />
                }
                label="PB"
              />
            </Row>
            <Row>
              <FormControlLabel
                className="col"
                control={
                  <Checkbox
                    color="primary"
                    id="showEtatNutritionnel"
                    checked={
                      props.checked && props.checked.showEtatNutritionnel
                    }
                    onChange={props.handleChange}
                    name="antoine"
                  />
                }
                label="Etat nutritionnel"
              />
            </Row>
            <Row>
              <FormControlLabel
                className="col"
                control={
                  <Checkbox
                    color="primary"
                    id="showFamilyChief"
                    checked={props.checked && props.checked.showFamilyChief}
                    onChange={props.handleChange}
                    name="jason"
                  />
                }
                label="Chef de famille"
              />
            </Row>
          </Container>
        );
      case ECONOMY:
      case NUTRITION:
        return (
          <Container>
            <Row>
              <FormControlLabel
                className="col"
                control={
                  <Checkbox
                    color="primary"
                    id="showFamilyChief"
                    checked={props.checked && props.checked.showFamilyChief}
                    onChange={props.handleChange}
                    name="jason"
                  />
                }
                label="Chef de famille"
              />
            </Row>
            <Row>
              <FormControlLabel
                className="col"
                control={
                  <Checkbox
                    color="primary"
                    id="showActivity"
                    checked={props.checked && props.checked.showActivity}
                    onChange={props.handleChange}
                    name="antoine"
                  />
                }
                label="Activité(s)"
              />
            </Row>
            <Row>
              <FormControlLabel
                className="col"
                control={
                  <Checkbox
                    color="primary"
                    id="showRevenus"
                    checked={props.checked && props.checked.showRevenus}
                    onChange={props.handleChange}
                    name="antoine"
                  />
                }
                label="Revenus"
              />
            </Row>
            <Row>
              <FormControlLabel
                className="col"
                control={
                  <Checkbox
                    color="primary"
                    id="showBienEtAvoir"
                    checked={props.checked && props.checked.showBienEtAvoir}
                    onChange={props.handleChange}
                    name="antoine"
                  />
                }
                label="Biens et Avoirs"
              />
            </Row>
            <Row>
              <FormControlLabel
                className="col"
                control={
                  <Checkbox
                    color="primary"
                    id="showDon"
                    checked={props.checked && props.checked.showDon}
                    onChange={props.handleChange}
                    name="antoine"
                  />
                }
                label="Don"
              />
            </Row>
            <Row>
              <FormControlLabel
                className="col"
                control={
                  <Checkbox
                    color="primary"
                    id="showNbIndividu"
                    checked={props.checked && props.checked.showNbIndividu}
                    onChange={props.handleChange}
                    name="antoine"
                  />
                }
                label="Nombre d'individu"
              />
            </Row>
            <Row>
              <FormControlLabel
                className="col"
                control={
                  <Checkbox
                    color="primary"
                    id="showNbDeath"
                    checked={props.checked && props.checked.showNbDeath}
                    onChange={props.handleChange}
                    name="antoine"
                  />
                }
                label="Nombre de décés"
              />
            </Row>
            <Row>
              <FormControlLabel
                className="col"
                control={
                  <Checkbox
                    color="primary"
                    id="showFood"
                    checked={props.checked && props.checked.showFood}
                    onChange={props.handleChange}
                    name="antoine"
                  />
                }
                label="Nourriture"
              />
            </Row>
          </Container>
        );
      case DEMO:
        if (props.location.state.search === MENAGE) {
          return (
            <Container>
              <Row>
                <FormControlLabel
                  className="col"
                  control={
                    <Checkbox
                      color="primary"
                      id="showChef"
                      checked={props.checked && props.checked.showChef}
                      onChange={props.handleChange}
                      name="jason2"
                    />
                  }
                  label="Chef de menage"
                />
              </Row>
              <Row>
                <FormControlLabel
                  className="col"
                  control={
                    <Checkbox
                      color="primary"
                      id="showNbMAM"
                      checked={props.checked && props.checked.showNbMAM}
                      onChange={props.handleChange}
                      name="jason2"
                    />
                  }
                  label="Nombre MAM"
                />
              </Row>
          
              <Row>
                <FormControlLabel
                  className="col"
                  control={
                    <Checkbox
                      color="primary"
                      id="showNbMAS"
                      checked={props.checked && props.checked.showNbMAS}
                      onChange={props.handleChange}
                      name="jason2"
                    />
                  }
                  label="Nombre MAS"
                />
              </Row>
            
              <Row>
                <FormControlLabel
                  className="col"
                  control={
                    <Checkbox
                      color="primary"
                      id="showNbDENUTRI"
                      checked={props.checked && props.checked.showNbDENUTRI}
                      onChange={props.handleChange}
                      name="jason2"
                    />
                  }
                  label="Nombre Dénutri"
                />
              </Row>
              <Row>
                <FormControlLabel
                  className="col"
                  control={
                    <Checkbox
                      color="primary"
                      id="showNomsMAM"
                      checked={props.checked && props.checked.showNomsMAM}
                      onChange={props.handleChange}
                      name="jason2"
                    />
                  }
                  label="Personne MAM"
                />
              </Row>
              <Row>
                <FormControlLabel
                  className="col"
                  control={
                    <Checkbox
                      color="primary"
                      id="showNomsMAS"
                      checked={props.checked && props.checked.showNomsMAS}
                      onChange={props.handleChange}
                      name="jason2"
                    />
                  }
                  label="Personne MAS"
                />
              </Row>
              <Row>
                <FormControlLabel
                  className="col"
                  control={
                    <Checkbox
                      color="primary"
                      id="showNomsDENUTRI"
                      checked={props.checked && props.checked.showNomsDENUTRI}
                      onChange={props.handleChange}
                      name="jason2"
                    />
                  }
                  label="Personne Dénutrie"
                />
              </Row>
              <Row>
                <FormControlLabel
                  className="col"
                  control={
                    <Checkbox
                      color="primary"
                      id="showNbIndividus"
                      checked={props.checked && props.checked.showNbIndividus}
                      onChange={props.handleChange}
                      name="antoine"
                    />
                  }
                  label="Nombre individus"
                />
              </Row>
              <Row>
                <FormControlLabel
                  className="col"
                  control={
                    <Checkbox
                      color="primary"
                      id="showNbEnfant"
                      checked={props.checked && props.checked.showNbEnfant}
                      onChange={props.handleChange}
                      name="antoine"
                    />
                  }
                  label="Nombre enfant moins de 5 ans"
                />
              </Row>
              <Row>
                <FormControlLabel
                  className="col"
                  control={
                    <Checkbox
                      color="primary"
                      id="showNbFemmeAllaitante"
                      checked={props.checked && props.checked.showNbFemmeAllaitante}
                      onChange={props.handleChange}
                      name="antoine"
                    />
                  }
                  label="Nombre Femme allaitante"
                />
              </Row>
              <Row>
                <FormControlLabel
                  className="col"
                  control={
                    <Checkbox
                      color="primary"
                      id="showNbFemmeEnceinte"
                      checked={props.checked && props.checked.showNbFemmeEnceinte}
                      onChange={props.handleChange}
                      name="antoine"
                    />
                  }
                  label="Nombre femme enceinte"
                />
              </Row>
              <Row>
                <FormControlLabel
                  className="col"
                  control={
                    <Checkbox
                      color="primary"
                      id="showNbPersonneAge"
                      checked={props.checked && props.checked.showNbPersonneAge}
                      onChange={props.handleChange}
                      name="antoine"
                    />
                  }
                  label="Nombre personne plus de 65 ans"
                />
              </Row>
              <Row>
                <FormControlLabel
                  className="col"
                  control={
                    <Checkbox
                      color="primary"
                      id="showNbPersonneHandicape"
                      checked={props.checked && props.checked.showNbPersonneHandicape}
                      onChange={props.handleChange}
                      name="antoine"
                    />
                  }
                  label="Nombre personnes handicapées"
                />
              </Row>
            </Container>
          );
        }
      default:
        break;
    }
  };

  return (
    <Modal
      isOpen={props.showModal}
      toggle={props.toggle}
      className="modal-check"
    >
      <ModalHeader toggle={props.toggle}>
        Liste des colonnes à afficher
      </ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <FormControlLabel
              className="col"
              control={
                <Checkbox
                  color="primary"
                  id="showAll"
                  checked={props.checked && props.checked.showAll}
                  onChange={props.handleChange}
                  name="gilad"
                />
              }
              label="Tous"
            />
          </Row>
          <Row>
            <FormControlLabel
              className="col"
              control={
                <Checkbox
                  color="primary"
                  id="showRegion"
                  checked={props.checked && props.checked.showRegion}
                  onChange={props.handleChange}
                  name="jason"
                />
              }
              label="Région"
            />
          </Row>
          <Row>
            <FormControlLabel
              className="col"
              control={
                <Checkbox
                  color="primary"
                  id="showDistrict"
                  checked={props.checked && props.checked.showDistrict}
                  onChange={props.handleChange}
                  name="antoine"
                />
              }
              label="District"
            />
          </Row>
          <Row>
            <FormControlLabel
              className="col"
              control={
                <Checkbox
                  color="primary"
                  id="showCommune"
                  checked={props.checked && props.checked.showCommune}
                  onChange={props.handleChange}
                  name="antoine"
                />
              }
              label="Commune"
            />
          </Row>
          <Row>
            <FormControlLabel
              className="col"
              control={
                <Checkbox
                  color="primary"
                  id="showFokontany"
                  checked={props.checked && props.checked.showFokontany}
                  onChange={props.handleChange}
                  name="antoine"
                />
              }
              label="Fokontany"
            />
          </Row>
          <Row>
            <FormControlLabel
              className="col"
              control={
                <Checkbox
                  color="primary"
                  id="showHameau"
                  checked={props.checked && props.checked.showHameau}
                  onChange={props.handleChange}
                  name="antoine"
                />
              }
              label="Hameau"
            />
          </Row>
          <Row>
            <FormControlLabel
              className="col"
              control={
                <Checkbox
                  color="primary"
                  id="showIdMenage"
                  checked={props.checked && props.checked.showIdMenage}
                  onChange={props.handleChange}
                  name="antoine"
                />
              }
              label="Id Menage"
            />
          </Row>
          <Row>
            <FormControlLabel
              className="col"
              control={
                <Checkbox
                  color="primary"
                  id="showFrequenceRepas"
                  checked={props.checked && props.checked.showFrequenceRepas}
                  onChange={props.handleChange}
                  name="antoine"
                />
              }
              label="Fréquence repas"
            />
          </Row>

          <Row>
            <FormControlLabel
              className="col"
              control={
                <Checkbox
                  color="primary"
                  id="showRation"
                  checked={props.checked && props.checked.showRation}
                  onChange={props.handleChange}
                  name="antoine"
                />
              }
              label="Ration"
            />
          </Row>
        </Container>
        {getCheckBoxList()}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={props.toggle}>
          Valider
        </Button>{" "}
        <Button color="secondary" onClick={props.cancel}>
          Annuler
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PopupCheck;
