import axios from "axios";
import { BACK_URL } from "data/constant/urls";
import authHeader from "./AuthHeader";

const API_URL = BACK_URL;

class DonService {
  findDonByMenageId(idMenage) {
    return axios.post(API_URL + `api/donmenage/findByMenage?idMenage=${idMenage}`, {},{
      headers: authHeader(),
    });
  }

  createDonMenage = (don) => {
    return axios.post(API_URL + "api/donmenage/add", don,{
        headers: authHeader(),
    });
  }
}

export default new DonService();
