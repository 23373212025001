import { getFiliation } from "common/data";
import React from "react";
// reactstrap components
import { Button, Modal, Table } from "reactstrap";
import MenageService from "services/MenageService";
import PageSpinner from "./PageSpinner";

class ListIndividuMenage extends React.Component {
  state = {
    isLoading: false,
    datas: [],
  };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };
  componentDidMount() {
    this.getListIndiviu();
  }

  getListIndiviu = () => {
    this.setState({
      isLoading: true,
    });
    
    MenageService.getIndividusByMenageId(this.props.id).then((respone) => {
      this.setState({
        datas: respone.data.data,
        isLoading: false,
      });
    });
  };
  render() {
    const tabledata =
      this.state.datas  &&
      this.state.datas.map((fiche, idx) => (
        <tr key={idx}>
          <td>{fiche.nom}</td>
          <td>{fiche.sexe}</td>
          <td>{fiche.age}</td>
          <td>{getFiliation(fiche.affiliation)}</td>
        </tr>
      ));
  //  tabledata= !this.state.datas && <tr><td colspan="5" style={{textAlign:'center'}}>Aucun individu</td></tr>
    return (
      <tr>
        {/* Button trigger modal */}

        {/* Modal */}
        <Modal
          className="modal-dialog-centered"
          isOpen={true}
          toggle={() => this.toggleModal("exampleModal")}
        >
          <Table className="align-items-center" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Nom et Prénoms</th>
                <th scope="col">Sexe</th>
                <th scope="col">Age</th>
                <th scope="col">Filiation</th>
              </tr>
            </thead>
            <tbody>{tabledata}</tbody>
            </Table>
            <div   className="row justify-content-md-center" style={{marginBottom: '20px'}}>
              
                <Button
                  type="button"
                
                  onClick={this.props.onCancel}
                >
                  OK
                </Button>
             
            </div>
      
          <PageSpinner show={this.state.isLoading} />
        </Modal>
      </tr>
    );
  }
}

export default ListIndividuMenage;
