import axios from "axios";
import { BACK_URL } from "data/constant/urls";
import authHeader from "./AuthHeader";

// const API_URL = process.env.REACT_APP_URL;
const API_URL = BACK_URL;

class ArticleService {
  getAllArticles() {
    return axios.get(API_URL + `api/article/findAll`, {
      headers: authHeader(),
    });
  }

  getAllArticlesPagination() {
    return axios.get(API_URL + `api/article/findAllPagination`, {
      headers: authHeader(),
    });
  }

  getArticleByLibelle(libelle) {
    return axios.get(
      API_URL + `/api/article/findByLibelle?libelle=${libelle}`,
      {
        headers: authHeader(),
      }
    );
  }

  getArticlesBySite(idSite) {
    return axios.get(API_URL + `api/article/findBySite ?idSite=${idSite}`, {
      headers: authHeader(),
    });
  }

  getArticlesBySitePagination(idSite) {
    return axios.get(
      API_URL + `api/article/findBySitePagination?idSite=${idSite}`,
      {
        headers: authHeader(),
      }
    );
  }

  createArticle = (article) => {
    return axios.post(API_URL + `api/article/addArticle`, article, {
      headers: authHeader(),
    });
  };

  updateArticle = (article) => {
    return axios.put(API_URL + `api/article/updatearticle`, article, {
      headers: authHeader(),
    });
  };

  deleteArticle = (id) => {
    return axios.delete(API_URL + `api/article/deletearticle?id=${id}`, {
      headers: authHeader(),
    });
  };

  findArticleBySiteId = (id, pageNo, pageSize) => {
    return axios.get(
      API_URL +
        `api/article/findArticleBySiteId?siteMedicalId=${id}&pageNo=${pageNo}&pageSize=${pageSize}`,
      {
        headers: authHeader(),
      }
    );
  };

  getArticleQuantity = (siteId = -1, partenaireId = -1, articleId = -1) => {
    return axios.get(
      API_URL +
        `api/article/getArticleQuantity?siteId=${siteId}&partenaireId=${partenaireId}&articleId=${articleId}`,
      {
        headers: authHeader(),
      }
    );
  };

  getArticleQuantityBySituation = (
    regionId = -1,
    districtId = -1,
    communeId = -1,
    fokontanyId = -1
  ) => {
    return axios.get(
      API_URL +
        `api/article/getArticleQuantityBySituation?regionId=${regionId}&districtId=${districtId}&communeId=${communeId}&fokontanyId=${fokontanyId}`,
      {
        headers: authHeader(),
      }
    );
  };
}

export default new ArticleService();
