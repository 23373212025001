import axios from "axios";
import { BACK_URL } from "data/constant/urls";
import authHeader from "./AuthHeader";

const API_URL = BACK_URL;

class TypeDonService {
  getAllTypeDon() {
    return axios.get(API_URL + "param/typedon/findAll", {
      headers: authHeader(),
    });
  }

  createTypeDon = (typedon) => {
    return axios.post(API_URL + "param/typedon/add", typedon,{
        headers: authHeader(),
    });
  }

  deleteTypeDon = (id) => {
    return axios.delete(API_URL + `param/typedon/delete?id=${id}`, {
        headers: authHeader(),
    });
  }

  updateTypeDon = (typedon) => {
    return axios.put(API_URL + `param/typedon/update`, typedon,{
        headers: authHeader(),
    });
}
}

export default new TypeDonService();
