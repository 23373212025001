// javascipt plugin for creating charts
import Chart from "chart.js";
import { user_viewer } from "data/constant/user.rights";
import React from "react";
import "reactjs-popup/dist/index.css";
import { chartOptions, parseOptions } from "variables/charts.js";
import PageSpinner from "./components/PageSpinner";
import "./Index.css";
import "../assets/scss/custom-style.scss";
import { SimpleMap } from "components/map";

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  render() {
    const roleName = localStorage.getItem("roleName");
    if(!user_viewer.includes(roleName)){
      this.props.history.push("/admin/gestion-menage");
    }
    return (
      <>
        <SimpleMap />
        <PageSpinner show={this.state.isLoading} />
      </>
    );
  }
}

export default Index;
