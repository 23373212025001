
import React from "react";
// reactstrap components
import {
  Button,
  Modal
} from "reactstrap";

class ConfirmationModal extends React.Component {

  state = {
    message: '',
  };

  componentDidMount() {
    this.setState({
      message: this.props.message ? this.props.message : 'Voulez-vous vraiment supprimer ?'
    })
  }

  state = {
    exampleModal: false
  };
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };
  render() {
    return (
      <>

        <Modal
          className="modal-dialog-centered"
          isOpen={true}
          toggle={() => this.toggleModal("exampleModal")}
        >
          <div className="modal-header text-center">

            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("exampleModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body text-center">
            <p>
              {this.state.message}
            </p>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={this.props.onCancel}
            >
              Annuler
            </Button>
            <Button
              onClick={this.props.onSave}
              color="primary" type="button" >
              Confirmer
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default ConfirmationModal;