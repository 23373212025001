import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, 
    InputGroup,
    InputGroupAddon,
    InputGroupText, } from 'reactstrap';
import moment, { now } from 'moment';
import ReactDatetime from "react-datetime";
const VoinaModal = (props) => {
    const save = () => {
        console.log(props);
        console.log(Date());
        if (props.selectedVoina && props.selectedVoina.id && props.selectedVoina.id > 0) {
            console.log('UPDATE VOINA');
            props.update();
            props.toggle();
        } else {
            console.log('SAVE VOINA');
            props.save();
            props.toggle();
        }

    }

    return (
        <Modal isOpen={props.showModal} toggle={props.toggle} className='modal-check' fade={true}>
            <ModalHeader toggle={props.toggle}>Ajout de nouveau Voina</ModalHeader>
            <ModalBody style={{ backgroundColor: 'transparent' }}>
                <FormGroup>

                    <Label for="voina">Nom du voina</Label>
                    <Input
                        type="text"
                        name="voina"
                        id="voina"
                        placeholder="Nom de voina"
                        value={(props.selectedVoina && props.selectedVoina.libelle) || ''}
                        onChange={props.handleChange}
                    />
                    <br></br>
                    <Label for="voina">Date debut</Label>
                    <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <ReactDatetime
                            inputProps={{
                                placeholder: (props.selectedVoina && props.selectedVoina.dateDebut)? moment(props.selectedVoina.dateDebut).format("DD/MM/YYYY") : "DD/MM/YYYY",
                            }}
                            initialViewDate={"01/01/2022"}
                            dateFormat={"DD/MM/YYYY"}
                            onChange={(event) =>props.handleChangeDate(event,"dateDebut")}
                            closeOnSelect={true}
                            timeFormat={false}
                            name="dateDebut"
                            // value={props.selectedVoina.dateDebut !== "" && props.selectedVoina.dateDebut }
                        />
                    </InputGroup>
                    <br></br>
                    <Label for="voina">Date fin</Label>
                    <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <ReactDatetime
                            inputProps={{
                                placeholder: (props.selectedVoina.dateFin)? moment(props.selectedVoina.dateFin).format('DD/MM/YYYY') : "DD/MM/YYYY",
                            }}
                            // initialValue={(props.selectedVoina.dateFin)&& moment(props.selectedVoina.dateFin).format('DD/MM/YYYY')}
                            dateFormat={"DD/MM/YYYY"}
                            closeOnSelect={true}
                            onChange={(event) =>props.handleChangeDate(event,"dateFin")}
                            timeFormat={false}
                            name="dateFin"
                            // value={props.selectedVoina.dateFin !== "" && moment(props.selectedVoina.dateFin).format('DD/MM/YYYY') }
                        />
                    </InputGroup>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={save}>Enregistrer</Button>{' '}
                <Button color="secondary" onClick={props.toggle}>Annuler</Button>
            </ModalFooter>
        </Modal >
    )
}

export default VoinaModal;

