import moment from "moment";
import React from "react";
import { Col, FormGroup, Input, Row } from "reactstrap";

class ReadInformationGenerale extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    informationGenerale: {
      menageId: "",
      chefMenage: "",
      siteList: [],
      siteId: "",
      selectedSite: "",
      dateEntree: "",
      voinaList: [],
      voina: {},
      voinaId: "",
      selectedVoina: "",
    },
    dateSortie: "empty",
  };

  componentWillReceiveProps = (props) => {
    this.setState({
      informationGenerale: props.data,
      dateSortie: props.data.dateSortie,
      chefMenage: props.data.menage.nomChef,
      selectedSite: props.data.site.nom_site,
      selectedVoina: props.data.voina.libelle,
    });
  };

  render() {
    return (
      <>
        <Row>
          <Col>
            <FormGroup>
              <h4 className="text-primary">Informations générales</h4>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="3">
            <FormGroup>
              <label
                className="form-control-label bg-fff"
                htmlFor="input-chef-menage"
              >
                Chef de ménage
              </label>

              <Input
                className="form-control-alternative"
                id="input-chef-menage"
                placeholder=""
                type="text"
                name="chefMenage"
                defaultValue={this.state.chefMenage}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <label className="form-control-label" htmlFor="selectedSite">
                Site
              </label>
              <Input
                className="form-control-alternative"
                id="input-selectedSite"
                placeholder=""
                type="text"
                name="selectedSite"
                defaultValue={this.state.selectedSite}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-dateEntree">
                Date et heure d'entrée
              </label>
              <Input
                className="form-control-alternative"
                id="input-dateEntree"
                placeholder=""
                type="text"
                name="dateEntree"
                defaultValue={
                  this.state.informationGenerale.dateEntree &&
                  moment
                    .utc(
                      this.state.informationGenerale.dateEntree,
                      "YYYY-MM-DDTHH:mm"
                    )
                    .format("DD/MM/YYYY HH:mm")
                }
                readOnly
              />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-date-sortie">
                Date et heure de sortie
              </label>
              <Input
                className="form-control-alternative"
                id="input-dateSortie"
                placeholder=""
                type="text"
                name="dateSortie"
                value={
                  this.state.dateSortie === null
                    ? "-"
                    : moment(this.state.dateSortie, "YYYY-MM-DDTHH:mm").format(
                        "DD/MM/YYYY HH:mm"
                      )
                }
                readOnly
              />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-voina">
                Voina
              </label>
              <Input
                className="form-control-alternative"
                id="input-selectedVoina"
                placeholder=""
                type="text"
                name="selectedVoina"
                defaultValue={this.state.selectedVoina}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Nb d'individu à déplacer
              </label>
              <Input
                className="form-control-alternative"
                id="input-nombreDeplaces"
                placeholder=""
                type="text"
                name="nombreDeplaces"
                defaultValue={this.state.informationGenerale.nombreDeplaces}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <div className="custom-control custom-checkbox mb-3">
                <Input
                  className="custom-control-input form-control"
                  id="hasJob"
                  type="checkbox"
                  name="hasJob"
                  defaultChecked={this.state.informationGenerale.hasJob}
                  value={this.state.informationGenerale.hasJob}
                  disabled={true}
                />
                <label
                  className="custom-control-label text-bold"
                  htmlFor="hasJob"
                >
                  A un moyen de subsistance (travail)
                </label>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }
}

export default ReadInformationGenerale;
