import React from "react";
import { FormGroup, Input, Row, Col } from "reactstrap";
import DataContext from "context/DataContext";

class Zonnage extends React.Component {
  static contextType = DataContext;

  state = {
    isFilter: true,
    requiredFields: {
      fokotany: "",
      region: "",
      district: "",
      commune: "",
    },
  };

  handleChangeZonage = (event, name) => {
    const dataId = event?.target[event.target?.selectedIndex]?.getAttribute("data-id") ;
    this.context.handleChangeZonage(event, (situation) => {
      
      this.props.handleChangeZonage(situation);
    },  {id: dataId});
  };

  componentDidMount() {
    // if (this.props.isFilter != null) {
    //   this.setState({ showAllFirst: true });
    // }
  }
  
  componentWillReceiveProps(props) {
    if (props.requiredFields) {
      this.setState({ requiredFields: props.requiredFields })
    }
    if (props.isFilter != null) {
      this.setState({ isFilter: props.isFilter });
    }
  }

  render() {
    const dataRegion =  this.context?.displayListRegion ;
    const dataDistrict =  this.context?.displayListDistrict ;
    const dataCommune =  this.context?.displayListCommune ;
    const dataFokotany =  this.context?.displayListFokotany ;

    
    const listeRegion =
      dataRegion?.map((elt, idx) => (
        <option key={elt.id} data-id={elt.id} value={this.state.isFilter ? elt.id : elt.nom_region }>
          {elt.nom_region}
        </option>
      ));

    const listeDistrict =
      dataDistrict?.map((elt, idx) => (
        <option key={elt.id} data-id={elt.id} value={this.state.isFilter ? elt.id : elt.nom_districte }>
          {elt.nom_districte}
        </option>
      ));

    const listeCommnue =
      dataCommune?.map((elt, idx) => (
        <option key={elt.id} data-id={elt.id} value={this.state.isFilter ? elt.id : elt.nomCommune }>
          {elt.nomCommune}
        </option>
      ));

    const listeFokotany =
      dataFokotany.map((elt, idx) => (
        <option key={elt.id} data-id={elt.id} value={this.state.isFilter ? elt.id : elt.nom_fokotany }>
          {elt.nom_fokotany}
        </option>
      ));



    const { regionId, districtId, communeId, fokotanyId } = this.props.filter;


    return (
      <Row>
        <Col lg="3">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-username">
              Région {this.state.isFilter ? "" : <span className="text-danger">*</span>}
            </label>
            <Input
              type="select"
              className="mdb-select md-form form-control"
              onChange={(event) => this.handleChangeZonage(event, "region")}
              name="region"
              value={this.state.isFilter ? regionId : this.props.filter?.region}>
              <option value={-1}>Afficher toutes les régions</option>
              {listeRegion}
            </Input>
            {!this.state.isFilter && this.state.requiredFields?.region?.length > 0 && (
              <small className="text-danger">champ obligatoire</small>
            )}
          </FormGroup>
        </Col>
        <Col lg="3">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-username">
              District {this.state.isFilter ? "" : <span className="text-danger">*</span>}
            </label>
            <Input
              type="select"
              className={"mdb-select md-form form-control"}
              onChange={(event) => this.handleChangeZonage(event, "district")}
              name="district"
              disabled={dataDistrict?.length === 0}
              value={this.state.isFilter ? districtId : this.props.filter?.district}>
              <option value={-1}>Afficher tous les districts</option>
              {listeDistrict}
            </Input>
            {!this.state.isFilter && this.state.requiredFields?.district?.length > 0 && (
              <small className="text-danger">champ obligatoire</small>
            )}
          </FormGroup>
        </Col>
        <Col lg="3">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-username">
              Commune {this.state.isFilter ? "" : <span className="text-danger">*</span>}
            </label>
            <Input
              type="select"
              className={"mdb-select md-form form-control"}
              onChange={(event) => this.handleChangeZonage(event, "commune")}
              name="commune"
              disabled={dataCommune?.length === 0}
              value={this.state.isFilter ? communeId : this.props.filter?.commune}>
              <option value={-1}>Afficher toutes les communes</option>
              {listeCommnue}
            </Input>
            {!this.state.isFilter && this.state.requiredFields?.commune?.length > 0 && (
              <small className="text-danger">champ obligatoire</small>
            )}
          </FormGroup>
        </Col>
        <Col lg="3">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-username">
              Fokontany {this.state.isFilter ? "" : <span className="text-danger">*</span>}
            </label>
            <Input
              type="select"
              className={"mdb-select md-form form-control"}
              onChange={(event) => this.handleChangeZonage(event, "fokotany")}
              name="fokotany"
              disabled={dataFokotany?.length === 0}
              value={this.state.isFilter ? fokotanyId : this.props.filter?.fokotany}>
              <option value="">Afficher tous les Fokontany</option>
              {listeFokotany}
            </Input>
            {!this.state.isFilter && this.state.requiredFields?.fokotany?.length > 0 && (
              <small className="text-danger">champ obligatoire</small>
            )}
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default Zonnage;
