import Index from "views/Index.js";
// import IndividuPage from "views/pages/IndividuPage.js";
import EnrolementPage from "views/pages/EnrolementPage";
import ObservationPage from "views/pages/ObservationPage";
import EnrolementEnregistrement from "views/components/EnrolementEnregistrement";
import ListFromBoardPage from "views/pages/ListFromBordPage";
import LoginPage from "views/pages/LoginPage.js";
import MenagePage from "views/pages/MenagePage.js";
import TableauDeBordChart from "views/pages/TableauDeBordChart";
import SiteMedicalePage from "views/pages/SiteMedicalePage";
import SiteDetails from "views/pages/SiteDetails";
import BesoinPage from "views/pages/BesoinsPage";
import StocksPage from "views/pages/StocksPage";
import HistoriqueAddPage from "views/pages/HistoriqueAddPage";
import MouvementPage from "views/pages/MouvementPage";
import InventoryPage from "views/pages/InventoryPage";
import MenageIndividuTraitement from "views/components/MenageIndividuTraitement";
import TableauDeBordSite from "views/pages/TableauDeBordSite";
import ReportingSitePage from "views/pages/ReportingSitePage";
import ReportingSinistrePage from "views/pages/ReportingSinistrePage";
import FokotanyMostAffected from "views/components/reporting/FokotanyMostAffected";

import {
  user_viewer,
  user_editor,
  user_gestionnaire,
  user_respes,
} from "data/constant/user.rights";

var routes = [
  {
    path: "/login",
    name: "Login",
    icon: "",
    component: LoginPage,
    layout: "/auth",
  },
  {
    path: "/accueil",
    name: "Accueil",
    icon: "ni ni-pin-3 text-secondary",
    component: Index,
    layout: "/admin",
    restrictionView: user_viewer,
  },
  // {
  //   path: "/gestion-individu",
  //   name: "Gestion individus",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: IndividuPage,
  //   layout: "/admin",
  //   restrictionView: user_viewer,
  // },

  // {
  //   path: "/chart/",
  //   name: "Tableau de bord",
  //   icon: "ni ni-tv-2 text-secondary",
  //   component: TableauDeBordChart,
  //   layout: "/admin",
  //   restrictionView: user_viewer,
  // },
  {
    path: "/gestion-menage",
    name: "Recensement et enrôlement",
    icon: "ni ni-books text-secondary",
    component: MenagePage,
    layout: "/admin",
    restrictionView: user_viewer,
  },
  {
    path: "/enrolement",
    name: "Historique des enrôlements",
    icon: "ni ni-collection text-secondary",
    component: EnrolementPage,
    layout: "/admin",
    restrictionView: user_viewer,
  },
  {
    path: "/sites",
    name: "Gestion des sites ",
    icon: "ni ni-building text-secondary",
    component: SiteMedicalePage,
    layout: "/admin",
    restrictionView: user_viewer,
  },
  {
    path: "/siteDetails/:id",
    name: "Site details ",
    icon: "ni ni-building text-secondary",
    component: SiteDetails,
    layout: "/admin",
    restrictionView: user_viewer,
  },
  {
    path: "/needs",
    name: "Etat des besoins ",
    icon: "ni ni-building text-secondary",
    component: BesoinPage,
    layout: "/admin",
    restrictionView: user_respes,
  },
  {
    path: "/observation",
    name: "Observations",
    icon: "ni ni-collection text-secondary",
    component: ObservationPage,
    layout: "/admin",
    restrictionView: user_respes,
  },
  {
    path: "/reporting/",
    name: "Reporting",
    icon: "ni ni-chart-bar-32 text-secondary",
    // component: ReportingPage,
    layout: "/admin",
    id: "reporting-main",
    restrictionView: user_viewer,
    children: [
      {
        path: "/reporting/site",
        id: "reporting-site",
        name: "Site",
        icon: "ni ni-sound-wave text-secondary",
        component: ReportingSitePage,
        layout: "/admin",
        restrictionView: user_viewer,
      },
      {
        path: "/reporting/sinistre",
        id: "reporting-sinistre",
        name: "Sinistré",
        icon: "ni ni-sound-wave text-secondary",
        component: ReportingSinistrePage,
        layout: "/admin",
        restrictionView: user_viewer,
      },
      {
        path: "/reporting/fokotany",
        id: "reporting-sinistre",
        name: "Fokontany",
        icon: "ni ni-sound-wave text-secondary",
        component: FokotanyMostAffected,
        layout: "/admin",
        restrictionView: user_viewer,
      },
    ]
  },
  {
    path: "/addEnrolement/:idMenage/:nomChefMenage",
    name: "Enregistrement enrolement",
    icon: "ni ni-collection text-secondary",
    component: EnrolementEnregistrement,
    layout: "/admin",
    restrictionView: user_editor,
  },
  {
    path: "/dashboard/sites/:id",
    name: "Tableau de bord site",
    icon: "ni ni-tv-2 text-secondary",
    component: TableauDeBordSite,
    layout: "/admin",
    restrictionView: user_viewer,
  },

  {
    path: "/individuList",
    name: "Liste",
    icon: "ni ni-single-02 text-yellow",
    component: ListFromBoardPage,
    layout: "/admin",
    restrictionView: user_viewer,
  },
  {
    path: "/menage/:id",
    name: "Création menage",
    icon: "ni ni-single-02 text-yellow",
    component: MenageIndividuTraitement,
    layout: "/admin",
    restrictionView: user_viewer,
  },
];
// var routes2=[]
var routes2 = [
  {
    path: "/stock",
    name: "Etat de stocks",
    icon: "ni ni-collection text-secondary",
    component: StocksPage,
    layout: "/admin",
    restrictionView: user_gestionnaire,
  },
  {
    path: "/addMouvement",
    name: "Ajout des mouvements",
    icon: "ni ni-collection text-secondary",
    component: HistoriqueAddPage,
    layout: "/admin",
    restrictionView: user_gestionnaire,
  },
  {
    path: "/listMouvement",
    name: "Mouvement de stocks",
    icon: "ni ni-collection text-secondary",
    component: MouvementPage,
    layout: "/admin",
    restrictionView: user_gestionnaire,
  },
  {
    path: "/addEnrolement/:idMenage/:nomChefMenage",
    name: "Enregistrement enrolement",
    icon: "ni ni-collection text-secondary",
    component: EnrolementEnregistrement,
    layout: "/admin",
    restrictionView: user_gestionnaire,
  },
  {
    path: "/inventory",
    name: "Inventaires",
    icon: "ni ni-collection text-secondary",
    component: InventoryPage,
    layout: "/admin",
    restrictionView: user_gestionnaire,
  },
];
export default { routes, routes2 };
