import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  Tooltip,
  UncontrolledDropdown,
} from "reactstrap";
import moment from "moment";
import SiteMedicalService from "services/SiteService";
import {
  PEUT_ENCORE_ACCUEILLIR,
  PLEIN,
  DEPASSE_LA_CAPACITE_D_ACCUEIL,
  DEPLACE,
  FERME,
  OUVERT,
} from "data/constant/data-type";
import {
  user_editor,
  user_gestionnaire,
  user_viewer,
} from "data/constant/user.rights";
import ConfirmationModal from "views/components/ConfirmationModal";
import CreateEditSiteMedicaleModal from "views/components/CreateEditSiteMedicaleModal";
import Zonnage from "views/components/Zonnage";
import PageSpinner from "views/components/PageSpinner";
import NotificationModal from "../components/NotificationModal";
import SiteTypeService from "services/SiteTypeService";

class SiteMedicalePage extends React.Component {
  state = {
    sites: [],
    nom_site: "",
    reference: "",
    state: "",
    currentPage: 0,
    totalPages: 1,
    totalItems: 0,
    pageSize: 10,
    filtre: {
      siteType: -1,
      regionId: -1,
      districtId: -1,
      communeId: -1,
      fokotanyId: -1,
    },
    site: {
      zonage: {
        commune: {
          id: -1,
          nomCommune: "",
        },
        districte: {
          id: -1,
          nom_districte: "",
        },
        fokotany: {
          id: -1,
          nom_fokotany: "",
        },
        region: {
          id: -1,
          nom_region: "",
        },
      },
    },
    selection: {
      fokotany: "",
      commune: "",
      district: "",
      region: "",
    },

    ShowSite: false,
    siteIdtoDelete: -1,
    showConfirmation: false,
    isError: false,
    isUpdate: false,
    siteToReopen: -1,
    siteToClose: -1,
    confirmationTitle: "",
    selectedSite: -1,
    tooltipOpen: [],
    selectedLineIndex: null,
  };

  componentDidMount() {
    this.getListMedical();
    this.getSiteType();
  }

  getSiteType = () => {
    SiteTypeService.getAllSiteType()
      .then((res) => res.data)
      .then((result) => {
        this.setState({
          siteTypeList: result.data,
        });
      });
  };
  nextPage = () => {
    this.setState(
      {
        currentPage: this.state.currentPage + 1,
      },
      () => this.getListMedical()
    );
  };

  previousPage = () => {
    this.setState(
      {
        currentPage: this.state.currentPage - 1,
      },
      () => {
        this.getListMedical();
      }
    );
  };

  handleChangeZonage = (situation) => {
    const { filtre } = this.state;
    filtre.communeId = situation.commune;
    filtre.districtId = situation.district;
    filtre.regionId = situation.region;
    filtre.fokotanyId = situation.fokontany;
    this.setState({ filtre });
  };

  onSave = () => {
    this.getListMedical();
    this.setState({
      ShowSite: false,
    });
  };

  handleChangeSiteType = (evt) => {
    const { value } = evt.target;
    this.setState({
      filtre: {
        ...this.state.filtre,
        siteType: value,
      },
    });
  };

  getListMedical = () => {
    this.setState({
      isLoading: true,
    });

    SiteMedicalService.getList(
      this.state.currentPage,
      this.state.pageSize,
      this.state.nom_site,
      this.state.reference,
      this.state.state,
      this.state.filtre
    ).then(
      (response) => {
        console.log("response.data.content", response.data.data);
        this.setState(
          {
            sites: response.data.data?.content || [],
            isLoading: false,
            totalPages: response.data.data?.totalPages,
            totalItems: response.data.data?.totalElements,
          },
          () => {
            const open = [];
            response.data.data &&
              response.data.data.length &&
              response.data.data.forEach((site) => {
                open[site.id] = false;
              });
            this.setState({ tooltipOpen: open });
          }
        );
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  onSearch = (e) => {
    this.getListMedical();
  };

  onShowSite = (site, isUpdate) => {
    this.setState({
      ShowSite: true,
      isUpdate: isUpdate,
      selectedSite: site,
    });
  };

  goToSiteDetails = (id, isUpdate) => {
    this.props.history.push(`/admin/siteDetails/${id}`, { isUpdate });
  };

  onCloseModal = () => {
    this.setState({
      ShowSite: false,
    });
  };

  onShowConfirmation = (id) => {
    this.setState({
      siteIdtoDelete: id,
      showConfirmation: true,
      siteToReopen: -1,
      siteToClose: -1,
      confirmationTitle: "Voulez-vous vraiment supprimer ce site?",
    });
  };

  onShowConfirmationToOpen = (id) => {
    this.setState({
      siteIdtoDelete: -1,
      showConfirmation: true,
      siteToReopen: id,
      siteToClose: -1,
      confirmationTitle: "Voulez-vous vraiment réouvrir ce site?",
    });
  };

  onShowConfirmationToClose = (id) => {
    this.setState({
      siteIdtoDelete: -1,
      showConfirmation: true,
      siteToReopen: -1,
      siteToClose: id,
      confirmationTitle: "Voulez-vous vraiment fermer ce site?",
    });
  };

  goToDashboard = (id) => {
    this.props.history.push("/admin/dashboard/sites/" + id);
  };

  closeErrorNotification = () => {
    this.setState({ isError: false });
  };

  cancelConfirmation = () => {
    this.setState({
      showConfirmation: false,
    });
  };

  handlePage = (event) => {
    this.setState({ [event.target.id]: event.target.value }, () =>
      this.getListMedical()
    );
  };

  deleteSite = () => {
    const { siteIdtoDelete } = this.state;
    SiteMedicalService.delete(siteIdtoDelete)
      .then((response) => response.data)
      .then(() =>
        this.setState(
          { isLoading: false, siteIdtoDelete: -1, siteToReopen: -1 },
          () => this.getListMedical()
        )
      )
      .catch((exception) =>
        this.setState({
          siteIdtoDelete: -1,
          siteToReopen: -1,
          isLoading: false,
          isError: true,
          errorMessage: exception.errorCode
            ? exception.errorMessage
            : "Contactez votre administrateur",
          errorTitle: "Erreur à la suppression d'un site",
        })
      );
  };

  reopenSite = () => {
    const { siteToReopen } = this.state;
    SiteMedicalService.reopen(siteToReopen)
      .then((response) => response.data)
      .then(() =>
        this.setState(
          { isLoading: false, siteIdtoDelete: -1, siteToReopen: -1 },
          () => this.getListMedical()
        )
      )
      .catch((exception) => {
        this.setState({
          siteIdtoDelete: -1,
          siteToReopen: -1,
          isLoading: false,
          isError: true,
          errorMessage: exception.errorCode
            ? exception.errorMessage
            : "Contactez votre administrateur",
          errorTitle: "Erreur à la réouverture d'un site",
        });
      });
  };

  closeSite = () => {
    const { siteToClose } = this.state;
    SiteMedicalService.close(siteToClose)
      .then((response) => response.data)
      .then(() =>
        this.setState(
          { isLoading: false, siteIdtoDelete: -1, siteToReopen: -1 },
          () => this.getListMedical()
        )
      )
      .catch((exception) => {
        this.setState({
          siteIdtoDelete: -1,
          siteToReopen: -1,
          isLoading: false,
          isError: true,
          errorMessage: exception.errorCode
            ? exception.errorMessage
            : "Contactez votre administrateur",
          errorTitle: "Erreur à la fermeture d'un site",
        });
      });
  };

  handleInput = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  saveConfirmation = () => {
    const { siteIdtoDelete, siteToReopen, siteToClose } = this.state;
    if (siteIdtoDelete !== -1) this.deleteSite();
    if (siteToReopen !== -1) this.reopenSite();
    if (siteToClose !== -1) this.closeSite();
    this.setState({ showConfirmation: false });
  };

  toggle = (siteId) => {
    const { tooltipOpen } = this.state;
    tooltipOpen[siteId] = !tooltipOpen[siteId];
    this.setState({
      tooltipOpen,
    });
  };

  testAlert = (site) => {
    const accommodationCapacity = site.accommodationCapacity || 0;
    const individuPresent =
      (site.individuPresent !== undefined && site.individuPresent) || 0;
    return (
      (site.covid && site.covid > 0) ||
      accommodationCapacity < individuPresent ||
      (site.validationDate &&
        moment(site?.validationDate).format("DD-MM-YYYY") ===
          moment().format("DD-MM-YYYY"))
    );
  };

  render() {
    const pageList = Array.from(Array(this.state.totalPages).keys()).map(
      (number) => <option value={number}>{number + 1}</option>
    );

    const siteTypeList =
      this.state.siteTypeList &&
      this.state.siteTypeList.map((elt) => (
        <option key={elt.id} value={elt.id}>
          {elt.name}
        </option>
      ));
    const tableData =
      this.state.sites &&
      this.state.sites.length > 0 &&
      this.state.sites
        .sort((a, b) =>
          a.nom_site > b.nom_site ? 1 : b.nom_site > a.nom_site ? -1 : 0
        )
        .map((site, index) => {
          const accommodationCapacity = site.accommodationCapacity || 0;
          const individuPresent =
            (site.individuPresent !== undefined && site.individuPresent) || 0;
          return (
            <tr
              key={index}
              className={
                this.state.selectedLineIndex === index ? "selected" : ""
              }>
              <td className="sticky-col sticky-left p-1">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light mr-0"
                    href="#"
                    role="button"
                    size="sm"
                    color=""
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ selectedLineIndex: index });
                    }}>
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu
                    className="dropdown-menu-arrow"
                    container="body"
                    positionFixed={true}
                    right>
                    {(user_gestionnaire.includes(
                      localStorage.getItem("roleName") || ""
                    ) ||
                      user_editor.includes(
                        localStorage.getItem("roleName") || ""
                      )) && (
                      <DropdownItem
                        href="#"
                        className="clickable"
                        onClick={() => this.onShowSite(site, true)}>
                        Modifier site
                      </DropdownItem>
                    )}
                    {user_gestionnaire.includes(
                      localStorage.getItem("roleName") || ""
                    ) && (
                      <DropdownItem
                        href="#"
                        className="clickable"
                        onClick={() => this.onShowConfirmation(site.id)}>
                        Supprimer site
                      </DropdownItem>
                    )}

                    {user_gestionnaire.includes(
                      localStorage.getItem("roleName" || "")
                    ) &&
                      site.state === FERME && (
                        <DropdownItem
                          href="#"
                          className="clickable"
                          onClick={() =>
                            this.onShowConfirmationToOpen(site.id)
                          }>
                          Réouvrir ce site
                        </DropdownItem>
                      )}

                    {user_gestionnaire.includes(
                      localStorage.getItem("roleName" || "")
                    ) &&
                      site.state === OUVERT && (
                        <DropdownItem
                          href="#"
                          className="clickable"
                          onClick={() =>
                            this.onShowConfirmationToClose(site.id)
                          }>
                          Fermer ce site
                        </DropdownItem>
                      )}
                    {user_viewer.includes(
                      localStorage.getItem("roleName") || ""
                    ) && (
                      <DropdownItem
                        href="#"
                        className="clickable"
                        onClick={() => this.goToDashboard(site.id)}>
                        Voir tableau de bord
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                  {this.testAlert(site) && (
                    <Tooltip
                      placement="top"
                      isOpen={this.state.tooltipOpen[site.id]}
                      target={`tooltip-${site.id}`}
                      toggle={() => this.toggle(site.id)}
                      contentStyle={{ backgroundColor: "#4A4A4A" }}>
                      {site.covid > 0 && (
                        <p>Ce site accueille des individus atteints du COVID</p>
                      )}
                      {accommodationCapacity < individuPresent && (
                        <p>Ce site a dépassé sa capacité d'accueil</p>
                      )}
                      {site?.validationDate &&
                        moment(site?.validationDate).format("DD-MM-YYYY") ===
                          moment().format("DD-MM-YYYY") && (
                          <p>Ce site ferme aujourd'hui</p>
                        )}
                    </Tooltip>
                  )}
                </UncontrolledDropdown>
              </td>

              <td>
                {this.testAlert(site) && (
                  <i
                    className="fas fa-exclamation-triangle"
                    style={{ color: "red" }}
                    id={`tooltip-${site.id}`}></i>
                )}
              </td>
              <td>{site.reference}</td>
              <td>{site.nom_site}</td>
              <td>{site?.siteType?.name}</td>
              <td>{site?.zonage?.region?.nom_region}</td>
              <td>{site?.zonage?.districte?.nom_districte}</td>
              <td>{site?.zonage?.commune?.nomCommune}</td>
              <td>{site?.zonage?.fokotany?.nom_fokotany}</td>
              <td>{site?.accommodationCapacity}</td>
              <td>{site?.state}</td>
              <td>
                {site?.validationDate &&
                  moment(site?.validationDate).format("DD-MM-YYYY")}
              </td>
              {/* <td>{site?.voina? "" : "Voina Data"}</td> */}
            </tr>
          );
        });

    const { showConfirmation } = this.state;
    return (
      <>
        {showConfirmation && (
          <ConfirmationModal
            onCancel={this.cancelConfirmation}
            onSave={this.saveConfirmation}
            message={this.state.confirmationTitle}
          />
        )}
        {this.state.ShowSite && (
          <CreateEditSiteMedicaleModal
            site={this.state.selectedSite}
            onCancel={this.onCloseModal}
            onSave={this.onSave}
            isUpdate={this.state.isUpdate}
          />
        )}
        {this.state.isError && (
          <NotificationModal
            show={this.state.isError}
            message={this.state.errorMessage}
            title={this.state.errorTitle}
            close={this.closeErrorNotification}
          />
        )}

        <div className="header bg-gradient-primary postition-absolute">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row></Row>
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt-5 custom-card-container" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <div>
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username">
                            Nom du site
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-nom-site"
                            placeholder="Nom du site"
                            type="text"
                            name="nom_site"
                            onChange={(event) => this.handleInput(event)}
                            value={this.state.nom_site}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username">
                            Référence
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-reference"
                            placeholder="Référence"
                            type="text"
                            name="reference"
                            onChange={(event) => this.handleInput(event)}
                            value={this.state.reference}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username">
                            Etat du site
                          </label>
                          <select
                            className={
                              this.state.isEmptysiteType
                                ? "form-control-alternative-error mdb-select md-form form-control"
                                : "mdb-select md-form form-control"
                            }
                            onChange={this.handleInput}
                            name="state"
                            value={this.state.state}>
                            <option value="">Tous les états</option>
                            <option value={OUVERT}>Ouvert</option>
                            <option value={PEUT_ENCORE_ACCUEILLIR}>
                              Peut encore accueillir
                            </option>
                            <option value={PLEIN}>Plein</option>
                            <option value={DEPASSE_LA_CAPACITE_D_ACCUEIL}>
                              Dépasse la capacité d'accueil
                            </option>
                            <option value={DEPLACE}>Déplacé</option>
                            <option value={FERME}>Fermé</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username">
                            Type site
                          </label>
                          <Input
                            type="select"
                            className="mdb-select md-form form-control"
                            onChange={this.handleChangeSiteType}
                            name="typeSite"
                            value={this.state.filtre.siteType}>
                            <option value={-1}>Tous les types</option>
                            {siteTypeList}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Zonnage
                      handleChangeZonage={this.handleChangeZonage}
                      filter={this.state.filtre}
                    />
                    <Row>
                      <Col>
                        <FormGroup className="d-flex align-items-center justify-content-center ml-auto flex-wrap justify-content-sm-end">
                          <Button
                            className="mb-3 mt-3 mb-sm-1"
                            onClick={this.onSearch}>
                            Rechercher
                          </Button>
                          {user_gestionnaire.includes(
                            localStorage.getItem("roleName") || ""
                          ) && (
                            <Button
                              className="mb-3 mt-3 mb-sm-1"
                              color="primary"
                              onClick={() => this.onShowSite(null, false)}>
                              Ajouter un site
                            </Button>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </CardHeader>
                <Table
                  className="align-items-center table-flush table table-sticky"
                  responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" className="p-1" />
                      <th scope="col"></th>
                      <th scope="col">Référence</th>
                      <th scope="col">Nom du site</th>
                      <th scope="col">Type</th>
                      <th scope="col">Region</th>
                      <th scope="col">District</th>
                      <th scope="col">Commune</th>
                      <th scope="col">Fokontany</th>
                      <th scope="col">Capacité d'accueil</th>
                      <th scope="col">Etat</th>
                      <th scope="col">Date de validité</th>
                      {/* <th scope="col">Voina</th> */}
                    </tr>
                  </thead>
                  <tbody>{tableData}</tbody>
                </Table>
                <CardFooter className="py-2 footer-pagination-container">
                  <div className="pagination-navigation-container">
                    <Label className="form-control-label">
                      <b>Nombre de lignes</b>
                    </Label>
                    <Input
                      id="pageSize"
                      type="select"
                      width={50}
                      style={{
                        display: "inline-block",
                        width: 80,
                        marginLeft: 10,
                        height: 40,
                      }}
                      value={this.state.pageSize}
                      onChange={this.handlePage}>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                      <option value={50}>50</option>
                    </Input>
                  </div>
                  <Label className="form-control-label">
                    <>
                      {this.state.pageSize > this.state.totalItems
                        ? this.state.totalItems
                        : this.state.pageSize}{" "}
                      sites sur {this.state.totalItems} /
                      {this.state.currentPage + 1} Page sur{" "}
                      {this.state.totalPages}
                    </>
                  </Label>
                  <div className="pagination-navigation-container">
                    <Label className="form-control-label">
                      <b>Numéro de page</b>
                    </Label>
                    <nav aria-label="...">
                      <Pagination
                        className="pagination pagination-lg justify-content-end mb-0"
                        listClassName="justify-content-end mb-0 pagination-lg">
                        <PaginationItem>
                          <Input
                            id="currentPage"
                            type="select"
                            width={50}
                            style={{
                              display: "inline-block",
                              width: 80,
                              marginRight: 10,
                              marginLeft: 10,
                              height: 40,
                            }}
                            value={this.state.currentPage}
                            onChange={this.handlePage}>
                            {pageList}
                          </Input>
                        </PaginationItem>
                        <PaginationItem>
                          {this.state.currentPage > 0 && (
                            <PaginationLink
                              href="#pablo"
                              onClick={this.previousPage}
                              tabIndex="-1">
                              <i className="fas fa-angle-left" />
                              <span className="sr-only">Previous</span>
                            </PaginationLink>
                          )}
                        </PaginationItem>
                        <PaginationItem
                          className={
                            this.state.currentPage + 1 < this.state.totalPages
                              ? "active"
                              : "disabled"
                          }>
                          <PaginationLink href="#" onClick={this.nextPage}>
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </nav>
                  </div>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
        <PageSpinner show={this.state.isLoading} />
      </>
    );
  }
}

export default SiteMedicalePage;
