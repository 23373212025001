import axios from "axios";
import { BACK_URL } from "data/constant/urls";
import authHeader from "./AuthHeader";
import { filterAndPageable, generateGetOptions } from "../common/RequestUtils";

const API_URL = BACK_URL;

class ReportingService {
  siteOccupationRate({
    idSite,
    idRegion,
    idDisctrict,
    idCommune,
    idFokontany,
    day,
  }) {
    const dataOptions = {
      site: idSite || "",
      region: idRegion || "",
      district: idDisctrict || "",
      commune: idCommune || "",
      fokontany: idFokontany || "",
      day: day || "",
    };

    return axios.get(
      API_URL +
        "reporting/siteOccupationRate" +
        generateGetOptions(dataOptions),
      {
        headers: authHeader(),
      }
    );
  }
  nombreSinistre(filter) {
    let data = [];
    if (filter) {
      parseInt(filter.siteId) !== -1 &&
        data.push({ option: "siteId", value: filter.siteId });
      parseInt(filter.voinaId) !== -1 &&
        data.push({ option: "voinaId", value: filter.voinaId });
      parseInt(filter.regionId) !== -1 &&
        data.push({ option: "regionId", value: filter.regionId });
      parseInt(filter.districtId) !== -1 &&
        data.push({ option: "districtId", value: filter.districtId });
      parseInt(filter.communeId) !== -1 &&
        data.push({ option: "communeId", value: filter.communeId });
      parseInt(filter.fokotanyId) !== -1 &&
        data.push({ option: "fokotanyId", value: filter.fokotanyId });
    }

    return axios.get(
      API_URL + "reporting/nbsinistre" + filterAndPageable(data),
      {
        headers: authHeader(),
      }
    );
  }
  nombreSite(filter) {
    let data = [];
    if (filter) {
      parseInt(filter.regionId) !== -1 &&
        data.push({ option: "regionId", value: filter.regionId });
      parseInt(filter.districtId) !== -1 &&
        data.push({ option: "districtId", value: filter.districtId });
      parseInt(filter.communeId) !== -1 &&
        data.push({ option: "communeId", value: filter.communeId });
      parseInt(filter.fokotanyId) !== -1 &&
        data.push({ option: "fokotanyId", value: filter.fokotanyId });
    }
    return axios.get(API_URL + "reporting/site" + filterAndPageable(data), {
      headers: authHeader(),
    });
  }
  getMostAffectedFokotany(filtre) {
    const dataOptions = {
      voina: (filtre.voinaId !== -1 && filtre.voinaId) || "",
      region: filtre.regionId || "",
      district: filtre.districtId || "",
      commune: filtre.communeId || "",
      fokontany: filtre.fokotanyId || "",
    };
    return axios.get(
      API_URL + "reporting/reportNbFokontany" + generateGetOptions(dataOptions),
      {
        headers: authHeader(),
      }
    );
  }
}

export default new ReportingService();
