import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
const TypeDonModal = (props) => {
    const save = () => {
        if (props.selectedTypeDon && props.selectedTypeDon.id && props.selectedTypeDon.id > 0) {
            props.update();
            props.toggle();
        } else {
            props.save();
            props.toggle();
        }
    }

    return (
        <Modal isOpen={props.showModal} toggle={props.toggle} className='modal-check' fade={true}>
            <ModalHeader toggle={props.toggle}>Ajout de nouveau type de don</ModalHeader>
            <ModalBody style={{ backgroundColor: 'transparent' }}>
                <FormGroup>

                    <Label for="typedon">Nom du type de don</Label>
                    <Input
                        type="text"
                        name="typedon"
                        id="typedon"
                        placeholder="Nom du typedon"
                        value={(props.selectedTypeDon && props.selectedTypeDon.name) || ''}
                        onChange={props.handleChange}
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={save}>Enregistrer</Button>{' '}
                <Button color="secondary" onClick={props.toggle}>Annuler</Button>
            </ModalFooter>
        </Modal >
    )
}

export default TypeDonModal;

