
import ChefFamille from "components/menage/ChefFamille";

import TraitementIndividu from "components/menage/TraitementIndividu";

import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalFooter,
} from "reactstrap";
import MenageIndividuTraitementService from "services/MenageIndividuTraitementService";
import PageSpinner from "views/components/PageSpinner";

class InformationModal extends React.Component {
  state = {
    update: false,
    isLoading: false,
    menage: {},
    individu: [],
    requiredFields: {},
  };

  componentDidMount() {
    const id = this.props.menage.id;
    this.isUpdate = false;
    if (id !== "0") {
      this.isUpdate = true;
      this.getData(id);
    }
  }

  getData(id) {
    MenageIndividuTraitementService.get(id).then(
      (response) => {
        if (response.data.code === "200") {
          const cinChef = response.data.data.menage.cinChef;
          const individu = response.data.data.menage.individu;
          let menage = individu.find((item) => item.cin === cinChef);

          const adresse = response.data.data.menage.adresse;
          const id = response.data.data.menage.id;
          const numeroCarnet = response.data.data.menage.numeroCarnet;
          const numeroRegistre = response.data.data.menage.numeroRegistre;
          const secteur = response.data.data.menage.secteur;
          const typeExistance = response.data.data.menage.typeExistance;
          const commune = response.data.data.menage.commune;
          const fokotany = response.data.data.menage.fokotany;
          const district = response.data.data.menage.district;
          const region = response.data.data.menage.region;

          menage = {
            ...menage,
            id,
            adresse,
            numeroCarnet,
            numeroRegistre,
            secteur,
            typeExistance,
            commune,
            fokotany,
            district,
            region,
            chef: "oui",
          };

          this.setState(
            {
              update: true,
              isLoading: false,
              menage,
              individu,
            },
            () => {
              this.state.menage.numeroCarnet &&
                this.state.menage.numeroCarnet !== "" &&
                this.setState({
                  menage: {
                    ...this.state.menage,
                    possedeCarnet: true,
                  },
                });
            }
          );
        }
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  }

  handleMenage = (data) => {
    this.setState(
      {
        menage: {
          ...this.state.menage,
          ...data,
        },
      } //,
      //() => this.checkValidation()
    );
  };

  handleIndividue = (data) => {
    this.setState({
      individu: data,
    });
  };

  formValid = () => {
    return false;
  };

  checkValidation = () => {
    const nom =
      this.state.menage &&
      this.state.menage.nom &&
      this.state.menage.nom.length > 0
        ? ""
        : "champ obligatoire";

    const lieuNaissance =
      this.state.menage &&
      this.state.menage.lieuNaissance &&
      this.state.menage.lieuNaissance.length > 0
        ? ""
        : "champ obligatoire";

    const cin =
      this.state.menage &&
      this.state.menage.cin &&
      this.state.menage.cin.length == 12
        ? ""
        : "12 chiffres réquis";

    const lieuCin =
      this.state.menage && this.state.menage.lieuCin ? "" : "champ obligatoire";

    const nationalite =
      this.state.menage &&
      this.state.menage.nationalite &&
      this.state.menage.nationalite.length > 0
        ? ""
        : "champ obligatoire";

    const adresse =
      this.state.menage &&
      this.state.menage.adresse &&
      this.state.menage.adresse.length > 0
        ? ""
        : "champ obligatoire";

    const telephone =
      this.state.menage &&
      this.state.menage.telephone &&
      this.state.menage.telephone.length != 10
        ? "10 chiffres réquis"
        : "";

    const requiredFields = {
      nom,
      lieuNaissance,
      cin,
      lieuCin,
      nationalite,
      adresse,
      telephone,
    };

    let result = true;
    this.setState({ requiredFields });

    if (
      nom.length > 0 ||
      adresse.length > 0 ||
      lieuNaissance.length > 0 ||
      cin.length > 0 ||
      lieuCin.length > 0 ||
      nationalite.length > 0 ||
      telephone.length > 0
    ) {
      result = false;
    }
    return result;
  };

  handleSubmit = () => {
    this.props.close();
  };

  processSave = (data) => {
    this.setState({
      isLoading: true,
    });

    let { menage } = data;
    menage = {
      ...menage,
      fokotanyid: menage.fokotany,
      communeid: menage.commune,
    };
    data.menage = menage;
    MenageIndividuTraitementService.post(data).then(
      (result) => {
        this.goToMenage();
        this.setState({
          isLoading: false,
        });
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  goToMenage = () => {
    this.props.history.push({
      pathname: "/admin/gestion-menage",
    });
  };

  processUpdate = (data) => {
    this.setState({
      isLoading: true,
    });

    let { menage } = data;
    menage = {
      ...menage,
      fokotanyid: menage.fokotany,
      communeid: menage.commune,
    };
    data.menage = menage;

    MenageIndividuTraitementService.update(data).then(
      (result) => {
        this.setState({
          isLoading: false,
        });
        this.goToMenage();
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={true}
          size="lg"
          // toggle={() => this.toggleModal("exampleModal")}
        >
          <Card className="shadow">
            <CardBody>
              <div>
                <ChefFamille
                  id={this.state.id}
                  data={this.handleMenage}
                  menage={this.state.menage}
                  requiredFieldsError={this.state.requiredFields}
                  update={this.state.update}
                  readOnly={true}
                />
                <hr className="my-4" />
                <TraitementIndividu
                  data={this.handleIndividue}
                  individues={this.state.individu}
                  menage={this.state.menage}
                  readOnly={true}
                />
              </div>
            </CardBody>
            <hr className="m-0" />
            <ModalFooter>
              <Button className="btn" onClick={this.handleSubmit}>
                Fermer
              </Button>
            </ModalFooter>
          </Card>
          <PageSpinner show={this.state.isLoading} />
        </Modal>
      </>
    );
  }
}

export default InformationModal;
