import React from "react";
import ReactDOM from "react-dom";
import DateTime from "react-datetime";
import CalendarContainer from "react-datetime/src/CalendarContainer";
import TetherComponent from "react-tether";

export default class TetheredDateTime extends DateTime {
  // componentDidMount() {
  //     let bodyEl = document.body ;
  //     // bodyEl.className = 'custom-rdt-body';
  //     let backdropEl = this.state.open ? <div className='tether-backdrop' onClick={this.closeCalendar} /> : <></>
  //     ReactDOM.render(backdropEl, bodyEl);
  // }

  // constructor(props) {
  //     super(props);

  //     this.wrapperRef = React.createRef();
  //     // this.setWrapperRef = this.setWrapperRef.bind(this);
  //     this.customHandleClickOutside = this.customHandleClickOutside.bind(this);

  //     this.state = { ...this.state, keepRefCurrent: null }
  // }

  // componentDidMount() {
  //     console.log('this.wrapperRef MOUNT', this.wrapperRef)
  //     console.log('this.wrapperRef.current MOUNT', this.wrapperRef.current)
  //     this.setState({ keepRefCurrent: this.wrapperRef[0]?.current }, () =>
  //         console.log('this.state MOUNT', this.state)
  //     )
  //     document.addEventListener('mousedown', this.customHandleClickOutside);

  // }

  // componentWillUnmount() {
  //     document.removeEventListener('mousedown', this.customHandleClickOutside);
  // }

  // customHandleClickOutside(event) {
  //     console.log('keepRefCurrent', [this.state.keepRefCurrent, event.target])

  //     if (this.state.keepRefCurrent && !this.state.keepRefCurrent.contains(event.target)) {
  //         console.log('keepRefCurrent CONTAINS', [this.wrapperRef, event.target])
  //     }
  // }

  render() {
    // let className = 'rdt' + (this.props.className ?
    //             ( Array.isArray( this.props.className ) ?
    //                 ' ' + this.props.className.join( ' ' ) : ' ' + this.props.className) : ''),
    let className = this.props.className,
      children = [];
    if (this.props.input) {
      const props = {
        key: "i",
        type: "text",
        className: "form-control",
        onFocus: this.openCalendar,
        // onBlur: (event) => {
        //     if (this.wrapperRef && !this.wrapperRef.current.contains(event.currentTarget)) {
        //         // alert('You clicked outside of me!');
        //         this.closeCalendar(event)
        //     }
        // },
        onChange: this.onInputChange,
        onKeyDown: this.onInputKey,
        value: this.state.inputValue,
        ...this.props.inputProps,
      };

      children = [<input {...props} />];
    } else {
      className += " rdtStatic";
    }

    return (
      <div className={`rdt-custom-container ${className}`}>
        <TetherComponent
          attachment="top left"
          targetAttachment="bottom left"
          constraints={[
            {
              to: "scrollParent",
            },
            {
              to: "window",
              pin: ["right", "left", "bottom"],
            },
            // {
            //     to: '.main-content',
            //     pin: ['right', 'left']
            // },
          ]}
          className={
            "custom-tether-portal " +
            (this.state.open ? "custom-tether-portal-open" : "")
          }
          // enabled={this.state.open}
          renderTarget={(ref) => <div ref={ref}>{children}</div>}
          // bodyElement={bodyEl}
          renderElement={(ref) => (
            <>
            <div
              className={`custom-rdt rdt ${this.state.open ? "rdtOpen" : ""}`}
              ref={ref}
            >
              <div
                className="rdtPicker rdtPicker-custom"
                // ref={this.wrapperRef}
              >
                <CalendarContainer
                  view={this.state.currentView}
                  viewProps={this.getComponentProps()}
                  onClickOutside={this.handleClickOutside}
                />
              </div>
            </div>
            <div className="custom-rdt-backdrop" onClick={this.closeCalendar}></div>
            </>
          )}
        />
      </div>
    );
  }
}
