import FooterTable from "components/Footer/Footer";
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import articleService from "services/ArticleService";
import inventoryService from "services/InventoryService";
import InventoryModal from "views/components/InventoryModal";
import PageSpinner from "views/components/PageSpinner";

class ArticlesPage extends React.Component {
  state = {
    selectedArticle: {},
    stockList: [],
    currentPage: 1,
    totalPage: 1000,
    pageSize: 10,
    showModal: false,
    date: "",
    idSite: "",
    idArticle: "",
    searchDTO: {
      articleId: "",
      siteMedicalId: "",
    },
    articleList: [],
    selectedStock: {},
  };

  // Function for WS ********************************
  getStockListFromWs = () => {
    this.setState({ isLoading: true });
    const { searchDTO, currentPage, pageSize } = this.state;
    inventoryService
      .getInventoryByPagination(searchDTO, currentPage - 1, pageSize)
      .then((response) => response.data)
      .then((result) => {
        const stockList = (result.data && result.data.stockDTOs) || [];
        const totalPage = (result.data && result.data.totalPage) || 0;
        this.setState({
          stockList,
          isLoading: false,
          totalPage,
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  addInventoryByList = () => {
    this.setState({ isLoading: true });
    const { stockList } = this.state;
    inventoryService
      .addInventoryByList(stockList)
      .then((result) => result.data)
      .then(() => {
        this.setState({ isLoading: false }, () => this.getStockListFromWs());
      })
      .catch(() => this.setState({ isLoading: false }));
  };

  getArticleListFromWS = () => {
    this.setState({ isLoading: true });
    articleService
      .getAllArticles()
      .then((response) => response.data)
      .then((result) => {
        this.setState({
          isLoading: false,
          articleList: result.data || [],
        });
      })
      .catch(() => this.setState({ isLoading: false, articleList: [] }));
  };

  //Function for handling change data ***************************
  componentDidMount() {
    this.getStockListFromWs();
  }

  doInventory = () => {
    this.setState({ isLoading: true });
    const { stockList } = this.state;
    inventoryService
      .addInventoryByList(stockList)
      .then((response) => response.data)
      .then((result) => {
        this.setState({ isLoading: false });
        this.props.history.push({
          pathname: "/admin/stock",
        });
      })
      .catch(() => this.setState({ isLoading: false }));
  };

  listMouvement = () => {
    this.props.history.push({
      pathname: "/admin/listMouvement",
    });
  };

  handleChange = (evt, index) => {
    const { stockList, searchDTO } = this.state;
    const eventId = evt.target.id;
    const eventValue = evt.target.value;
    switch (eventId) {
      case "qt_real":
        stockList[index].qt_real = eventValue;
        this.setState({ stockList });
        break;
      case "siteMedicalId":
      case "articleId":
        searchDTO[eventId] = eventValue;
        this.setState({ searchDTO }, () => {
          this.getStockListFromWs();
        });
        break;
      default:
        break;
    }
  };

  handleQuantityChange = (event, index) => {
    const { stockList } = this.state;
    const eventId = event.target.id;
    const eventValue = event.target.value;
    stockList[index][eventId] = eventValue;
    this.setState({ stockList });
  };

  handleChangeDate = (event) => {
    this.setState({ date: event.format("DD/MM/YYYY") });
  };

  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  handleChangePage = (event, index) => {
    event.preventDefault();
    if (index > 0 && index < this.getTotalPage())
      this.setState(
        {
          currentPage: index,
        },
        () => this.getStockList()
      );
  };

  handlePage = (event) => {
    this.setState(
      {
        [event.target.id]: parseInt(event.target.value),
      },
      () => this.getStockList()
    );
  };

  getTotalPage = () => {
    const { totalPage } = this.state;
    return totalPage;
  };

  // Function for render
  getStockList = () => {
    const { stockList } = this.state;
    return (
      stockList &&
      stockList.map((stock, index) => (
        <tr key={index}>
          <td>{(stock.partenaire && stock.partenaire.designation) || ""}</td>
          <td>{(stock.sitemedical && stock.sitemedical.nom_site) || ""}</td>
          <td>
            {(stock.sitemedical && stock.sitemedical?.siteType?.name) || ""}
          </td>
          <td>{(stock.article && stock.article.libelle) || ""}</td>
          <td>
            {(stock.article &&
              stock.article.categorie &&
              stock.article.categorie.libelle) ||
              ""}
          </td>
          <td>
            {(stock.article &&
              stock.article.unite &&
              stock.article.unite.libelle) ||
              ""}
          </td>
          <td>{stock.qt_theorique || "0"}</td>
          <td>
            <Input
              className="form-control-alternative"
              id="qt_real"
              placeholder="Identifiant de l'article"
              type="number"
              name="qt_real"
              value={stock.qt_real || "0"}
              onChange={(event) => this.handleQuantityChange(event, index)}
            />
          </td>
        </tr>
      ))
    );
  };

  render() {
    const roleName = localStorage.getItem("roleName");
    if (roleName !== "superadmin" && roleName !== "adminadd") {
      this.props.history.push("/admin/accueil");
    }

    const {
      currentPage,
      isLoading,
      showModal,
      date,
      selectedArticle,
      searchDTO,
      articleList,
    } = this.state;
    return (
      <>
        <div className="header bg-gradient-primary postition-absolute">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row></Row>
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt-5 custom-card-container" fluid >
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0 d-flex flex-wrap">
                  <Row className="mb-2 d-flex flex-wrap mr-auto">
                    <Col xs="12" sm="6">
                      <FormGroup>
                        <Input
                          className="form-control-alternative"
                          id="articleId"
                          placeholder="Identifiant de l'article"
                          type="text"
                          name="nom"
                          value={searchDTO.articleId || ""}
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs="12" sm="6">
                      <FormGroup>
                        <Input
                          className="form-control-alternative"
                          id="siteMedicalId"
                          placeholder="Id site"
                          type="text"
                          name="nom"
                          value={searchDTO.siteMedicalId || ""}
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>                   
                  </Row>
                  <div className="my-1 d-flex flex-wrap ">
                    <FormGroup>
                      <Button className="btn" onClick={this.doInventory}>
                        {" "}
                        Enregistrer l'inventaire
                      </Button>
                    </FormGroup>
                  </div>
                </CardHeader>
                <div className="table-responsive">
                  <table
                    className="table table-bordered align-items-center table-flush table"
                    responsive
                    style={{ height: "100%" }}>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Partenaire</th>
                        <th scope="col">Nom du Site</th>
                        <th scope="col">Type de Site</th>
                        <th scope="col">Article</th>
                        <th scope="col">Catégorie</th>
                        <th scope="col">Unité</th>
                        <th scope="col">Quantité théorique</th>
                        <th scope="col">Quantité Réelle</th>
                      </tr>
                    </thead>
                    <tbody>{this.getStockList()}</tbody>
                  </table>
                </div>
                <FooterTable
                  currentPage={currentPage}
                  handlePage={this.handleChange}
                  handleChangePage={this.handleChangePage}
                  getTotalPage={this.getTotalPage}
                />
              </Card>
            </div>
          </Row>
          <InventoryModal
            articleList={articleList}
            selectedArticle={selectedArticle}
            showModal={showModal}
            toggle={this.toggle}
            handleChangeDate={this.handleChangeDate}
            handleChange={this.handleChange}
            date={date}
          />
          <PageSpinner show={isLoading} />
        </Container>
      </>
    );
  }
}

export default ArticlesPage;
