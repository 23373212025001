import React from "react";
import ReactDatetime from "react-datetime";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  Row
} from "reactstrap";
import IndividuService from "services/IndividuService";
import NotificationModal from "./NotificationModal";
import PageSpinner from "./PageSpinner";
class CreateEditIndividuModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange.bind(this);
    this.handleSubmit.bind(this);
    this.showFiche.bind(this);
    this.isUpdate = false;
    this.title = "Création individu";
    this.notificationMessage = "";
    this.notificationTitle = "";
  }

  state = {
    successIndicator: false,
    disableFieldAge: false,
    disableFieldMois: false,
    loading: false,
    showNotification: false,
    nom: "",
    prenoms: "",
    cin: "",
    sexe: "",
    telephone: "",
    affiliation: "",
    age: "",
    age_mois: "",
    allaité: "",
    jumeaux: "",
    femme_enceinte: "",
    femme_allaitante: "",
    taille: "",
    menageid: 0,
    handicape: "",
    maladie: "",
    dateNaissance: "",
    derniereClasse: "",
    marie: "",
    isChild: false,
    tranche_age: 'Enfant',
    pb: '',
    poids: '',
    priseEnMain: '',
    nbConjoint: '',
    traitementDTO: {
      dimunitionPoidsRecent: '',
      diarrhees: "",
      bay: "",
      oedemes: '',
      maladieChronique: '',
      remarque: ''
    },
    isError: {
      nom: "",
      cin: "",
      age: "",
      age_mois: "",
      telephone: "",
      sexe: "",
    },
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  closeNotification = () => {
    this.setState({
      showNotification: false,
    });
  };

  showFiche = (event) => {
    event.preventDefault();
    if (this.checkValidation()) {
      this.setState({
        loading: true,
      });
      const DTO = { ...this.state };
      delete DTO.loading;
      IndividuService.post(DTO).then(
        (data) => {
          this.setState({
            loading: false,
          });
          this.props.onShowFiche(data.data.data);
        },
        (error) => {
          this.setState({
            loading: false,
          });
          // this.props.onCancel();
        }
      );
    }
  };
  componentDidMount() {
    if (this.props.menage) {
      this.setState({
        menageid: parseInt(this.props.menage.id, 10),
      });
    }
    this.isUpdate = this.props.user ? true : false;
    if (this.isUpdate) {
      this.title = "Modification individu";
      const isError = {
        nom: "",
        cin: "",
        age: "",
        telephone: "",
        sexe: "",
      };
      const data = { ...this.props.user, isError };
      this.setState(data);
    }
  }

  getIndividu = () => {
    const { id } = this.props.user;
    IndividuService.findById(id).then((data) => {
      this.state = data;
    });
  };

  handeleDateNaissance = (date) => {
    let value = "";
    let age = '';
    if (date.isValid) {
      value = date.format("DD/MM/YYYY");
      age = this.getAge(value)
    }
    this.setState({
      dateNaissance: value,
      age,
    });
  };

  getAge(value) {
    const data = value.split('/').reverse();
    data[1] = parseInt(data[1], 10) - 1;
    const date = new Date(...data)
    var diff = Date.now() - date.getTime();
    var age = new Date(diff);
    return Math.abs(age.getUTCFullYear() - 1970);
  }

  handleSubmit = (event) => {
    if (this.checkValidation()) {
      event.preventDefault();
      this.setState({
        loading: true,
      });
      const DTO = { ...this.state };
      delete DTO.loading;
      this.isUpdate ? this.processUpdate(DTO) : this.processSave(DTO);
    }
  };

  processSave = (DTO) => {
    IndividuService.post(DTO).then(
      (response) => {
        this.setState({
          loading: false,
          successIndicator: true,
        });
        // this.props.onSave();
        if (response.data.code && response.data.code.toString() === "200") {
          this.props.onSave();
        } else if (
          response.data.code &&
          response.data.code.toString() === "400" &&
          response.data.message === "CIN ALREADY EXIST"
        ) {
          this.notificationMessage = "Le CIN exite déjà";
          this.notificationTitle = "Ajout individu impossible";
          this.setState({
            showNotification: true,
            isLoading: false,
          });
        }
      },
      (error) => {
        this.setState({
          loading: false,
        });
        // this.props.onCancel();
      }
    );
  };
  processUpdate = (DTO) => {
    IndividuService.update(DTO).then(
      (data) => {
        this.setState({
          loading: false,
        });
        this.props.onSave();
      },
      (error) => {
        this.setState({
          loading: false,
        });
        this.props.onCancel();
      }
    );
  };

  handleChange = (evt) => {
    let { name, value } = evt.target;
    let isError = { ...this.state.isError };
    switch (name) {
      case "nom":
        isError.nom = value === "" ? "Champ obligatoire" : "";
        this.setState({
          isLoading: true,
        });
        IndividuService.getListByPagination(0, 10, value, "").then((data) => {
          this.setState({
            isLoading: false,
            individu: {
              ...this.state.individu,
              autocompletion: data.data.data,
            },
          });
        });
        break;
      case "cin":
        value = value.replace(/[^\d]/, "");
        isError.cin = value.length !== 12 ? "12 chiffres réquis" : "";
        if (value.length > 12) {
          return "";
        }
        break;
      case "age":
        isError.age = value === "" ? "Champ obligatoire" : "";
        value = value.replace(/[^\d]/, "");
        if (0 < value.length) {
          if (value.length > 2) {
            return "";
          }

          this.setState(
            {
              disableFieldMois: true,
              [evt.target.name]: value,
            });
        } else {
          this.setState(
            {
              disableFieldMois: false,
              [evt.target.name]: value,
            });
        }

        break;

      case "age_mois":
        isError.age_mois = value === "" ? "Champ obligatoire" : "";
        value = value.replace(/[^\d]/, "");
        if (0 < value.length) {
          if (value.length > 2) {
            return "";
          }
          this.setState(
            {
              disableFieldAge: true,
              [evt.target.name]: value,
            });
        } else {
          this.setState(
            {
              disableFieldAge: false,
              [evt.target.name]: value,
            });
        }

        break;

      case "telephone":
        value = value.replace(/[^\d]/, "");
        isError.telephone =
          value.length !== 0 && value.length !== 10 ? "10 chiffres réquis" : "";
        if (value.length > 10) {
          return "";
        }
        break;
      case "sexe":
        isError.sexe = value === "" ? "Champ obligatoire" : "";
        break;
      default:
        break;
    }
    if (name === "age" || name === "age_mois") {
    } else if (['dimunitionPoidsRecent', 'oedemes', 'bay', 'maladieChronique', 'remarque', 'diarrhees'].includes(name)) {
      this.setState({
        ...this.state,
        isError,
        traitementDTO: {
          ...this.state.traitementDTO,
          [evt.target.name]: value
        }
      });
    } else {
      this.setState({
        ...this.state,
        isError,
        [evt.target.name]: value,
      });
    }
  };

  close = () => {
    this.setState({
      nom: "",
      prenoms: "",
      cin: "",
      sexe: "",
      telephone: "",
      affiliation: "",
      age: "",
      age_mois: "",
      allaité: "",
      jumeaux: "",
      femme_enceinte: "",
      femme_allaitante: "",
      taille: "",
      handicape: "",
      maladie: "",
      successIndicator: false,
      disableFieldMois: false,
      disableFieldAge: false,
    });
  };
  checkValidation = () => {
    const nom =
      this.state.nom && this.state.nom.length > 0 ? "" : "champ obligatoire";
    const age =
      this.state.age && this.state.age.length > 0 ? "" : "champ obligatoire";
    const sexe =
      this.state.sexe && this.state.sexe.length > 0 ? "" : "champ obligatoire";
    let cin = "";
    let telephone = "";
    if (
      this.state.cin &&
      this.state.cin.length !== 0 &&
      this.state.cin.length !== 12
    ) {
      cin = "12 chiffres requis";
    }
    if (
      this.state.telephone &&
      this.state.telephone.length !== 0 &&
      this.state.telephone.length !== 10
    ) {
      telephone = "10 chiffres requis";
    }
    let result = true;
    this.setState({
      isError: {
        nom,
        cin,
        age,
        telephone,
        sexe,
      },
    });

    if (nom.length > 0 || cin.length > 0 || telephone.length > 0) {
      result = false;
    }

    return result;
  };



  render() {
    return (
      <>
        <Modal className="modal-dialog-centered" size="lg" isOpen={true}>
          <div className="modal-body p-0">
            <Card className="bg-light-secondary shadow border-0">
              <CardHeader className="bg-transparent pb-0">
                <div className="text-muted text-center mt-2 mb-3 ">
                  <h2>{this.title}</h2>
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <Form>
                  <div className="pl-lg-4">
                    {/* -------Paramètre civil: */}
                    <Row>
                      <Col lg="12"> <p className="individuTitle parametreCivil">Paramètre civil&nbsp;&nbsp;&nbsp;</p></Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Nom et prénoms *
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder="Nom et Prénoms"
                            type="text"
                            name="nom"
                            value={this.state.nom}
                            onChange={this.handleChange}
                          />
                          {this.state.isError.nom.length > 0 && (
                            <small className="text-danger">
                              champ obligatoire
                            </small>
                          )}
                        </FormGroup>
                      </Col>

                      
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Genre
                          </label>
                          <div className="input-group-alternative input-group">
                            <select
                              className="mdb-select md-form  form-control"
                              onChange={this.handleChange}
                              name="sexe"
                              value={this.state.sexe}
                            >
                              <option value=""></option>
                              <option value="Masculin">Masculin</option>
                              <option value="Feminin">Féminin</option>
                            </select>
                          </div>
                        </FormGroup>
                      </Col>


                      {/* <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >Tranche d'age</label>
                          <div className="input-group-alternative input-group">
                            <select
                              name="isChild"
                              onChange={this.handleChange}
                              value={this.state.tranche_age}
                              className="mdb-select md-form  form-control"
                            >
                              <option value={'Adulte'}>Adulte</option>
                              <option value={'Adolescent'}>Adolescent</option>
                              <option value={'Enfant'}>Enfant</option>

                            </select>
                          </div>
                        </FormGroup>
                      </Col> */}
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            CIN
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            placeholder="CIN"
                            type="text"
                            value={this.state.cin}
                            onChange={this.handleChange}
                            name="cin"
                          />
                          {this.state.isError.cin.length > 0 && (
                            <small className="text-danger">
                              {this.state.isError.cin}
                            </small>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Marié
                          </label>
                          <div className="input-group-alternative input-group">
                            <select
                              className="mdb-select md-form  form-control"
                              onChange={this.handleChange}
                              name="marie"
                              value={this.state.marie}
                            >
                              <option value=""></option>
                              <option value="oui">Oui</option>
                              <option value="non">Non</option>
                            </select>
                          </div>
                        </FormGroup>
                      </Col>

                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Nb Conjoint(e)
                          </label>
                          <div className="input-group-alternative input-group">
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              placeholder="Nb Conjoint(e)"
                              type="number"
                              value={this.state.nbConjoint}
                              onChange={this.handleChange}
                              name="nbConjoint"
                            />
                          </div>
                        </FormGroup>
                      </Col>

                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Filiation
                          </label>
                          <div className="input-group-alternative input-group">
                            <select
                              className="mdb-select md-form  form-control"
                              onChange={this.handleChange}
                              name="affiliation"
                              value={this.state.affiliation}
                            >
                              {this.props.user?.affiliation === "CF" && (
                                <>
                                  <option value="CF">Chef de famille</option>
                                </>
                              )}
                              {(this.props.menage ||
                                (this.isUpdate &&
                                  this.props?.user.affiliation !== "CF")) && (
                                  <>
                                    <option value=""></option>
                                    <option value="ENFANT">Enfant</option>
                                    <option value="PETITENFANT">
                                      Petit-enfant
                                  </option>
                                    <option value="GRANDMERE">Grand-mère</option>
                                    <option value="GRANDPERE">Grand-père</option>
                                    <option value="MERE">Mère</option>
                                    <option value="AUTRE">Autre</option>
                                  </>
                                )}
                            </select>
                          </div>
                        </FormGroup>
                      </Col>

                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Date de naissance
                          </label>
                          <FormGroup>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-calendar-grid-58" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <ReactDatetime className="dateIndividu"
                                inputProps={{
                                  placeholder: "Choisir date",
                                }}
                                format={"DD/MM/YYYY"}
                                closeOnSelect={true}
                                timeFormat={false}
                                onChange={this.handeleDateNaissance}
                                name="dateNaissance"
                                selected={this.state.dateNaissance}
                                value={
                                  this.state.dateNaissance !== "" &&
                                  this.state.dateNaissance
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                        </FormGroup>
                      </Col>

                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Age
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder="Nombre d'année/Nombre de mois"
                            type="number"
                            min={0}
                            value={this.state.age}
                            disabled={this.state.disableFieldAge}
                            onChange={this.handleChange}
                            name="age"
                          />
                        </FormGroup>
                      </Col>


                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Niveau d'étude
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            placeholder="dernière classe"
                            type="text"
                            value={this.state.derniereClasse}
                            onChange={this.handleChange}
                            name="derniereClasse"
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="12"> <p className="individuTitle">Paramètre médicaux&nbsp;&nbsp;&nbsp;</p></Col>
                      {/* -------Paramètre médicaux */}

                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Femme allaitante
                          </label>
                          <div className="input-group-alternative input-group">
                            <select
                              className="mdb-select md-form  form-control"
                              name="femme_allaitante"
                              disabled={
                                this.state.sexe &&
                                this.state.sexe.toLocaleLowerCase() ===
                                "masculin"
                              }
                              value={this.state.femme_allaitante || ""}
                              onChange={this.handleChange}
                            >
                              <option value=""></option>
                              <option value="Oui">Oui</option>
                              <option value="Non">Non</option>
                            </select>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Femme enceinte
                          </label>
                          <div className="input-group-alternative input-group">
                            <select
                              className="mdb-select md-form  form-control"
                              disabled={
                                this.state.sexe &&
                                this.state.sexe.toLocaleLowerCase() ===
                                "masculin"
                              }
                              name="femme_enceinte"
                              value={this.state.femme_enceinte || ""}
                              onChange={this.handleChange}
                            >
                              <option value=""></option>
                              <option value="Oui">Oui</option>
                              <option value="Non">Non</option>
                            </select>
                          </div>
                        </FormGroup>
                      </Col>


                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Handicapée
                          </label>
                          <div className="input-group-alternative input-group">
                            <select
                              className="mdb-select md-form  form-control"
                              onChange={this.handleChange}
                              name="handicape"
                              value={this.state.handicape}
                            >
                              <option value=""></option>
                              <option value="oui">Oui</option>
                              <option value="non">Non</option>
                            </select>
                          </div>
                        </FormGroup>
                      </Col>
                      {/* <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Maladie
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            placeholder="maladie"
                            type="text"
                            value={this.state.maladie}
                            onChange={this.handleChange}
                            name="maladie"
                          />
                        </FormGroup>
                      </Col> */}

                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            PB
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder="Pb"
                            type="number"
                            value={this.state.pb}
                            onChange={this.handleChange}
                            name="pb"
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Poids
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder="Poids"
                            type="number"
                            value={this.state.poids}
                            onChange={this.handleChange}
                            name="poids"
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Taille
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder="Taille"
                            type="number"
                            value={this.state.taille}
                            onChange={this.handleChange}
                            name="taille"
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            IMC
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder="Imc"
                            type="number"
                            value={this.state.taille && (this.state.poids / Math.pow(this.state.taille / 100, 2)).toFixed(2)}
                            name="imc"
                            disabled />
                        </FormGroup>
                      </Col>

                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Diarrhée
                          </label>
                          <div className="input-group-alternative input-group">
                            <select
                              className="mdb-select md-form  form-control"
                              onChange={this.handleChange}
                              name="diarrhees"
                              value={this.state.traitementDTO.diarrhees}
                            >
                              <option value=""></option>
                              <option value="true">Oui</option>
                              <option value="false">Non</option>
                            </select>
                          </div>
                        </FormGroup>
                      </Col>

                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Bay
                          </label>
                          <div className="input-group-alternative input-group">
                            <select
                              className="mdb-select md-form  form-control"
                              onChange={this.handleChange}
                              name="bay"
                              value={this.state.traitementDTO.bay}
                            >
                              <option value=""></option>
                              <option value="true">Oui</option>
                              <option value="false">Non</option>
                            </select>
                          </div>
                        </FormGroup>
                      </Col>

                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Oedème
                          </label>
                          <div className="input-group-alternative input-group">
                            <select
                              className="mdb-select md-form  form-control"
                              onChange={this.handleChange}
                              name="oedemes"
                              value={this.state.traitementDTO.oedemes}
                            >
                              <option value=""></option>
                              <option value="true">Oui</option>
                              <option value="false">Non</option>
                            </select>
                          </div>
                        </FormGroup>
                      </Col>

                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Prise en main
                          </label>
                          <div className="input-group-alternative input-group">
                            <select
                              className="mdb-select md-form  form-control"
                              onChange={this.handleChange}
                              name="priseEnMain"
                              value={this.state.priseEnMain}
                            >
                              <option value="PPN">PPN</option>
                              <option value="PPS">PPS</option>
                              <option value="AUTRE">Autre</option>
                            </select>
                          </div>
                        </FormGroup>
                      </Col>

                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Maladie chronique
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder="Maladie chronique"
                            type="text"
                            value={this.state.traitementDTO.maladieChronique}
                            onChange={this.handleChange}
                            name="maladieChronique"
                          />
                        </FormGroup>
                      </Col>

                      {/* ----Maladie chronique */}
                      <Col lg="12"> <p className="individuTitle">Autres informations&nbsp;&nbsp;&nbsp;</p></Col>

                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Téléphone
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder="Téléphone"
                            type="text"
                            value={this.state.telephone}
                            onChange={this.handleChange}
                            name="telephone"
                          />
                          {this.state.isError.telephone.length > 0 && (
                            <small className="text-danger">
                              {this.state.isError.telephone}
                            </small>
                          )}
                        </FormGroup>
                      </Col>


                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Dim. rég. récent
                          </label>
                          <div className="input-group-alternative input-group">
                            <select
                              className="mdb-select md-form  form-control"
                              onChange={this.handleChange}
                              name="dimunitionPoidsRecent"
                              value={this.state.traitementDTO.dimunitionPoidsRecent}
                            >
                              <option value=""></option>
                              <option value={true}>Oui</option>
                              <option value={false}>Non</option>
                            </select>
                          </div>
                        </FormGroup>
                      </Col>

                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Remarque
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder="Remarque"
                            type="text"
                            value={this.state.traitementDTO.remarque}
                            onChange={this.handleChange}
                            name="remarque"
                          />
                        </FormGroup>
                      </Col>

                      {this.state.successIndicator && (
                        <Col lg="6">
                          <div className="alert alert-success alert-dismissible">
                            <span
                              href="#"
                              type="button"
                              data-dismiss="alert"
                              className="close"
                              aria-label="close"
                              onClick={() => this.close()}
                            >
                              &times;
                            </span>
                            <strong></strong> Enregistrement individu avec
                            succès
                          </div>
                        </Col>
                      )}
                    </Row>
                  </div>
                  <div className="pl-lg-4"></div>
                  <div className="modal-footer">
                    <Button type="button" onClick={this.props.onCancel}>
                      Annuler
                    </Button>

                    <Button type="button" onClick={this.handleSubmit}>
                      <span>Enregistrer</span>
                    </Button>
                    {!this.isUpdate && (
                      <Button
                        type="button"
                        color="primary"
                        onClick={this.showFiche}
                      >
                        Créer Fiche de traitement{" "}
                        <i className="fas fa-angle-double-right"></i>
                      </Button>
                    )}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
          <PageSpinner show={this.state.loading} />
          <NotificationModal
            show={this.state.showNotification}
            message={this.notificationMessage}
            title={this.notificationTitle}
            close={this.closeNotification}
          />
        </Modal>
      </>
    );
  }
}

export default CreateEditIndividuModal;
