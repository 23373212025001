import React from "react";
// reactstrap components
import {
  Table,
  Modal, Button,
  ModalHeader,
  CardBody,
} from "reactstrap";
import PageSpinner from "./PageSpinner";

class LoharanoListModal extends React.Component {
  state = {
  };


  handleChange = (evt) => {
    const value = evt.target.value;
    this.setState({
      ...this.state,
      [evt.target.name]: value,
    });
  };

  onPressEnter = (event) => {
    if (event.key === "Enter") {
      this.onSearch();
    }
  };

  // Nouveau menage
  onShowMenage = () => {
    this.props.history.push(`/admin/menage/0`);
  };

  render() {
    const { data } = this.props;
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={true}
          size={data && data.length > 0 ? "lg" : "sm"}
        >
            {data && data.length > 0 ? (
              <>
              <ModalHeader toggle={this.props.close} className="h3 align-items-center justify-content-center modal-header">
                Résultat de la recherche sur LOHARANO :
              </ModalHeader>
              <hr className="m-0"/>
              <CardBody className="pt-0">
                <Table
                  className="align-items-center table-flush table"
                  responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col"> nom </th >
                      <th scope="col"> Prénom </th >
                      <th scope="col"> CIN </th >
                      <th scope="col"> Date de naissance </th >
                      <th scope="col"> Province </th >
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.map((item, index) => (
                        <tr key={index} onClick={() => this.props.onSearch(item.bookNumber)} className="clickable">
                          <td>{item.lastName}</td>
                          <td>{item.firstName}</td>
                          <td>{item.CNI}</td>
                          <td>{item.birthDate ? new Date(item.birthDate).toLocaleDateString() : ""}</td>
                          <td>{item.address.province.name}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </CardBody>
              </>
            )
              :
              (
                <>
                <ModalHeader toggle={this.props.close}></ModalHeader>
                <CardBody className="pt-0 text-center">
                  <p className="bg-translucent-white color-swatch font-weight-600 p-2">
                    Aucun résultat trouvé au niveau de LOHARANO
                  </p>
                  <Button
                    className="btn"
                    onClick={this.onShowMenage}
                    color="primary">
                    Créer un nouveau Ménage
                  </Button>
                </CardBody>
                </>
              )
            }
          
        </Modal>
        <PageSpinner show={this.state.isLoading} />
      </>
    );
  }
}

export default LoharanoListModal;
