import React from "react";
import ReactDatetime from "react-datetime";
import {
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import nationalites from "common/nationalites";
import Zonnage from "views/components/Zonnage";

class ChefFamille extends React.Component {
  state = {
    individu: {
      nom: "",
      prenom: "",
      dateNaissance: new Date().toLocaleDateString(),
      lieuNaissance: "",
      cin: "",
      dateCin: new Date().toLocaleDateString(),
      lieuCin: "",
      pere: "",
      mentionPere: "",
      mere: "",
      mentionMere: "",
      telephone: "",
      sexe: "Masculin",
      nationalite: "Malagasy",
      affiliation: "",
      observation: "",
      handicape: "false",
      activite: "",
      adresse: "",
      numeroRegistre: "",
      numeroCarnet: "",
      secteur: "",
      locataire: "false",
      typeExistance: "1",
      chef: "oui",
      
      /** ZONAGE */
      regionId: -1,
      districtId: -1,
      communeId: -1,
      fokotanyId: -1,
    },
    requiredFields: {
      nom: "",
      lieuNaissance: "",
      nationalite: "",
      cin: "",
      lieuCin: "",
      adresse: "",
    },
  };

  componentWillReceiveProps = (props) => {
    if (props.requiredFieldsError) {
      this.setState({ requiredFields: props.requiredFieldsError });
    }
    if (props.update) {
      this.setState({ individu: { ...props.menage } }, () => {
        this.state.individu.numeroCarnet &&
          this.state.individu.numeroCarnet != "" &&
          this.setState({
            individu: {
              ...this.state.individu,
            },
          });
      });
    }
  };

  handleChangeZonage = (situation) => {
    const { individu } = this.state;
    individu.communeId = situation?.commune;
    individu.districtId = situation?.district;
    individu.regionId = situation?.region;
    individu.fokotanyId = situation?.fokontany;
    let requiredFields = { ...this.state.requiredFields };

    if( individu.regionId === -1) {
      requiredFields.region = "champs obligatoire";
    }
    if( individu.districtId === -1) {
      requiredFields.district = "champs obligatoire";
    }
    if( individu.communeId === -1) {
      requiredFields.commune = "champs obligatoire";
    }
    if( individu.fokotanyId === -1) {
      requiredFields.fokotany = "champs obligatoire";
    }
    
    this.setState({ individu, requiredFields });
  };

  handleChange = (evt) => {
    let { name, value } = evt.target;

    let requiredFields = { ...this.state.requiredFields };
    switch (name) {
      case 'nom':
        value = value.replace(/[0-9]/, "");
        break;
        
      case "cin":
        value = value.replace(/[^\d]/, "");
        requiredFields.cin = value.length != 12 ? "12 chiffres réquis" : "";
        if (value.length > 12) {
          return "";
        }
        break;

      case "telephone":
        value = value.replace(/[^\d]/, "");
        requiredFields.telephone =
          value.length !== 0 && value.length !== 10 ? "10 chiffres réquis" : "";
        if (value.length > 10) {
          return "";
        }
        break;
      default:
        break;
    }

    this.setState(
      {
        individu: {
          ...this.state.individu,
          [evt.target.name]: value,
        },
        requiredFields,
      },
      () => {
        this.props.data(this.state.individu);
      }
    );
  };

  // handleZonage = (data) => {
  //   this.setState(
  //     {
  //       ...this.state,
  //       individu: {
  //         ...this.state.individu,
  //         ...data,
  //       },
  //     },
  //     () => {
  //       this.props.data(this.state.individu);
  //     }
  //   );
  // };

  handleCheckBoxChange = (event) => {
    const { name } = event.target;
    console.log("DDDDDDD : ", name, " --- ", event.target.checked);
    this.setState(
      {
        individu: {
          ...this.state.individu,
          [name]: event.target.checked,
        },
      },
      () => {
        this.props.data(this.state.individu);
      }
    );
  };

  handeleDateNaissance = (date) => {
    let value = "";
    if (date.isValid) {
      value = date.format("YYYY");
    }
    this.setState(
      {
        individu: {
          ...this.state.individu,
          dateNaissance: value,
        },
      },
      () => {
        this.props.data(this.state.individu);
      }
    );
  };

  getBirthYear = (data) => {
    if (data.dateNaissance) {
      return data.dateNaissance.toString().includes("/")
        ? data.dateNaissance?.split("/").pop()
        : data.dateNaissance;
    } else {
      return data.age;
    }
  };

  handeleDateCin = (date) => {
    let value = "";
    if (date.isValid) {
      value = date.format("DD/MM/YYYY");
    }
    this.setState(
      {
        individu: {
          ...this.state.individu,
          dateCin: value,
        },
      },
      () => {
        this.props.data(this.state.individu);
      }
    );
  };

  getNationalite = (data) => {
    return nationalites.find((item) => item.id == data || item.value == data);
  };

  render() {
    const { readOnly } = this.props;
    // const zonageData = {
    //   ...this.state.individu,
    //   requiredFields: this.state.requiredFields,
    // };

    return (
      <>
        <Row>
          <Col>
            <FormGroup>
              <div className="h3 text-primary">Chef de Famille</div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-nom">
                Nom et Prénom*
              </label>
              <Input
                className="form-control-alternative"
                id="input-nom"
                placeholder=""
                type="text"
                name="nom"
                value={this.state.individu.nom}
                onChange={this.handleChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                readOnly={readOnly}
              />
              {this.state.requiredFields &&
                this.state.requiredFields.nom &&
                this.state.requiredFields.nom.length > 0 && (
                  <small className="text-danger">
                    {" "}
                    {this.state.requiredFields.nom}
                  </small>
                )}
            </FormGroup>
          </Col>

          {/* <Col lg='5'>
            <FormGroup>
              <label className="form-control-label" htmlFor="input-prenom">
                Prénom
              </label>
              <Input
                className="form-control-alternative"
                id="input-prenom"
                placeholder=""
                type="text"
                name="prenom"
                value={this.state.individu.prenom}
                onChange={this.handleChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                readOnly={readOnly}
              />
            </FormGroup>
          </Col> */}

          <Col lg="3">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-sexe">
                Genre
              </label>
              <div className="input-group-alternative input-group">
                <select
                  className="mdb-select md-form  form-control"
                  onChange={this.handleChange}
                  name="sexe"
                  value={this.state.individu.sexe}
                  readOnly={readOnly}
                >
                  <option value="Masculin">Masculin</option>
                  <option value="Feminin">Feminin</option>
                </select>
              </div>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg="4">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-dateNaissance"
              >
                Année de naissance*
              </label>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <ReactDatetime
                    inputProps={{
                      placeholder: "Choisir date",
                      onKeyDown: (e) => e.preventDefault(),
                      readOnly: { readOnly },
                    }}
                    dateFormat="YYYY"
                    timeFormat={false}
                    closeOnSelect={true}
                    onChange={this.handeleDateNaissance}
                    name="dateNaissance"
                    selected={this.state.individu.dateNaissance}
                    value={this.getBirthYear(this.state.individu)}
                  />
                </InputGroup>
              </FormGroup>
            </FormGroup>
          </Col>

          <Col lg="5">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-lieuNaissance"
              >
                Lieu de naissance*
              </label>
              <Input
                className="form-control-alternative"
                id="input-lieuNaissance"
                placeholder=""
                type="text"
                name="lieuNaissance"
                value={this.state.individu.lieuNaissance}
                onChange={this.handleChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                readOnly={readOnly}
              />
              {this.state.requiredFields &&
                this.state.requiredFields.lieuNaissance &&
                this.state.requiredFields.lieuNaissance.length > 0 && (
                  <small className="text-danger">
                    {" "}
                    {this.state.requiredFields.lieuNaissance}
                  </small>
                )}
            </FormGroup>
          </Col>

          <Col lg="3">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-sexe">
                Nationalité*
              </label>
              <div className="input-group-alternative input-group">
                <select
                  className="mdb-select md-form  form-control"
                  onChange={this.handleChange}
                  name="nationalite"
                  value={
                    this.getNationalite(this.state.individu.nationalite)
                      ?.value || ""
                  }
                  readOnly={readOnly}
                >
                  <option></option>
                  {nationalites.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.value}
                    </option>
                  ))}
                </select>
              </div>
              {this.state.requiredFields && this.state.requiredFields.nationalite && (
                <small className="text-danger">
                  {this.state.requiredFields.nationalite}
                </small>
              )}
            </FormGroup>
          </Col>
        </Row>

        

        <Row>
          <Col lg="8">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-pere">
                Père
              </label>
              <Input
                className="form-control-alternative"
                id="input-pere"
                placeholder=""
                type="text"
                name="pere"
                value={this.state.individu.pere}
                onChange={this.handleChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                readOnly={readOnly}
              />
            </FormGroup>
          </Col>

          <Col lg="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-mentionPere">
                Mention père
              </label>
              <Input
                className="form-control-alternative"
                id="input-mentionPere"
                placeholder=""
                type="text"
                name="mentionPere"
                value={this.state.individu.mentionPere}
                onChange={this.handleChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                readOnly={readOnly}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg="8">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-mere">
                Mère
              </label>
              <Input
                className="form-control-alternative"
                id="input-mere"
                placeholder=""
                type="text"
                name="mere"
                value={this.state.individu.mere}
                onChange={this.handleChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                readOnly={readOnly}
              />
            </FormGroup>
          </Col>

          <Col lg="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-mentionMere">
                Mention mère
              </label>
              <Input
                className="form-control-alternative"
                id="input-mentionMere"
                placeholder=""
                type="text"
                name="mentionMere"
                value={this.state.individu.mentionMere}
                onChange={this.handleChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                readOnly={readOnly}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg="2">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-telephone">
                Téléphone
              </label>
              <Input
                className="form-control-alternative"
                placeholder=""
                type="text"
                value={this.state.individu.telephone}
                onChange={this.handleChange}
                name="telephone"
                readOnly={readOnly}
              />
              {this.state.requiredFields &&
                this.state.requiredFields.telephone &&
                this.state.requiredFields.telephone.length > 0 && (
                  <small className="text-danger">
                    {" "}
                    {this.state.requiredFields.telephone}
                  </small>
                )}
            </FormGroup>
          </Col>

          <Col lg="4">
            <FormGroup>
              <label
                className="form-control- requiredFields = label"
                htmlFor="input-adresse"
              >
                Adresse*
              </label>
              <Input
                className="form-control-alternative"
                placeholder=""
                type="text"
                value={this.state.individu.adresse}
                onChange={this.handleChange}
                name="adresse"
                readOnly={readOnly}
              />
              {this.state.requiredFields &&
                this.state.requiredFields.adresse &&
                this.state.requiredFields.adresse.length > 0 && (
                  <small className="text-danger">
                    {" "}
                    {this.state.requiredFields.adresse}
                  </small>
                )}
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  className="custom-control-input form-control"
                  id="input-possedeCarnet"
                  type="checkbox"
                  name="possedeCarnet"
                  defaultChecked={this.state.individu.possedeCarnet}
                  value={this.state.individu.possedeCarnet}
                  onClick={this.handleCheckBoxChange}
                  disabled={readOnly}
                />
                <label
                  className="custom-control-label text-bold"
                  htmlFor="input-possedeCarnet"
                >
                  Possède carnet
                </label>
              </div>
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-numeroCarnet"
              >
                Numéro carnet
              </label>
              <Input
                className="form-control-alternative"
                placeholder=""
                type="text"
                value={this.state.individu.numeroCarnet}
                onChange={this.handleChange}
                name="numeroCarnet"
                readOnly={readOnly}
                disabled={!this.state.individu.possedeCarnet}
              />
            </FormGroup>
          </Col>
        </Row>

        <hr className="my-4" />
        <div className="mb-4">
          {/* <Zonage
            data={this.handleZonage}
            menage={zonageData}
            data2="tb"
            hideHameau={true}
          /> */}

          <Zonnage
            handleChangeZonage={this.handleChangeZonage}
            filter={this.state.individu}
            isFilter={false}
            requiredFields={this.state.requiredFields}
          />
        </div>

        <Row>
          <Col lg="2">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-numeroRegistre"
              >
                Numéro régistre
              </label>
              <Input
                className="form-control-alternative"
                placeholder=""
                type="text"
                value={this.state.individu.numeroRegistre}
                onChange={this.handleChange}
                name="numeroRegistre"
                readOnly={readOnly}
              />
            </FormGroup>
          </Col>

          <Col lg="5">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-secteur">
                Secteur
              </label>
              <Input
                className="form-control-alternative"
                placeholder=""
                type="text"
                value={this.state.individu.secteur}
                onChange={this.handleChange}
                name="secteur"
                readOnly={readOnly}
              />
            </FormGroup>
          </Col>

          <Col lg="5">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-activite">
                Profession
              </label>
              <Input
                className="form-control-alternative"
                placeholder=""
                type="text"
                value={this.state.individu.activite}
                onChange={this.handleChange}
                name="activite"
                readOnly={readOnly}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg="2">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-first-name">
                Handicap
              </label>
              <div className="input-group-alternative input-group">
                <select
                  className="mdb-select md-form  form-control"
                  onChange={this.handleChange}
                  name="handicap"
                  value={this.state.individu.handicap}
                  readOnly={readOnly}
                >
                  <option value="false">Non</option>
                  <option value="true">Oui</option>
                </select>
              </div>
            </FormGroup>
          </Col>

          <Col lg="2">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-first-name">
                Locataire
              </label>
              <div className="input-group-alternative input-group">
                <select
                  className="mdb-select md-form  form-control"
                  onChange={this.handleChange}
                  name="locataire"
                  value={this.state.individu.locataire}
                  readOnly={readOnly}
                >
                  <option value="false">Non</option>
                  <option value="true">Oui</option>
                </select>
              </div>
            </FormGroup>
          </Col>

          <Col lg="8">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-affiliation">
                Affiliation
              </label>
              <Input
                className="form-control-alternative"
                placeholder=""
                type="text"
                value={this.state.individu.affiliation}
                onChange={this.handleChange}
                name="affiliation"
                readOnly={readOnly}
              />
            </FormGroup>
          </Col>

          <Col lg="12">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-observation">
                Observation
              </label>
              <Input
                className="form-control-alternative"
                placeholder=""
                type="text"
                value={this.state.individu.observation}
                onChange={this.handleChange}
                name="observation"
                readOnly={readOnly}
              />
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }
}

export default ChefFamille;
