import React from "react";
// reactstrap components
import { Button, Modal } from "reactstrap";

class NotificationModal extends React.Component {
  constructor(props) {
    super(props);

  }
  state = {
    show: false,
  };

  componentDidMount() {
    this.setState({
      show: this.props.show
    })
  }

  closeModal = () => {
    this.props.close()
  };
  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered modal-danger"
          contentClassName="bg-gradient-danger"
          isOpen={this.props.show}
        >
          <div className="modal-header">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"

            >
              <span aria-hidden={true} onClick={this.closeModal}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <i className="ni ni-bell-55 ni-3x" />
              <h4 className="heading mt-4">{this.props.title}</h4>
              <p>
                {this.props.message}
              </p>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              className="text-white ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={this.closeModal}
            >
              Fermer
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default NotificationModal;
