import axios from "axios";
import { BACK_URL } from "data/constant/urls";
import authHeader from "./AuthHeader";
import { filterAndPageable } from "../common/RequestUtils";
// const API_URL = process.env.REACT_APP_URL;
const API_URL = BACK_URL;

class EnrolementEnregistrementServices {
  post(data) {
    return axios.post(`${API_URL}api/enrolement/addEnrolement`, data, {
      headers: authHeader(),
    });
  }

  getList(page, size, filtre) {
    let requestWithDate = "";
    let data = [];
    if (filtre) {
      filtre.nomChefFiltre &&
        filtre.nomChefFiltre !== "" &&
        data.push({ option: "nomChef", value: filtre.nomChefFiltre });
      filtre.siteId &&
        filtre.siteId != -1 &&
        data.push({ option: "reference", value: filtre.siteId });
      filtre.voina &&
        filtre.voina !== "" &&
        data.push({ option: "voina", value: filtre.voina });
      if (filtre.avecDateSortie !== -1) {
        if (filtre.avecDateSortie === 1) requestWithDate = `&date=true`;
        else requestWithDate = "&date=false";
      }
      filtre.regionId &&
        filtre.regionId !== -1 &&
        data.push({ option: "region", value: filtre.regionId });
      filtre.districtId &&
        filtre.districtId !== -1 &&
        data.push({ option: "district", value: filtre.districtId });
      filtre.communeId &&
        filtre.communeId !== -1 &&
        data.push({ option: "commune", value: filtre.communeId });
      filtre.fokotanyId &&
        filtre.fokotanyId !== -1 &&
        data.push({ option: "fokotany", value: filtre.fokotanyId });
    }
    page && data.push({ option: "pageNo", value: page });
    size && data.push({ option: "pageSize", value: size });
    console.log("filterAndPageable(data) ", filterAndPageable(data));
    return axios.get(
      API_URL +
        "api/enrolement/findAllPagination" +
        filterAndPageable(data) +
        requestWithDate,
      {
        headers: authHeader(),
      }
    );
  }
  getListByMenage(page, size, idmenage) {
    let data = [];
    idmenage && data.push({ option: "idmenage", value: idmenage });
    page && data.push({ option: "page", value: page });
    size && data.push({ option: "size", value: size });
    return axios.get(
      API_URL +
        "api/enrolement/enrolementList?" +
        "page=" +
        page +
        "&size=" +
        size +
        "&idmenage=" +
        idmenage,
      {
        headers: authHeader(),
      }
    );
  }
  exitFromSite(data) {
    return axios.post(API_URL + "api/enrolement/sortieMenage", data, {
      headers: authHeader(),
    });
  }
  moveToOtherSite(data) {
    return axios.post(API_URL + "api/enrolement/deplaceMenage", data, {
      headers: authHeader(),
    });
  }
}

export default new EnrolementEnregistrementServices();
