import axios from "axios";
import { BACK_URL } from "data/constant/urls";
import authHeader from "./AuthHeader";

const API_URL = BACK_URL;

class TypeSiteService {
  getAllTypeSite() {
    return axios.get(API_URL + "param/sitetype/findAll", {
      headers: authHeader(),
    });
  }

  createTypeSite = (sitetype) => {
    return axios.post(API_URL + "param/sitetype/add", sitetype,{
        headers: authHeader(),
    });
}

  deleteTypeSite = (id) => {
    return axios.delete(API_URL + `param/sitetype/delete?id=${id}`, {
        headers: authHeader(),
    });
  }

  updateTypeSite = (sitetype) => {
    return axios.put(API_URL + `param/sitetype/update`, sitetype,{
        headers: authHeader(),
    });
}
}

export default new TypeSiteService();
