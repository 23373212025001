import AuthService from './AuthService';

export default function authHeader() {
  const user = JSON.parse(localStorage.getItem('user'));
  if (!user) {
    AuthService.logout();
  }
  const token = parseJwt(user?.token);

  if (Date.now() >= token.exp * 1000) {
    AuthService.logout();
    return;
  }

  if (user && user.token) {
    localStorage.setItem('username', user.nom);
    localStorage.setItem('roleName', user.roleName);

    return { Authorization: 'Bearer ' + user.token };
  } else {
    return {};
  }

}

export function authHeaderLoharano() {
  return {
    Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1NjgiLCJpYXQiOjE2NDMzNTQxMjIsImV4cCI6MTY0MzM1NzcyMn0.tfPP4cgwiEyrZxDw-DlEQTBz5mLDp1hi7MIzCkk8S2g"
  };
}

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};
