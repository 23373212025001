import axios from "axios";
import { BACK_URL } from "data/constant/urls";
import authHeader from "./AuthHeader";

const API_URL = BACK_URL;

class VoinaService {
  getAllVoina() {
    return axios.get(API_URL + "param/voina/findAll", {
      headers: authHeader(),
    });
  }

  createVoina = (voina) => {
    return axios.post(API_URL + "param/voina/add", voina, {
      headers: authHeader(),
    });
  };

  deleteVoina = (id) => {
    return axios.delete(API_URL + `param/voina/delete?id=${id}`, {
      headers: authHeader(),
    });
  };

  updateVoina = (voina) => {
    return axios.put(API_URL + `param/voina/update`, voina, {
      headers: authHeader(),
    });
  };
  getDefaultVoina() {
    return axios.get(API_URL + "param/voina/findDefaultVoina", {
      headers: authHeader(),
    });
  }
  setDefaultVoina(voinaId) {
    return axios.post(
      API_URL + "param/voina/addDefaultVoina?voinaId=" + voinaId,
      null,
      {
        headers: authHeader(),
      }
    );
  }
  updateDefaultVoina(voinaId) {
    return axios.put(
      API_URL + "param/voina/updateDefault?voinaId=" + voinaId,
      null,
      {
        headers: authHeader(),
      }
    );
  }
}

export default new VoinaService();
