import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormGroup, 
  Input,
  Modal,
  Row,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { Col, Form } from "react-bootstrap";

import SiteService from "services/SiteService";
import NotificationModal from "./NotificationModal";
import PageSpinner from "./PageSpinner";
import moment from "moment";
import ReactDatetime from "react-datetime";
import ObservationService from "services/ObservationService";
import TetheredDateTime from "./TetheredDateTime";

class CreateEditObservationModal extends React.Component {
  state = {
    showNotification: false,
    isLoading: false,
    title: "",
    site: {
      id: -1,
    },
    siteList: [],
    dateObservation: moment().format("YYYY-MM-DDTHH:mm:ss"),
    displaydDateObservation: moment().format('DD/MM/YYYY HH:mm'),
    id_site: 0,
    libelle: "",
    isError: false,
    supprimable: false,
    errorMessage: "",
    errorTitle: "",
    isUpdate: false,
    isEmptyLibelle: false,
    isSiteEmpty: false,
  };

  closeErrorNotification = () => {
    this.setState({
      ...this.state,
      isError: false,
    });
  };

  closeNotification = () => {
    this.setState({
      showNotification: false,
    });
  };

  handleChangeDate = (date) => {
    let value = "";
    if (date.isValid) {
      value = date.format("YYYY-MM-DDTHH:mm:ss");
    }
    this.setState({
      dateObservation: value,
      displaydDateObservation: moment.utc(value).format('DD/MM/YYYY HH:mm')
    });
  };

  handleCheckBoxChange = (event) => {
    const { name } = event.target;
    this.setState({
      [name]: event.target.checked,
    });
  };

  componentDidMount() {
    this.initialisation();
    this.setState({
      isUpdate: this.props.isUpdate,
    });
    !this.props.isUpdate &&
      this.setState({
        title: "AJOUT D'UNE NOUVELLE OBSERVATION",
      });
    this.props.isUpdate &&
      this.props.observation &&
      this.setDefaultData(this.props.observation);
  }

  initialisation = () => {
    this.getSites();
  };

  setDefaultData = (observation) => {
    this.setState({
      dateObservation: observation.dateObservation,
      displaydDateObservation: moment.utc(observation.dateObservation).format('DD/MM/YYYY HH:mm'),
      id_site: observation.site.id,
      libelle: observation.libelle,
      supprimable: observation.etatSupprimable,
      id: observation.id,
      title: "MODIFICATION D'UNE OBSERVATION",
      site: {id: observation.site.id},
    });
    
  };

  getSites = () => {
    this.setState({
      isLoading: true,
    });
    SiteService.getAllSite()
      .then((res) => res.data)
      .then((result) => {
        this.setState({
          siteList: result.data,
          isLoading: false,
        });
      });
  };

  handleInput = (event) => {
    const { site } = this.state;
    site[event.target.name] = event.target.value;
    this.setState({ site });
  };

  handleChangeLibelle = (evt) => {
    this.setState(
      {
        ...this.state,
        libelle: evt.target.value,
      },
      () => this.formValid()
    );
  };

  handleChangeSite = (evt) => {
    const { value } = evt.target;
    const { site } = this.state;
    site.id = value;
    this.setState(
      {
        site,
      },
      () => this.formValid()
    );
  };

  formValid = () => {
    const { site, libelle } = this.state;
    let { isEmptyLibelle, isSiteEmpty } = this.state;
    isEmptyLibelle = libelle == "";
    isSiteEmpty = site.id == -1;
    this.setState({
      isEmptyLibelle,
      isSiteEmpty,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.formValid();
    const { site, libelle } = this.state;
    if (libelle != "" && site.id != -1) {
      this.state.isUpdate ? this.processUpdate() : this.processSave();
    }
  };

  processSave = () => {
    this.setState({
      isLoading: true,
    });
    const data = this.state;
    const requestDTO = {
      dateObservation: data.dateObservation,
      etatSupprimable: data.supprimable,
      libelle: data.libelle,
      siteMedicalId: data.site.id,
    };
    ObservationService.create(requestDTO)
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        this.props.onSave();
      })
      .catch((exception) => {
        this.setState({
          isLoading: false,
          isError: true,
          errorMessage: exception.errorCode
            ? exception.errorMessage
            : "Contactez votre administrateur",
          errorTitle: "Erreur à l'ajout d'un site",
        });
      });
  };
  processUpdate = () => {
    this.setState({
      isLoading: true,
    });
    const data = this.state;
    const requestDTO = {
      dateObservation: data.dateObservation,
      etatSupprimable: data.supprimable,
      libelle: data.libelle,
      id: data.id,
      siteMedicalId: data.site.id,
    };
    ObservationService.update(requestDTO)
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        this.props.onSave();
      })
      .catch((exception) => {
        this.setState({
          isLoading: false,
          isError: true,
          errorMessage: exception.errorCode
            ? exception.errorMessage
            : "Contactez votre administrateur",
          errorTitle: "Erreur à l'ajout d'un site",
        });
      });
  };

  handleRemoveObservation = (id) => {
    // SiteService.delete(id).catch((exception) => {
    //   this.setState({
    //     isLoading: false,
    //     isError: true,
    //     errorMessage: exception.errorCode
    //       ? exception.errorMessage
    //       : "Contactez votre administrateur",
    //     errorTitle: "Erreur à la modification d'un site",
    //   });
    // });
  };

  render() {
    console.log('DATE OBSERVATION');
    console.log(this.state.dateObservation);

    const siteList =
      this.state.siteList &&
      this.state.siteList.map((elt) => (
        <option key={elt.id} value={elt.id}>
          {elt.nom_site}
        </option>
      ));

    return (
      <>
        <PageSpinner show={this.state.isLoading} />
        <Modal
          className="modal-dialog-centered"
          isOpen={true}
          size="lg"
          style={{ zIndex: "-100", maxWidth: "1000px", width: "100%" }}
        >
          <div className="modal-body p-0">
            <Card className=" border-0">
              <CardHeader className="bg-light-secondary pb-0">
                <div className="text-muted text-center mt-2 mb-3 ">
                  <h2>{this.state.title}</h2>
                </div>
              </CardHeader>
              <CardBody className="px-lg-4 py-lg-2">
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Site concerné
                          </label>
                          <Input
                            type="select"
                            className={
                              this.state.isSiteEmpty
                                ? "form-control-alternative-error mdb-select md-form form-control"
                                : "mdb-select md-form form-control"
                            }
                            onChange={this.handleChangeSite}
                            name="typeSite"
                            value={this.state.site.id}
                          >
                            <option value={-1}></option>
                            {siteList}
                          </Input>
                          {this.state.isSiteEmpty && (
                            <small className="text-danger">
                              Champ obligatoire
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Libellé de l'observation
                          </label>
                          <Input
                            className={
                              this.state.isEmptyLibelle
                                ? "form-control-alternative-error"
                                : "form-control-alternative"
                            }
                            id="input-username"
                            placeholder=""
                            type="text"
                            name="libelle"
                            value={this.state.libelle}
                            onChange={this.handleChangeLibelle}
                          />
                          {this.state.isEmptyLibelle && (
                            <small className="text-danger">
                              Champ obligatoire
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            htmlFor="input-date-enquete"
                          >
                            Date de l'observation
                          </Label>
                          <InputGroup className="input-group-alternative flex-nowrap">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-calendar-grid-58" />
                              </InputGroupText>
                            </InputGroupAddon>
                            {/* <ReactDatetime */}
                            <TetheredDateTime
                              className="position-relative"
                              // positionFixed={true}
                              inputProps={{
                                placeholder: "DD/MM/YYYY HH:mm",
                                onKeyDown: (e) => e.preventDefault(),
                              }}
                              dateFormat={"DD/MM/YYYY"}
                              closeOnSelect={true}
                              timeFormat={'HH:mm'}
                              name="dateObservation"
                              id="dateObservation"
                              onChange={(date) => this.handleChangeDate(date)}
                              value={this.state.displaydDateObservation}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              className="custom-control-input form-control"
                              id="supprimable"
                              type="checkbox"
                              name="supprimable"
                              defaultChecked={this.state.supprimable}
                              value={this.state.supprimable}
                              onChange={this.handleCheckBoxChange}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="supprimable"
                            >
                              Observation supprimable
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  
                </Form>
              </CardBody>
              <hr className="m-0" />
              <div className="modal-footer">
                {this.state.isError && <span>(*) </span>}
                <Button type="button" onClick={this.props.onCancel}>
                  Annuler
                </Button>
                <Button
                  type="button"
                  color="primary"
                  onClick={this.handleSubmit}
                >
                  <span>Enregistrer</span>
                </Button>
              </div>
            </Card>
          </div>
        </Modal>
        {this.state.showNotification && (
          <NotificationModal
            show={this.state.showNotification}
            message={this.notificationMessage}
            title={this.notificationTitle}
            close={this.closeNotification}
          />
        )}
        {this.state.isError && (
          <NotificationModal
            show={this.state.isError}
            message={this.state.errorMessage}
            title={this.state.errorTitle}
            close={this.closeErrorNotification}
          />
        )}
      </>
    );
  }
}

export default CreateEditObservationModal;
