import React from "react";
import { getRole } from "common/data.js";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Media,
  Modal,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import UtilisateurService from "services/UtilisateurService";
import CreateEditUtilisateurModal from "views/components/CreateEditUtilisateurModal";
import PageSpinner from "views/components/PageSpinner";
import ConfirmationModal from "views/components/ConfirmationModal";
import { user_fullRights } from "data/constant/user.rights";

class UtilisateurPage extends React.Component {
  constructor(props) {
    super(props);
    this.selectedUser = null;
  }
  state = {
    isLoadig:false,
    utilisateurs: [],
    page: 0,
    pageSize:10,
    totalPage: 1,
    items: 0,
    totalItems: 0,
    showUser: false,
    nom:'',
    showModalDelete:false,
    selectedLineIndex: null,
    isUpdate: false,
  };

  componentDidMount() {
    this.getListUtilisateurs();
  }

  nextPage = () => {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => this.getListUtilisateurs()
    );
  };

  onSucces = () =>{
    this.setState({
      showUser:false
    })
    this.getListUtilisateurs();
  }

  onSearch = () =>{
    this.setState({
      isLoading: true,
      page:0,
    });
    UtilisateurService.getListUtilisateurs(this.state.nom, this.state.page, this.state.pageSize).then(
      (response) => {
        this.setState({
          utilisateurs: response?.data?.data,
          isLoading: false,
          items:  response?.data?.data?response?.data?.data?.length:0,
        });
        if (response?.data?.totalPage) {
          this.setState({
            totalPage: response?.data?.totalPage,
            items: response?.data?.data?.length,
            totalItems: response?.data?.totalItems,
          });
        }
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  }

  previousPage = () => {
    this.setState(
      {
        page: this.state.page - 1,
      },
      () => {
        this.setState({
          isLoading: true,
        });

        UtilisateurService.getListUtilisateurs(this.state.nom, this.state.page, this.state.pageSize).then(
          (response) => {
            this.setState({
              utilisateurs: response?.data?.data,
              isLoading: false,
            });
            if (response.data.totalPage) {
              this.setState({
                totalPage: response?.data?.totalPage,
                items: response?.data?.data?.length,
                totalItems: response?.data?.totalItems,
              });
            }
          },
          (error) => {
            this.setState({
              isLoading: false,
            });
          }
        );
      }
    );
  };

  onPressEnter = (event) => {
    if (event.key === "Enter") {
      this.onSearch();
    }
  };

  getListUtilisateurs = () => {
    this.setState({
      isLoading: true,
    });
    UtilisateurService.getListUtilisateurs(this.state.nom, this.state.page).then(
      (response) => {
        this.setState({
          utilisateurs: response.data.data,
          isLoading: false,
        });
        if (response.data.totalPage) {
          this.setState({
            totalPage: response.data.totalPage,
            items: response.data.data.length,
            totalItems: response.data.totalItems,
          });
        }
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  onShowUser = (user, isUpdate) => {

    this.selectedUser = user;
    this.setState({
      showUser: true,
      isUpdate
    });
  };

  onDeleteUser = (user) => {
    this.selectedUser = user;
    this.setState({
      showModalDelete: true,
    });
  };

  onHideUser = () => {
    this.setState({
      showUser: false,
    });
  };

  handleChange= (e) =>{
    const {name, value} =e.target;
    this.setState({
      [name]:value
    })
  }

  hideModalDelete = () =>{
    this.setState({
      showModalDelete:false
    })
  }

  saveModalDelete = () =>{
    this.setState({
      isLoading:true,
      showModalDelete:false,
    })
    UtilisateurService.delete(this.selectedUser.id).then(data=>{
      this.getListUtilisateurs();
      this.setState({
        isLoading:false
      })
    })
  }

  render() {
    const roleName = localStorage.getItem("roleName");
    if(!user_fullRights.includes(roleName)){
      this.props.history.push("/admin/gestion-menage");
    }
    const tableData =
      this.state.utilisateurs &&
      this.state.utilisateurs.map((utilisateur, index) => (
        <tr key={index}
          className={this.state.selectedLineIndex == index ? "selected" : ""}
        >
          <td className="sticky-col sticky-left p-1">
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-icon-only text-light mr-0"
                href="#"
                role="button"
                size="sm"
                color=""
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ selectedLineIndex: index });
                }}
              >
                <i className="fas fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" container="body" positionFixed={true}  right>
                <DropdownItem
                  href="#"
                  className="clickable"
                  onClick={() => this.onShowUser(utilisateur, true)}
                >
                  Modifier utilisateur
                </DropdownItem>
                <DropdownItem
                  href="#"
                  className="clickable"
                  onClick={() => this.onDeleteUser(utilisateur)}
                >
                  Supprimer utilisateur
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
          <td>{utilisateur.pseudo}</td>
          <td>{utilisateur.nom}</td>
          <td>{getRole(utilisateur.roleName)}</td>
          
        </tr>
      ));
    return (
      <>
      {this.state.showModalDelete && (
        <ConfirmationModal
        onCancel={this.hideModalDelete}
        onSave={this.saveModalDelete}
        />
      )}
        {this.state.showUser && (
          <CreateEditUtilisateurModal
            utilisateur={this.selectedUser}
            onCancel={this.onHideUser}
            onSucces={this.onSucces}
            isUpdate={this.state.isUpdate}
          />
        )}
        <div className="header bg-gradient-primary postition-absolute"></div>
        {/* Page content */}
        <Container className="mt-5 custom-card-container" fluid >
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <div className="container">
                    <Row>
                      <Col lg="6" className="mb-2 mb-xl-0">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder="Nom et Prénoms"
                            type="text"
                            name="nom"
                            onChange={this.handleChange}
                            onKeyDown={this.onPressEnter}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="mb-2 mb-xl-0">
                        <FormGroup>
                          <Button
                            className="btn"
                            onClick={this.onSearch}
                            color="primary"
                          >
                            Rechercher
                          </Button>
                        </FormGroup>
                      </Col>
                      <Col className="mb-2 mb-xl-0">
                        <FormGroup>
                          <Button
                            className="btn"
                            onClick={() => this.onShowUser(null, false)}
                          >
                            Ajouter un nouvel utilisateur
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </CardHeader>
                <Table
                  className="align-items-center table-flush table table-sticky"
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" className="p-1" />
                      <th scope="col">Pseudo</th>
                      <th scope="col">Nom et prénoms</th>
                      <th scope="col">Rôle</th>
                    </tr>
                  </thead>
                  <tbody>{tableData}</tbody>
                </Table>
                <CardFooter className="py-2 footer-pagination-container">
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    <b>Total Page:</b> {this.state.totalPage}
                  </label>
                
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    {this.state.items} <b>individus sur</b> {this.state.totalItems}
                  </label>
                  <div className="pagination-navigation-container">
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      <b>Page courante:</b> {this.state.page + 1}
                    </label>
                    <nav aria-label="...">
                      <Pagination
                        className="pagination pagination-lg justify-content-end mb-0"
                        listClassName="justify-content-end mb-0 pagination-lg"
                      >
                        <PaginationItem>
                          {this.state.page > 0 && (
                            <PaginationLink
                              href="#pablo"
                              onClick={this.previousPage}
                              tabIndex="-1"
                            >
                              <i className="fas fa-angle-left" />
                              <span className="sr-only">Previous</span>
                            </PaginationLink>
                          )}
                        </PaginationItem>
                        <PaginationItem
                          className={
                            this.state.page + 1 < this.state.totalPage
                              ? "active"
                              : "disabled"
                          }
                        >
                          <PaginationLink href="#" onClick={this.nextPage}>
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </nav>
                  </div>
                </CardFooter>
              </Card>
            </div>
          </Row>
          <PageSpinner show={this.state.isLoading}/>
        </Container>
      </>
    );
  }
}

export default UtilisateurPage;
