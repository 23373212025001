import { ListActivites } from "common/data";
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import MenageService from "services/MenageService";
import ConfirmationModal from "views/components/ConfirmationModal";
import CreateEditFicheTraitementModal from "views/components/CreateEditFicheTraitementModal";
import EnrolementParMenageModal from "views/components/EnrolementParMenageModal";
import CreateEditIndividuModal from "views/components/CreateEditIndividuModal";
import CreateMenageModal from "views/components/CreateMenageModal";
import FicheMenageModal from "views/components/FicheMenageModal";
import InformationModal from "views/components/InformationModal";
import LoharanoModal from "views/components/LoharanoModal";
import DonCreateModal from "views/components/DonCreateModal";
import DonListModal from "views/components/DonListModal";
import LoharanoListModal from "views/components/LoharanoListModal";
import QRContainer from "views/containers/QRContainer";
import ListIndividuMenage from "views/components/ListIndividusMenage";
import PageSpinner from "views/components/PageSpinner";
import Zonnage from "views/components/Zonnage";
import { Download } from "helper/ImpressionHelper";
import { user_editor } from "data/constant/user.rights";
import eFokontanyService from "services/eFokontanyService";
import { user_viewer } from "data/constant/user.rights";
import { user_gest_respes } from "data/constant/user.rights";
import DataContext from "context/DataContext";
import NotificationModal from "views/components/NotificationModal";

class MenagePage extends React.Component {
  constructor(props) {
    super(props);
    this.onShowIndividu.bind(this);
    this.onShowFiche.bind(this);
    this.onHideFiche.bind(this);
    this.onHideMenage.bind(this);
    this.onHideIndividu.bind(this);
    this.onSuivreTraitement.bind(this);
    this.onSave.bind(this);
    this.nextPage.bind(this);
    this.previousPage.bind(this);
    this.onSearch.bind(this);
    this.showConfirmationModal = this.showConfirmationModal.bind(this);
    this.updateFiche = this.updateFiche.bind(this);
    this.idMenage = 0;
    this.activites = ListActivites;
    this.menage = null;
  }

  static contextType = DataContext;

  state = {
    content: [],
    showNotification: false,
    showFicheMenage: false,
    showMenage: false,
    showFiche: false,
    ShowHistory: false,
    showIndividu: false,
    isLoading: false,
    confirmationModal: false,
    listerIndividu: false,
    page: 0,
    totalPage: 1,
    items: 0,
    totalitems: 0,
    activite: "",
    chefFamille: "",
    identifiantMenage: "",
    commune: "",
    fokotany: "",
    district: "",
    region: "",
    currentIndiviu: null,
    currentMenage: null,
    cf: false,
    pageSize: "10",
    filtre: {},
    checked: [],
    confirmationModalDeleteMenages: false,
    isQRreader: false,
    isInformation: false,
    nom: "",
    prenom: "",
    cin: "",
    selectedMenage: null,
    showDonCreateModal: false,
    showDonListModal: false,
    selectedLineIndex: null,
  };

  updateFiche = () => {
    // console.log("menage");
  };

  showEnrolerSurSite = (menage) => {
    this.idMenage = menage.id;
    this.props.history.push(
      `/admin/addEnrolement/${menage.id}/${menage.nomChef}`
    );
  };
  onCloseModal = () => {
    this.setState({
      ShowHistory: false,
    });
  };
  closeNotification = () => {
    this.setState({
      showNotification: false,
    });
  };
  showEnrolementHistory = (menage) => {
    this.setState({
      ShowHistory: true,
      selectedMenage: menage,
    });
  };

  addDon = (menage) => {
    this.setState({ selectedMenage: menage, showDonCreateModal: true });
  };

  showListDon = (menage) => {
    this.setState({ selectedMenage: menage, showDonListModal: true });
  };

  hideListIndividu = () => {
    this.setState({
      listerIndividu: false,
    });
  };

  addInvidu = (menage) => {
    this.menage = menage;
    this.setState({
      showIndividu: true,
    });
  };
  listerIndividu = (individu) => {
    MenageService.getIndividusByMenageId(individu.id).then((data) => {});
  };

  deleteMenage = (menage) => {
    this.menage = menage;
    this.idMenage = menage.id;
    this.setState({
      confirmationModal: true,
    });
  };

  componentDidMount() {
    this.getListMenage();
  }

  onSearch = () => {
    this.setState({
      isLoading: true,
      page: 0,
    });
    const size = parseInt(this.state.pageSize);
    MenageService.getListByPagination(0, size, this.state.filtre).then(
      (response) => {
        this.setState({
          content: response.data.data,
          isLoading: false,
          items: response.data.data ? response.data.data.length : 0,
          checked: [],
        });
        if (response.data.totalPage) {
          this.setState({
            totalPage: response.data.totalPage,
            items: response.data.data.length,
            totalitems: response.data.totalitems,
          });
        }
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  onPressEnter = (event) => {
    if (event.key === "Enter") {
      this.onSearch();
    }
  };

  showConfirmationModal = () => {
    this.setState({
      confirmationModal: true,
      individuSelected: true,
    });
  };

  onShowIndividu = (id) => {
    this.idMenage = id;
    this.setState({
      showMenage: false,
      showIndividu: true,
    });
  };

  onHideIndividu = () => {
    this.setState({
      showIndividu: false,
    });
  };

  getListMenage() {
    this.setState({
      isLoading: true,
    });
    const page = parseInt(this.state.pageSize);
    MenageService.getListByPagination(
      this.state.page,
      page,
      this.state.filtre
    ).then(
      (response) => {
        this.setState({
          content: response.data.data,
          isLoading: false,
        });
        if (response.data.totalPage) {
          this.setState({
            totalPage: response.data.totalPage,
            items: this.state.items + response.data.data.length,
            totalitems: response.data.totalitems,
          });
        }
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  }

  handleChange = (evt) => {
    let value = evt.target.value;
    if (evt.target.name === "cin") value = evt.target.value.replace(/\D/g, "");
    this.setState({
      filtre: {
        ...this.state.filtre,
        [evt.target.name]: value,
      },
    });
  };

  onHideMenage = () => {
    this.setState({
      showMenage: false,
    });
  };

  onSave = (data) => {
    this.setState(
      {
        showIndividu: false,
        showMenage: false,
        identifiantMenage: data.adresse,
      },
      () => this.onSearch()
    );
  };

  // loharano
  showSearchLoharano = () => {
    this.setState({
      isLoharanoSearch: true,
    });
  };

  updateMenage = (menage) => {
    this.props.history.push(`/admin/menage/${menage.id}`);
  };

  onShowFiche = (data) => {
    if (data) {
      this.currentIndiviu = { id: data.id, nom: `${data.nom} ` };
    }
    this.getListMenage();
    this.setState({
      showFiche: true,
      showIndividu: false,
      showMenage: false,
    });
  };

  onHideFiche = () => {
    this.setState({
      showFiche: false,
    });
  };

  onSuivreTraitement = (data) => {
    this.setState({
      showFiche: false,
    });
    this.props.history.push("/admin/fiche-suivi/" + data.id);
  };

  nextPage = (e) => {
    e.preventDefault();
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => this.getListMenage()
    );
  };

  previousPage = () => {
    const page = parseInt(this.state.pageSize);
    this.setState(
      {
        page: this.state.page - 1,
      },
      () => {
        this.setState({
          isLoading: true,
        });
        MenageService.getListByPagination(
          this.state.page,
          page,
          this.state.filtre
        ).then(
          (response) => {
            this.setState({
              content: response.data.data,
              isLoading: false,
            });
            if (response.data.totalPage) {
              this.setState({
                totalPage: response.data.totalPage,
                items: this.state.items - response.data.data.length,
                totalitems: response.data.totalitems,
              });
            }
          },
          (error) => {
            this.setState({
              isLoading: false,
            });
          }
        );
      }
    );
  };

  cancelConfirmation = () => {
    this.setState({
      confirmationModal: false,
      confirmationModalDeleteMenages: false,
    });
  };

  saveConfirmation = (e) => {
    this.setState({
      isLoading: true,
    });
    MenageService.delete(this.menage.id)
      .then((data) => {
        if (data.data?.code === "400") {
          this.notificationMessage = data.data?.message;
          this.notificationTitle = "Erreur lors de la suppression";
          this.setState({
            showNotification: true,
            confirmationModal: false,
            isLoading: false,
          });
        } else {
          this.getListMenage();
          this.setState({
            confirmationModal: false,
            isLoading: false,
            checked: this.state.checked.filter((c) => c !== this.menage.id),
          });
        }
      })
      .catch((error) => {
        console.log("Erreur de suppression : ", error);
        this.notificationMessage = "Contactez votre administrateur";
        this.notificationTitle = "Erreur lors de la suppression";
        this.setState({
          showNotification: true,
          confirmationModal: false,
          isLoading: false,
        });
      });
  };

  showFicheMenage = (e, menage) => {
    e.preventDefault();

    this.setState({
      showFicheMenage: true,
      currentMenage: menage,
    });
  };
  hideFicheMenage = () => {
    this.setState({
      showFicheMenage: false,
    });
  };
  handlePage = (event) => {
    this.setState(
      {
        items: 0,
        [event.target.id]: parseInt(event.target.value),
      },
      () => {
        this.setState({
          isLoading: true,
          page: 0,
        });
        const page = parseInt(this.state.pageSize);
        MenageService.getListByPagination(0, page, this.state.filtre).then(
          (response) => {
            this.setState({
              content: response.data.data,
              isLoading: false,
            });
            if (response.data.totalPage) {
              this.setState({
                totalPage: response.data.totalPage,
                items: response.data.data.length,
                totalitems: response.data.totalitems,
              });
            }
          },
          (error) => {
            this.setState({
              isLoading: false,
            });
          }
        );
      }
    );
  };

  handleMenage = (data) => {
    this.setState({
      ...this.state,
      ...data,
      filtre: {
        ...this.state.filtre,
        ...data,
      },
    });
  };

  handleChangeDelete = (menage, checked) => {
    const IdToDelete =
      menage === "all"
        ? this.state.content
          ? this.state.content.map(({ id }) => id)
          : []
        : [menage];
    this.setState({
      ...this.state,
      checked: checked
        ? [...this.state.checked, ...IdToDelete].filter(
            (data, index, self) => index === self.findIndex((d) => d === data)
          )
        : this.state.checked.filter((el) => !IdToDelete.includes(el)),
    });
  };

  onDeleteMenages = () => {
    this.setState({
      confirmationModalDeleteMenages: true,
    });
  };

  deleteMenages = () => {
    this.setState({
      isLoading: true,
    });
    const ids = this.state.checked
      .reduce(
        (accumulateur, current) => [...accumulateur, `ids=${current}`],
        []
      )
      .join("&");

    MenageService.deleteMenages(ids)
      .then(
        (data) => {
          console.log("Data response : ", data);
          this.getListMenage();
          this.setState({
            confirmationModalDeleteMenages: false,
            isLoading: false,
            checked: [],
          });
        },
        (error) => {
          console.log("EROOR : ", error);
          this.setState({
            confirmationModalDeleteMenages: false,
            isLoading: false,
          });
        }
      )
      .catch((error) => {
        console.log("Data error response : ", error);
      });
  };

  handleChangeZonage = (situation) => {
    const { filtre } = this.state;

    filtre.regionId = situation.region;
    filtre.districtId = situation.district;
    filtre.communeId = situation.commune;
    filtre.fokotanyId = situation.fokontany;

    const filterRegion = this.context?.displayListRegion.filter(
      (region) => region.id === situation.region
    );

    console.log("filterRegion ", filterRegion);

    filtre.region =
      (filterRegion && filterRegion[0] && filterRegion[0].nom_region) || "";

    const filterDistrict = this.context?.displayListDistrict.filter(
      (district) => district.id === situation.district
    );

    filtre.district =
      (filterDistrict &&
        filterDistrict[0] &&
        filterDistrict[0].nom_districte) ||
      "";

    const filterCommune = this.context?.displayListCommune.filter(
      (commune) => commune.id === situation.commune
    );
    filtre.commune =
      (filterCommune && filterCommune[0] && filterCommune[0].nom_commune) || "";

    const filterFokontany = this.context?.displayListFokotany.filter(
      (fokontany) => fokontany.id === situation.fokontany
    );
    filtre.fokotany =
      (filterFokontany &&
        filterFokontany[0] &&
        filterFokontany[0].nom_fokotany) ||
      "";

    console.log("this.state.filtre", filtre);

    this.setState({ filtre });
  };

  exportData = () => {
    this.setState({
      isLoading: true,
    });

    const data = {
      cf: this.state.cf,
      individu: this.state.chefFamille,
      situation: {
        commune: this.state.commune,
        district: this.state.district,
        fokotany: this.state.fokotany,
        region: this.state.region,
      },
      type: "EXPORTMENAGES",
    };

    MenageService.exportDatas(data)
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        Download(response);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });
  };

  showInformation = (menage) => {
    this.setState({
      isInformation: true,
      currentMenage: menage,
    });
  };

  hideInformation = () => {
    this.setState({
      isInformation: false,
      currentMenage: {},
    });
  };

  showQRreader = () => {
    this.setState({
      isQRreader: true,
      isLoharanoSearch: false,
    });
  };

  hideQRreader = () => {
    this.setState({
      isQRreader: false,
      isLoharanoSearch: true,
    });
  };

  searchQRcode = (data) => {
    this.setState({
      isQRreader: false,
      isLoharanoResult: false,
    });
    this.props.history.push({
      pathname: `/admin/menage/loharano`,
      search: data,
    });
  };

  searchLoharano = (data) => {
    this.setState({ isLoharanoSearch: false, isLoading: true });
    data &&
      eFokontanyService
        .searchCitizen(data)
        .then((result) => {
          this.setState({
            isLoharanoResult: true,
            isLoading: false,
            loharanoResult: result ? result.data : {},
          });
        })
        .catch((error) => this.setState({ isLoading: false }));
  };

  render() {
    // console.log("this.state.content", this.state.content);
    const tableData =
      this.state.content &&
      this.state.content.map((menage, index) => {
        return (
          <tr
            key={index}
            className={this.state.selectedLineIndex === index ? "selected" : ""}
          >
            <td className="sticky-col sticky-left p-1">
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light m-0"
                  href="#"
                  role="button"
                  size="sm"
                  color=""
                  outline={true}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ selectedLineIndex: index });
                  }}
                >
                  <i className="fas fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu
                  container="body"
                  positionFixed={true}
                  className="dropdown-menu-arrow"
                  right
                >
                  {/** 
                  <DropdownItem
                    href="#"
                    onClick={() => this.addInvidu(menage)}
                  >
                    Ajouter individu
                  </DropdownItem>
                  */}
                  {user_gest_respes.includes(
                    localStorage.getItem("roleName") || ""
                  ) && (
                    <DropdownItem
                      href="#"
                      onClick={() => this.showEnrolerSurSite(menage)}
                    >
                      Enrôler sur un site
                    </DropdownItem>
                  )}
                  {user_gest_respes.includes(
                    localStorage.getItem("roleName") || ""
                  ) && (
                    <DropdownItem href="#" onClick={() => this.addDon(menage)}>
                      Ajouter un don
                    </DropdownItem>
                  )}
                  <DropdownItem
                    href="#"
                    onClick={() => this.showListDon(menage)}
                  >
                    Voir les dons
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    onClick={() => this.showEnrolementHistory(menage)}
                    onCancel={() => this.onCloseModal()}
                  >
                    Voir l'historique d'enrolement
                  </DropdownItem>
                  {(user_gest_respes.includes(
                    localStorage.getItem("roleName") || ""
                  ) ||
                    user_editor.includes(
                      localStorage.getItem("roleName") || ""
                    )) && (
                    <DropdownItem
                      href="#"
                      onClick={() => this.updateMenage(menage)}
                    >
                      Modifier ménage
                    </DropdownItem>
                  )}
                  {user_gest_respes.includes(
                    localStorage.getItem("roleName") || ""
                  ) && (
                    <DropdownItem
                      href="#"
                      onClick={() => this.deleteMenage(menage)}
                    >
                      Supprimer ménage
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </td>
            <td
              className="clickable"
              onClick={() => this.showInformation(menage)}
              // onClick={(e) => this.showFicheMenage(e, menage)}
              onCancel={this.hideFicheMenage}
            >
              {menage.nomChef}
            </td>
            <td>{menage.cinChef}</td>
            <td>{menage.adresse}</td>
            <td>{menage.numeroCarnet}</td>
            <td>{menage.region}</td>
            <td>{menage.district}</td>
            <td>{menage.commune}</td>
            <td>{menage.fokotany}</td>
          </tr>
        );
      });
    return (
      <>
        {this.state.ShowHistory && (
          <EnrolementParMenageModal
            menage={this.state.selectedMenage}
            onCancel={this.onCloseModal}
          />
        )}
        {this.state.listerIndividu && (
          <ListIndividuMenage
            id={this.idMenage}
            onCancel={this.hideListIndividu}
          />
        )}
        {(this.state.confirmationModal ||
          this.state.confirmationModalDeleteMenages) && (
          <ConfirmationModal
            onCancel={this.cancelConfirmation}
            onSave={
              this.state.confirmationModal
                ? this.saveConfirmation
                : this.deleteMenages
            }
            message={
              this.state.confirmationModal
                ? "Voulez-vous vraiment supprimer ?"
                : "Voulez-vous vraiment supprimer ces ménages?"
            }
          />
        )}

        {this.state.showIndividu && (
          <CreateEditIndividuModal
            onCancel={this.onHideIndividu}
            onShowFiche={this.onShowFiche}
            onSave={this.onSave}
            menage={this.menage}
          />
        )}

        {this.state.showMenage && (
          <CreateMenageModal
            onCancel={this.onHideMenage}
            onShowIndividu={this.onShowIndividu}
            onSave={this.onSave}
            data={this.menage}
            onShowFiche={this.onShowFiche}
          />
        )}

        {this.state.showFiche && (
          <CreateEditFicheTraitementModal
            onCancel={this.onHideFiche}
            onSuivreTraitement={this.onSuivreTraitement}
            individu={this.currentIndiviu}
          />
        )}

        {this.state.showFicheMenage && (
          <FicheMenageModal
            menage={this.state.currentMenage}
            onCancel={this.hideFicheMenage}
          />
        )}

        {this.state.isInformation && (
          <InformationModal
            menage={this.state.currentMenage}
            close={this.hideInformation}
          />
        )}

        {this.state.isLoharanoSearch && (
          <LoharanoModal
            close={() => this.setState({ isLoharanoSearch: false })}
            onSearch={this.searchLoharano}
            QRReader={this.showQRreader}
          />
        )}

        {this.state.showDonCreateModal && (
          <DonCreateModal
            showModal={this.state.showDonCreateModal}
            menage={this.state.selectedMenage}
            toggle={() =>
              this.setState({ showDonCreateModal: false, selectedMenage: null })
            }
          />
        )}

        {this.state.showDonListModal && (
          <DonListModal
            showModal={this.state.showDonListModal}
            menage={this.state.selectedMenage}
            toggle={() =>
              this.setState({ showDonListModal: false, selectedMenage: null })
            }
          />
        )}

        {this.state.isLoharanoResult && (
          <LoharanoListModal
            close={() => this.setState({ isLoharanoResult: false })}
            data={this.state.loharanoResult}
            onSearch={this.searchQRcode}
            {...this.props}
          />
        )}

        {this.state.isQRreader && (
          <QRContainer onSearch={this.searchQRcode} close={this.hideQRreader} />
        )}

        <div className="header bg-gradient-primary postition-absolute">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row></Row>
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt-5 custom-card-container" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0 mt-2">
                  <Row>
                    <Col lg="4" sm="6" xs="12">
                      <FormGroup className="mb-3 mb-lg-0">
                        <div className="input-group-alternative input-group">
                          <input
                            placeholder="Nom et Prénom"
                            type="text"
                            className="form-control"
                            value={this.state.filtre.nom || ""}
                            onChange={this.handleChange}
                            name="nom"
                            onKeyDown={this.onPressEnter}
                          />
                        </div>
                      </FormGroup>
                    </Col>

                    {/* <Col lg="4" sm="6" xs="12">
                      <FormGroup className="mb-3 mb-lg-0">
                        <div className="input-group-alternative input-group">
                          <input
                            placeholder="Prénom"
                            type="text"
                            className="form-control"
                            value={this.state.filtre.prenom || ""}
                            onChange={this.handleChange}
                            name="prenom"
                            onKeyDown={this.onPressEnter}
                          />
                        </div>
                      </FormGroup>
                    </Col> */}

                    <Col lg="4" sm="6" xs="12">
                      <FormGroup className="mb-3 mb-lg-0">
                        <div className="input-group-alternative input-group">
                          <input
                            placeholder="CIN"
                            type="text"
                            className="form-control"
                            value={this.state.filtre.cin || ""}
                            onChange={this.handleChange}
                            name="cin"
                            onKeyDown={this.onPressEnter}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Zonnage
                    handleChangeZonage={this.handleChangeZonage}
                    filter={this.state.filtre}
                  />

                  <Row>
                    <Col>
                      <FormGroup>
                        <Button
                          style={{ width: "100%", fontSize: 14 }}
                          className="btn"
                          color="primary"
                          width="100%"
                          onClick={this.onSearch}
                          // onClick={this.showQRreader}
                        >
                          Rechercher
                        </Button>
                      </FormGroup>
                    </Col>
                    {user_viewer.includes(
                      localStorage.getItem("roleName") || ""
                    ) && (
                      <React.Fragment>
                        <Col>
                          <FormGroup>
                            <Button
                              style={{ width: "100%", fontSize: 14 }}
                              className="btn"
                              color="secondary"
                              onClick={this.showSearchLoharano}
                            >
                              Rechercher sur Loharano
                            </Button>
                          </FormGroup>
                        </Col>

                        {/* <Col>
                          {user_gestionnaire.includes(
                            localStorage.getItem("roleName") || ""
                          ) && (
                            <FormGroup>
                              <Button
                                style={{ width: "100%", fontSize: 14 }}
                                className="btn"
                                onClick={this.onShowMenage}
                                color="warning">
                                Nouveau Menage
                              </Button>
                            </FormGroup>
                          )}
                        </Col> */}

                        {/* <Col>
                            <FormGroup>
                              <Button
                                style={{ width: "100%", fontSize: 14 }}
                                className="btn"
                                color="danger"
                                onClick={this.onDeleteMenages}
                                disabled={!this.state.checked.length}
                              >
                                Supprimer des ménages
                              </Button>
                            </FormGroup>
                          </Col> */}
                      </React.Fragment>
                    )}
                  </Row>
                </CardHeader>
                <Table
                  className="align-items-center table-flush table table-sticky"
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" className="p-1" />

                      <th scope="col">Nom et prénom</th>
                      <th scope="col">CIN</th>
                      <th scope="col">Adresse</th>
                      <th scope="col">Numero carnet</th>

                      <th scope="col">Région</th>
                      <th scope="col">District</th>
                      <th scope="col">Commune</th>
                      <th scope="col">Fokontany</th>
                    </tr>
                  </thead>

                  <tbody>{tableData}</tbody>
                </Table>
                <CardFooter className="py-2 footer-pagination-container">
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    <b>Total Page: </b> {this.state.totalPage}
                  </label>
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    <b>Page courante: </b>
                    {this.state.page + 1}
                  </label>
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    {this.state.items} <b>ménages sur </b>{" "}
                    {this.state.totalitems}
                  </label>
                  <div className="pagination-navigation-container">
                    <Label className="form-control-label">
                      <b>Nombre de lignes </b>
                    </Label>
                    <Input
                      id="pageSize"
                      type="select"
                      width={50}
                      style={{
                        display: "inline-block",
                        width: 80,
                        marginLeft: 10,
                        height: 40,
                      }}
                      value={this.state.pageSize}
                      onChange={this.handlePage}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                    </Input>

                    <nav aria-label="...">
                      <Pagination
                        className="pagination pagination-lg justify-content-end mb-0"
                        listClassName="justify-content-end mb-0 pagination-lg"
                      >
                        <PaginationItem>
                          {this.state.page > 0 && (
                            <PaginationLink
                              href="#pablo"
                              onClick={this.previousPage}
                              tabIndex="-1"
                            >
                              <i className="fas fa-angle-left" />
                              <span className="sr-only">Previous</span>
                            </PaginationLink>
                          )}
                        </PaginationItem>
                        <PaginationItem
                          className={
                            this.state.page + 1 < this.state.totalPage
                              ? "active"
                              : "disabled"
                          }
                        >
                          <PaginationLink href="#pablo" onClick={this.nextPage}>
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </nav>
                  </div>
                  {/* <div className="content-export-menage">
                    <Button onClick={this.exportData}>Exporter</Button>
                  </div> */}
                </CardFooter>
              </Card>
            </div>
          </Row>
          <PageSpinner show={this.state.isLoading} />{" "}
          {this.state.showNotification && (
            <NotificationModal
              show={this.state.showNotification}
              message={this.notificationMessage}
              title={this.notificationTitle}
              close={this.closeNotification}
            />
          )}
        </Container>
      </>
    );
  }
}

export default MenagePage;
