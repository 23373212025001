import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Nav,
  Navbar,
  UncontrolledDropdown,
} from "reactstrap";
import AuthService from "services/AuthService";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.handleLogout.bind(this);
  }
  state = {
    username: "",
    isParamsOpen: true,
  };
  handleLogout = () => {
    AuthService.logout();
    localStorage.removeItem("user");
    localStorage.removeItem("username");
    localStorage.removeItem("roleName");
    this.props.history.push("/auth/login");
  };

  componentDidMount() {
    this.setState({
      username: localStorage.getItem("username"),
    });
  }
  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark bg-white" expand="md" id="navbar-main" style={{position: 'relative'}}>
          <Container fluid className="justify-content-end">
            {/* <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/"
            >
              <br />
            </Link> */}

            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      {/* <img
                        alt="..."
                        src={require("assets/img/theme/admin-profil.jpg")}
                      /> */}
                      <i className="fa fa-user" />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold text-black-50">
                        {this.state.username}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" container="body" positionFixed={true}  right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Bienvenue!</h6>
                  </DropdownItem>
                  {/* <DropdownItem >
                    <i className="ni ni-settings-gear-65" />
                    <span onClick={this.handleLogout}>Paramètre du Compte</span>
                  </DropdownItem> */}
                  <DropdownItem >
                    <i className="ni ni-user-run" />
                    <span onClick={this.handleLogout}>Se déconnecter</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
