
import React from "react";
import { Container, Row, Card, CardBody } from "reactstrap";
function DashboardChart() {
  return (
    <>
      <div className="header bg-gradient-primary postition-absolute">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row></Row>
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt-5" fluid>
        <Card>
          <CardBody>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default DashboardChart;
