// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n", ""]);
// Exports
exports.locals = {
	"cPrimary": "#F8670D",
	"cSecondary": "#543002",
	"cThird": "#F219DD"
};
module.exports = exports;
