import React from "react";
import ReactDatetime from "react-datetime";
import VoinaService from "services/VoinaService";
import TypeDonService from "services/TypeDonService";
import DonService from "services/DonService";
import moment from "moment";
import {
  Button,
  Modal,
  CardHeader,
  Card,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from "reactstrap";
import TetheredDateTime from "./TetheredDateTime";

const DonCreateModal = (props) => {
  const [allVoina, setAllVoina] = React.useState([]);
  const [allTypeDon, setAllTypeDon] = React.useState([]);
  const [selectedVoina, setSelectedVoina] = React.useState("");
  const [selectedTypeDon, setSelectedTypeDon] = React.useState("");
  const [validFields, setValidFields] = React.useState({
    date: true,
    voina: true,
    typeDon: true,
  });
  const [dateDon, setDateDon] = React.useState(moment());
  const [displayedDateDon, setDisplayedDateDon] = React.useState(
    moment().format("DD/MM/YYYY HH:mm")
  );

  React.useEffect(() => {
    TypeDonService.getAllTypeDon().then((res) => {
      if (res?.data?.data) {
        setAllTypeDon(res.data.data);
      }
    });
    VoinaService.getAllVoina().then((res) => {
      if (res?.data?.data) {
        setAllVoina(res.data.data);
      }
    });
  }, []);

  React.useEffect(() => {
    allTypeDon &&
      allTypeDon.length > 0 &&
      setSelectedTypeDon(allTypeDon[0]?.id);
  }, [allTypeDon]);

  React.useEffect(() => {
    // allVoina && allVoina.length > 0 && setSelectedVoina(allVoina[0]?.id);
    allVoina && allVoina.length > 0 && setDefaultVoina();
  }, [allVoina]);

  const checkValidation = () => {
    let validObj = {
      date: dateDon.isValid(),
      voina: selectedVoina != null && selectedVoina != "",
      typeDon: selectedTypeDon != null && selectedTypeDon != "",
    };
    setValidFields({ ...validObj });
    return Object.entries(validObj).reduce((acc, val) => acc && val[1]);
  };
  const setDefaultVoina = () => {
    //recuperation du voina par defaut ..
    VoinaService.getDefaultVoina().then((result) => {
      result.data.data && setSelectedVoina(result.data.data.id);
    });
  };

  const save = () => {
    if (checkValidation()) {
      DonService.createDonMenage({
        dateDon: dateDon.format("YYYY-MM-DDTHH:mm:ss") + ".000Z" || "",
        idMenage: props?.menage?.id || "",
        idTypeDon: selectedTypeDon,
        idVoina: selectedVoina,
      }).catch((err) => {
        alert("Erreur de sauvegarde, contactez votre administrateur");
      });

      props.toggle();
    }
  };

  const handleChangeDate = (date) => {
    let value = null;
    if (date.isValid) {
      // value = date;
      value = date.format("YYYY-MM-DDTHH:mm:ss");
    }
    setDateDon(value);
    setDisplayedDateDon(moment(value).format("DD/MM/YYYY HH:mm"));
    setValidFields((prevState) => ({ ...prevState, date: true }));
    console.log("DATE EN COURS : ", dateDon);
  };

  const handlChangeVoina = (e) => {
    setSelectedVoina(e.target.value);
    setValidFields((prevState) => ({ ...prevState, voina: true }));
  };

  const handlChangeTypeDon = (e) => {
    setSelectedTypeDon(e.target.value);
    setValidFields((prevState) => ({ ...prevState, typeDon: true }));
  };
  return (
    <Modal
      isOpen={props.showModal}
      toggle={props.toggle}
      className="modal-check"
      fade={true}
      className="modal-dialog-centered"
    >
      <Card className="shadow">
        <CardHeader className="pb-0">
          <div className="text-muted text-center mt-2 mb-3 ">
            <h2>Ajout d'un nouveau don</h2>
          </div>
        </CardHeader>
        <ModalBody style={{ backgroundColor: "transparent" }}>
          <FormGroup>
            <div>
              <Label className="ml-1" style={{ fontSize: 16, marginTop: 6 }}>
                <b>Id Menage : </b>
              </Label>{" "}
              <span>{props?.menage?.id}</span>
            </div>
            <div>
              <Label className="ml-1" style={{ fontSize: 16, marginTop: 6 }}>
                <b>Chef de famille : </b>{" "}
              </Label>{" "}
              <span>
                {props?.menage?.nomChef}{" "}
                {props?.menage?.prenomChef &&
                props?.menage?.prenomChef != null &&
                props?.menage?.prenomChef !== "null"
                  ? props?.menage?.prenomChef
                  : ""}
              </span>
            </div>
          </FormGroup>
          <FormGroup>
            <label className="form-control-label" htmlFor="input-date-enquete">
              Date du don
            </label>
            <InputGroup className="input-group-alternative flex-nowrap">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-calendar-grid-58" />
                </InputGroupText>
              </InputGroupAddon>
              <TetheredDateTime
                inputProps={{
                  placeholder:
                    displayedDateDon !== ""
                      ? displayedDateDon
                      : "DD/MM/YYYY HH:mm",
                  onKeyDown: (e) => e.preventDefault(),
                }}
                dateFormat={"DD/MM/YYYY"}
                closeOnSelect={true}
                autoClose={true}
                // timeInputLabel="Time:"
                // showTimeInput={false}
                timeFormat={"HH:mm"}
                // open={false}
                onChange={handleChangeDate}
                name="dateDon"
                // selected={dateDon}
                value={(displayedDateDon && displayedDateDon) || ""}
              />
            </InputGroup>
            {!validFields.date && (
              <small className="text-danger">champ obligatoire</small>
            )}
          </FormGroup>

          <FormGroup>
            <Label style={{ marginLeft: 10, fontSize: 16, marginTop: 6 }}>
              Voina
            </Label>
            <div className="input-group-alternative input-group">
              <Input
                id="select-voina"
                type="select"
                className="form-control"
                onChange={handlChangeVoina}
                value={selectedVoina}
              >
                <option></option>
                {Array.isArray(allVoina) &&
                  allVoina?.map((elt, idx) => (
                    <option key={elt.id} value={elt.id}>
                      {elt.libelle}
                    </option>
                  ))}
              </Input>
            </div>
            {!validFields.voina && (
              <small className="text-danger">champ obligatoire</small>
            )}
          </FormGroup>
          <FormGroup>
            <Label style={{ marginLeft: 10, fontSize: 16, marginTop: 6 }}>
              Type de don
            </Label>
            <div className="input-group-alternative input-group">
              <Input
                required
                id="select-type-don"
                type="select"
                className="form-control"
                onChange={handlChangeTypeDon}
                value={selectedTypeDon}
              >
                <option></option>
                {Array.isArray(allTypeDon) &&
                  allTypeDon?.map((elt, idx) => (
                    <option key={elt.id} value={elt.id}>
                      {elt.name}
                    </option>
                  ))}
              </Input>
            </div>
            {!validFields.typeDon && (
              <small className="text-danger">champ obligatoire</small>
            )}
          </FormGroup>
        </ModalBody>
      </Card>
      <ModalFooter>
        <Button color="primary" onClick={save}>
          Enregistrer
        </Button>{" "}
        <Button color="secondary" onClick={props.toggle}>
          Annuler
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DonCreateModal;
