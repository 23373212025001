import nationalites from "common/nationalites";
import React from "react";
// reactstrap components
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Form,
  UncontrolledTooltip,
  Button,
} from "reactstrap";
import eFokontanyService from "services/eFokontanyService";
import MenageIndividuTraitementService from "services/MenageIndividuTraitementService";

import TetheredDateTime from "views/components/TetheredDateTime";
import PageSpinner from "views/components/PageSpinner";
import moment from "moment";
import NotificationModal from "./NotificationModal";
import Zonnage from "./Zonnage";
import CustomInput from "../components/input/input";
import IndividuList from "./input/individuList";

class MenageIndividuTraitement extends React.Component {
  state = {
    editZonage: false,
    isEnroler: true,
    update: false,
    isLoading: false,
    menageIndividu: {
      individu: [
        {
          activite: "",
          affiliation: "",
          age: "0",
          chef: "",
          cin: "",
          dateCin: "",
          dateNaissance: "",
          handicape: "",
          id: 0,
          lieuCin: "",
          lieuNaissance: "",
          menageid: 0,
          mentionMere: "",
          mentionPere: "",
          mere: "",
          nationalite: "",
          nom: " ",
          observation: "",
          pere: "",
          prenom: "",
          sexe: "",
          situationMatrimoniale: "",
          telephone: "",
          unicite: "",
        },
      ],
      menage: {
        adresse: "",
        cinChef: "",
        id: 0,
        locataire: true,
        nomChef: "",
        numeroCarnet: "",
        numeroRegistre: "",
        prenomChef: "",

        secteur: "",
        tailleMenage: 0,
        typeExistance: "0",
        region: "",
        district: "",
        commune: "",
        fokotany: "",
        regionId: "",
        districtId: "",
        communeId: "",
        fokotanyId: "",
      },
      removedIndividuIds: [],
    },
    readOnly: false,
    showNotification: false,
    requiredFields: {},
    listFokotany: [],
    listRegion: [],
    listDistrict: [],
    listCommune: [],
    displayRegion: [],
    displayCommune: [],
    displayFokotany: [],
  };

  componentDidMount() {
    // this.getFokotany();
    // this.getRegion();
    // this.getDistrict();
    // this.getCommune();

    const id = this.props.match.params.id;
    this.isUpdate = false;
    switch (id) {
      case "loharano":
        this.setState({ isLoading: true });
        const search = this.props.location.search;
        const bookNumber = search.split("?").pop();
        eFokontanyService
          .searchByQRCode(bookNumber)
          .then((result) => {
            result && this.getDTOLoharano(result.data);
          })
          .catch((error) => this.setState({ isLoading: false }));

        break;

      default:
        if (id !== "0") {
          this.isUpdate = true;
          this.getData(id);
        }
        break;
    }
    this.initDefaultData()
  }

  initDefaultData = () => {
    const { menageIndividu } = this.state;
    
    for (let ind of menageIndividu?.individu) {
      if (ind.nationalite === "")  {
        ind.nationalite = "Malagasy";
      }
    }

    this.setState({menageIndividu})
  }
  handleCheckBoxChange = (event) => {
    const { name } = event.target;

    this.setState(
      {
        [name]: event.target.checked,
      },
      () => {
        if (this.state.menageIndividu.menage.typeExistance !== "1") {
          let typeExistence = this.state.possedeCarnet ? "2" : "0";
          if (this.state.possedeCarnet) {
            if (this.state.menageIndividu.menage.numeroCarnet === "")
              typeExistence = "0";
            else typeExistence = "2";
          }
          this.setState({
            menageIndividu: {
              ...this.state.menageIndividu,
              menage: {
                ...this.state.menageIndividu.menage,
                typeExistance: typeExistence,
              },
            },
          });
        }
      }
    );
  };
  closeNotification = () => {
    this.setState({
      showNotification: false,
    });
  };
  getDTOLoharano = (loharano) => {
    const { bookNumber, citizens, address, registerNumber } = loharano;
    const { menageIndividu } = this.state;
    menageIndividu.individu = [];
    let chiefData = {};
    var isChiefExist = false;
    citizens.map((item) => {
      const data = {
        nom: item?.lastName,
        prenom: item?.firstName,
        dateNaissance: new Date(item.birthDate).toLocaleDateString(),
        lieuNaissance: item?.birthPlace,
        cin: item.CNI && item?.CNI.replace(/ /g, ""),
        dateCin: new Date(item.CNIDeliveryDate).toLocaleDateString(),
        lieuCin: item?.CNIDeliveryPlace,
        pere: item?.father,
        mentionPere: item?.fatherStatus,
        mere: item?.mother,
        mentionMere: item?.motherStatus,
        telephone: item?.phoneNumber,
        sexe: item?.gender && item?.gender === 1 ? "Masculin" : "Feminin",
        nationalite: item?.nationalityId + "",
        affiliation: "item.",
        observation: "tem.",
        handicape: item?.isHandicapped,
        activite: item?.jobId + "",
        locataire: false,
        typeExistance: "1", // code QR
        chef: item?.isChief,
        numeroCarnet: bookNumber,
        numeroRegistre: registerNumber,
        // adress
        adresse: address.name,
        secteur: address.sector,
        commune: address.municipality.name,
        fokotany: address.fokontany.name,
        district: address.district.name,
        region: address.region.name,
        age: item.birthDate && this.getAgeFromBirthDay(item.birthDate),
      };

      if (item.isChief) {
        isChiefExist = true;
        chiefData = data;
        menageIndividu.menage.nomChef = item.lastName;
        menageIndividu.menage.cinChef = item.CNI && item.CNI.replace(/ /g, "");
        menageIndividu.menage.numeroCarnet = bookNumber;
        menageIndividu.menage.numeroRegistre = registerNumber;
        menageIndividu.menage.adresse = address?.name;
        menageIndividu.menage.secteur = address?.sector;
        menageIndividu.menage.commune = address?.municipality?.name;
        menageIndividu.menage.fokotany = address?.fokontany?.name;
        menageIndividu.menage.district = address?.district?.name;
        menageIndividu.menage.region = address?.region?.name;
        menageIndividu.menage.typeExistance = "1";
      } else {
        menageIndividu.individu.push(data);
      }
      return null;
    });

    if (isChiefExist) {
      menageIndividu.individu = [chiefData, ...menageIndividu.individu];
    } else {
      menageIndividu.menage.nomChef = citizens[0].lastName;
      menageIndividu.menage.cinChef =
        citizens[0].CNI && citizens[0].CNI.replace(/ /g, "");
      menageIndividu.menage.numeroCarnet = bookNumber;
      menageIndividu.menage.numeroRegistre = registerNumber;
      menageIndividu.menage.adresse = address?.name;
      menageIndividu.menage.secteur = address?.sector;
      menageIndividu.menage.commune = address?.municipality?.name;
      menageIndividu.menage.fokotany = address?.fokontany?.name;
      menageIndividu.menage.district = address?.district?.name;
      menageIndividu.menage.region = address?.region?.name;
      menageIndividu.menage.typeExistance = "1"; //type 1 correspond à avec QRCode
    }

    menageIndividu.removedIndividuIds = [];


    this.setState(
      {
        menageIndividu,
        isLoading: false,
        update: false,
        readOnly: true,
      },
      () => {
        if (this.state.menageIndividu.menage.numeroCarnet) {
          if (this.state.menageIndividu.menage.numeroCarnet !== "")
            this.setState({
              possedeCarnet: true,
            });
          else
            this.setState({
              possedeCarnet: false,
            });
        }
        // this.checkValidation();
      }
    );
  };

  getData(id) {
    MenageIndividuTraitementService.get(id).then(
      (response) => {
        if (response.data.code === "200") {
          let menageIndividu = response.data?.data;
          menageIndividu.removedIndividuIds = [];

          this.setState(
            {
              menageIndividu,
              isEnroler: false,
            },
            () => {
              if (this.state.menageIndividu.menage.numeroCarnet) {
                if (this.state.menageIndividu.menage.numeroCarnet !== "")
                  this.setState({
                    possedeCarnet: true,
                  });
                else
                  this.setState({
                    possedeCarnet: false,
                  });
              }
            }
          );
        }
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  }

  handleMenageInputChange = (evt) => {
    const { menageIndividu } = this.state;
    menageIndividu.menage[evt.target.name] = evt.target.value;
    this.setState({ menageIndividu }
      // , () => this.checkValidation()
    );
  };

  handleCheckbox = (evt) => {
    const { menageIndividu } = this.state;
    menageIndividu.menage[evt.target.name] = evt.target.checked;
    this.setState({ menageIndividu }
      // ,() => this.checkValidation()
    );
  };

  addIndividu = (evt) => {
    evt.preventDefault();
    const { menageIndividu } = this.state;
    menageIndividu.individu = [
      ...menageIndividu.individu,
      {
        activite: "",
        affiliation: "",
        age: "0",
        chef: "",
        cin: "",
        dateCin: "",
        dateNaissance: "",
        handicape: "",
        lieuCin: " ",
        lieuNaissance: "",
        id: -1,
        menageid: 0,
        mentionMere: "",
        mentionPere: "",
        mere: "",
        nationalite: "",
        nom: "",
        observation: "",
        pere: "",
        prenom: "",
        sexe: "",
        situationMatrimoniale: "",
        telephone: "",
        unicite: "",
      },
    ];
    this.setState({ menageIndividu });
  };

  removeIndividu = (elt, index) => {
    const { menageIndividu } = this.state;
    const individusList = [...menageIndividu.individu];

    menageIndividu.individu =
      menageIndividu.individu.length > 1 &&
      menageIndividu.individu.filter((elt, idx) => idx !== index);

    const individu =
      individusList.length > 1 &&
      individusList.filter((item) => elt.id === item.id);

    individu &&
      individu.length > 0 &&
      individu[0].id !== -1 &&
      menageIndividu.removedIndividuIds.push(individu[0].id);

    this.setState({
      menageIndividu,
    });
  };

  handleActivateEditZonage = () => {
    const { menageIndividu } = this.state;
    menageIndividu.menage.regionId = -1;
    menageIndividu.menage.districtId = -1;
    menageIndividu.menage.communeId = -1;
    menageIndividu.menage.fokotanyId = -1;

    menageIndividu.menage.region = "";
    menageIndividu.menage.district = "";
    menageIndividu.menage.commune = "";
    menageIndividu.menage.fokotany = "";

    this.setState({
      editZonage: true,
      menageIndividu: menageIndividu,
    })
  }
  handleChangeZonage = (situation) => {
    const { menageIndividu } = this.state;
    menageIndividu.menage.regionId = situation?.region;
    menageIndividu.menage.districtId = situation?.district;
    menageIndividu.menage.communeId = situation?.commune;
    menageIndividu.menage.fokotanyId = situation?.fokontany;

    menageIndividu.menage.region = situation?.regionDTO?.nom_region;
    menageIndividu.menage.district = situation?.districtDTO?.nom_districte;
    menageIndividu.menage.commune = situation?.communeDTO?.nomCommune;
    menageIndividu.menage.fokotany = situation?.fokontanyDTO?.nom_fokotany;

    menageIndividu.menage.regionDTO = situation?.regionDTO;
    menageIndividu.menage.districtDTO = situation?.districtDTO;
    menageIndividu.menage.communeDTO = situation?.communeDTO;
    menageIndividu.menage.fokotanyDTO = situation?.fokontanyDTO;

    let requiredFields = { ...this.state.requiredFields };

    requiredFields.region =
      menageIndividu.menage.regionId === -1 ? "champs obligatoire" : "";
    requiredFields.district =
      menageIndividu.menage.districtId === -1 ? "champs obligatoire" : "";
    requiredFields.commune =
      menageIndividu.menage.communeId === -1 ? "champs obligatoire" : "";
    requiredFields.fokotany =
      menageIndividu.menage.fokotanyId === -1 ? "champs obligatoire" : "";

    this.setState({ menageIndividu, requiredFields });
  };

  getAge(data) {
    if (data.age) {
      return data.age;
    } else if (data.dateNaissance) {
      return data.dateNaissance.toString().includes("/")
        ? new Date().getFullYear() - data.dateNaissance.split("/").pop()
        : new Date().getFullYear() - data.dateNaissance;
    } else {
      return "";
    }
  }

  getDateNaissance(data) {
    if (data.dateNaissance) {
      return data.dateNaissance.toString().includes("/")
        ? data.dateNaissance.split("/").pop()
        : data.dateNaissance;
    } else if (data.age) {
      return new Date().getFullYear() - data.age;
    } else {
      return "";
    }
  }

  handleRemovedIndividue = (data) => {
    this.setState({
      removedIndividuIds: data,
    });
  };

  formValid = () => {
    return false;
  };

  handleDateCin = (event, index) => {
    const { menageIndividu } = this.state;
    if (event.isValid)
      menageIndividu.individu[index].dateCin = event.format("DD/MM/YYYY");
    this.setState({ menageIndividu }); //, () => this.checkValidation());
  };

  checkValidation = () => {
    const nom =
      this.state.menageIndividu?.individu[0]?.nom &&
        this.state.menageIndividu?.individu[0]?.nom?.length > 0
        ? ""
        : "champ obligatoire";

    const prenom =
      this.state.menageIndividu?.individu[0]?.prenom &&
        this.state.menageIndividu?.individu[0]?.prenom?.length > 0
        ? ""
        : "champ obligatoire";

    const gender =
      this.state.menageIndividu?.individu[0]?.sexe &&
        this.state.menageIndividu?.individu[0]?.sexe?.length > 0
        ? ""
        : "champ obligatoire";

    const lieuNaissance =
      this.state.menageIndividu?.individu[0]?.lieuNaissance &&
        this.state.menageIndividu?.individu[0]?.lieuNaissance?.length > 0
        ? ""
        : "champ obligatoire";

    const cin =
      this.state.menageIndividu?.individu[0]?.cin &&
        this.state.menageIndividu?.individu[0]?.cin?.length == 12
        ? ""
        : "12 chiffres réquis";

    const lieuCin = this.state.menageIndividu?.individu[0]?.lieuCin
      ? ""
      : "champ obligatoire";

    const nationalite =
      this.state.menageIndividu?.individu[0]?.nationalite &&
        this.state.menageIndividu?.individu[0]?.nationalite?.length > 0
        ? ""
        : "champ obligatoire";

    const adresse =
      this.state.menageIndividu?.menage.adresse &&
        this.state.menageIndividu?.menage?.adresse?.length > 0
        ? ""
        : "champ obligatoire";

    const telephone =
      this.state.menageIndividu?.individu[0]?.telephone &&
        this.state.menageIndividu?.individu[0]?.telephone?.length > 0 &&
        this.state.menageIndividu?.individu[0]?.telephone?.length != 10
        ? "10 chiffres réquis"
        : "";

    const region =
      this.state.menageIndividu?.menage?.region &&
        this.state.menageIndividu?.menage?.region !== ""
        ? ""
        : "champ obligatoire";

    const district =
      this.state.menageIndividu?.menage?.district &&
        this.state.menageIndividu?.menage?.district !== ""
        ? ""
        : "champ obligatoire";

    const commune =
      this.state.menageIndividu?.menage?.commune &&
        this.state.menageIndividu?.menage?.commune !== ""
        ? ""
        : "champ obligatoire";

    const fokotany =
      this.state.menageIndividu?.menage?.fokotany &&
        this.state.menageIndividu?.menage?.fokotany !== ""
        ? ""
        : "champ obligatoire";

    const requiredFields = {
      ...this.state.requiredFields,
      nom,
      lieuNaissance,
      cin,
      lieuCin,
      nationalite,
      adresse,
      telephone,
      region,
      district,
      commune,
      fokotany,
      prenom,
      gender,
    };

    let result = true;
    this.setState({ requiredFields });

    result =
      Object.entries(requiredFields).find((x) => x[1].length > 0) == null;

    return result;
  };

  handleChangeInputIndividu = (event, index) => {
    event.preventDefault();
    const { menageIndividu } = this.state;
    if (!menageIndividu.individu || menageIndividu.individu.length <= 0) {
      this.notificationMessage =
        "Veuillez d'abord ajouter un individu dans la liste d'individu";
      this.notificationTitle = "Modification impossible";
      this.setState({
        showNotification: true,
      });
      event.target.value = "";
      menageIndividu.menage.nomChef = "";
    } else {
      menageIndividu.individu[index][event.target.name] = event.target.value;
      if (event.target.name === "age") {
        menageIndividu.individu[index].age =
          event.target.value === "" ? "0" : event.target.value;
        menageIndividu.individu[index].dateNaissance = moment().add(
          -event.target.value,
          "years"
        );
      }
      if (event.target.name === "nom") {
        if (index === 0) {
          menageIndividu.menage.nomChef = event.target.value.replace(
            /[0-9]/,
            ""
          );
          menageIndividu.individu[0].chef = "oui";
        }

        menageIndividu.individu[index][event.target.name] =
          event.target.value.replace(/[0-9]/, "");
      }
      if (event.target.name === "cin")
        menageIndividu.individu[index][event.target.name] =
          event.target.value.replace(/\D/g, "");

      if (event.target.name === "cin" && index === 0)
        menageIndividu.menage.cinChef = event.target.value;
      this.setState({ menageIndividu }
        // , () => this.checkValidation()
      );
    }
  };

  handleChangeDateIndividu = (event, index) => {
    const { menageIndividu } = this.state;


    if (event.isValid) {
      menageIndividu.individu[index].dateNaissance = event.format("DD/MM/YYYY");
      var numberOfYearsString = event.month(0).fromNow();
      menageIndividu.individu[index].age = numberOfYearsString.includes(
        "a year"
      )
        ? "1"
        : numberOfYearsString.includes("a month")
          ? "0"
          : numberOfYearsString.substring(0, numberOfYearsString.indexOf(" "));
    }
    this.setState({ menageIndividu }
      // , () => this.checkValidation()
    );
  };

  getAgeFromBirthDay = (birthDay) => {
    var numberOfYearsString = moment(birthDay).month(0).fromNow();
    if (numberOfYearsString.includes("a year")) {
      return "1";
    } else if (numberOfYearsString.includes("month")) {
      return "0";
    } else {
      return numberOfYearsString.substring(0, numberOfYearsString.indexOf(" "));
    }
  };

  saveAndEnroler = (event) => {
    event.preventDefault();
    if (!this.state.possedeCarnet) {
      this.setState({
        menageIndividu: {
          ...this.state.menageIndividu,
          menage: {
            ...this.state.menageIndividu.menage,
            numeroCarnet: "",
          },
        },
      });
    }
    if (this.checkValidation()) {
      this.processSaveAndEnroler();
    }
  };

  getBirthYear = (dateNaissance) => {
    if (dateNaissance) {
      return dateNaissance.toString().includes("/")
        ? dateNaissance?.split("/").pop()
        : dateNaissance;
    }
    return "";
  };

  processSaveAndEnroler = () => {
    this.setState({
      isLoading: true,
    });

    const { menageIndividu } = this.state;
    MenageIndividuTraitementService.post(menageIndividu).then(
      (result) => {
        if (
          result &&
          result.data &&
          result.data.data &&
          result.data.data.menage
        ) {
          const menage = result.data.data.menage;
          this.props.history.push(
            `/admin/addEnrolement/${menage.id}/${menage.nomChef}`
          );
        }
        this.setState({
          isLoading: false,
        });
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.state.possedeCarnet) {
      this.setState({
        menageIndividu: {
          ...this.state.menageIndividu,
          menage: {
            ...this.state.menageIndividu.menage,
            numeroCarnet: "",
          },
        },
      });
    }
    if (this.checkValidation()) {
      this.isUpdate ? this.processUpdate() : this.processSave();
    }
  };

  processSave = () => {
    const { menageIndividu } = this.state;

    this.setState({
      isLoading: true,
    });

    MenageIndividuTraitementService.post(menageIndividu).then(
      (result) => {
        this.goToMenage();
        this.setState({
          isLoading: false,
        });
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  processUpdate = () => {
    const { menageIndividu } = this.state;
    this.setState({
      isLoading: true,
    });

    MenageIndividuTraitementService.update(menageIndividu).then(
      (result) => {
        this.setState({
          isLoading: false,
        });
        this.goToMenage();
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  goToMenage = () => {
    this.props.history.push({
      pathname: "/admin/gestion-menage",
    });
  };

  render() {
    const { readOnly } = this.state;
    const individuBody =
      this.state.menageIndividu?.individu &&
      this.state.menageIndividu?.individu.map((elt, index) => {
        return (
          <tr key={index}>
            {!readOnly && (
              <td className="col-action">
                {index > 0 && (
                  <FormGroup>
                    <button
                      className="btn m-0"
                      id="stooltip6112347451"
                      type="button"
                      onClick={() => this.removeIndividu(elt, index)}>
                      <i className="fa fa-trash-alt" />
                    </button>
                    <UncontrolledTooltip
                      delay={0}
                      placement="top"
                      target="stooltip6112347451">
                      Supprimer
                    </UncontrolledTooltip>
                  </FormGroup>
                )}
              </td>
            )}

            <td>
              <CustomInput
                name="nom"
                handleChangeInputIndividu={(event) =>
                  this.handleChangeInputIndividu(event, index)
                }
                value={elt.nom}
                isRequired={
                  index === 0 &&
                  this.state.requiredFields &&
                  this.state.requiredFields?.nom &&
                  this.state.requiredFields?.nom?.length > 0
                }
                fieldRequiredMessage={this.state.requiredFields.nom}
              />
            </td>

            <td>
              <CustomInput
                name="prenom"
                handleChangeInputIndividu={(event) =>
                  this.handleChangeInputIndividu(event, index)
                }
                value={elt.prenom}
                isRequired={
                  index === 0 &&
                  this.state.requiredFields &&
                  this.state.requiredFields?.prenom &&
                  this.state.requiredFields?.prenom?.length > 0
                }
                fieldRequiredMessage={this.state.requiredFields.prenom}
              />
            </td>
            <td>
              <CustomInput
                name="cin"
                handleChangeInputIndividu={(event) =>
                  this.handleChangeInputIndividu(event, index)
                }
                value={elt.cin || ""}
                isRequired={
                  index === 0 &&
                  this.state.requiredFields &&
                  this.state.requiredFields?.cin &&
                  this.state.requiredFields?.cin?.length > 0
                }
                fieldRequiredMessage={this.state.requiredFields.cin}
                maxLength={12}
              />
            </td>
            <td>
              <FormGroup>
                <InputGroup className="input-group-alternative flex-nowrap">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <TetheredDateTime
                    inputProps={{
                      placeholder: "Choisir date",
                      onKeyDown: (e) => e.preventDefault(),
                    }}
                    viewMode={"years"}
                    format={"DD/MM/YYYY"}
                    closeOnSelect={true}
                    timeFormat={false}
                    onChange={(event) => this.handleDateCin(event, index)}
                    name="dateCin"
                    selected={elt.dateCin}
                    value={elt.dateCin}
                  />
                </InputGroup>
              </FormGroup>
            </td>
            <td>
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  id="input-lieuCin"
                  placeholder=""
                  type="text"
                  name="lieuCin"
                  value={elt?.lieuCin || ""}
                  onChange={(event) =>
                    this.handleChangeInputIndividu(event, index)
                  }
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                />
                {index === 0 &&
                  this.state.requiredFields &&
                  this.state.requiredFields?.lieuCin &&
                  this.state.requiredFields?.lieuCin?.length > 0 && (
                    <small className="text-danger">
                      {" "}
                      {this.state.requiredFields.lieuCin}
                    </small>
                  )}
              </FormGroup>
            </td>
            <td className="col-mini">
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  placeholder=""
                  type="number"
                  min={0}
                  onChange={(event) =>
                    this.handleChangeInputIndividu(event, index)
                  }
                  name="age"
                  value={
                    elt?.age ||
                    this.getAgeFromBirthDay(elt?.dateNaissance) ||
                    ""
                  }
                />
              </FormGroup>
            </td>

            <td>
              <Form autoComplete="off">
                <FormGroup autoComplete="off">
                  <InputGroup
                    className="input-group-alternative  flex-nowrap"
                    autoComplete="off">
                    <InputGroupAddon addonType="prepend" autoComplete="off">
                      <InputGroupText autoComplete="off">
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <TetheredDateTime
                      inputProps={{
                        placeholder: "",
                        onKeyDown: (e) => e.preventDefault(),
                      }}
                      disableOnClickOutside={false}
                      dateFormat="YYYY"
                      timeFormat={false}
                      closeOnSelect={true}
                      autocomplete="off"
                      placeholder="Choisir l'année"
                      onChange={(event) =>
                        this.handleChangeDateIndividu(event, index)
                      }
                      name="dateNaissance"
                      selected={elt.dateNaissance}
                      value={this.getBirthYear(elt.dateNaissance)}
                    />
                  </InputGroup>
                </FormGroup>
              </Form>
            </td>

            <td>
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  placeholder=""
                  type="text"
                  name="lieuNaissance"
                  onChange={(event) =>
                    this.handleChangeInputIndividu(event, index)
                  }
                  value={elt?.lieuNaissance || ""}
                />
                {index === 0 &&
                  this.state.requiredFields &&
                  this.state.requiredFields.lieuNaissance && (
                    <small className="text-danger">
                      {this.state.requiredFields.lieuNaissance}
                    </small>
                  )}
              </FormGroup>
            </td>
            <td>
              <FormGroup>
                <div className="input-group-alternative input-group">
                  <select
                    className="mdb-select md-form  form-control"
                    onChange={(event) =>
                      this.handleChangeInputIndividu(event, index)
                    }
                    name="sexe"
                    value={elt?.sexe}>
                    <option value=""></option>
                    <option value="Masculin">Masculin</option>
                    <option value="Feminin">Feminin</option>
                  </select>
                </div>
                {
                  // index === 0 &&
                  this.state.requiredFields &&
                  this.state.requiredFields.gender && (
                    <small className="text-danger">
                      {this.state.requiredFields.gender}
                    </small>
                  )}
              </FormGroup>
            </td>
            <td>
              <FormGroup>
                <div className="input-group-alternative input-group">
                  <select
                    className="mdb-select md-form  form-control"
                    onChange={(event) =>
                      this.handleChangeInputIndividu(event, index)
                    }
                    value={elt?.nationalite || "Malagasy"}
                    name="nationalite">
                    {/* <option></option> */}
                    {nationalites.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.value}
                      </option>
                    ))}
                  </select>
                </div>
                {
                  index === 0 &&
                  this.state.requiredFields &&
                  this.state.requiredFields.nationalite && (
                    <small className="text-danger">
                      {this.state.requiredFields.nationalite}
                    </small>
                  )}
              </FormGroup>
            </td>
            <td>
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  id="input-pere"
                  placeholder=""
                  type="text"
                  name="pere"
                  value={elt?.pere || ""}
                  onChange={(event) =>
                    this.handleChangeInputIndividu(event, index)
                  }
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                />
              </FormGroup>
            </td>
            <td>
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  id="input-mentionPere"
                  placeholder=""
                  type="text"
                  name="mentionPere"
                  value={elt?.mentionPere || ""}
                  onChange={(event) =>
                    this.handleChangeInputIndividu(event, index)
                  }
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                />
              </FormGroup>
            </td>
            <td>
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  id="input-mere"
                  placeholder=""
                  type="text"
                  name="mere"
                  value={elt?.mere || ""}
                  onChange={(event) =>
                    this.handleChangeInputIndividu(event, index)
                  }
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                />
              </FormGroup>
            </td>
            <td>
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  id="input-mentionMere"
                  placeholder=""
                  type="text"
                  name="mentionMere"
                  value={elt?.mentionMere || ""}
                  onChange={(event) =>
                    this.handleChangeInputIndividu(event, index)
                  }
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                />
              </FormGroup>
            </td>
            <td>
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  placeholder=""
                  type="text"
                  value={elt?.telephone || ""}
                  maxLength={10}
                  minLength={10}
                  onChange={(event) =>
                    this.handleChangeInputIndividu(event, index)
                  }
                  name="telephone"
                />
                {elt?.telephone?.length > 0 &&
                  this.state.requiredFields &&
                  this.state.requiredFields?.telephone &&
                  this.state.requiredFields?.telephone?.length > 0 && (
                    <small className="text-danger">
                      {" "}
                      {this.state.requiredFields.telephone}
                    </small>
                  )}
              </FormGroup>
            </td>
            <td>
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  placeholder=""
                  type="text"
                  value={elt.situationMatrimoniale || ""}
                  onChange={(event) =>
                    this.handleChangeInputIndividu(event, index)
                  }
                  name="situationMatrimoniale"
                />
              </FormGroup>
            </td>
            <td>
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  placeholder=""
                  type="text"
                  value={elt?.activite || ""}
                  onChange={(event) =>
                    this.handleChangeInputIndividu(event, index)
                  }
                  name="activite"
                />
              </FormGroup>
            </td>
            <td>
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  placeholder=""
                  type="text"
                  value={elt?.observation || ""}
                  onChange={(event) =>
                    this.handleChangeInputIndividu(event, index)
                  }
                  name="observation"
                />
              </FormGroup>
            </td>
            <td>
              {" "}
              <FormGroup>
                <div className="input-group-alternative input-group">
                  <select
                    className="mdb-select md-form  form-control"
                    onChange={(event) =>
                      this.handleChangeInputIndividu(event, index)
                    }
                    name="handicape"
                    value={elt?.handicape || false}>
                    <option value="false">Non</option>
                    <option value="true">Oui</option>
                  </select>
                </div>
              </FormGroup>
            </td>
          </tr>
        );
      });

    return (
      <>
        <div className="header bg-gradient-primary postition-absolute"></div>
        <Container className="mt-5 custom-card-container" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardBody>
                  <>
                    <Row>
                      <Col>
                        <FormGroup>
                          <div className="h3 text-primary">Chef de Famille</div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-nom">
                            Nom *
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-nom"
                            placeholder=""
                            type="text"
                            name="nom"
                            value={this.state.menageIndividu?.individu[0]?.nom}
                            onChange={(event) =>
                              this.handleChangeInputIndividu(event, 0)
                            }
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                          />
                          {this.state.requiredFields &&
                            this.state.requiredFields?.nom &&
                            this.state.requiredFields?.nom?.length > 0 && (
                              <small className="text-danger">
                                {" "}
                                {this.state.requiredFields.nom}
                              </small>
                            )}
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-prenom">
                            Prénom *
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-prenom"
                            placeholder=""
                            type="text"
                            name="prenom"
                            value={
                              this.state.menageIndividu?.individu[0]?.prenom
                            }
                            onChange={(event) =>
                              this.handleChangeInputIndividu(event, 0)
                            }
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                          />
                          {this.state.requiredFields &&
                            this.state.requiredFields?.prenom &&
                            this.state.requiredFields?.prenom?.length > 0 && (
                              <small className="text-danger">
                                {" "}
                                {this.state.requiredFields.nom}
                              </small>
                            )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control- requiredFields = label"
                            htmlFor="input-adresse">
                            Adresse*
                          </label>
                          <Input
                            className="form-control-alternative"
                            placeholder=""
                            type="text"
                            value={this.state.menageIndividu?.menage?.adresse}
                            onChange={this.handleMenageInputChange}
                            name="adresse"
                          // readOnly={readOnly}
                          />
                          {this.state.requiredFields &&
                            this.state.requiredFields?.adresse &&
                            this.state.requiredFields?.adresse?.length > 0 && (
                              <small className="text-danger">
                                {" "}
                                {this.state.requiredFields.adresse}
                              </small>
                            )}
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        <FormGroup>
                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              className="custom-control-input form-control"
                              id="input-possedeCarnet"
                              type="checkbox"
                              name="possedeCarnet"
                              defaultChecked={this.state.possedeCarnet}
                              value={this.state.possedeCarnet}
                              onClick={this.handleCheckBoxChange}
                            // disabled={readOnly}
                            />
                            <label
                              className="custom-control-label text-bold"
                              htmlFor="input-possedeCarnet">
                              Possède carnet
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-numeroCarnet">
                            Numéro carnet
                          </label>
                          <Input
                            className="form-control-alternative"
                            placeholder=""
                            id="input-numeroCarnet"
                            type="text"
                            value={
                              this.state.menageIndividu?.menage?.numeroCarnet
                            }
                            onChange={this.handleMenageInputChange}
                            name="numeroCarnet"
                            disabled={!this.state.possedeCarnet}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <hr className="my-4" />
                    {
                      this.state.editZonage ?
                        <>
                          <div className="h3 text-primary">Zonage :</div>
                          <div className="mb-4">                            
                            <Zonnage
                              handleChangeZonage={this.handleChangeZonage}
                              filter={this.state.menageIndividu?.menage}
                              isFilter={false}
                              requiredFields={this.state.requiredFields}
                            />
                          </div>
                        </>
                        :
                        <>                          
                          <div className="d-flex justify-content-between align-items-center position-relative">
                            <div className="h3 text-primary">Zonage actuel :</div>
                            <Button
                              className="mx-2 position-absolute right-0"
                              color="primary"
                              type="button"
                              onClick={this.handleActivateEditZonage}
                              id="tooltipEditZonage">
                              <i className="fa fa-edit" />
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              placement="top"
                              target="tooltipEditZonage">
                              Modifier le zonage
                            </UncontrolledTooltip>
                          </div>
                          <Row>
                            <Col lg="8" md="12">
                              <ul>
                                <li><b>Région :</b> {this.state.menageIndividu?.menage?.region || ""}</li>
                                <li><b>District :</b> {this.state.menageIndividu?.menage?.district || ""}</li>
                                <li><b>Commune :</b> {this.state.menageIndividu?.menage?.commune || ""}</li>
                                <li><b>Fokontany :</b> {this.state.menageIndividu?.menage?.fokotany || ""}</li>
                              </ul>
                            </Col>
                          </Row>
                        </>
                    }

                    {
                      ((this.state.requiredFields?.region && this.state.requiredFields?.region?.length > 0) ||
                      (this.state.requiredFields?.district && this.state.requiredFields?.district?.length > 0) ||
                      (this.state.requiredFields?.commune && this.state.requiredFields?.commune?.length > 0) ||
                      (this.state.requiredFields?.fokotany && this.state.requiredFields?.fokotany?.length > 0)) &&
                       (
                        <div>
                        <small className="text-danger">
                          {"Zonage obligatoire"}
                        </small>
                        </div>
                      )
                    }
                    <hr className="my-4" />
                    <Row>
                      <Col lg="2">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-numeroRegistre">
                            Numéro régistre
                          </label>
                          <Input
                            className="form-control-alternative"
                            placeholder=""
                            type="text"
                            value={
                              this.state.menageIndividu?.menage
                                ?.numeroRegistre || ""
                            }
                            onChange={this.handleMenageInputChange}
                            name="numeroRegistre"
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="5">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-secteur">
                            Secteur
                          </label>
                          <Input
                            className="form-control-alternative"
                            placeholder=""
                            type="text"
                            value={
                              this.state.menageIndividu.menage?.secteur || ""
                            }
                            onChange={this.handleMenageInputChange}
                            name="secteur"
                          // readOnly={readOnly}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="2">
                        <FormGroup>
                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              className="custom-control-input form-control"
                              id="locataire"
                              type="checkbox"
                              name="locataire"
                              checked={
                                this.state.menageIndividu?.menage?.locataire
                              }
                              onChange={this.handleCheckbox}
                            />
                            <label
                              className="custom-control-label text-bold"
                              htmlFor="locataire">
                              Locataire
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </>
                  {/* ************************************ TRAITEMENT INDIVIDU ************************************** */}

                  <>
                    <Row>
                      <Col>
                        <FormGroup className={!readOnly ? "m-0" : ""}>
                          <div className="h3 text-primary d-flex justify-content-between align-items-center">
                            <span>Liste individus</span>
                            {!readOnly && (
                              <div>
                                <Button
                                  className="m-2"
                                  color="primary"
                                  type="button"
                                  onClick={this.addIndividu}
                                  id="tooltip611234744">
                                  <i className="fa fa-plus" />
                                </Button>
                                <UncontrolledTooltip
                                  delay={0}
                                  placement="top"
                                  target="tooltip611234744">
                                  Ajouter
                                </UncontrolledTooltip>
                              </div>
                            )}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <div className="table-responsive  table-secondary">
                          <table
                            className="traitement table-form"
                            cellSpacing="0"
                            cellPadding="0">
                            <thead>
                              <tr>
                                {!readOnly && (
                                  <th className="col-action">
                                    <small className="text-bold">Action</small>
                                  </th>
                                )}
                                <th className="">
                                  <small className="text-bold">Nom *</small>
                                </th>
                                <th className="">
                                  <small className="text-bold">Prénom *</small>
                                </th>
                                <th className="">
                                  <small className="text-bold">CIN *</small>
                                </th>
                                <th className="">
                                  <small className="text-bold">Date CIN</small>
                                </th>
                                <th className="">
                                  <small className="text-bold">
                                    Lieu CIN *
                                  </small>
                                </th>

                                <th className="col-mini">
                                  <small className="text-bold">Age</small>
                                </th>
                                <th className="">
                                  <small className="text-bold">
                                    Date de naissance
                                  </small>
                                </th>
                                <th className="">
                                  <small className="text-bold">
                                    Lieu de naissance
                                  </small>
                                </th>
                                <th className="">
                                  <small className="text-bold">Genre</small>
                                </th>
                                <th className="">
                                  <small className="text-bold">
                                    Nationalité *
                                  </small>
                                </th>
                                <th className="">
                                  <small className="text-bold">Père</small>
                                </th>
                                <th className="">
                                  <small className="text-bold">
                                    Mention Père
                                  </small>
                                </th>
                                <th className="">
                                  <small className="text-bold">Mère</small>
                                </th>
                                <th className="">
                                  <small className="text-bold">
                                    Mention Mère
                                  </small>
                                </th>
                                <th className="">
                                  <small className="text-bold">Téléphone</small>
                                </th>
                                <th className="">
                                  <small className="text-bold">
                                    Situation matrimoniale
                                  </small>
                                </th>
                                <th className="">
                                  <small className="text-bold">Activité</small>
                                </th>
                                <th className="">
                                  <small className="text-bold">
                                    Observation
                                  </small>
                                </th>
                                <th className="">
                                  <small className="text-bold">Handicap</small>
                                </th>
                              </tr>
                            </thead>
                            <tbody>{individuBody}</tbody>
                          </table>
                        </div>
                      </Col>
                    </Row>
                  </>
                </CardBody>
                <hr className="mt-1 mb-2 " />
                <div className="d-flex justify-content-end p-3">
                  <button
                    className="btn btn-primary"
                    onClick={this.handleSubmit}>
                    ENREGISTRER
                  </button>
                  {this.state.isEnroler && (
                    <button
                      className="btn btn-primary"
                      onClick={this.saveAndEnroler}>
                      ENREGISTRER ET ENROLER SUR UN SITE
                    </button>
                  )}
                  <button
                    className="btn btn-secondary"
                    onClick={() =>
                      this.props.history.push(`/admin/gestion-menage`)
                    }>
                    ANNULER
                  </button>
                </div>
              </Card>
            </div>
          </Row>
        </Container>
        <PageSpinner show={this.state.isLoading} />
        {this.state.showNotification && (
          <NotificationModal
            show={this.state.showNotification}
            message={this.notificationMessage}
            title={this.notificationTitle}
            close={this.closeNotification}
          />
        )}
      </>
    );
  }
}

export default MenageIndividuTraitement;
