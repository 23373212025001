import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  Label,
  Row
} from "reactstrap";
import UtilisateurService from "services/UtilisateurService";
import PageSpinner from "./PageSpinner";

class UserNewPasswordModal extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    newPassword: "",
    validationMessage: "",
  };
  handleChange = (evt) => {
    const { name, value } = evt.target;
    this.setState({
      newPassword: value,
      validationMessage: this.props.passwordValidation(value)
    })
  }
  handleSubmit = () => {
    if (this.state.validationMessage == "" && this.props.individu?.pseudo ) {
      UtilisateurService.adminChangePassword({
        pseudo: this.props.individu?.pseudo,
        newPassword: this.state.newPassword
      });
      this.props.onCancel();
    }

  }
  render() {
    return (
      <>
        <Modal className="modal-dialog-centered" isOpen={this.props.isOpen} size="md">
          <div className="modal-body p-0">
            <Card className=" border-0">
              <CardHeader className="bg-light-secondary pb-0">
                <div className="text-muted text-center mt-2 mb-3 ">
                  <h2>Definir un nouveau mot de passe pour :</h2>
                </div>
              </CardHeader>
              <CardBody className="px-lg-4 py-lg-2">
                  <div>
                    <Label className="ml-1" >
                      <b>Pseudo : </b>
                    </Label>{" "}
                    <span>{this.props?.individu?.pseudo || ""}</span>
                  </div>
                  <div>
                    <Label className="ml-1" >
                      <b>Nom : </b>{" "}
                    </Label>{" "}
                    <span>
                      {this.props?.individu?.nom || ""}
                    </span>
                  </div>
                <FormGroup>
                  <>
                  <label
                    className="form-control-label"
                    htmlFor="new-password"
                  >
                    Nouveau mot de passe
                  </label>

                  <Input
                    className={ `form-control-alternative ${this.state.newPassword.length == 0 ? 'border-danger ' : ''}` }
                    id="new-password"
                    placeholder="Nouveau mot de passe"
                    type="password"
                    name="new-password"
                    value={this.state.newPassword}
                    onChange={this.handleChange}
                  />
                  {
                    (this.state.validationMessage && this.state.validationMessage.length > 0) && 
                    <small className="text-danger">
                      {this.state.validationMessage}
                    </small>                  
                  }
                  </>
                </FormGroup>
              </CardBody>
              <hr className="m-0" />
              <div className="modal-footer">
                <Button type="button" onClick={this.props.onCancel}>
                  Annuler
                </Button>
                <Button type="button" color="primary" onClick={this.handleSubmit}>
                  <span>Enregistrer</span>
                </Button>
              </div>
            </Card>
          </div>
        </Modal>
      </>
    );
  }
}

export default UserNewPasswordModal;
