import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Col,
  FormGroup,
  Modal,
  Row,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import PageSpinner from "./PageSpinner";

class LoharanoModal extends React.Component {
  state = {
    // cin: "114 151 004 003"
  };


  handleChange = (evt) => {
    let { name, value } = evt.target;
    if (name == "cin") {
      value = value.replace(/[^\d]/, "");
      value = value.replace(/\s+/g, '');
      if (value.length > 12) {
        return "";
      }
    }
    this.setState({
      ...this.state,
      [evt.target.name]: value,
    });
  };

  onSearch = () => {
    this.props.onSearch(this.state);
  }

  onPressEnter = (event) => {
    if (event.key === "Enter") {
      this.onSearch();
    }
  };

  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={true}
          size="lg"
        >
          <ModalHeader toggle={this.props.close}> </ModalHeader>

          <ModalBody>
            <div className="modal-body p-0">
              <Row>
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <Row>
                        <Col>
                          <FormGroup>
                            <div className="input-group-alternative input-group">
                              <input
                                placeholder="Nom et prénom"
                                type="text"
                                className="form-control"
                                value={this.state.nom}
                                onChange={this.handleChange}
                                name="nom"
                                onKeyDown={this.onPressEnter}
                              />
                            </div>
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup>
                            <div className="input-group-alternative input-group">
                              <input
                                placeholder="CIN"
                                type="text"
                                className="form-control"
                                value={this.state.cin}
                                onChange={this.handleChange}
                                name="cin"
                                onKeyDown={this.onPressEnter}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <div className="input-group-alternative input-group">
                              <input
                                placeholder="Date de naissance"
                                type="text"
                                className="form-control"
                                value={this.state.dateNaissance}
                                onChange={this.handleChange}
                                name="dateNaissance"
                                onKeyDown={this.onPressEnter}
                              />
                            </div>
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup>
                            <div className="input-group-alternative input-group">
                              <input
                                placeholder="Lieu de naissance"
                                type="text"
                                className="form-control"
                                value={this.state.lieuNaissance}
                                onChange={this.handleChange}
                                name="lieuNaissance"
                                onKeyDown={this.onPressEnter}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardHeader>
                  </Card>
                </div>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col >
                <Button
                  className="btn"
                  color="primary"
                  width="100%"
                  onClick={() => this.onSearch()}
                >
                  Rechercher sur loharano
                </Button>
              </Col>

              <Col >
                <Button
                  className="btn"
                  color="third"
                  width="100%"
                  onClick={this.props.QRReader}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/qr-code-scan.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">QR code</span>
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        <PageSpinner show={this.state.isLoading} />
      </>
    );
  }
}

export default LoharanoModal;
