import axios from "axios";
import { BACK_URL } from "data/constant/urls";
import authHeader from "./AuthHeader";

// const API_URL = process.env.REACT_APP_URL;
const API_URL = BACK_URL;

class BoardService {

    serviceFokontany() {
        return axios.get(
            API_URL +
            `/api/fokotany/findAllfokotany?nomFokotany&nomCommune&nomDistricte&nomRegion`,
            {
                headers: authHeader(),
            }
        );
    }
    getStatBoard(commune, district, region) {
        return axios.get(
            API_URL +
            `tableau/suivimalade/getAllSuiviByCommuneDistrictRegion?commune=${commune}&district=${district}&region=${region}`,
            {
                headers: authHeader(),
            }
        );
    }
    getStatBoardGeneralCommune(commune) {
        return axios.get(
            API_URL + `tableau/suivimalade/getAllSuiviByCommune?nom=${commune}`,
            {
                headers: authHeader(),
            }
        );
    }
    getStatBoardGeneralDistrict(district) {
        return axios.get(
            API_URL + `tableau/suivimalade/getAllSuiviByDistricte?nom=${district}`,
            {
                headers: authHeader(),
            }
        );
    }
    getStatBoardGeneralRegion(region) {
        return axios.get(
            API_URL + `tableau/suivimalade/getAllSuiviByRegion?nom=${region}`,
            {
                headers: authHeader(),
            }
        );
    }

    getHistogrammeStatSuivie() {
        return axios.get(API_URL + "tableau/suivimalade/getSuiviDistrict", {
            headers: authHeader(),
        });
        //"nom" : Amboasary
        //"nbr" : 1
    }

    getHistogrammeStatNoSuivie() {
        return axios.get(API_URL + "tableau/suivimalade/getNoSuiviDistrict", {
            headers: authHeader(),
        });
        //"nom" : Amboasary
        //"nbr" : 1
    }

    chartBoardEtatGeneral() {
        return axios.get(API_URL + "tableau/suivimalade/getAllSuivi", {
            headers: authHeader(),
        });
    }

    chartEvolutionPatient(commune, district, region) {
        return axios.get(
            API_URL +
            `tableau/suivimalade/getSuivi12Semaine?commune=${commune}&district=${district}&region=${region}`,
            {
                headers: authHeader(),
            }
        );
    }

    camembertEtatNutrionnelMasEnfant(commune, district, region, fokontany) {
        return axios.get(
            API_URL +
            `tableau/suivimalade/getMASEnfantCommuneDistricteRegion?commune=${commune}&district=${district}&region=${region}&fokontany=${fokontany}`,
            {
                headers: authHeader(),
            }
        );
    }
    camembertEtatNutrionnelMamEnfant(commune, district, region, fokontany) {
        return axios.get(
            API_URL +
            `tableau/suivimalade/getMAMEnfantCommuneDistricteRegion?commune=${commune}&district=${district}&region=${region}&fokontany=${fokontany}`,
            {
                headers: authHeader(),
            }
        );
    }
    camembertEtatNutrionnelMasAdos(commune, district, region, fokontany) {
        return axios.get(
            API_URL +
            `tableau/suivimalade/getMASAdoCommuneDistricteRegion?commune=${commune}&district=${district}&region=${region}&fokontany=${fokontany}`,
            {
                headers: authHeader(),
            }
        );
    }
    camembertEtatNutrionnelMamAdos(commune, district, region, fokontany) {
        return axios.get(
            API_URL +
            `tableau/suivimalade/getMAMAdoCommuneDistricteRegion?commune=${commune}&district=${district}&region=${region}&fokontany=${fokontany}`,
            {
                headers: authHeader(),
            }
        );
    }
    camembertEtatNutrionnelAdulte(commune, district, region, fokontany) {
        return axios.get(
            API_URL +
            `tableau/suivimalade/getDenutriCommuneDistricteRegion?commune=${commune}&district=${district}&region=${region}&fokontany=${fokontany}`,
            {
                headers: authHeader(),
            }
        );
    }

    camembertRatioGueriDeces(commune, district, region) {
        return axios.get(
            API_URL +
            `tableau/suivimalade/getSuiviGueriDecesRatio?commune=${commune}&district=${district}&region=${region}`,
            {
                headers: authHeader(),
            }
        );
        // "nbr_deces": "0",
        // "nbr_gueri": "1",
    }

    getRegionCommuneDistrict() {
        return axios.get(
            API_URL +
            "api/commune/findAllCommuneByRegionDistricte?nomCommune=&nomDistricte=&idRegion=",
            {
                headers: authHeader(),
            }
        );
    }

    findAllIndividuHealed(commune, district, region, begin, pageSize) {
        return axios.get(
            API_URL +
            "tableau/suivimalade/getInfoEtatSanteGuerieCommuneDistricteRegion?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || "") +
            "&deb=" +
            begin +
            "&pageSize=" +
            pageSize,
            {
                headers: authHeader(),
            }
        );
    }

    findAllIndividuDead(commune, district, region, begin, pageSize) {
        return axios.get(
            API_URL +
            "tableau/suivimalade/getInfoEtatSanteDecesCommuneDistricteRegion?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || "") +
            "&deb=" +
            begin +
            "&pageSize=" +
            pageSize,
            {
                headers: authHeader(),
            }
        );
    }



    findAllIndividuNewCase(commune, district, region, begin, pageSize) {
        return axios.get(
            API_URL +
            "tableau/suivimalade/getInfoEtatSanteNouveauCasCommuneDistricteRegion?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || "") +
            "&deb=" +
            begin +
            "&pageSize=" +
            pageSize,
            {
                headers: authHeader(),
            }
        );
    }

    findAllIndividuBeingProcessed(commune, district, region, begin, pageSize) {
        return axios.get(
            API_URL +
            "tableau/suivimalade/getInfoEtatSanteEnCoursCommuneDistricteRegion?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || "") +
            "&deb=" +
            begin +
            "&pageSize=" +
            pageSize,
            {
                headers: authHeader(),
            }
        );
    }
    findAllDetecteMasEnfant(commune, district, region, fokontany, begin, pageSize) {
        return axios.get(
            API_URL +
            "tableau/suivimalade/getInfoMASEnfantCommuneDistricteRegion?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || "") +
            "&fokontany=" +
            (fokontany || "") +
            "&pageNo=" +
            begin +
            "&pageSize=" +
            pageSize,
            {
                headers: authHeader(),
            }
        );
    }
    findAllDetecteMamEnfant(commune, district, region, fokontany, begin, pageSize) {
        return axios.get(
            API_URL +
            "tableau/suivimalade/getInfoMAMEnfantCommuneDistricteRegion?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || "") +
            "&fokontany=" +
            (fokontany || "") +
            "&pageNo=" +
            begin +
            "&pageSize=" +
            pageSize,
            {
                headers: authHeader(),
            }
        );
    }
    findAllMASMAM_ENFANT(commune, district, region, fokontany, begin, pageSize) {
        return axios.get(
            API_URL +
            "tableau/suivimalade/getInfoMASversMAMEnfantCommuneDistricteRegion?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || "") +
            "&fokontany=" +
            (fokontany || "") +
            "&pageNo=" +
            begin +
            "&pageSize=" +
            pageSize,
            {
                headers: authHeader(),
            }
        );
    }
    findAllMAMMAS_ENFANT(commune, district, region, fokontany, begin, pageSize) {
        return axios.get(
            API_URL +
            "tableau/suivimalade/getInfoMAMversMASEnfantCommuneDistricteRegion?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || "") +
            "&fokontany=" +
            (fokontany || "") +
            "&pageNo=" +
            begin +
            "&pageSize=" +
            pageSize,
            {
                headers: authHeader(),
            }
        );
    }
    findAllBON_ENFANT(commune, district, region, fokontany, begin, pageSize) {
        return axios.get(
            API_URL +
            "tableau/suivimalade/getInfoBonEnfantCommuneDistricteRegion?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || "") +
            "&fokontany=" +
            (fokontany || "") +
            "&pageNo=" +
            begin +
            "&pageSize=" +
            pageSize,
            {
                headers: authHeader(),
            }
        );
    }
    findAllNON_ENFANT(commune, district, region, fokontany, begin, pageSize) {
        return axios.get(
            API_URL +
            "tableau/suivimalade/getInfoNONEnfantCommuneDistricteRegion?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || "") +
            "&fokontany=" +
            (fokontany || "") +
            "&pageNo=" +
            begin +
            "&pageSize=" +
            pageSize,
            {
                headers: authHeader(),
            }
        );
    }
    findAllDetecteMasAdos(commune, district, region, fokontany, begin, pageSize) {
        return axios.get(
            API_URL +
            "tableau/suivimalade/getInfoMASAdoCommuneDistricteRegion?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || "") +
            "&fokontany=" +
            (fokontany || "") +
            "&pageNo=" +
            begin +
            "&pageSize=" +
            pageSize,
            {
                headers: authHeader(),
            }
        );
    }
    findAllDetecteMamAdos(commune, district, region, fokontany, begin, pageSize) {
        return axios.get(
            API_URL +
            "tableau/suivimalade/getInfoMAMAdoCommuneDistricteRegion?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || "") +
            "&fokontany=" +
            (fokontany || "") +
            "&pageNo=" +
            begin +
            "&pageSize=" +
            pageSize,
            {
                headers: authHeader(),
            }
        );
    }
    findAllMASMAM_ADOS(commune, district, region, fokontany, begin, pageSize) {
        return axios.get(
            API_URL +
            "tableau/suivimalade/getInfoMASversMAMAdoCommuneDistricteRegion?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || "") +
            "&fokontany=" +
            (fokontany || "") +
            "&pageNo=" +
            begin +
            "&pageSize=" +
            pageSize,
            {
                headers: authHeader(),
            }
        );
    }
    findAllMAMMAS_ADOS(commune, district, region, fokontany, begin, pageSize) {
        return axios.get(
            API_URL +
            "tableau/suivimalade/getInfoMAMversMASAdoCommuneDistricteRegion?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || "") +
            "&fokontany=" +
            (fokontany || "") +
            "&pageNo=" +
            begin +
            "&pageSize=" +
            pageSize,
            {
                headers: authHeader(),
            }
        );
    }
    findAllBON_ADOS(commune, district, region, fokontany, begin, pageSize) {
        return axios.get(
            API_URL +
            "tableau/suivimalade/getInfoBonAdoCommuneDistricteRegion?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || "") +
            "&fokontany=" +
            (fokontany || "") +
            "&pageNo=" +
            begin +
            "&pageSize=" +
            pageSize,
            {
                headers: authHeader(),
            }
        );
    }
    findAllNON_ADOS(commune, district, region, fokontany, begin, pageSize) {
        return axios.get(
            API_URL +
            "tableau/suivimalade/getInfoNONAdoCommuneDistricteRegion?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || "") +
            "&fokontany=" +
            (fokontany || "") +
            "&pageNo=" +
            begin +
            "&pageSize=" +
            pageSize,
            {
                headers: authHeader(),
            }
        );
    }
    findAllMAL(commune, district, region, fokontany, begin, pageSize) {
        return axios.get(
            API_URL +
            "tableau/suivimalade/getInfoDenutriCommuneDistricteRegion?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || "") +
            "&fokontany=" +
            (fokontany || "") +
            "&pageNo=" +
            begin +
            "&pageSize=" +
            pageSize,
            {
                headers: authHeader(),
            }
        );
    }
    findAllNUTRI(commune, district, region, fokontany, begin, pageSize) {
        return axios.get(
            API_URL +
            "tableau/suivimalade/getInfoNutriCommuneDistricteRegion?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || "") +
            "&fokontany=" +
            (fokontany || "") +
            "&pageNo=" +
            begin +
            "&pageSize=" +
            pageSize,
            {
                headers: authHeader(),
            }
        );
    }
    findAllNON_ADULTE(commune, district, region, fokontany, begin, pageSize) {
        return axios.get(
            API_URL +
            "tableau/suivimalade/getInfoNutriCommuneDistricteRegion?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || "") +
            "&fokontany=" +
            (fokontany || "") +
            "&pageNo=" +
            begin +
            "&pageSize=" +
            pageSize,
            {
                headers: authHeader(),
            }
        );
    }


    findAllNON_ClassifieADULTE(commune, district, region, fokontany, begin, pageSize) {
        return axios.get(
            API_URL +
            "tableau/suivimalade/getListNonClassifierAdultestCommuneDistricteRegion?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || "") +
            "&fokontany=" +
            (fokontany || "") +
            "&pageNo=" +
            begin +
            "&pageSize=" +
            pageSize,
            {
                headers: authHeader(),
            }
        );
    }


    getRevenuBoard(commune, district, region, fokontany) {
        return axios.get(
            API_URL +
            "tableau/suividon/getRatioMenageByRevenu?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || "") +
            "&fokontany=" +
            (fokontany || ""),
            {
                headers: authHeader(),
            }
        );
    }

    getActivityBoard(commune, district, region) {
        return axios.get(
            API_URL +
            "tableau/suividon/getMenageActivite?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || ""),
            {
                headers: authHeader(),
            }
        );
    }

    getDon(commune, district, region) {
        return axios.get(
            API_URL +
            "tableau/suividon/getMenageDon?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || ""),
            {
                headers: authHeader(),
            }
        );
    }

    findAllIndividuTOSIKA_FAMENO(commune, district, region, begin, pageSize) {
        return axios.get(
            API_URL +
            "tableau/suividon/getInfoMenageDonTosikaFameno?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || "") +
            "&deb=" +
            begin +
            "&pageSize=" +
            pageSize,
            {
                headers: authHeader(),
            }
        );
    }

    findAllIndividuFIAVOTA(commune, district, region, begin, pageSize) {
        return axios.get(
            API_URL +
            "tableau/suividon/getInfoMenageDonFiavota?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || "") +
            "&deb=" +
            begin +
            "&pageSize=" +
            pageSize,
            {
                headers: authHeader(),
            }
        );
    }

    findAllIndividuGFD(commune, district, region, begin, pageSize) {
        return axios.get(
            API_URL +
            "tableau/suividon/getInfoMenageDonGFD?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || "") +
            "&deb=" +
            begin +
            "&pageSize=" +
            pageSize,
            {
                headers: authHeader(),
            }
        );
    }

    findAllIndividuNOT_SPECIFIED(commune, district, region, begin, pageSize) {
        return axios.get(
            API_URL +
            "tableau/suividon/getInfoMenageNoDon?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || "") +
            "&deb=" +
            begin +
            "&pageSize=" +
            pageSize,
            {
                headers: authHeader(),
            }
        );
    }

    getFrequenceRepasBoard(commune, district, region, fokontany) {
        return axios.get(
            API_URL +
            "tableau/suivialimentaire/getFrequenceRepas?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || "") +
            "&fokontany=" +
            (fokontany || ""),
            {
                headers: authHeader(),
            }
        );
    }

    findAllIndividuFrequenceRepas(frequence, commune, district, region, fokontany, begin, pageSize) {
        return axios.get(
            API_URL +
            "tableau/suivialimentaire/getInfoFrequenceRepas?frequence=" +
            (frequence || "") +
            "&commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || "") +
            "&fokontany=" +
            (fokontany || "") +
            "&pageNo=" +
            begin +
            "&pageSize=" +
            pageSize,
            {
                headers: authHeader(),
            }
        );
    }

    getRationBoard(commune, district, region) {
        return axios.get(
            API_URL +
            "tableau/suivialimentaire/getRation?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || ""),
            {
                headers: authHeader(),
            }
        );
    }

    findAllIndividuRation(commune, district, region, begin, pageSize) {
        return axios.get(
            API_URL +
            "tableau/suivialimentaire/getInfoRation?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || "") +
            "&deb=" +
            begin +
            "&pageSize=" +
            pageSize,
            {
                headers: authHeader(),
            }
        );
    }

    getBiensEtAvoirs(commune, district, region) {
        return axios.get(
            API_URL +
            "tableau/suividon/getBienAvoir?commune=" +
            (commune || "") +
            "&district=" +
            (district || "") +
            "&region=" +
            (region || ""),
            {
                headers: authHeader(),
            }
        );
    }


    exportDatas(data) {
        const url = API_URL + "api/exportExcel";
        const method = "POST";
        const responseType = "arraybuffer";
        return axios.request({
            url,
            method,
            responseType,
            data,
            headers: authHeader(),
        });
    }

    exportData(data) {
        return axios.get(
            API_URL + "tableau/suivialimentaire/download",
            {
                headers: authHeader(),
            },
            data
        );
    }

    getStatBoardDemographique(commune, region, district, fokontany) {
        return axios.get(
            API_URL + `tableau/suivipopulation/getPopulationCommuneDistricteRegion?commune=${commune}&district=${district}&region=${region}&fokontany=${fokontany}`,
            {
                headers: authHeader(),
            },

        );
    }
    getPopulationDistrict(commune, district, region) {
        return axios.get(
            API_URL +
            "tableau/suivipopulation/getPopulatoinDistrict",
            {
                headers: authHeader(),
            }
        );
    }

    findAllMoin5ans(commune, district, region, fokontany, begin, pageSize) {
        return axios.get(
            API_URL + `tableau/suivipopulation/getInfoPopulationMois5CommuneDistricteRegion?pageNo=${begin}&pageSize=${pageSize}&commune=${commune}&district=${district}&region=${region}&fokontany=${fokontany}`,
            {
                headers: authHeader(),
            },

        );
    }

    findAllPlus65ans(commune, district, region, fokontany, begin, pageSize) {
        return axios.get(
            API_URL + `tableau/suivipopulation/getInfoPopulationPlus65CommuneDistricteRegion?pageNo=${begin}&pageSize=${pageSize}&commune=${commune}&district=${district}&region=${region}&fokontany=${fokontany}`,
            {
                headers: authHeader(),
            },

        );
    }

    findAllAllAllaitante(commune, district, region, fokontany, begin, pageSize) {
        return axios.get(
            API_URL + `tableau/suivipopulation/getInfoPopulationAllaitanteCommuneDistricteRegion?pageNo=${begin}&pageSize=${pageSize}&commune=${commune}&district=${district}&region=${region}&fokontany=${fokontany}`,
            {
                headers: authHeader(),
            },

        );
    }

    findAllAllEnceinte(commune, district, region, fokontany, begin, pageSize) {
        return axios.get(
            API_URL + `tableau/suivipopulation/getInfoPopulationEnceinteCommuneDistricteRegion?pageNo=${begin}&pageSize=${pageSize}&commune=${commune}&district=${district}&region=${region}&fokontany=${fokontany}`,
            {
                headers: authHeader(),
            },

        );
    }
    findAllListeMenage(commune, district, region, fokontany, begin, pageSize) {
        return axios.get(
            API_URL + `api/menage/getListMenageByZones?pageNo=${begin}&pageSize=${pageSize}&commune=${commune}&district=${district}&region=${region}&fokontany=${fokontany}`,
            {
                headers: authHeader(),
            },

        );
    }

    findAllAllHandicape(commune, district, region, fokontany, begin, pageSize) {
        return axios.get(
            API_URL + `tableau/suivipopulation/getInfoPopulationHandicapCommuneDistricteRegion?pageNo=${begin}&pageSize=${pageSize}&commune=${commune}&district=${district}&region=${region}&fokontany=${fokontany}`,
            {
                headers: authHeader(),
            },

        );
    }

    getWaterBoard(commune, district, region, fokontany) {
        return axios.get(
            API_URL + `tableau/suividon/getMenageEau?commune=${commune}&district=${district}&region=${region}&fokontany=${fokontany}`,
            {
                headers: authHeader(),
            },

        );
    }

    getSecurityBoard(commune, district, region, fokontany) {
        return axios.get(
            API_URL + `tableau/suividon/getSecuriteMenage?commune=${commune}&district=${district}&region=${region}&fokontany=${fokontany}`,
            {
                headers: authHeader(),
            },

        );
    }

    getSecurityInfo(commune, district, region, fokontany, begin, pageSize, type) {
        return axios.get(
            API_URL + `tableau/suividon/getInfoSecurtCommuneDistricteRegionSecurt?pageNo=${begin}&pageSize=${pageSize}&commune=${commune}&district=${district}&region=${region}&fkt=${fokontany}&securt=${type}`,
            {
                headers: authHeader(),
            },

        );
    }

    getWaterInfo(commune, district, region, fokontany, begin, pageSize, type) {
        return axios.get(
            API_URL + `tableau/suividon/getInfoEauCommuneDistricteRegionEau?pageNo=${begin}&pageSize=${pageSize}&commune=${commune}&district=${district}&region=${region}&fokontany=${fokontany}&eau=${type}`,
            {
                headers: authHeader(),
            },

        );
    }

    getWaterSource(commune, district, region, fokontany, begin, pageSize, type) {
        return axios.get(
            API_URL + `tableau/suividon/getInfoEauSourceCommuneDistricteRegionEau?pageNo=${begin}&pageSize=${pageSize}&commune=${commune}&district=${district}&region=${region}&fokontany=${fokontany}&source=${type}`,
            {
                headers: authHeader(),
            },

        );
    }

    getWaterSourceMenage(commune, district, region, fokontany) {
        return axios.get(
            API_URL + `tableau/suividon/getMenageEauSource?commune=${commune}&district=${district}&region=${region}&fokontany=${fokontany}`,
            {
                headers: authHeader(),
            },
        );
    }
    getDonTosikaFameno(commune, district, region, deb, pageSize) {
        return axios.get(
            API_URL + `tableau/suividon/getInfoMenageDonTosikaFameno?commune=${commune}&district=${district}&region=${region}&deb=${deb}&pageSize=${pageSize}`,
            {
                headers: authHeader(),
            },
        );
    }

    getRevenuGeneraux(commune, district, region, revenu, pageNo = 0, pageSize = 10, fokontany) {
        return axios.get(
            API_URL + `tableau/suividon/getInfoRevenuCommuneDistricteRegion?pageNo=${pageNo}&pageSize=${pageSize}&commune=${commune}&district=${district}&region=${region}&fokontany=${fokontany}&revenu=${revenu}`,
            {
                headers: authHeader(),
            },
        );
    }

    getEtatSanteZone( region, district, commune, fokontany ) {
      return axios.get(
        API_URL + `tableau/suivipopulation/getEtatSanteZone?fokontany=${fokontany}&commune=${commune}&district=${district}&region=${region}`,
        {
          headers: authHeader(),
        },
      );
     }
     
    exportDataStock(data) {
        const url = API_URL + "api/exportExcel";
        const method = "POST";
        const responseType = "arraybuffer";
        return axios.request({
            url,
            method,
            responseType,
            data,
            headers: authHeader(),
        });
    }


}
export default new BoardService();
