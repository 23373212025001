import axios from 'axios';
import { BACK_URL } from 'data/constant/urls';
import authHeader from './AuthHeader';

// const API_URL = process.env.REACT_APP_URL;
const API_URL = BACK_URL;

class MenageService {
  getListSite() {
    return axios.get(API_URL + 'traboina/tdb/carte', {
      headers: authHeader(),
    });
  }
}

export default new MenageService();