import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  Modal,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { Form } from "react-bootstrap";
import moment from "moment";
import ReactDatetime from "react-datetime";

import NotificationModal from "./NotificationModal";
import PageSpinner from "./PageSpinner";
import EnrolementEnregistrementServices from "services/EnrolementEnregistrementServices";
import TetheredDateTime from "./TetheredDateTime";

class MoveToOtherSiteModal extends React.Component {
  state = {
    showNotification: false,
    isLoading: false,
    title: "DEPLACEMENT VERS UN AUTRE SITE",
    enrolement: {
      enqueteInfo: {
        enqueteur: "",
        dateEnquete: moment().format("YYYY-MM-DDTHH:mm:ss"),
        displaydDateEnquete: moment().format("DD/MM/YYYY HH:mm"),
      },
    },
    defaultEnqueteur: "",
    defaultDateEnquete: "",
    siteName: "",
    chefMenage: "",
    newSiteId: "",
    defaultIdEnqueteur: "",
    isError: false,
    isInvalid: false,
    isSiteEmpty: true,
    isDateInvalid: false,
    errorMessage: "",
    errorTitle: "",
    isInit: true,
  };

  closeErrorNotification = () => {
    this.setState({
      ...this.state,
      isError: false,
    });
  };

  closeNotification = () => {
    this.setState({
      showNotification: false,
    });
  };

  handleChangeInput = (event) => {
    const siteId = event.target.value;
    event.target.value === "-1"
      ? this.setState({
          newSiteId: "-1",
          isSiteEmpty: true,
        })
      : this.setState({
          newSiteId: siteId,
          isSiteEmpty: false,
          isInit: false,
        });
  };
  handleChange = (event) => {
    const { name, value } = event.target;
    let requiredFields = "";
    let newId = "";
    if (name === "enqueteur") {
      if (value === this.state.defaultEnqueteur) {
        newId = this.state.defaultIdEnqueteur;
      } else newId = "";
    }

    this.setState({
      enrolement: {
        ...this.state.enrolement,
        enqueteInfo: {
          ...this.state.enrolement?.enqueteInfo,
          enqueteur: value,
          id: newId,
        },
      },
    });
  };

  componentWillReceiveProps = (props) => {
    this.setState({
      siteName: props.siteName,
      chefMenage: props.chefMenage,
      enrolement: {
        ...props.enrolement,
        enqueteInfo: {
          ...props.enrolement?.enqueteInfo,
          dateEnquete: props.enrolement?.enqueteInfo?.dateEnquete,
          displaydDateEnquete: moment
            .utc(props.enrolement?.enqueteInfo?.dateEnquete)
            .format("DD/MM/YYYY HH:mm"),
        },
        dateEntree: props.enrolement?.dateEntree,
        displyedDateEntree: moment
          .utc(props.enrolement?.dateEntree)
          .format("DD/MM/YYYY HH:mm"),
        dateSortie: moment().format("YYYY-MM-DDTHH:mm:ss"),
        displyedDateSortie: moment().format("MM/DD/YYYY HH:mm"),
      },
      defaultIdEnqueteur: props.enrolement?.enqueteInfo?.id,
      defaultEnqueteur: props.enrolement?.enqueteInfo?.enqueteur,
      defaultDateEnquete: props.enrolement?.enqueteInfo?.dateEnquete,
    });
  };

  isNotValidDate = () => {
    const dateArrivee = new Date(
      this.state.enrolement?.dateEntree?.slice(0, 10)
    );
    const dateSortie = new Date(
      this.state.enrolement?.dateSortie?.slice(0, 10)
    );
    return dateArrivee > dateSortie;
  };

  formValid = () => {
    const formValid = this.state.isSiteEmpty ? false : true;

    this.setState({
      isInvalid: !formValid,
      isDateInvalid: this.isNotValidDate(),
    });

    const enqueteurValid =
      this.state.enrolement?.enqueteInfo?.enqueteur?.length > 0;
    return formValid && !this.isNotValidDate() && enqueteurValid;
  };

  handleDateChange = (date) => {
    let value = "";
    if (date.isValid) {
      value = date.format("YYYY-MM-DDTHH:mm:ss");
    }
    let newId = "";
    if (
      value &&
      value.slice(0, 19) === this.state.defaultDateEnquete.slice(0, 19)
    ) {
      newId = this.state.defaultIdEnqueteur;
    } else newId = "";

    this.setState({
      enrolement: {
        ...this.state.enrolement,
        enqueteInfo: {
          ...this.state.enrolement?.enqueteInfo,
          id: newId,
          dateEnquete: value + ".000Z",
          displaydDateEnquete: moment(value).format("DD/MM/YYYY HH:mm"),
        },
      },
      isInit: false,
      // isDateInvalid: this.isNotValidDate(),
    });
  };

  handleSubmit = (event) => {
    if (!this.formValid()) {
      const errMsg = this.setState({
        isError: true,
        isInvalid: true,
        isInit: false,
        errorMessage: "",
        errorTitle: "Données invalide",
      });
    } else {
      if (
        this.state.defaultEnqueteur !==
          this.state.enrolement?.enqueteInfo?.enqueteur ||
        this.state.defaultDateEnquete !==
          this.state.enrolement?.enqueteInfo?.dateEnquete
      ) {
        this.setState({
          enrolement: {
            ...this.state.enrolement,
            enqueteInfo: {
              ...this.state.enrolement?.enqueteInfo,
              id: "",
            },
          },
        });
      } else {
        this.setState({
          enrolement: {
            ...this.state.enrolement,
            enqueteInfo: {
              ...this.state.enrolement?.enqueteInfo,
              id: this.state.defaultIdEnqueteur,
            },
          },
        });
      }
      this.processMoveToSite();
    }
  };

  processMoveToSite = () => {
    this.setState({
      isLoading: true,
    });
    const data = this.state;
    const requestDTO = {
      dateSortie: data.enrolement?.dateSortie,
      enqueteInfo: {
        dateEnquete: data.enrolement?.enqueteInfo?.dateEnquete,
        enqueteur: data.enrolement?.enqueteInfo?.enqueteur,
        id: data.enrolement?.enqueteInfo?.id,
      },
      id: data.enrolement?.id,
      menageId: data.enrolement?.menage?.id,
      nombreDeplacement: data.enrolement?.nombreDeplaces,
      siteArriveeId: data.newSiteId,
      siteDepartId: data.enrolement?.site?.id,
      enrolementId: data.enrolement?.id,
    };

    EnrolementEnregistrementServices.moveToOtherSite(requestDTO)
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        this.props.onSave();
      })
      .catch((exception) => {
        this.setState({
          isLoading: false,
          isError: true,
          errorMessage: exception.errorCode
            ? exception.errorMessage
            : "Contactez votre administrateur",
          errorTitle: "Erreur à l'enregistrement de la sortie",
        });
      });
  };

  handleChangeDate = (date) => {
    let value = "";
    if (date.isValid) {
      value = date.format("YYYY-MM-DDTHH:mm:ss");
    }
    this.setState(
      {
        enrolement: {
          ...this.state.enrolement,
          dateSortie: value + ".000Z",
          displyedDateSortie: moment(value).format("MM/DD/YYYY, h:mm:ss a"),
        },
        isInit: false,
      },
      () => {
        if (!this.isNotValidDate()) {
          this.setState({
            isDateInvalid: false,
          });
        }
      }
    );
  };

  render() {
    const siteOptions =
      this.props.siteList &&
      this.props.siteList.map(
        (elt, idx) =>
          elt.nom_site != this.state.siteName && (
            <option key={elt.id} value={elt.id}>
              {elt.nom_site}
            </option>
          )
      );

    return (
      <>
        <PageSpinner show={this.state.isLoading} />
        <Modal
          className="modal-dialog-centered"
          isOpen={true}
          style={{ zIndex: "-100" }}
        >
          <div className="modal-body p-0">
            <Card className=" border-0">
              <CardHeader className="bg-light-secondary pb-0">
                <div className="text-muted text-center mt-2 mb-3 ">
                  <h2>{this.state.title}</h2>
                </div>
              </CardHeader>
              <CardBody className="px-lg-4 py-lg-2">
                <Form>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Site actuel
                    </Label>
                    <Input
                      type="text"
                      className="mdb-select md-form form-control"
                      name="site"
                      placeholder="site "
                      readOnly
                      value={this.state.siteName}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="input-date-enquete"
                    >
                      Date et heure d'arrivée
                    </Label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <ReactDatetime
                        inputProps={{
                          placeholder: "YYYY-MM-DD",
                          onKeyDown: (e) => e.preventDefault(),
                          readOnly: true,
                        }}
                        format={"YYYY-MM-DD"}
                        closeOnSelect={false}
                        timeFormat={false}
                        name="dateArrivee"
                        open={false}
                        value={this.state.enrolement?.displyedDateEntree}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Chef de menage
                    </Label>
                    <Input
                      className="mdb-select md-form form-control"
                      type="text"
                      name="chefMenage"
                      id="chefMenage"
                      placeholder="chef de Menage"
                      readOnly
                      value={this.state.chefMenage}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Site de déplacement
                    </Label>
                    <div className="input-group-alternative input-group">
                      <Input
                        type="select"
                        onChange={this.handleChangeInput}
                        name="siteId"
                      >
                        <option value={-1}></option>
                        {siteOptions}
                      </Input>
                    </div>
                    {this.state.isSiteEmpty && !this.state.isInit && (
                      <small className="text-danger">Champ obligatoire</small>
                    )}
                  </FormGroup>

                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="input-date-enquete"
                    >
                      Date et heure de déplacement
                    </Label>
                    <InputGroup className="input-group-alternative flex-nowrap">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <TetheredDateTime
                        inputProps={{
                          placeholder: this.state.enrolement?.displyedDateSortie
                            ? moment(
                                this.state.enrolement?.displyedDateSortie
                              ).format("DD/MM/YYYY HH:mm")
                            : "DD/MM/YYYY HH:mm",
                          onKeyDown: (e) => e.preventDefault(),
                        }}
                        dateFormat={"DD/MM/YYYY"}
                        format={"DD/MM/YYYY HH:MM"}
                        autoClose={true}
                        timeFormat={"HH:mm"}
                        name="dateSortie"
                        id="dateSortie"
                        onChange={this.handleChangeDate}
                        closeOnSelect={true}
                        // value={this.state.enrolement?.dateSortie}
                      />
                    </InputGroup>
                    {this.state.isDateInvalid && !this.state.isInit && (
                      <small className="text-danger">
                        Doit être après la date d'arrivée
                      </small>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-enqueteur"
                    >
                      Nom de l'enquêteur
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-enqueteur"
                      placeholder=""
                      type="text"
                      value={this.state.enrolement?.enqueteInfo?.enqueteur}
                      onChange={this.handleChange}
                      name="enqueteur"
                    />
                    {this.state.enrolement &&
                      this.state.enrolement?.enqueteInfo &&
                      this.state.enrolement?.enqueteInfo?.enqueteur?.length <=
                        0 && (
                        <small className="text-danger">champ obligatoire</small>
                      )}
                  </FormGroup>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-date-enquete"
                    >
                      Date et heure de l'enquête
                    </label>
                    <InputGroup className="input-group-alternative flex-nowrap">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <TetheredDateTime
                        inputProps={{
                          placeholder: this.state.enrolement?.enqueteInfo
                            ?.dateEnquete
                            ? moment(
                                this.state.enrolement?.enqueteInfo.dateEnquete
                              ).format("DD/MM/YYYY HH:mm")
                            : "DD/MM/YYYY HH:mm",
                          onKeyDown: (e) => e.preventDefault(),
                        }}
                        // format={"DD/MM/YYYY HH:MM:SS"}
                        dateFormat={"DD/MM/YYYY"}
                        closeOnSelect={true}
                        // timeInputLabel="Time:"
                        // showTimeInput
                        timeFormat={"HH:mm"}
                        onChange={(date) => this.handleDateChange(date)}
                        name="dateEnquete"
                        // selected={
                        //   this.state.enrolement?.enqueteInfo?.dateEnquete
                        // }
                        value={
                          this.state.enrolement?.enqueteInfo.displaydDateEnquete
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                </Form>
              </CardBody>
              <div className="modal-footer">
                {this.state.isError && <span>(*) </span>}
                <Button type="button" onClick={this.props.onCancel}>
                  Annuler
                </Button>
                <Button
                  type="button"
                  color="primary"
                  onClick={this.handleSubmit}
                >
                  <span>Enregistrer</span>
                </Button>
              </div>
            </Card>
          </div>
        </Modal>
        {this.state.showNotification && (
          <NotificationModal
            show={this.state.showNotification}
            message={this.notificationMessage}
            title={this.notificationTitle}
            close={this.closeNotification}
          />
        )}
        {this.state.isError && (
          <NotificationModal
            show={this.state.isError}
            message={this.state.errorMessage}
            title={this.state.errorTitle}
            close={this.closeErrorNotification}
          />
        )}
      </>
    );
  }
}

export default MoveToOtherSiteModal;
