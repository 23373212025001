import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  Modal,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { Form } from "react-bootstrap";
import moment from "moment";

import NotificationModal from "./NotificationModal";
import PageSpinner from "./PageSpinner";
import EnrolementEnregistrementServices from "services/EnrolementEnregistrementServices";
import TetheredDateTime from "./TetheredDateTime";

class ExitFromSiteModal extends React.Component {
  state = {
    showNotification: false,
    isLoading: false,
    title: "ENREGISTREMENT SORTIE DU SITE",
    enrolement: {},
    siteName: "",
    chefMenage: "",
    isError: false,
    isInvalid: false,
    errorMessage: "",
    errorTitle: "",
  };

  closeErrorNotification = () => {
    this.setState({
      ...this.state,
      isError: false,
    });
  };

  closeNotification = () => {
    this.setState({
      showNotification: false,
    });
  };

  componentWillReceiveProps = (props) => {
    this.setState({
      siteName: props.siteName,
      chefMenage: props.chefMenage,
      enrolement: {
        ...props.enrolement,
        dateEntree: props.enrolement.dateEntree,
        displayedDateEntree: moment
          .utc(props.enrolement.dateEntree)
          .format("DD/MM/YYYY HH:mm"),
        dateSortie: moment().format("YYYY-MM-DDTHH:mm:ss"),
        displayedDateSortie: moment().format("DD/MM/YYYY HH:mm"),
      },
    });
  };

  formValid = () => {
    const dateArrivee = new Date(this.state.enrolement.dateEntree.slice(0, 19));
    const dateSortie = new Date(this.state.enrolement.dateSortie.slice(0, 19));
    const dateComparaison = dateArrivee > dateSortie;
    return dateComparaison;
  };

  handleSubmit = (event) => {
    if (this.formValid()) {
      this.setState({
        isError: true,
        isInvalid: true,
        errorMessage: "Date de sortie antérieur à la date d'arrivée",
        errorTitle: "Date de sortie invalide",
      });
    } else {
      this.processUpdate();
    }
  };

  processUpdate = () => {
    this.setState({
      isLoading: true,
    });
    const requestDTO = this.state.enrolement;
    EnrolementEnregistrementServices.exitFromSite(requestDTO)
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        this.props.onSave();
      })
      .catch((exception) => {
        this.setState({
          isLoading: false,
          isError: true,
          errorMessage: exception.errorCode
            ? exception.errorMessage
            : "Contactez votre administrateur",
          errorTitle: "Erreur à l'enregistrement de la sortie",
        });
      });
  };

  handleChangeDate = (date) => {
    let value = "";
    if (date.isValid) {
      value = date.format("YYYY-MM-DDTHH:mm:ss");
    }
    const isNotValid = this.formValid() ? false : true;
    this.setState({
      enrolement: {
        ...this.state.enrolement,
        dateSortie: value + ".000Z",
        displayedDateSortie: moment(value).format("DD/MM/YYYY HH:mm"),
      },
      isInvalid: isNotValid,
    });
  };

  render() {
    return (
      <>
        <PageSpinner show={this.state.isLoading} />
        <Modal
          className="modal-dialog-centered"
          isOpen={true}
          style={{ zIndex: "-100" }}
        >
          <div className="modal-body p-0">
            <Card className=" border-0">
              <CardHeader className="bg-light-secondary pb-0">
                <div className="text-muted text-center mt-2 mb-3 ">
                  <h2>{this.state.title}</h2>
                </div>
              </CardHeader>
              <CardBody className="px-lg-4 py-lg-2">
                <Form>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Site concerné
                    </Label>
                    <Input
                      type="text"
                      className="mdb-select md-form form-control"
                      name="site"
                      placeholder="site "
                      readOnly
                      value={this.state.siteName}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Chef de menage
                    </Label>
                    <Input
                      className="mdb-select md-form form-control"
                      type="text"
                      name="chefMenage"
                      id="chefMenage"
                      placeholder="chef de Menage"
                      readOnly
                      value={this.state.chefMenage}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="input-date-enquete"
                    >
                      Date d'arrivée
                    </Label>
                    <InputGroup className="input-group-alternative flex-nowrap">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <TetheredDateTime
                        inputProps={{
                          placeholder: "DD/MM/YYYY HH:mm",
                          onKeyDown: (e) => e.preventDefault(),
                          readOnly: true,
                          disabled: true,
                        }}
                        dateFormat={false}
                        closeOnSelect={false}
                        timeFormat={false}
                        name="dateArrivee"
                        open={false}
                        value={this.state.enrolement.displayedDateEntree}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="input-date-enquete"
                    >
                      Date de sortie
                    </Label>
                    <InputGroup className="input-group-alternative flex-nowrap">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <TetheredDateTime
                        inputProps={{
                          placeholder: "YYYY-MM-DD",
                          onKeyDown: (e) => e.preventDefault(),
                        }}
                        dateFormat={"DD/MM/YYYY"}
                        autoClose={true}
                        timeFormat={"HH:mm"}
                        closeOnSelect={true}
                        name="dateSortie"
                        id="dateSortie"
                        onChange={this.handleChangeDate}
                        value={this.state.enrolement.displayedDateSortie}
                      />
                    </InputGroup>
                  </FormGroup>
                </Form>
              </CardBody>
              <hr className="m-0 " />
              <div className="modal-footer">
                {this.state.isError && <span>(*) </span>}
                <Button type="button" onClick={this.props.onCancel}>
                  Annuler
                </Button>
                <Button
                  type="button"
                  color="primary"
                  onClick={this.handleSubmit}
                >
                  <span>Enregistrer</span>
                </Button>
              </div>
            </Card>
          </div>
        </Modal>
        {this.state.showNotification && (
          <NotificationModal
            show={this.state.showNotification}
            message={this.notificationMessage}
            title={this.notificationTitle}
            close={this.closeNotification}
          />
        )}
        {this.state.isError && (
          <NotificationModal
            show={this.state.isError}
            message={this.state.errorMessage}
            title={this.state.errorTitle}
            close={this.closeErrorNotification}
          />
        )}
      </>
    );
  }
}

export default ExitFromSiteModal;
