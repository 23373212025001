import React from "react";
import { FormGroup, Input } from "reactstrap";

function input({
  handleChangeInputIndividu,
  value,
  isRequired,
  fieldRequiredMessage,
  name,
  maxLength,
}) {
  return (
    <FormGroup>
      <Input
        className="form-control-alternative"
        placeholder=""
        type="text"
        name={name}
        onChange={handleChangeInputIndividu}
        value={value}
        maxLength={maxLength}
      />
      {isRequired && (
        <small className="text-danger"> {fieldRequiredMessage}</small>
      )}
    </FormGroup>
  );
}

export default input;
