import moment from "moment";
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Input,
  Pagination,
  Modal,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  Label,
} from "reactstrap";
import EnrolementEnregistrementServices from "services/EnrolementEnregistrementServices";
import PageSpinner from "views/components/PageSpinner";

class EnrolementParMenageModal extends React.Component {
  state = {
    sites: [],
    enrolements: [],
    menageId: "",
    chefMenage: "",
    nom_site: "",
    reference: "",
    currentPage: 0,
    totalPages: 1,
    totalItems: 0,
    items: 0,
    pageSize: 10,
    title: "HISTORIQUE DES ENROLEMENTS DU MENAGE",
  };

  componentWillReceiveProps = (props) => {
    this.setState(
      {
        menageId: props.menage.id,
        chefMenage: props.menage.nomChef,
      },
      () => this.getListEnrolementHistory()
    );
  };

  nextPage = () => {
    this.setState(
      {
        currentPage: this.state.currentPage + 1,
      },
      () => this.getListEnrolementHistory()
    );
  };

  previousPage = () => {
    this.setState(
      {
        currentPage: this.state.currentPage - 1,
      },
      () => {
        this.getListEnrolementHistory();
      }
    );
  };

  getListEnrolementHistory = () => {
    // this.setState({
    //   isLoading: true,
    // });
    EnrolementEnregistrementServices.getListByMenage(
      this.state.currentPage,
      this.state.pageSize,
      this.state.menageId
    ).then(
      (response) => {
        this.setState({
          enrolements: response.data.data.content,
          isLoading: false,
          totalPages: response.data.data.totalPages,
          items: response.data.data.content.length,
          totalItems: response.data.data.totalElements,
        });
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  handlePage = (event) => {
    this.setState({ [event.target.id]: event.target.value }, () =>
      this.getListEnrolementHistory()
    );
  };

  render() {
    const pageList = Array.from(Array(this.state.totalPages).keys()).map(
      (number, index) => (
        <option key="index" value={number}>
          {number + 1}
        </option>
      )
    );

    const tableData =
      this.state &&
      this.state.enrolements.map((data, index) => (
        <tr key={index}>
          <td>{this.state.chefMenage}</td>
          <td>{data.site.nom_site}</td>
          <td>{moment.utc(data.dateEntree).format("DD/MM/YYYY HH:mm")}</td>
          <td>
            {data.dateSortie
              ? moment.utc(data.dateSortie).format("DD/MM/YYYY HH:mm")
              : "----------------"}
          </td>
          <td>{data.voina.libelle}</td>
        </tr>
      ));

    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={true}
          size="lg"
          style={{ zIndex: "-100", maxWidth: "1000px", width: "100%" }}
        >
          {/* Page content */}
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="pb-0">
                  <div className="text-muted text-center mt-2 mb-3 ">
                    <h2>{this.state.title}</h2>
                  </div>
                </CardHeader>
                <Table
                  className="align-items-center table-flush table"
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Chef de menage</th>
                      <th scope="col">Nom du site</th>
                      <th scope="col">Date d'entrée</th>
                      <th scope="col">Date de sortie</th>
                      <th scope="col">Voina</th>
                    </tr>
                  </thead>
                  <tbody>{tableData}</tbody>
                </Table>
                <CardFooter className="py-2 footer-pagination-container">
                  <div className="pagination-navigation-container">
                    <Label className="form-control-label">
                      <b>Nombre de lignes</b>
                    </Label>
                    <Input
                      id="pageSize"
                      type="select"
                      width={50}
                      style={{
                        display: "inline-block",
                        width: 80,
                        marginLeft: 10,
                        height: 40,
                      }}
                      value={this.state.pageSize}
                      onChange={this.handlePage}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                      <option value={50}>50</option>
                    </Input>
                  </div>
                  <Label className="form-control-label">
                    {this.state.items + " "}
                    <b>enrôlements sur</b>
                    {" " + this.state.totalItems}
                  </Label>
                  <Label className="form-control-label">
                    {this.state.currentPage + 1} <b>Page sur</b>{" "}
                    {this.state.totalPages}
                  </Label>
                  <div className="pagination-navigation-container">
                    <Label className="form-control-label">
                      <b>Numéro de page</b>
                    </Label>
                    <Input
                      id="currentPage"
                      type="select"
                      width={50}
                      style={{
                        display: "inline-block",
                        width: 80,
                        marginRight: 10,
                        marginLeft: 10,
                        height: 40,
                      }}
                      value={this.state.currentPage}
                      onChange={this.handlePage}
                    >
                      {pageList}
                    </Input>
                    <nav aria-label="...">
                      <Pagination
                        className="pagination pagination-lg justify-content-end mb-0"
                        listClassName="justify-content-end mb-0 pagination-lg"
                      >
                        <PaginationItem className="active">
                          {this.state.currentPage > 0 && (
                            <PaginationLink
                              href="#pablo"
                              onClick={this.previousPage}
                              tabIndex="-1"
                            >
                              <i className="fas fa-angle-left" />
                              <span className="sr-only">Previous</span>
                            </PaginationLink>
                          )}
                        </PaginationItem>
                        <PaginationItem
                          className={
                            this.state.currentPage + 1 < this.state.totalPages
                              ? "active"
                              : "disabled"
                          }
                        >
                          <PaginationLink href="#" onClick={this.nextPage}>
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </nav>
                  </div>
                  <Button type="button" onClick={this.props.onCancel}>
                    Fermer
                  </Button>
                </CardFooter>
              </Card>
            </div>
          </Row>
          <PageSpinner show={this.state.isLoading} />
        </Modal>
      </>
    );
  }
}

export default EnrolementParMenageModal;
