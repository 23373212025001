import FooterTable from 'components/Footer/Footer';
import React from "react";
// reactstrap components
import {
    Card,
    CardHeader,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row,
    Button,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle
} from "reactstrap";
import articleService from 'services/ArticleService';
import mouvementTypeService from 'services/MouvementTypeService';
import partenaireService from 'services/PartenaireService';
import siteMedicalService from "services/SiteService";
import ConfirmationModal from "views/components/ConfirmationModal";
import PageSpinner from "views/components/PageSpinner";
import historiqueService from 'services/HistoriqueService';
import { ENTREE, SORTIE, TRANSFERT } from 'data/constant/data-type';
import NotificationModal from '../components/NotificationModal';


class HistoriqueAddPage extends React.Component {
    state = {
        articleId: '',
        articleList: [],
        siteList: [],
        mouvementTypeList: [],
        partenaireList: [],
        currentPage: 1,
        totalPage: 0,
        pageSize: 10,
        showModal: false,
        confirmationModal: false,
        data: {
            articleDTO: [],
            mouvementType: {},
            partenaire: {},
            siteDestinataire: {},
            siteSource: {}
        },
        articleDTO: [],
        checkSuperior: [],
        checkValue: [],
        isEntryError: false,
        partnerDropDownSelected: '',
        siteDropDownSelected: '',
        mouvementTypeDropDownSelected: '',
        idSiteSource: '-1',
        idSiteDestinataire: '-1',
        idMouvement: '-1',
        idPartenaire: '-1'
    };

    // function for web service
    getListMedicalFromWS = () => {
        this.setState({
            isLoading: true,
        });

        siteMedicalService.getAllSite()
            .then(response => response.data)
            .then(result => {

                const { data } = this.state;
                if (result.data && result.data.length > 0) {
                    data.siteDestinataire = result.data[0];
                    data.siteSource = result.data[0];
                }
                this.setState({
                    siteList: result.data || [],
                    isLoading: false,
                    data,
                    idSiteSource: result.data[0].id,
                    idSiteDestinataire: result.data[0].id,
                    siteSourceDropDownSelected: result.data[0].nom_site,
                    siteDestinataireDropDownSelected: result.data[0].nom_site
                });
            })

            .catch(() => this.setState({
                isLoading: false,
            }))

    };

    getArticleListFromWS = () => {
        this.setState({ isLoading: true });
        articleService.getAllArticles()
            .then(response => response.data)
            .then(result => {
                this.setState({
                    isLoading: false,
                    articleList: result.data || []
                })
            })
            .catch(() => this.setState({ isLoading: false }))

    }

    getArticleListBySiteFromWS = () => {
        const { data, currentPage, pageSize } = this.state;
        if (data.siteSource && data.siteSource.id) {
            this.setState({ isLoading: true });
            articleService.findArticleBySiteId(data.siteSource.id, currentPage - 1, pageSize)
                .then(response => response.data)
                .then(result => {
                    this.dataArticle(result)
                })
                .catch(() => { this.setState({ isLoading: false }) })
        }
    }



    dataArticle = (result) => {
        const { data, checkSuperior } = this.state;
        if (result.data && result.data && result.data.articleDTO && result.data.articleDTO.length > 0) {
            const articleDTOSize = result.data.articleDTO.length;
            data.articleDTO = []
            for (let index = 0; index < articleDTOSize; index++) {
                const element = result.data.articleDTO[index];
                data.articleDTO[index] = element;
                checkSuperior.push({ isSuperior: false })
            }
        } else {
            data.articleDTO = []
        }
        this.setState({
            isLoading: false,
            data,
            totalPage: (result.data && result.data.totalPage) || 0
        })
    }

    getMouvementTypeListFromWS = () => {
        this.setState({ isLoading: true });
        mouvementTypeService.getAllMouvementType()
            .then(response => response.data)
            .then(result => {
                const { data } = this.state;
                if (result.data && result.data.length > 0)
                    data.mouvementType = result.data[0];
                this.setState({
                    isLoading: false,
                    mouvementTypeList: result.data || [],
                    data,
                    idMouvement: data.mouvementType.id ? data.mouvementType.id : '-1',
                    mouvementTypeDropDownSelected: data.mouvementType.id ? data.mouvementType.designation : this.state.mouvementTypeDropDownSelected
                })
            })
            .catch(() => this.setState({ isLoading: false }))

    }

    getPartenaireListFromWS = () => {
        this.setState({ isLoading: true })
        partenaireService.getAllPartenaire()
            .then(response => response.data)
            .then(result => {
                const { data } = this.state;
                if (result.partenaires && result.partenaires.length > 0)
                    data.partenaire = result.partenaires[0];
                this.setState({
                    isLoading: false,
                    partenaireList: result.partenaires || [],
                    data,
                    idPartenaire: data.partenaire.id,
                    partnerDropDownSelected: data.partenaire.designation
                })
            })

            .catch(() => this.setState({ isLoading: false }))
    }

    saveData = () => {
        const { data, articleDTO } = this.state;
        this.setState({ isLoading: true });

        const isEntry = data && data.mouvementType && data.mouvementType.value === ENTREE;
        if (isEntry) {
            const indexes = this.getAllEmptyArticleIndexes(articleDTO);
            const isNonValidArticles = articleDTO.length === indexes.length;

            if (isNonValidArticles) {
                this.setState({
                    isEntryError: true,
                    errorMessage: 'Pas d\'article selectionné',
                    errorTitle: 'Ajout de mouvements',
                    isLoading: false
                });
                return;
            } else {
                indexes.forEach(index => {
                    articleDTO.splice(index, 1);
                });
                data.articleDTO = articleDTO;
            }
        }
        historiqueService.addHistorique(data)
            .then(response => response.data)
            .then(() => {
                this.setState({ isLoading: false })
                this.props.history.push({
                    pathname: '/admin/listMouvement',
                })
            })
            .catch(() => this.setState({ isLoading: false }))
    }

    getAllEmptyArticleIndexes = (arr) => {
        let indexes = [], i = -1;
        for (i = 0; i < arr.length; i++) {
            const id = arr[i].article && arr[i].article.articleId ? true : false;
            const qt = !arr[i].quantity || arr[i].quantity === "0" ? true : false;
            if (!id || qt) {
                indexes.push(i);
            }
        }
        return indexes;
    }

    // function for data change
    componentDidMount() {

        this.getArticleListFromWS();
        this.getPartenaireListFromWS();
        this.getMouvementTypeListFromWS();
        this.getListMedicalFromWS();
    }

    handleSelectChange = (index, value) => {
        const { data, siteList, partenaireList, mouvementTypeList } = this.state;
        switch (index) {
            case 1:
                const siteSourceSelected = siteList && siteList.filter(site => site.id === parseInt(value));
                data.siteSource = siteSourceSelected.length > 0 ? siteSourceSelected[0] : {};
                break;
            case 2:
                const siteDestinataireSelected = siteList && siteList.filter(site => site.id === parseInt(value));
                data.siteDestinataire = siteDestinataireSelected.length > 0 ? siteDestinataireSelected[0] : {};
                break;
            case 3:
                const mouvementTypeSelected = mouvementTypeList && mouvementTypeList.filter(mouvementType => mouvementType.id === parseInt(value));
                data.mouvementType = mouvementTypeSelected.length > 0 ? mouvementTypeSelected[0] : {};
                break;
            case 4:
                const partenaireSelected = partenaireList && partenaireList.filter(partenaire => partenaire.id === parseInt(value));
                data.partenaire = partenaireSelected.length > 0 ? partenaireSelected[0] : {};
                break;

            default:
                break;
        }

        const isExit = data && data.mouvementType && data.mouvementType.value === SORTIE;
        const isTransfert = data && data.mouvementType && data.mouvementType.value === TRANSFERT;

        if (isExit || isTransfert) {
            this.setState({
                data,
                idSiteSource: index === 1 ? value : this.state.idSiteSource,
                siteSourceDropDownSelected: index === 1 ? this.state.siteList && this.state.siteList.filter(site => site.id === value)[0] ? this.state.siteList.filter(site => site.id === value)[0].nom_site : this.state.siteList[0].nom_site : this.state.siteSourceDropDownSelected,
                idSiteDestinataire: index === 2 ? value : this.state.idSiteDestinataire,
                siteDestinataireDropDownSelected: index === 2 ? this.state.siteList && this.state.siteList.filter(site => site.id === value)[0] ? this.state.siteList.filter(site => site.id === value)[0].nom_site : this.state.siteList[0].nom_site : this.state.siteDestinataireDropDownSelected,
                idMouvement: index === 3 ? value : this.state.idMouvement,
                mouvementTypeDropDownSelected: index === 3 ? this.state.mouvementTypeList && this.state.mouvementTypeList.filter(mouvement => mouvement.id === value)[0] ? this.state.mouvementTypeList.filter(mouvement => mouvement.id === value)[0].designation : this.state.mouvementTypeList[0].designation : this.state.mouvementTypeDropDownSelected,
                idPartenaire: index === 4 ? value : this.state.idPartenaire,
                partnerDropDownSelected: index === 4 ? this.state.partenaireList && this.state.partenaireList.filter(partner => partner.id === value)[0] ? this.state.partenaireList.filter(partner => partner.id === value)[0].designation : this.state.partenaireList[0].designation : this.state.partnerDropDownSelected
            }, () => this.getArticleListBySiteFromWS())
        }
        else {
            this.setState({
                data,
                idSiteSource: index === 1 ? value : this.state.idSiteSource,
                siteSourceDropDownSelected: index === 1 ? this.state.siteList && this.state.siteList.filter(site => site.id === value)[0] ? this.state.siteList.filter(site => site.id === value)[0].nom_site : this.state.siteList[0].nom_site : this.state.siteSourceDropDownSelected,
                idSiteDestinataire: index === 2 ? value : this.state.idSiteDestinataire,
                siteDestinataireDropDownSelected: index === 2 ? this.state.siteList && this.state.siteList.filter(site => site.id === value)[0] ? this.state.siteList.filter(site => site.id === value)[0].nom_site : this.state.siteList[0].nom_site : this.state.siteDestinataireDropDownSelected,
                idMouvement: index === 3 ? value : this.state.idMouvement,
                mouvementTypeDropDownSelected: index === 3 ? this.state.mouvementTypeList && this.state.mouvementTypeList.filter(mouvement => mouvement.id === value)[0] ? this.state.mouvementTypeList.filter(mouvement => mouvement.id === value)[0].designation : this.state.mouvementTypeList[0].designation : this.state.mouvementTypeDropDownSelected,
                idPartenaire: index === 4 ? value : this.state.idPartenaire,
                partnerDropDownSelected: index === 4 ? this.state.partenaireList && this.state.partenaireList.filter(partner => partner.id === value)[0] ? this.state.partenaireList.filter(partner => partner.id === value)[0].designation : this.state.partenaireList[0].designation : this.state.partnerDropDownSelected
            }, () => this.getArticleListFromWS());
        }

    }

    handleQuantityChange = (event, index) => {
        const { articleDTO } = this.state;
        const eventValue = event.target.value && event.target.value < 0 ? "0" : event.target.value;

        articleDTO[index].quantity = parseFloat(eventValue);
        this.setState({ articleDTO })
    }

    handleNumeric = (event, index) => {
        const { articleDTO } = this.state;
        const eventValue = !!event.target.value;
        if (eventValue === false && articleDTO[index]) {
            articleDTO[index].quantity = "";
            this.setState({ articleDTO })
        }
    }

    handleQuantityData = (event, index) => {
        const { data, checkSuperior } = this.state;
        let eventValue = event.target.value ? event.target.value < 0 ? "0" : event.target.value : "0";
        data.articleDTO[index].quantity = parseFloat(eventValue) > data.articleDTO[index].quantityPlanned ? data.articleDTO[index].quantityPlanned : parseFloat(eventValue);
        checkSuperior[index].isSuperior = data.articleDTO[index].quantity > data.articleDTO[index].quantityPlanned;
        this.setState({ data, checkSuperior })
    }

    handlePage = (event) => {
        this.setState({
            currentPage: parseInt(event.target.value)
        },
            () => this.getArticleListBySiteFromWS());
    }

    handleChangePage = (event, index) => {
        event.preventDefault();

        if (index > 0 && index <= this.getTotalPage())
            this.setState({
                currentPage: index
            }, () => this.getArticleListBySiteFromWS())
    }

    //function to insert into render
    getArticleList = () => {
        const { articleList } = this.state;
        return articleList && articleList.map((article, index) =>
            <option key={index} value={article.id}> {article.libelle}</option>
        );
    }

    getSiteList = (index) => {
        const { siteList } = this.state;
        if (index === 1) {
            return siteList && siteList.map((site, index) =>
                <DropdownItem key={index}
                    onClick={() => this.handleSelectChange(1, site.id)}>
                    {site.nom_site}
                </DropdownItem>
            );
        } else {
            return siteList && siteList.map((site, index) =>
                <DropdownItem key={index}
                    onClick={() => this.handleSelectChange(2, site.id)}>
                    {site.nom_site}
                </DropdownItem>
            );
        }
    }

    getPartenaireList = () => {
        const { partenaireList } = this.state;
        return partenaireList && partenaireList.map((partenaire, index) =>
            <DropdownItem key={index}
                onClick={() => this.handleSelectChange(4, partenaire.id)}>
                {partenaire.designation}
            </DropdownItem>
        );
    }

    getMouvementTypeList = () => {
        const { mouvementTypeList } = this.state;
        return mouvementTypeList && mouvementTypeList.map((mouvementType, index) =>
            <DropdownItem key={index}
                onClick={() => this.handleSelectChange(3, mouvementType.id)}>
                {mouvementType.designation}
            </DropdownItem>
        );
    }

    handleArticleChange = (event, index) => {
        const eventValue = event.target.value;
        const { articleDTO, articleList } = this.state;
        const articleOutput = articleList && articleList.filter(item => item.id === parseInt(eventValue));
        articleDTO[index].article = articleOutput && articleOutput.length > 0 ? articleOutput[0] : {};
        this.setState({ articleDTO });
    }

    getAddListArticle = () => {
        const { articleDTO } = this.state;
        return articleDTO && articleDTO.length > 0 && articleDTO.map((article, index) =>
            <tr key={index}>
                <td>
                    <Input
                        type="select"
                        name="category"
                        id="articleId"
                        value={(article.article && article.article.id) || -1}
                        onChange={(event) => this.handleArticleChange(event, index, article)}
                    >
                        <option value={-1}></option>
                        {this.getArticleList()}
                    </Input>
                </td>
                <td>
                    <Input
                        className="form-control-alternative"
                        id="quantity"
                        placeholder="Quantité"
                        type="number"
                        name="quantity"
                        value={(article.quantity)}
                        onChange={(event) => this.handleQuantityChange(event, index)}
                        onInput={(event) => this.handleNumeric(event, index)}
                    />
                </td>
            </tr>
        )
    }

    getArticleListBySite = () => {
        const { articleDTO } = this.state.data;
        const { checkSuperior } = this.state;
        return articleDTO && articleDTO.length > 0 && articleDTO.map((article, index) => {
            const availableQuantity = article.quantityPlanned;

            return (<tr key={index}>
                <td>{(article.article && article.article.libelle) || ''}</td>
                <td>{availableQuantity || 0}</td>
                <td>
                    <Input
                        className="form-control-alternative"
                        id="quantity"
                        placeholder="Quantité"
                        type="number"
                        name="quantity"
                        value={(availableQuantity >= article.quantity ? article.quantity : availableQuantity)}
                        onChange={(event) => this.handleQuantityData(event, index)}
                        onInput={(event) => this.handleNumeric(event, index)}
                    />
                    {
                        (checkSuperior && checkSuperior[index] && checkSuperior[index].isSuperior) && (<Label style={{ color: 'red' }}>Quantité à transférer supérieur à quantité disponible</Label>)
                    }
                </td>
            </tr>
            )
        }
        )
    }

    addNewArticle = () => {
        const { articleDTO } = this.state;
        articleDTO.push({
            article: {},
            quantity: 0,
            quantityPlanned: 0
        })
        this.setState({ articleDTO });
    }

    getTotalPage = () => {
        const { totalPage } = this.state;
        return totalPage;
    }

    checkSuperior = () => {
        const { checkSuperior } = false;
        return checkSuperior && checkSuperior.filter(item => item.isSuperior) > 0;
    }

    closeErrorNotification = () => {
        this.setState({
            isEntryError: false
        })
    }

    render() {
        const roleName = localStorage.getItem("roleName");
        if(roleName!=='superadmin'){
          this.props.history.push("/admin/gestion-menage");
        }
        const {
            currentPage,
            isLoading,
            data,
            pageSize
        } = this.state;

        const modifiersDropDown = {
            setMaxHeight: {
                enabled: true,
                order: 890,
                fn: (data) => {
                    return {
                        ...data,
                        styles: {
                            ...data.styles,
                            overflow: 'auto',
                            maxHeight: '190px'
                        },
                    };
                },
            }
        };

        const isEntry = data && data.mouvementType && data.mouvementType.value === ENTREE;
        const isExit = data && data.mouvementType && data.mouvementType.value === SORTIE;
        const isTransfert = data && data.mouvementType && data.mouvementType.value === TRANSFERT;
        return (
            <>

                {this.state.confirmationModal && (
                    <ConfirmationModal
                        onCancel={this.cancelConfirmation}
                        onSave={this.saveConfirmation}
                    />
                )}
                {
                    this.state.isEntryError && (
                        <NotificationModal
                            show={this.state.isEntryError}
                            message={this.state.errorMessage}
                            title={this.state.errorTitle}
                            close={this.closeErrorNotification}
                        />
                    )
                }
                <div className="header bg-gradient-primary postition-absolute">
                    <Container fluid>
                        <div className="header-body">
                            <Row>
                                <Col></Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                {/* Page content */}
                <Container className="mt-5 custom-card-container" fluid >
                    {/* Table */}
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row>
                                        <Col lg="6">
                                            <Row>
                                                <Col className="d-flex align-items-end justify-content-start flex-wrap">
                                                    <FormGroup className="mr-3">
                                                        <Label for="mouvementType">Type de mouvement</Label><br />
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle caret>
                                                                {this.state.mouvementTypeDropDownSelected}
                                                            </DropdownToggle>
                                                            <DropdownMenu className="dropdown-menu-arrow" container="body" positionFixed={true}  down='true'
                                                                modifiers={{
                                                                    ...modifiersDropDown
                                                                }}
                                                            >
                                                                {this.getMouvementTypeList()}
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </FormGroup>
                                                {
                                                    (isExit || isTransfert) && (
                                                        <FormGroup className="mr-3">
                                                            <Label for="category">Site Source</Label><br />
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle caret>
                                                                    {this.state.siteSourceDropDownSelected}
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu-arrow" container="body" positionFixed={true}  down='true'
                                                                    modifiers={{
                                                                        ...modifiersDropDown
                                                                    }}
                                                                >
                                                                    {this.getSiteList(1)}
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </FormGroup>
                                                    )
                                                }

                                                {
                                                    (isEntry || isTransfert) && (
                                                        <FormGroup className="mr-3">
                                                            <Label for="siteDestinataire">Site Destinataire</Label><br />
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle caret>
                                                                    {this.state.siteDestinataireDropDownSelected}
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu-arrow" container="body" positionFixed={true}  down='true'
                                                                    modifiers={{
                                                                        ...modifiersDropDown
                                                                    }}
                                                                >
                                                                    {this.getSiteList(2)}
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </FormGroup>
                                                    )
                                                }
                                                                                                {
                                                    isEntry && (                                                        
                                                        <FormGroup className="mr-3">
                                                            <Label for="category">Partenaire</Label><br />
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle caret>
                                                                    {this.state.partnerDropDownSelected}
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu-arrow" container="body" positionFixed={true}  down='true'
                                                                    modifiers={{
                                                                        ...modifiersDropDown
                                                                    }}
                                                                >
                                                                    {this.getPartenaireList()}
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </FormGroup>
                                                    )
                                                }
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col className="d-flex justify-content-end pt-3 align-items-end">

                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                {isEntry && <Button color="primary" onClick={this.addNewArticle}>Ajouter un nouvel article</Button>}
                                                <Button color="primary" onClick={this.saveData}>Enregistrer le mouvement</Button>
                                            </div>

                                        </Col>
                                    </Row>

                                </CardHeader>
                                {isEntry && (
                                    <table
                                        className="align-items-center table table-bordered table-flush table"
                                        responsive="true"
                                        style={{ height: '100%' }}
                                    >
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Articles</th>
                                                <th scope="col">Quantité</th>
                                            </tr>
                                        </thead>
                                        <tbody>{this.getAddListArticle()}</tbody>
                                    </table>
                                )}

                                {(isTransfert || isExit) &&
                                    (
                                        <table
                                            className="align-items-center table table-bordered table-flush table"
                                            responsive="true"
                                            style={{ height: '100%' }}
                                        >
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">Articles</th>
                                                    <th scope="col">Quantité disponible</th>
                                                    <th scope="col">Quantité à transférer</th>
                                                </tr>
                                            </thead>
                                            <tbody>{this.getArticleListBySite()}</tbody>
                                        </table>
                                    )
                                }

                                <FooterTable
                                    currentPage={currentPage}
                                    handlePage={this.handlePage}
                                    handleChangePage={this.handleChangePage}
                                    getTotalPage={this.getTotalPage}
                                />
                            </Card>
                        </div>
                    </Row>
                    <PageSpinner show={isLoading} />

                </Container>
            </>
        );
    }
}


export default HistoriqueAddPage;
