import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
const PartenaireModal = (props) => {
    const save = () => {
        if (props.selectedUnity && props.selectedUnity.id && props.selectedUnity.id > 0) {
            props.update();
            props.toggle();
        } else {
            props.save();
            props.toggle();
        }

    }
    return (
        <Modal isOpen={props.showModal} toggle={props.toggle} className='modal-check' fade={true}>
            <ModalHeader toggle={props.toggle}>Ajout de nouveau partenaire</ModalHeader>
            <ModalBody style={{ backgroundColor: 'transparent' }}>
                <FormGroup>
                   
                    <Label for="partenaire">Nom du partenaire</Label>
                    <Input
                        type="text"
                        name="partenaire"
                        id="partenaire"
                        placeholder="Nom de partenaire"
                        value={(props.selectedPartenaire && props.selectedPartenaire.designation) || ''}
                        onChange={props.handleChange}
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={save}>Enregistrer</Button>{' '}
                <Button color="secondary" onClick={props.toggle}>Annuler</Button>
            </ModalFooter>
        </Modal >
    )
}

export default PartenaireModal;

