import axios from 'axios';
import { BACK_URL } from 'data/constant/urls';
import authHeader from './AuthHeader';

// const API_URL = process.env.REACT_APP_URL;
const API_URL = BACK_URL;

class SuiviService {

  addSuivi(data) {
    return axios.post(API_URL + "api/suivi/addsuivi", data, {
      headers: authHeader(),
    });
  }

  getSuiviByTraitementId(id) {
    return axios.get(API_URL + "api/suivi/findsuiviByTraitement?id=" + id, {
      headers: authHeader(),
    });
  }
  closeFiche(id, bool) {
    return axios.get(API_URL + `api/suivi/cloturerFicheSuivi?id=${id}&cloture=${bool}`, {
      headers: authHeader(),
    });
  }

  lastFiche(individuId) {
    return new Promise(async (success, error) => {
      const result = await axios.get(API_URL + `api/suivi/lastSuivi?individuId=${individuId}`, {
        headers: authHeader(),
      }).catch((e) => error(e));
      success(result);
    });
  }
}

export default new SuiviService();