import axios from 'axios';
import { BACK_URL } from 'data/constant/urls';
import authHeader from './AuthHeader';

// const API_URL = process.env.REACT_APP_URL;
const API_URL = BACK_URL;

class UtilisateurService {

  getListUtilisateurs(nom='',pageNo=0, pageSize=10) {
    return axios.get(API_URL + `api/user/findAllPagination?nom=${nom}&pageNo=${pageNo}&pageSize=${pageSize}`, {
      headers: authHeader(),
    });
  }
  get(id) {
    return axios.get(API_URL + `api/user/findUserById?id=${id}`, {
      headers: authHeader(),
    });
  }

  delete(id) {
    return axios.delete(API_URL + `api/user/deleteUser?id=${id}`, {
      headers: authHeader(),
    });
  }

  update(data) {
    return axios.put(`${API_URL}api/user/updateUser`, data, {
      headers: authHeader(),
    });
  }

  post(data) {
    return axios.post(`${API_URL}api/user/adduser`, data, {
      headers: authHeader(),
    });
  }

  /** change user password */
  adminChangePassword(data) {
    return axios.post(`${API_URL}adminChangePassword`, data, {
      headers: authHeader(),
    });
  }
  
}

export default new UtilisateurService();