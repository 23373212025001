import FooterTable from "components/Footer/Footer";
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import categoryService from "services/CategoryService";
import unityService from "services/UnityService";
import PartenaireService from "services/PartenaireService";
import CategoryModal from "views/components/CategoryModal";
import PageSpinner from "views/components/PageSpinner";
import UnityModal from "views/components/UnityModal";
import ConfirmationModal from "views/components/ConfirmationModal";
import PartenaireModal from "views/components/PartenaireModal";
import TypeSiteService from "services/TypeSiteService";
import TypeSiteModal from "views/components/TypeSiteModal";
import VoinaService from "services/VoinaService";
import VoinaModal from "views/components/VoinaModal";
import TypeDonService from "services/TypeDonService";
import TypeDonModal from "views/components/TypeDonModal";
import Articles from "views/pages/ArticlesPage";

class ArticlesPage extends React.Component {
  state = {
    articleId: "",
    unityList: [],
    categoryList: [],
    partenaireList: [],
    selectedUnity: {},
    selectedCategory: {},
    selectedPartenaire: {},
    currentPage: 0,
    totalArticle: 0,
    pageSize: 10,
    showCategoryModal: false,
    showUnityModal: false,
    showPartenaireModal: false,
    showConfirmationModal: false,
    fieldToDelete: "",
    voinaList: [],
    selectedVoina: {},
    showVoinaModal: false,
    typesiteList: [],
    selectedTypeSite: {},
    showTypeSiteModal: false,
    typedonList: [],
    selectedTypeDon: {},
    showTypeDonModal: false,
    isDefault: false,
    defaultVoina: {
      id: -1,
    },
  };

  componentDidMount() {
    this.getCategoryListFromWs();
    this.getUnityListFromWS();
    this.getPartenaireListFromWS();
    this.getVoinaListFromWS();
    this.getDefaultVoinaFromWS();
    this.getTypeSiteListFromWS();
    this.getTypeDonListFromWS();
  }

  getCategoryListFromWs = () => {
    this.setState({ isLoading: true });
    categoryService
      .getAllCategories()
      .then((response) => response.data)
      .then((result) => {
        this.setState({
          isLoading: false,
          categoryList: result.data || [],
        });
      })
      .catch(() => this.setState({ isLoading: false, categoryList: [] }));
  };

  getUnityListFromWS = () => {
    this.setState({ isLoading: true });
    unityService
      .getAllUnities()
      .then((response) => response.data)
      .then((result) => {
        this.setState({
          isLoading: false,
          unityList: result.data || [],
        });
      })
      .catch(() => this.setState({ isLoading: false, unityList: [] }));
  };
  getPartenaireListFromWS = () => {
    this.setState({ isLoading: true });
    PartenaireService.getAllPartenaire()
      .then((response) => response.data)
      .then((result) => {
        this.setState({
          isLoading: false,
          partenaireList: result.partenaires || [],
        });
      })
      .catch(() => this.setState({ isLoading: false, partenaireList: [] }));
  };
  getVoinaListFromWS = () => {
    this.setState({ isLoading: true });
    VoinaService.getAllVoina()
      .then((result) => {
        this.setState({
          isLoading: false,
          voinaList: result.data.data || [],
        });
      })
      .catch(() => this.setState({ isLoading: false, voinaList: [] }));
  };
  getDefaultVoinaFromWS = () => {
    this.setState({ isLoading: true });
    VoinaService.getDefaultVoina()
      .then((result) => {
        this.setState({
          isLoading: false,
          defaultVoina: result.data.data || { id: -1 },
        });
      })
      .catch(() =>
        this.setState({ isLoading: false, defaultVoina: { id: -1 } })
      );
  };
  getTypeSiteListFromWS = () => {
    this.setState({ isLoading: true });
    TypeSiteService.getAllTypeSite()
      .then((result) => {
        this.setState({
          isLoading: false,
          typesiteList: result.data.data || [],
        });
      })
      .catch(() => this.setState({ isLoading: false, typesiteList: [] }));
  };
  getTypeDonListFromWS = () => {
    this.setState({ isLoading: true });
    TypeDonService.getAllTypeDon()
      .then((result) => {
        this.setState({
          isLoading: false,
          typedonList: result.data.data || [],
        });
      })
      .catch(() => this.setState({ isLoading: false, typedonList: [] }));
  };

  handleChange = (evt) => {
    switch (evt.target.name) {
      case "category":
        const { selectedCategory } = this.state;
        selectedCategory.libelle = evt.target.value;
        this.setState({ selectedCategory });
        break;
      case "unity":
        const { selectedUnity } = this.state;
        selectedUnity.libelle = evt.target.value;
        this.setState({ selectedUnity });
        break;
      case "partenaire":
        const { selectedPartenaire } = this.state;
        selectedPartenaire.designation = evt.target.value;
        this.setState({ selectedPartenaire });
        break;
      case "voina":
        const { selectedVoina } = this.state;
        selectedVoina.libelle = evt.target.value;
        this.setState({ selectedVoina });
        break;
      case "typesite":
        const { selectedTypeSite } = this.state;
        selectedTypeSite.name = evt.target.value;
        this.setState({ selectedTypeSite });
        break;
      case "typedon":
        const { selectedTypeDon } = this.state;
        selectedTypeDon.name = evt.target.value;
        this.setState({ selectedTypeDon });
        break;
      default:
        break;
    }
  };

  handleChangeDate = (event, type) => {
    const { selectedVoina } = this.state;
    if (type === "dateDebut") {
      if (event.isValid) {
        selectedVoina.dateDebut = event.format("YYYY-MM-DDTHH:mm:ssZ");
        console.log(selectedVoina.dateDebut);
      }
    } else if (type === "dateFin") {
      if (event.isValid) {
        selectedVoina.dateFin = event.format("YYYY-MM-DDTHH:mm:ssZ");
        console.log(selectedVoina.dateFin);
      }
    }
  };

  onUpdate = (item, field) => {
    switch (field) {
      case "category":
        this.setState({ selectedCategory: item, showCategoryModal: true });
        break;
      case "unity":
        this.setState({ selectedUnity: item, showUnityModal: true });
        break;
      case "partenaire":
        this.setState({ selectedPartenaire: item, showPartenaireModal: true });
        break;
      case "voina":
        this.setState({ selectedVoina: item, showVoinaModal: true });
        break;
      case "typesite":
        this.setState({ selectedTypeSite: item, showTypeSiteModal: true });
        break;
      case "typedon":
        this.setState({ selectedTypeDon: item, showTypeDonModal: true });
        break;
      default:
        break;
    }
  };

  onDelete = (item, field) => {
    switch (field) {
      case "category":
        this.setState({
          selectedCategory: item,
          showConfirmationModal: true,
          fieldToDelete: "category",
        });
        break;
      case "unity":
        this.setState({
          selectedUnity: item,
          showConfirmationModal: true,
          fieldToDelete: "unity",
        });
        break;
      case "partenaire":
        this.setState({
          selectedPartenaire: item,
          showConfirmationModal: true,
          fieldToDelete: "partenaire",
        });
        break;
      case "voina":
        this.setState({
          selectedVoina: item,
          showConfirmationModal: true,
          fieldToDelete: "voina",
        });
        break;
      case "typesite":
        this.setState({
          selectedTypeSite: item,
          showConfirmationModal: true,
          fieldToDelete: "typesite",
        });
        break;
      case "typedon":
        this.setState({
          selectedTypeDon: item,
          showConfirmationModal: true,
          fieldToDelete: "typedon",
        });
        break;
      default:
        break;
    }
  };

  delete = () => {
    const { fieldToDelete } = this.state;
    this.setState({ isLoading: true });
    switch (fieldToDelete) {
      case "category":
        const { selectedCategory } = this.state;
        categoryService
          .deleteCategory(selectedCategory.id)
          .then((response) => response.data)
          .then(() => this.getCategoryListFromWs())
          .catch(() => this.setState({ isLoading: false }));
        break;

      case "unity":
        const { selectedUnity } = this.state;
        unityService
          .deleteUnity(selectedUnity.id)
          .then((response) => response.data)
          .then((result) => this.getUnityListFromWS())
          .catch(() => this.setState({ isLoading: false }));
        break;
      case "partenaire":
        const { selectedPartenaire } = this.state;
        PartenaireService.deletePartenaire(selectedPartenaire.id)
          .then((response) => response.data)
          .then((result) => this.getPartenaireListFromWS())
          .catch(() => this.setState({ isLoading: false }));
        break;
      case "voina":
        const { selectedVoina } = this.state;
        VoinaService.deleteVoina(selectedVoina.id)
          .then((response) => response.data)
          .then((result) => this.getVoinaListFromWS())
          .catch(() => this.setState({ isLoading: false }));
        break;
      case "typesite":
        const { selectedTypeSite } = this.state;
        TypeSiteService.deleteTypeSite(selectedTypeSite.id)
          .then((response) => response.data)
          .then((result) => this.getTypeSiteListFromWS())
          .catch(() => this.setState({ isLoading: false }));
        break;
      case "typedon":
        const { selectedTypeDon } = this.state;
        console.log("selectedTypeDon: ", selectedTypeDon);
        TypeDonService.deleteTypeDon(selectedTypeDon.id)
          .then((response) => response.data)
          .then((result) => this.getTypeDonListFromWS())
          .catch(() => this.setState({ isLoading: false }));
        break;
      default:
        break;
    }
    this.toggleConfirmation();
  };

  update = (field) => {
    switch (field) {
      case "category":
        const { selectedCategory } = this.state;
        categoryService
          .updateCategory(selectedCategory)
          .then((response) => response.data)
          .then((result) => {
            this.getCategoryListFromWs();
          })
          .catch(() => this.setState({ isLoading: false }));

        break;
      case "unity":
        const { selectedUnity } = this.state;
        unityService
          .updateUnity(selectedUnity)
          .then((response) => response.data)
          .then(() => this.getUnityListFromWS())
          .catch(() => this.setState({ isLoading: false }));
        break;
      case "partenaire":
        const { selectedPartenaire } = this.state;
        PartenaireService.updatePartenaire(selectedPartenaire)
          .then((response) => response.partenaires)
          .then(() => this.getPartenaireListFromWS())
          .catch(() => this.setState({ isLoading: false }));
        break;
      case "voina":
        const { selectedVoina } = this.state;
        VoinaService.updateVoina(selectedVoina)
          .then((response) => response.voinas)
          .then(() => this.getVoinaListFromWS())
          .catch(() => this.setState({ isLoading: false }));
        break;
      case "typesite":
        const { selectedTypeSite } = this.state;
        TypeSiteService.updateTypeSite(selectedTypeSite)
          .then((response) => response.typesites)
          .then(() => this.getTypeSiteListFromWS())
          .catch(() => this.setState({ isLoading: false }));
        break;
      case "typedon":
        const { selectedTypeDon } = this.state;
        TypeDonService.updateTypeDon(selectedTypeDon)
          .then((response) => response.typedons)
          .then(() => this.getTypeDonListFromWS())
          .catch(() => this.setState({ isLoading: false }));
        break;
      default:
        break;
    }
  };

  save = (field) => {
    switch (field) {
      case "category":
        const { selectedCategory } = this.state;
        categoryService
          .createCategory(selectedCategory)
          .then((response) => response.data)
          .then((result) => {
            this.getCategoryListFromWs();
          })
          .catch(() => this.setState({ isLoading: false }));

        break;
      case "unity":
        const { selectedUnity } = this.state;
        unityService
          .createUnity(selectedUnity)
          .then((response) => response.data)
          .then(() => this.getUnityListFromWS())
          .catch(() => this.setState({ isLoading: false }));
        break;
      case "partenaire":
        const { selectedPartenaire } = this.state;
        PartenaireService.createPartenaire(selectedPartenaire)
          .then((response) => response.partenaires)
          .then(() => this.getPartenaireListFromWS())
          .catch(() => this.setState({ isLoading: false }));
        break;
      case "voina":
        const { selectedVoina } = this.state;
        VoinaService.createVoina(selectedVoina)
          .then((response) => response.voinas)
          .then(() => this.getVoinaListFromWS())
          .catch(() => this.setState({ isLoading: false }));
        break;
      case "typesite":
        const { selectedTypeSite } = this.state;
        TypeSiteService.createTypeSite(selectedTypeSite)
          .then((response) => response.typesites)
          .then(() => this.getTypeSiteListFromWS())
          .catch(() => this.setState({ isLoading: false }));
        break;
      case "typedon":
        const { selectedTypeDon } = this.state;
        TypeDonService.createTypeDon(selectedTypeDon)
          .then((response) => response.typedons)
          .then(() => this.getTypeDonListFromWS())
          .catch(() => this.setState({ isLoading: false }));
        break;
      default:
        break;
    }
  };

  handleCheckBoxInitialisation = (item, type) => {
    if (type === "voina") {
      if (item.id == this.state.defaultVoina?.id) {
        return (
          <td>
            <input
              className=""
              id="customCheckbyVoinaDefault"
              type="checkbox"
              name="byVoinaDefault"
              checked={true}
              onChange={() => this.handleCheckbox(item)}
            />
          </td>
        );
      } else
        return (
          <td>
            <input
              className=""
              id="customCheck5"
              type="checkbox"
              name="byVoinaDefault"
              checked={false}
              onChange={() => this.handleCheckbox(item)}
            />
          </td>
        );
    }
  };
  handleCheckbox = (item) => {
    this.setState({
      isLoading: true,
    });
    if (this.state.defaultVoina.id == -1) {
      VoinaService.setDefaultVoina(item.id)
        .then((result) => {
          this.getDefaultVoinaFromWS();
        })
        .catch(() => this.setState({ isLoading: false }));
    }
    VoinaService.updateDefaultVoina(item.id)
      .then((result) => {
        this.getDefaultVoinaFromWS();
      })
      .catch(() => this.setState({ isLoading: false }));
  };

  getDataList = (data, field, type) => {
    let displayField = "";
    if (type === "category") displayField = "catégorie";
    else if (type === "unity") displayField = "unité";
    else if (type === "partenaire") displayField = "partenaire";
    else if (type === "voina") displayField = "voina";
    else if (type === "typesite") displayField = "typesite";
    else displayField = "typedon";

    return (
      data &&
      data.map((item, index) => (
        <tr key={index} style={{ textAlign: "center" }}>
          {this.handleCheckBoxInitialisation(item, type)}
          <td>{item[field]}</td>
          <td style={{ textAlign: "center" }}>
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-icon-only text-light"
                href="#"
                role="button"
                size="sm"
                color=""
                outline={true}
                style={{ zIndex: 1 }}
                onClick={(e) => e.preventDefault()}
              >
                <i className="fas fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" container="body" positionFixed={true}  right>
                <DropdownItem
                  href="#"
                  className="clickable"
                  onClick={() => {
                    this.onUpdate(item, type);
                  }}
                >
                  Modifier {displayField}
                </DropdownItem>
                <DropdownItem
                  href="#"
                  className="clickable"
                  onClick={() => {
                    this.onDelete(item, type);
                  }}
                >
                  Supprimer {displayField}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>
      ))
    );
  };

  toggle = (value) => {
    if (value === "UNITY")
      this.setState({
        showUnityModal: !this.state.showUnityModal,
        selectedUnity: {},
      });
    if (value === "CATEGORY")
      this.setState({
        showCategoryModal: !this.state.showCategoryModal,
        selectedCategory: {},
      });
    if (value === "PARTENAIRE")
      this.setState({
        showPartenaireModal: !this.state.showPartenaireModal,
        selectedPartenaire: {},
      });
    if (value === "VOINA")
      this.setState({
        showVoinaModal: !this.state.showVoinaModal,
        selectedVoina: {},
      });
    if (value === "TYPESITE")
      this.setState({
        showTypeSiteModal: !this.state.showTypeSiteModal,
        selectedTypeSite: {},
      });
    if (value === "TYPEDON")
      this.setState({
        showTypeDonModal: !this.state.showTypeDonModal,
        selectedTypeDon: {},
      });
  };

  toggleConfirmation = () => {
    const { fieldToDelete } = this.state;
    if (fieldToDelete === "unity")
      this.setState((previousState) => ({
        showConfirmationModal: !previousState.showConfirmationModal,
        selectedUnity: {},
      }));
    if (fieldToDelete === "category")
      this.setState((previousState) => ({
        showConfirmationModal: !previousState.showConfirmationModal,
        selectedCategory: {},
      }));
    if (fieldToDelete === "partenaire")
      this.setState((previousState) => ({
        showConfirmationModal: !previousState.showConfirmationModal,
        selectedPartenaire: {},
      }));
    if (fieldToDelete === "voina")
      this.setState((previousState) => ({
        showConfirmationModal: !previousState.showConfirmationModal,
        selectedVoina: {},
      }));
    if (fieldToDelete === "typesite")
      this.setState((previousState) => ({
        showConfirmationModal: !previousState.showConfirmationModal,
        selectedTypeSite: {},
      }));
    if (fieldToDelete === "typedon")
      this.setState((previousState) => ({
        showConfirmationModal: !previousState.showConfirmationModal,
        selectedTypeDon: {},
      }));
  };

  handleChangePage = (event, index) => {
    event.preventDefault();
    if (index > 0 && index < this.getTotalPage())
      this.setState(
        {
          currentPage: index,
        },
        () => this.getArticleList()
      );
  };

  handlePage = (event) => {
    this.setState({ [event.target.id]: parseInt(event.target.value) }, () =>
      this.getArticleList()
    );
  };

  getTotalPage = () => {
    const { totalArticle, pageSize } = this.state;
    return Math.ceil(totalArticle / pageSize);
  };

  render() {
    const roleName = localStorage.getItem("roleName");
    if (roleName !== "superadmin" && roleName !== "adminadd") {
      this.props.history.push("/admin/accueil");
    }
    const {
      currentPage,
      isLoading,
      showCategoryModal,
      showUnityModal,
      showPartenaireModal,
      categoryList,
      unityList,
      partenaireList,
      selectedCategory,
      selectedUnity,
      selectedPartenaire,
      showConfirmationModal,
      showVoinaModal,
      voinaList,
      selectedVoina,
      showTypeSiteModal,
      typesiteList,
      selectedTypeSite,
      showTypeDonModal,
      typedonList,
      selectedTypeDon,
    } = this.state;
    return (
      <>
        <div className="header bg-gradient-primary postition-absolute">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row></Row>
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt-5 custom-card-container" fluid>
          <Row >
            <Col lg="6">
              {/* Table */}
              <Row>
                <div className="col mb-4">
                  <Card className="shadow" style={{ height: 500 }}>
                    <CardHeader className="border-0">
                      <Row></Row>
                    </CardHeader>
                    <div style={{ overflow: "auto" }}>
                      <table
                        className="table table-bordered align-items-center table-flush table"
                        responsive="true"
                        style={{ height: "100%" }}
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Catégorie</th>
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                          {this.getDataList(
                            categoryList,
                            "libelle",
                            "category"
                          )}
                        </tbody>
                      </table>
                    </div>
                    <FooterTable
                      currentPage={currentPage}
                      handlePage={this.handleChange}
                      handleChangePage={this.handleChangePage}
                      getTotalPage={this.getTotalPage}
                      style={{ marginTop: "auto" }}
                    >
                      <Button
                        className="btn"
                        onClick={() => this.toggle("CATEGORY")}
                      >
                        Nouvelle catégorie
                      </Button>
                    </FooterTable>
                  </Card>
                </div>
              </Row>
            </Col>

            <Col lg="6">
              {/* Table */}
              <Row>
                <div className="col mb-4">
                  <Card className="shadow" style={{ height: 500 }}>
                    <CardHeader className="border-0">
                      <Row></Row>
                    </CardHeader>
                    <div style={{ overflow: "auto" }}>
                      <table
                        className="table table-bordered align-items-center table-flush table"
                        responsive="true"
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Unité</th>
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                          {this.getDataList(unityList, "libelle", "unity")}
                        </tbody>
                      </table>
                    </div>
                    <FooterTable
                      currentPage={currentPage}
                      handlePage={this.handleChange}
                      handleChangePage={this.handleChangePage}
                      getTotalPage={this.getTotalPage}
                      style={{ marginTop: "auto" }}
                    >
                      <Button
                        className="btn"
                        onClick={() => this.toggle("UNITY")}
                      >
                        Nouvelle Unité
                      </Button>
                    </FooterTable>
                  </Card>
                </div>
              </Row>
            </Col>
            <Col lg="6">
              {/* Table */}
              <Row>
                <div className="col mb-4">
                  <Card className="shadow" style={{ height: 500 }}>
                    <CardHeader className="border-0">
                      <Row></Row>
                    </CardHeader>
                    <div style={{ overflow: "auto" }}>
                      <table
                        className="table table-bordered align-items-center table-flush table"
                        responsive="true"
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Partenaire</th>
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                          {this.getDataList(
                            partenaireList,
                            "designation",
                            "partenaire"
                          )}
                        </tbody>
                      </table>
                    </div>
                    <FooterTable
                      currentPage={currentPage}
                      handlePage={this.handleChange}
                      handleChangePage={this.handleChangePage}
                      getTotalPage={this.getTotalPage}
                      style={{ marginTop: "auto" }}
                    >
                      <Button
                        className="btn"
                        onClick={() => this.toggle("PARTENAIRE")}
                      >
                        Nouveau Partenaire
                      </Button>
                    </FooterTable>
                  </Card>
                </div>
              </Row>
            </Col>
            <Col lg="6">
              {/* Table */}
              <Row>
                <div className="col mb-4">
                  <Card className="shadow" style={{ height: 500 }}>
                    <CardHeader className="border-0">
                      <Row></Row>
                    </CardHeader>
                    <div style={{ overflow: "auto" }}>
                      <table
                        className="table table-bordered align-items-center table-flush table"
                        responsive="true"
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Par defaut</th>
                            <th scope="col">Voina</th>
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                          {this.getDataList(voinaList, "libelle", "voina")}
                        </tbody>
                      </table>
                    </div>
                    <FooterTable
                      currentPage={currentPage}
                      handlePage={this.handleChange}
                      handleChangePage={this.handleChangePage}
                      getTotalPage={this.getTotalPage}
                      style={{ marginTop: "auto" }}
                    >
                      <Button
                        className="btn"
                        onClick={() => this.toggle("VOINA")}
                      >
                        Nouveau Voina
                      </Button>
                    </FooterTable>
                  </Card>
                </div>
              </Row>
            </Col>
            <Col lg="6">
              {/* Table */}
              <Row>
                <div className="col mb-4">
                  <Card className="shadow" style={{ height: 500 }}>
                    <CardHeader className="border-0">
                      <Row></Row>
                    </CardHeader>
                    <div style={{ overflow: "auto" }}>
                      <table
                        className="table table-bordered align-items-center table-flush table"
                        responsive="true"
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Type de site</th>
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                          {this.getDataList(typesiteList, "name", "typesite")}
                        </tbody>
                      </table>
                    </div>
                    <FooterTable
                      currentPage={currentPage}
                      handlePage={this.handleChange}
                      handleChangePage={this.handleChangePage}
                      getTotalPage={this.getTotalPage}
                      style={{ marginTop: "auto" }}
                    >
                      <Button
                        className="btn"
                        onClick={() => this.toggle("TYPESITE")}
                      >
                        Nouveau Type de Site
                      </Button>
                    </FooterTable>
                  </Card>
                </div>
              </Row>
            </Col>
            <Col lg="6">
              {/* Table */}
              <Row>
                <div className="col mb-4">
                  <Card className="shadow" style={{ height: 500 }}>
                    <CardHeader className="border-0">
                      <Row></Row>
                    </CardHeader>
                    <div style={{ overflow: "auto" }}>
                      <table
                        className="table table-bordered align-items-center table-flush table"
                        responsive="true"
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Type de don</th>
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                          {this.getDataList(typedonList, "name", "typedon")}
                        </tbody>
                      </table>
                    </div>
                    <FooterTable
                      currentPage={currentPage}
                      handlePage={this.handleChange}
                      handleChangePage={this.handleChangePage}
                      getTotalPage={this.getTotalPage}
                      style={{ marginTop: "auto" }}
                    >
                      <Button
                        className="btn"
                        onClick={() => this.toggle("TYPEDON")}
                      >
                        Nouveau Type de Don
                      </Button>
                    </FooterTable>
                  </Card>
                </div>
              </Row>
            </Col>

            <CategoryModal
              showModal={showCategoryModal}
              toggle={() => this.toggle("CATEGORY")}
              selectedCategory={selectedCategory}
              handleChange={this.handleChange}
              update={() => this.update("category")}
              save={() => this.save("category")}
            />
            <UnityModal
              showModal={showUnityModal}
              toggle={() => this.toggle("UNITY")}
              selectedUnity={selectedUnity}
              handleChange={this.handleChange}
              update={() => this.update("unity")}
              save={() => this.save("unity")}
            />
            <PartenaireModal
              showModal={showPartenaireModal}
              toggle={() => this.toggle("PARTENAIRE")}
              selectedPartenaire={selectedPartenaire}
              handleChange={this.handleChange}
              update={() => this.update("partenaire")}
              save={() => this.save("partenaire")}
            />
            <VoinaModal
              showModal={showVoinaModal}
              toggle={() => this.toggle("VOINA")}
              selectedVoina={selectedVoina}
              handleChange={this.handleChange}
              handleChangeDate={this.handleChangeDate}
              update={() => this.update("voina")}
              save={() => this.save("voina")}
            />
            <TypeSiteModal
              showModal={showTypeSiteModal}
              toggle={() => this.toggle("TYPESITE")}
              selectedTypeSite={selectedTypeSite}
              handleChange={this.handleChange}
              update={() => this.update("typesite")}
              save={() => this.save("typesite")}
            />
            <TypeDonModal
              showModal={showTypeDonModal}
              toggle={() => this.toggle("TYPEDON")}
              selectedTypeDon={selectedTypeDon}
              handleChange={this.handleChange}
              update={() => this.update("typedon")}
              save={() => this.save("typedon")}
            />

            {showConfirmationModal && (
              <ConfirmationModal
                onCancel={this.toggleConfirmation}
                onSave={this.delete}
              />
            )}
            <PageSpinner show={isLoading} />
          </Row>
        </Container>
        <Articles showHeader={false} />
      </>
    );
  }
}

export default ArticlesPage;
