import React from "react";
import { Col, FormGroup, Input, Row } from "reactstrap";
class ReadInformationSupplementaire extends React.Component {
  state = {
    sinistre: {},
  };

  componentWillReceiveProps = (props) => {
    this.setState({
      sinistre: props.data.sinistre,
    });
  };
  render() {
    return (
      <>
        <Row>
          <Col>
            <FormGroup>
              <h4 className="text-primary">Informations supplémentaires</h4>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="2">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-nb-homme">
                Nb hommes
              </label>
              <Input
                className="form-control-alternative"
                id="input-nombreHommes"
                placeholder=""
                type="text"
                name="nombreHommes"
                defaultValue={this.state.sinistre.nombreHommes}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-nb-femme">
                Nb femmes
              </label>
              <Input
                className="form-control-alternative"
                id="input-nombreFemmes"
                placeholder=""
                type="text"
                name="nombreFemmes"
                defaultValue={this.state.sinistre.nombreFemmes}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-etudiants">
                Nb enfants scolarisés
              </label>
              <Input
                className="form-control-alternative"
                id="input-nombreEtudiants"
                placeholder=""
                type="text"
                name="nombreEtudiants"
                defaultValue={this.state.sinistre.nombreEtudiants}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-enfant">
                Nb enfants 0 à 5 ans
              </label>
              <Input
                className="form-control-alternative"
                id="input-nombreEnfants0a5"
                placeholder=""
                type="text"
                name="nombreEnfants0a5"
                defaultValue={this.state.sinistre.nombreEnfants0a5}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-enceintes">
                Nb femmes enceintes
              </label>
              <Input
                className="form-control-alternative"
                id="input-nombreFemmesEnceintes"
                placeholder=""
                type="text"
                name="nombreFemmesEnceintes"
                defaultValue={this.state.sinistre.nombreFemmesEnceintes}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-allaitent">
                Nb femmes qui allaitent
              </label>
              <Input
                className="form-control-alternative"
                id="input-nombreFemmesAllaitent"
                placeholder=""
                type="text"
                name="nombreFemmesAllaitent"
                defaultValue={this.state.sinistre.nombreFemmesAllaitent}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-handicapes">
                Nb handicapés
              </label>
              <Input
                className="form-control-alternative"
                id="input-nombreHandicapes"
                placeholder=""
                type="text"
                name="nombreHandicapes"
                defaultValue={this.state.sinistre.nombreHandicapes}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-plus-soixante"
              >
                Nb de + 60 ans
              </label>
              <Input
                className="form-control-alternative"
                id="input-nombrePlus60"
                placeholder=""
                type="text"
                name="nombrePlus60"
                defaultValue={this.state.sinistre.nombrePlus60}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-diarrhee">
                Nb ayant diarrhée
              </label>
              <Input
                className="form-control-alternative"
                id="input-nombreDiarhee"
                placeholder=""
                type="text"
                name="nombreDiarhee"
                defaultValue={this.state.sinistre.nombreDiarhee}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-pblm-respiratoire"
              >
                Nb problèmes respiratoires
              </label>
              <Input
                className="form-control-alternative"
                id="input-nombreProblemeRespiratoire"
                placeholder=""
                type="text"
                name="nombreProblemeRespiratoire"
                defaultValue={this.state.sinistre.nombreProblemeRespiratoire}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-grippe">
                Nb ayant la grippe
              </label>
              <Input
                className="form-control-alternative"
                id="input-nombreGrippe"
                placeholder=""
                type="text"
                name="nombreGrippe"
                defaultValue={this.state.sinistre.nombreGrippe}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-paludisme">
                Nb ayant le paludisme
              </label>
              <Input
                className="form-control-alternative"
                id="input-nombrePaludisme"
                placeholder=""
                type="text"
                name="nombrePaludisme"
                defaultValue={this.state.sinistre.nombrePaludisme}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-covid">
                Nb ayant le Covid
              </label>
              <Input
                className="form-control-alternative"
                id="input-nombreCovid"
                placeholder=""
                type="text"
                name="nombreCovid"
                defaultValue={this.state.sinistre.nombreCovid}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-chronique">
                Nb ayant maladie chronique
              </label>
              <Input
                className="form-control-alternative"
                id="input-nombreChronique"
                placeholder=""
                type="text"
                name="nombreChronique"
                defaultValue={this.state.sinistre.nombreChronique}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="3">
            <FormGroup>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  className="custom-control-input form-control"
                  id="customCheckKit"
                  type="checkbox"
                  name="kitScolaireDetruit"
                  defaultChecked={this.state.sinistre.kitScolaireDetruit}
                  value={this.state.sinistre.kitScolaireDetruit}
                  onChange={this.handleCheckBoxChange}
                  disabled={true}
                />
                <label
                  className="custom-control-label text-bold"
                  htmlFor="customCheckKit"
                >
                  Kit scolaire détruit
                </label>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  className="custom-control-input form-control"
                  id="cuisineDetruite"
                  type="checkbox"
                  name="cuisineDetruite"
                  defaultChecked={this.state.sinistre.cuisineDetruite}
                  value={this.state.sinistre.cuisineDetruite}
                  onChange={this.handleCheckBoxChange}
                  disabled={true}
                />
                <label
                  className="custom-control-label text-bold"
                  htmlFor="cuisineDetruite"
                >
                  Equipement cuisine détruit
                </label>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  className="custom-control-input form-control"
                  id="sourceEauDetruite"
                  type="checkbox"
                  name="sourceEauDetruite"
                  defaultChecked={this.state.sinistre.sourceEauDetruite}
                  value={this.state.sinistre.sourceEauDetruite}
                  onChange={this.handleCheckBoxChange}
                  disabled={true}
                />
                <label
                  className="custom-control-label text-bold"
                  htmlFor="sourceEauDetruite"
                >
                  Source d'eau détruit
                </label>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  className="custom-control-input form-control"
                  id="reserveEauDetruite"
                  type="checkbox"
                  name="reserveEauDetruite"
                  defaultChecked={this.state.sinistre.reserveEauDetruite}
                  value={this.state.sinistre.reserveEauDetruite}
                  onChange={this.handleCheckBoxChange}
                  disabled={true}
                />
                <label
                  className="custom-control-label text-bold"
                  htmlFor="reserveEauDetruite"
                >
                  Réserve d'eau détruit
                </label>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  className="custom-control-input form-control"
                  id="accesEauPotable"
                  type="checkbox"
                  name="accesEauPotable"
                  defaultChecked={this.state.sinistre.accesEauPotable}
                  value={this.state.sinistre.accesEauPotable}
                  onChange={this.handleCheckBoxChange}
                  disabled={true}
                />
                <label
                  className="custom-control-label text-bold"
                  htmlFor="accesEauPotable"
                >
                  Accès eau potable
                </label>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  className="custom-control-input form-control"
                  id="accesElectricite"
                  type="checkbox"
                  name="accesElectricite"
                  defaultChecked={this.state.sinistre.accesElectricite}
                  value={this.state.sinistre.accesElectricite}
                  onChange={this.handleCheckBoxChange}
                  disabled={true}
                />
                <label
                  className="custom-control-label text-bold"
                  htmlFor="accesElectricite"
                >
                  Maison avec électricité
                </label>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  className="custom-control-input form-control"
                  id="maisonDetruite"
                  type="checkbox"
                  name="maisonDetruite"
                  defaultChecked={this.state.sinistre.maisonDetruite}
                  value={this.state.sinistre.maisonDetruite}
                  onChange={this.handleCheckBoxChange}
                  disabled={true}
                />
                <label
                  className="custom-control-label text-bold"
                  htmlFor="maisonDetruite"
                >
                  Maison détruit
                </label>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  className="custom-control-input form-control"
                  id="maisonBrulee"
                  type="checkbox"
                  name="maisonBrulee"
                  defaultChecked={this.state.sinistre.maisonBrulee}
                  value={this.state.sinistre.maisonBrulee}
                  onChange={this.handleCheckBoxChange}
                  disabled={true}
                />
                <label
                  className="custom-control-label text-bold"
                  htmlFor="maisonBrulee"
                >
                  Maison brulée
                </label>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  className="custom-control-input form-control"
                  id="maisonInondee"
                  type="checkbox"
                  name="maisonInondee"
                  defaultChecked={this.state.sinistre.maisonInondee}
                  value={this.state.sinistre.maisonInondee}
                  onChange={this.handleCheckBoxChange}
                  disabled={true}
                />
                <label
                  className="custom-control-label text-bold"
                  htmlFor="maisonInondee"
                >
                  Maison inondée
                </label>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  className="custom-control-input form-control"
                  id="toitDetruit"
                  type="checkbox"
                  name="toitDetruit"
                  defaultChecked={this.state.sinistre.toitDetruit}
                  value={this.state.sinistre.toitDetruit}
                  onChange={this.handleCheckBoxChange}
                  disabled={true}
                />
                <label
                  className="custom-control-label text-bold"
                  htmlFor="toitDetruit"
                >
                  Toit détruit
                </label>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }
}

export default ReadInformationSupplementaire;
