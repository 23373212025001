import moment from "moment";
import React from "react";
import ReactDatetime from "react-datetime";
import {
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import SiteService from "services/SiteService";
import VoinaService from "services/VoinaService";
import MenageService from "services/MenageService";
import TetheredDateTime from "views/components/TetheredDateTime";

class InformationGenerale extends React.Component {
  state = {
    informationGenerale: {
      menageId: "",
      chefMenage: "",
      siteList: [],
      siteId: -1,
      dateEntree: moment().format("YYYY-MM-DDTHH:mm:ss"),
      displayedDateEntree: moment().format("DD/MM/YYYY HH:mm"),
      dateSortie: "",
      avoirMoyenDeSubsistance: false,
      voinaList: [],
      voina: {},
      voinaId: -1,
      selectedVoina: "",
      nombreDeplaces: 1,
      nombreIndidivusMax: 1,
    },
    autocompletion: [],
    requiredFields: {
      site: "",
      voina: "",
      avoirMoyenDeSubsistance: false,
      dateEntree: moment().format("YYYY-MM-DDTHH:mm:ss"),
    },
  };

  componentWillReceiveProps = (props) => {
    if (props.requiredFieldsError) {
      this.setState({ requiredFields: props.requiredFieldsError });
    }
  };

  componentDidMount() {
    const { idMenage, nomChefMenage } = this.props.match.params;
    this.getSite();
    this.getNombreIndividu();
    this.getVoina();
    this.setState({
      informationGenerale: {
        ...this.state.informationGenerale,
        menageId: idMenage,
        chefMenage: nomChefMenage,
        dateEntree: moment().format("YYYY-MM-DDTHH:mm:ss"),
      },
      requiredFields: {
        site: "",
        voina: "",
      },
    });
    this.props.data(this.state.informationGenerale);
  }

  getNombreIndividu() {
    const idMenage = this.props.match.params.idMenage;
    MenageService.getMenageById(idMenage).then(
      (result) => {
        this.setState({
          informationGenerale: {
            ...this.state.informationGenerale,
            nombreDeplaces: result.data.data.menage.tailleMenage,
            nombreIndidivusMax: result.data.data.menage.tailleMenage,
          },
        });
      },
      (error) => {
        console.log("ERROR : ", error);
      }
    );
  }
  getSite = () => {
    //recuperation des sites ..
    SiteService.getAllSite().then((result) => {
      this.setState({
        informationGenerale: {
          ...this.state.informationGenerale,
          siteList: result.data.data,
        },
      });
    });
  };
  getVoina = () => {
    //recuperation des voina ..
    VoinaService.getAllVoina().then((result) => {
      this.setState(
        {
          informationGenerale: {
            ...this.state.informationGenerale,
            voinaList: result.data.data,
          },
        },
        () => this.setDefaultVoina()
      );
    });
  };

  setDefaultVoina() {
    //recuperation du voina par defaut ..
    VoinaService.getDefaultVoina().then((result) => {
      result.data.data &&
        this.setState(
          {
            informationGenerale: {
              ...this.state.informationGenerale,
              voinaId: result.data.data.id,
              selectedVoina: result.data.data.libelle,
              voina: {
                dateDebut: result.data.data.dateDebut,
                dateFin: result.data.data.dateFin,
                id: result.data.data.id,
                libelle: result.data.data.libelle,
              },
            },
          },
          () => this.props.data(this.state.informationGenerale)
        );
    });
  }

  handleCheckBoxChange = (event) => {
    const { name } = event.target;
    this.setState(
      {
        informationGenerale: {
          ...this.state.informationGenerale,
          [name]: event.target.checked,
          isInit: false,
        },
      },
      () => {
        this.props.data(this.state.informationGenerale);
      }
    );
  };

  handleChange = (evt) => {
    let { value } = evt.target;
    let requiredFields = { ...this.state.requiredFields };

    this.setState(
      {
        informationGenerale: {
          ...this.state.informationGenerale,
          [evt.target.name]: value,
          isInit: false,
        },
        requiredFields,
      },
      () => {
        this.props.data(this.state.informationGenerale);
      }
    );
  };
  handleSiteChange = (evt) => {
    let { value } = evt.target;
    let requiredFields = { ...this.state.requiredFields };

    this.setState(
      {
        informationGenerale: {
          ...this.state.informationGenerale,
          siteId: parseInt(value),
          isInit: false,
        },
        requiredFields,
      },
      () => {
        this.props.data(this.state.informationGenerale);
      }
    );
  };
  handleVoinaChange = (evt) => {
    let { value } = evt.target;
    let idVoina = "";
    let dateDebut = "",
      libelle = "",
      dateFin = "";
    let requiredFields = { ...this.state.requiredFields };

    this.setState(
      {
        informationGenerale: {
          ...this.state.informationGenerale,
          [evt.target.name]: value,
          voinaId: parseInt(value),
          voina: {
            dateDebut: dateDebut,
            dateFin: dateFin,
            id: idVoina,
            libelle: libelle,
          },
        },
        requiredFields,
      },
      () => {
        this.props.data(this.state.informationGenerale);
      }
    );
  };

  handeleDateEntree = (date) => {
    let value = "";
    if (date.isValid) {
      value = date.format("YYYY-MM-DDTHH:mm:ss");
    }
    this.setState(
      {
        informationGenerale: {
          ...this.state.informationGenerale,
          dateEntree: moment.utc(value),
          displayedDateEntree: moment(value).format("DD/MM/YYYY HH:mm"),
        },
      },
      () => {
        this.props.data(this.state.informationGenerale);
      }
    );
  };
  render() {
    const listeSite =
      this.state.informationGenerale.siteList &&
      this.state.informationGenerale.siteList.map((elt) => (
        <option key={elt.id} value={elt.id}>
          {elt.nom_site}
        </option>
      ));
    const listeVoina =
      this.state.informationGenerale.voinaList &&
      this.state.informationGenerale.voinaList.map((elt, idx) => (
        <option key={elt.id} value={elt.id}>
          {elt.libelle}
        </option>
      ));

    return (
      <>
        <Row>
          <Col>
            <FormGroup>
              <div className="h3 text-primary">Informations générales</div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-chef-menage">
                Chef de ménage
              </label>

              <Input
                className="form-control-alternative"
                id="input-chef-menage"
                placeholder=""
                type="text"
                name="chefMenage"
                value={this.state.informationGenerale.chefMenage}
                disabled={true}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
              />

              {this.state.requiredFields &&
                this.state.requiredFields.chef &&
                this.state.requiredFields.chef.length > 0 && (
                  <small className="text-danger">champ obligatoire</small>
                )}
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-site">
                Site*
              </label>
              <div className="input-group-alternative input-group">
                <select
                  className="mdb-select md-form  form-control"
                  onChange={this.handleSiteChange}
                  name="siteId"
                  value={this.state.informationGenerale.siteId}
                >
                  <option value={-1}></option>
                  {listeSite}
                </select>
              </div>
              {this.state.informationGenerale.siteId &&
                this.state.informationGenerale.siteId == -1 && (
                  <small className="text-danger">
                    {" "}
                    {this.state.requiredFields.site}
                  </small>
                )}
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-date-deplacement"
              >
                Date et heure de déplacement
              </label>
              <FormGroup>
                <InputGroup className="input-group-alternative flex-nowrap">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <TetheredDateTime
                    className="position-relative"
                    inputProps={{
                      placeholder:
                        this.state.informationGenerale.displayedDateEntree !==
                        ""
                          ? this.state.informationGenerale.displayedDateEntree
                          : "DD/MM/YYYY HH:mm",
                      onKeyDown: (e) => e.preventDefault(),
                    }}
                    dateFormat={"DD/MM/YYYY"}
                    format={"DD/MM/YYYY HH:MM"}
                    autoClose={true}
                    timeFormat={"HH:mm"}
                    onChange={this.handeleDateEntree}
                    name="dateEntree"
                    closeOnSelect={true}
                    value={this.state.informationGenerale.displayedDateEntree}
                  />
                </InputGroup>
              </FormGroup>
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-date-sortie">
                Date et heure de sortie
              </label>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <ReactDatetime
                    inputProps={{
                      placeholder: " - ",
                      onKeyDown: (e) => e.preventDefault(),
                      readOnly: true,
                    }}
                    format={"DD/MM/YYYY HH:MM:SS"}
                    closeOnSelect={true}
                    timeFormat={false}
                    name="dateSortie"
                    open={false}
                    showClose={true}
                    // inputProps={{ readOnly: true }}
                  />
                </InputGroup>
              </FormGroup>
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-voina">
                Voina*
              </label>
              <div className="input-group-alternative input-group">
                <select
                  className="mdb-select md-form  form-control"
                  onChange={this.handleVoinaChange}
                  name="voinaId"
                  value={this.state.informationGenerale.voinaId}
                >
                  <option value={-1}></option>
                  {listeVoina}
                </select>
              </div>
              {this.state.informationGenerale.voinaId &&
                this.state.informationGenerale.voinaId === -1 && (
                  <small className="text-danger">
                    {" "}
                    {this.state.requiredFields.voina}
                  </small>
                )}
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Nb d'individu à déplacer
              </label>
              <Input
                className="form-control-alternative"
                id="input-username"
                placeholder=""
                type="number"
                min={1}
                max={this.state.informationGenerale.nombreIndidivusMax}
                value={this.state.informationGenerale.nombreDeplaces}
                onChange={this.handleChange}
                name="nombreDeplaces"
              />
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  className="custom-control-input form-control"
                  id="avoirMoyenDeSubsistance"
                  type="checkbox"
                  name="avoirMoyenDeSubsistance"
                  defaultChecked={
                    this.state.informationGenerale.avoirMoyenDeSubsistance
                  }
                  value={this.state.informationGenerale.avoirMoyenDeSubsistance}
                  onChange={this.handleCheckBoxChange}
                />
                <label
                  className="custom-control-label text-bold"
                  htmlFor="avoirMoyenDeSubsistance"
                >
                  A un moyen de subsistance (travail)
                </label>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }
}

export default InformationGenerale;
