import FooterTable from "components/Footer/Footer";
import {
  ALLAITANTE,
  AUTRE,
  BEING_PROCESSED,
  BETWEEN_1_TO_5_KILOMETER,
  BETWEEN_5_TO_10_KILOMETER,
  BON_ADOS,
  BON_ENFANT,
  DEATH,
  DEMO,
  DMAM_ADOS,
  DMAM_ENFANT,
  DMAS_ADOS,
  DMAS_ENFANT,
  ECONOMY,
  ENCEINTE,
  FIAVOTA,
  FLEUVE,
  FREQUENCE,
  GFD,
  HANDICAPE,
  HEALED,
  HEALTH,
  IN_ALERT,
  IN_SAFE,
  MAL,
  MAMMAS_ADOS,
  MAMMAS_ENFANT,
  MASMAM_ADOS,
  MASMAM_ENFANT,
  MENAGE,
  MOIN5ANS,
  MOINS_1_KILOMETER,
  NEW_CASE,
  NON_ADOS,
  NON_ADULTE,
  NON_ENFANT,
  NOT_SPECIFIED,
  NOT_SPECIFIED_NUTRI,
  NOT_SPECIFIED_SECURITY,
  NOT_SPECIFIED_WATER,
  NUTRI,
  NUTRITION,
  NUTRITION_1FOIS,
  NUTRITION_1FOIS_OU_MOINS,
  NUTRITION_2FOIS,
  NUTRITION_3FOIS,
  NUTRITION_MOIN_1FOIS,
  NUTRITION_PLUS_2FOIS,
  PLUS65ANS,
  PLUS_10_KILOMETER,
  POMPE,
  PUIT,
  RATION,
  RED_ZONE,
  REVENU_0_100,
  REVENU_100_200,
  REVENU_200_300,
  REVENU_300_400,
  REVENU_NON,
  REVENU_PLUS_400,
  TOSIKA_FAMENO
} from "data/constant/data-type";
import { user_viewer } from "data/constant/user.rights";
import { Download } from "helper/ImpressionHelper";
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table
} from "reactstrap";
import BoardService from "services/BoardService";
import PageSpinner from "views/components/PageSpinner";
import PopupChech from "views/components/popup-check";
import { AlertModal } from "../components/AlertModal";

class FicheTraitementPage extends React.Component {
  state = {
    demoCheck: false,
    nutriCheck: false,
    ecoCheck: false,
    santeCheck: false,
    exampleModal: false,
    showModal: false,
    showFicheModal: false,
    individu: "",
    listIndividu: [],
    listMenage: [],
    confirmationModal: false,
    individuSelected: null,
    currentPage: 1,
    totalIndividu: 0,
    totalMenage: 0,
    totalPage: 1,
    isLoading: false,
    nom: "",
    affiliation: "",
    showTraitement: false,
    currentIndividu: null,
    initChecked: {},
    pageSize: "10",
    showAlert:false,
    checked: {
      showAll: false,
      showName: false,
      showSex: false,
      showAge: false,
      showTel: false,
      showRegion: false,
      showDistrict: false,
      showCommune: false,
      showFokontany: false,
      showHameau: false,
      showIdMenage: false,
      showHandicap: false,
      showTaille: false,
      showPoids: false,
      showPB: false,
      showEtatNutritionnel: false,
      showFrequenceRepas: false,
      showRation: false,
      showFamilyChief: false,
      showSecurity: false,
      showWaterDistance: false,
      showWaterSource: false,
      showActivity: false,
      showRevenus: false,
      showBienEtAvoir: false,
      showDon: false,
      showNbDeath: false,
      showNbIndividu: false,
      showFood: false,
    },
  };

  getTotalPage = () => {
    const { totalIndividu, pageSize, totalPage } = this.state;
    if(totalPage && totalPage>1){
      return totalPage
    }
    return Math.ceil(totalIndividu / pageSize);
  };

  closeAlert = ()=>{
    this.setState({
      showAlert:false
    })
  }

  getData(menage, filter, currentPage, pageSize) {
    const fokotany = menage.fokotany ? menage.fokotany : "";
    const commune = menage.commune ? menage.commune : "";
    const district = menage.district ? menage.district : "";
    const region = menage.region ? menage.region : "";
    BoardService.getRevenuGeneraux(
      commune,
      district,
      region,
      [filter],
      currentPage - 1,
      pageSize,
      fokotany
    )
      .then((response) => {
        this.setState({
          listIndividu: response.data.data || [],
          isLoading: false,
          totalIndividu: response.data.totalitems || 0,
        });
      })
      .catch(() => this.setState({ isLoading: false }));
  }
  exportData = () => {
    this.setState({
      isLoading: true,
    });
    const { search, data, menage } = this.props.location.state;
    const { checked } = this.state;
    const dataToExport = {
      checked,
      data,
      type: search,
      situation: menage,
    };

    BoardService.exportDatas(dataToExport)
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        Download(response);
      })
      .catch((error) => {
        this.setState({
          isLoading:false,
        })
        this.setState({
          showAlert:true
        })
      
      });
  };

  getListIndividu = () => {
    this.setState({
      isLoading: true,
    });

    const { currentPage, pageSize } = this.state;

    const menage = (this.props.location &&
      this.props.location.state &&
      this.props.location.state.menage) || {
      commune: "",
      district: "",
      region: "",
      fokotany: "",
    };

    switch (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.data
    ) {
      case HEALTH:
        this.setState({
          santeCheck: true,
        });
        if (
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.data
        ) {
          switch (
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.search
          ) {
            case NEW_CASE:
              BoardService.findAllIndividuNewCase(
                menage.commune,
                menage.district,
                menage.region,
                currentPage - 1,
                pageSize
              )
                .then((response) => {
                  this.setState({
                    listIndividu: (response.data && response.data.data) || [],
                    isLoading: false,
                    totalIndividu: response.data.totalPage || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case HEALED:
              BoardService.findAllIndividuHealed(
                menage.commune,
                menage.district,
                menage.region,
                currentPage - 1,
                pageSize
              )
                .then((response) => {
                  this.setState({
                    listIndividu: (response.data && response.data.data) || [],
                    isLoading: false,
                    totalIndividu: response.data.totalPage || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case DEATH:
              BoardService.findAllIndividuDead(
                menage.commune,
                menage.district,
                menage.region,
                currentPage - 1,
                pageSize
              )
                .then((response) => {
                  this.setState({
                    listIndividu: (response.data && response.data.data) || [],
                    isLoading: false,
                    totalIndividu: response.data.totalPage || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case BEING_PROCESSED:
              BoardService.findAllIndividuBeingProcessed(
                menage.commune,
                menage.district,
                menage.region,
                currentPage - 1,
                pageSize
              )
                .then((response) => {
                  this.setState({
                    listIndividu: (response.data && response.data.data) || [],
                    isLoading: false,
                    totalIndividu: response.data.totalPage || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case DMAM_ENFANT:
              BoardService.findAllDetecteMamEnfant(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize
              )
                .then((response) => {
                  this.setState({
                    listIndividu: (response.data && response.data.data) || [],
                    isLoading: false,
                    totalIndividu: response.data.totalItems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case DMAS_ENFANT:
              BoardService.findAllDetecteMasEnfant(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize
              )
                .then((response) => {
                  this.setState({
                    listIndividu: (response.data && response.data.data) || [],
                    isLoading: false,
                    totalIndividu: response.data.totalItems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case MASMAM_ENFANT:
              BoardService.findAllMASMAM_ENFANT(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize
              )
                .then((response) => {
                  this.setState({
                    listIndividu: (response.data && response.data.data) || [],
                    isLoading: false,
                    totalIndividu: response.data.totalItems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case MAMMAS_ENFANT:
              BoardService.findAllMAMMAS_ENFANT(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize
              )
                .then((response) => {
                  this.setState({
                    listIndividu: (response.data && response.data.data) || [],
                    isLoading: false,
                    totalIndividu: response.data.totalItems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case BON_ENFANT:
              BoardService.findAllBON_ENFANT(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize
              )
                .then((response) => {
                  this.setState({
                    listIndividu: (response.data && response.data.data) || [],
                    isLoading: false,
                    totalIndividu: response.data.totalItems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case NON_ENFANT:
              BoardService.findAllNON_ENFANT(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize
              )
                .then((response) => {
                  this.setState({
                    listIndividu: (response.data && response.data.data) || [],
                    isLoading: false,
                    totalIndividu: response.data.totalItems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;

            case DMAM_ADOS:
              BoardService.findAllDetecteMamAdos(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize
              )
                .then((response) => {
                  this.setState({
                    listIndividu: (response.data && response.data.data) || [],
                    isLoading: false,
                    totalIndividu: response.data.totalItems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case DMAS_ADOS:
              BoardService.findAllDetecteMasAdos(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize
              )
                .then((response) => {
                  this.setState({
                    listIndividu: (response.data && response.data.data) || [],
                    isLoading: false,
                    totalIndividu: response.data.totalItems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case MASMAM_ADOS:
              BoardService.findAllMASMAM_ADOS(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize
              )
                .then((response) => {
                  this.setState({
                    listIndividu: (response.data && response.data.data) || [],
                    isLoading: false,
                    totalIndividu: response.data.totalItems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case MAMMAS_ADOS:
              BoardService.findAllMAMMAS_ADOS(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize
              )
                .then((response) => {
                  this.setState({
                    listIndividu: (response.data && response.data.data) || [],
                    isLoading: false,
                    totalIndividu: response.data.totalItems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case BON_ADOS:
              BoardService.findAllBON_ADOS(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize
              )
                .then((response) => {
                  this.setState({
                    listIndividu: (response.data && response.data.data) || [],
                    isLoading: false,
                    totalIndividu: response.data.totalItems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case NON_ADOS:
              BoardService.findAllNON_ADOS(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize
              )
                .then((response) => {
                  this.setState({
                    listIndividu: (response.data && response.data.data) || [],
                    isLoading: false,
                    totalIndividu: response.data.totalItems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case MAL:
              BoardService.findAllMAL(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize
              )
                .then((response) => {
                  this.setState({
                    listIndividu: (response.data && response.data.data) || [],
                    isLoading: false,
                    totalIndividu: response.data.totalItems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case NUTRI:
              BoardService.findAllNUTRI(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize
              )
                .then((response) => {
                  this.setState({
                    listIndividu: (response.data && response.data.data) || [],
                    isLoading: false,
                    totalIndividu: response.data.totalItems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case NON_ADULTE:
              BoardService.findAllNON_ClassifieADULTE(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize
              )
                .then((response) => {
                  this.setState({
                    listIndividu: (response.data && response.data.data) || [],
                    isLoading: false,
                    totalIndividu: response.data.totalItems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            default:
              break;
          }
        }
        break;

      case ECONOMY:
        const filter = this.props.location.state.search;
        this.setState({
          ecoCheck: true,
        });
        if (
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.data
        ) {
          switch (
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.search
          ) {
            case REVENU_0_100:
              this.getData(menage, filter, currentPage, pageSize);
              break;
            case REVENU_100_200:
              this.getData(menage, filter, currentPage, pageSize);
              break;
            case REVENU_200_300:
              this.getData(menage, filter, currentPage, pageSize);
              break;
            case REVENU_300_400:
              this.getData(menage, filter, currentPage, pageSize);
              break;
            case REVENU_PLUS_400:
              this.getData(menage, filter, currentPage, pageSize);
              break;
            case REVENU_NON:
              this.getData(menage, filter, currentPage, pageSize);
              break;
            case TOSIKA_FAMENO:
              BoardService.findAllIndividuTOSIKA_FAMENO(
                menage.commune,
                menage.district,
                menage.region,
                currentPage - 1,
                pageSize
              )
                .then((response) => {
                  this.setState({
                    listIndividu: response.data.data || [],
                    isLoading: false,
                    totalIndividu: response.data.totalItems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;

            case FIAVOTA:
              BoardService.findAllIndividuFIAVOTA(
                menage.commune,
                menage.district,
                menage.region,
                currentPage - 1,
                pageSize
              )
                .then((response) => {
                  this.setState({
                    listIndividu: response.data.data || [],
                    isLoading: false,
                    totalIndividu: response.data.totalItems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;

            case GFD:
              BoardService.findAllIndividuGFD(
                menage.commune,
                menage.district,
                menage.region,
                currentPage - 1,
                pageSize
              )
                .then((response) => {
                  this.setState({
                    listIndividu: response.data.data || [],
                    isLoading: false,
                    totalIndividu: response.data.totalItems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;

            case NOT_SPECIFIED:
              BoardService.findAllIndividuNOT_SPECIFIED(
                menage.commune,
                menage.district,
                "",
                menage.region,
                currentPage - 1,
                pageSize
              )
                .then((response) => {
                  this.setState({
                    listIndividu: response.data.data || [],
                    isLoading: false,
                    totalIndividu: response.data.totalItems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case NOT_SPECIFIED_SECURITY:
              BoardService.getSecurityInfo(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize,
                this.props.location.state.search
              )
                .then((response) => {
                  this.setState({
                    listIndividu: response.data.data || [],
                    isLoading: false,
                    totalIndividu: response.data.totalitems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case IN_SAFE:
              BoardService.getSecurityInfo(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize,
                this.props.location.state.search
              )
                .then((response) => {
                  this.setState(
                    {
                      listIndividu: response.data.data || [],
                      isLoading: false,
                      totalIndividu: response.data.totalitems || 0,
                    }
                  );
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case IN_ALERT:
              BoardService.getSecurityInfo(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize,
                this.props.location.state.search
              )
                .then((response) => {
                  this.setState(
                    {
                      listIndividu: response.data.data || [],
                      isLoading: false,
                      totalIndividu: response.data.totalitems || 0,
                    }
                  );
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case RED_ZONE:
              BoardService.getSecurityInfo(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize,
                this.props.location.state.search
              )
                .then((response) => {
                  this.setState({
                    listIndividu: response.data.data || [],
                    isLoading: false,
                    totalIndividu: response.data.totalitems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;

            case AUTRE:
              BoardService.getWaterSource(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize,
                this.props.location.state.search
              )
                .then((response) => {
                  this.setState({
                    listIndividu: response.data.data || [],
                    isLoading: false,
                    totalIndividu: response.data.totalitems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case POMPE:
              BoardService.getWaterSource(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize,
                this.props.location.state.search
              )
                .then((response) => {
                  this.setState({
                    listIndividu: response.data.data || [],
                    isLoading: false,
                    totalIndividu: response.data.totalitems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case PUIT:
              BoardService.getWaterSource(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize,
                this.props.location.state.search
              )
                .then((response) => {
                  this.setState({
                    listIndividu: response.data.data || [],
                    isLoading: false,
                    totalIndividu: response.data.totalitems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case FLEUVE:
              BoardService.getWaterSource(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize,
                this.props.location.state.search
              )
                .then((response) => {
                  this.setState({
                    listIndividu: response.data.data || [],
                    isLoading: false,
                    totalIndividu: response.data.totalitems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;

            case MOINS_1_KILOMETER:
              BoardService.getWaterInfo(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize,
                this.props.location.state.search
              )
                .then((response) => {
                  this.setState({
                    listIndividu: response.data.data || [],
                    isLoading: false,
                    totalIndividu: response.data.totalitems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case NOT_SPECIFIED_WATER:
              BoardService.getWaterInfo(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize,
                this.props.location.state.search
              )
                .then((response) => {
                  this.setState({
                    listIndividu: response.data.data || [],
                    isLoading: false,
                    totalIndividu: response.data.totalitems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case BETWEEN_1_TO_5_KILOMETER:
              BoardService.getWaterInfo(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize,
                this.props.location.state.search
              )
                .then((response) => {
                  this.setState({
                    listIndividu: response.data.data || [],
                    isLoading: false,
                    totalIndividu: response.data.totalitems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case BETWEEN_5_TO_10_KILOMETER:
              BoardService.getWaterInfo(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize,
                this.props.location.state.search
              )
                .then((response) => {
                  this.setState({
                    listIndividu: response.data.data || [],
                    isLoading: false,
                    totalIndividu: response.data.totalitems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case PLUS_10_KILOMETER:
              BoardService.getWaterInfo(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize,
                "%2B10 km"
              )
                .then((response) => {
                  this.setState({
                    listIndividu: response.data.data || [],
                    isLoading: false,
                    totalIndividu: response.data.totalitems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;

            default:
              break;
          }
        }
        break;
      case NUTRITION:
        this.setState({
          isLoading: true,
          nutriCheck: true,
        });

        if (
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.data
        ) {
          BoardService.findAllIndividuFrequenceRepas(
            this.props.location.state.search,
            menage.commune,
            menage.district,
            menage.region,
            menage.fokotany,
            currentPage - 1,
            pageSize
          )
            .then((response) => {
              this.setState({
                listIndividu: response.data.data || [],
                isLoading: false,
                totalIndividu: response.data.totalPage || 0,
                totalPage: response.data.totalPage || 0,
              });
            })
            .catch(() => this.setState({ isLoading: false }));
        
        }
        break;
      case DEMO:
        if (
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.data
        ) {
          switch (
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.search
          ) {
            case MOIN5ANS:
              BoardService.findAllMoin5ans(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize
              )
                .then((response) => {
                  this.setState({
                    listIndividu: response.data.data || [],
                    isLoading: false,
                    totalIndividu: response.data.totalItems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;

            case PLUS65ANS:
              BoardService.findAllPlus65ans(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize
              )
                .then((response) => {
                  this.setState({
                    listIndividu: response.data.data || [],
                    isLoading: false,
                    totalIndividu: response.data.totalItems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case ALLAITANTE:
              BoardService.findAllAllAllaitante(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize
              )
                .then((response) => {
                  this.setState({
                    listIndividu: response.data.data || [],
                    isLoading: false,
                    totalIndividu: response.data.totalItems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case ENCEINTE:
              BoardService.findAllAllEnceinte(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize
              )
                .then((response) => {
                  this.setState({
                    listIndividu: response.data.data || [],
                    isLoading: false,
                    totalIndividu: response.data.totalItems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case MENAGE:
              this.setState({
                demoCheck: true,
              });

              BoardService.findAllListeMenage(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize
              )
                .then((response) => {
                  this.setState({
                    listIndividu: response.data.data || [],
                    isLoading: false,
                    totalIndividu: response.data.totalitems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;
            case HANDICAPE:
              BoardService.findAllAllHandicape(
                menage.commune,
                menage.district,
                menage.region,
                menage.fokotany,
                currentPage - 1,
                pageSize
              )
                .then((response) => {
                  this.setState({
                    listIndividu: response.data.data || [],
                    isLoading: false,
                    totalIndividu: response.data.totalItems || 0,
                  });
                })
                .catch(() => this.setState({ isLoading: false }));
              break;

            default:
              break;
          }
        }
        break;

      default:
        this.setState({ isLoading: false });
        break;
    }
  };

  initCheckBox = (checked) => {
    let initChecked;
    switch (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.data
    ) {
      case HEALTH:
        initChecked = {
          ...checked,
          showName: true,
          showAge: true,
          showSex: true,
          showFamilyChief: true,
          showHandicap: true,
          showTaille: true,
          showPoids: true,
          showPB: true,
          showEtatNutritionnel: true,
        };
        break;
      case ECONOMY:
        initChecked = {
          ...checked,
          showRegion: true,
          showDistrict: true,
          showCommune: true,
          showFokontany: true,
          showFamilyChief: true,
          showFrequenceRepas: true,
          showSecurity: true,
          showWaterDistance: true,
          showWaterSource: true,
        };
        break;

      case NUTRITION:
        initChecked = {
          ...checked,
          showIdMenage: true,
          showFamilyChief: true,
          showFrequenceRepas: true,
          // showRation: true,
        };
        break;
      case DEMO:
        if (this.props.location.state.search !== MENAGE) {
          initChecked = {
            ...checked,
            showName: true,
            showAge: true,
            showSex: true,
            showHandicap: true,
            showTaille: true,
            showPoids: true,
            showPB: true,
            showEtatNutritionnel: true,
          };
        } else {
          initChecked = {
            ...checked,
            showChef: true,
            showNbIndividus: true,
            showNbEnfant: true,
            showNbFemmeAllaitante: true,
            showNbFemmeEnceinte: true,
            showNbPersonneAge: true,
            showNbPersonneHandicape: true,
            showNbMAM: true,
            showNbMAS: true,
            showNbDENUTRI: true,
            showNomsMAM: true,
            showNomsMAS: true,
            showNomsDENUTRI: true,
          };
        }
        break;
      default:
        break;
    }
    this.setState({ checked: initChecked });
  };

  handleChange = (evt) => {
    const { checked } = this.state;
    if (evt.target.id === "showAll") {
      if (evt.target.checked) {
        checked.showRegion = evt.target.checked;
        checked.showDistrict = evt.target.checked;
        checked.showCommune = evt.target.checked;
        checked.showFokontany = evt.target.checked;
        checked.showHameau = evt.target.checked;
        checked.showIdMenage = evt.target.checked;
        checked.showFrequenceRepas = evt.target.checked;
        checked.showRation = evt.target.checked;

        switch (
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.data
        ) {
          case HEALTH:
            checked.showName = evt.target.checked;
            checked.showSex = evt.target.checked;
            checked.showAge = evt.target.checked;
            checked.showHandicap = evt.target.checked;
            checked.showTaille = evt.target.checked;
            checked.showPoids = evt.target.checked;
            checked.showPB = evt.target.checked;
            checked.showEtatNutritionnel = evt.target.checked;
            checked.showTel = evt.target.checked;
            break;
          case ECONOMY:
            checked.showActivity = evt.target.checked;
            checked.showRevenus = evt.target.checked;
            checked.showBienEtAvoir = evt.target.checked;
            checked.showDon = evt.target.checked;
            checked.showNbDeath = evt.target.checked;
            checked.showNbIndividu = evt.target.checked;
            checked.showFood = evt.target.checked;
            break;
          case NUTRITION:
            checked.showFamilyChief = evt.target.checked;
            checked.showActivity = evt.target.checked;
            checked.showRevenus = evt.target.checked;
            checked.showBienEtAvoir = evt.target.checked;
            checked.showDon = evt.target.checked;
            checked.showNbDeath = evt.target.checked;
            checked.showNbIndividu = evt.target.checked;
            checked.showFood = evt.target.checked;
            break;
          case DEMO:
            if (this.props.location.state.search !== MENAGE) {
              checked.showName = evt.target.checked;
              checked.showSex = evt.target.checked;
              checked.showAge = evt.target.checked;
              checked.showHandicap = evt.target.checked;
              checked.showTaille = evt.target.checked;
              checked.showPoids = evt.target.checked;
              checked.showPB = evt.target.checked;
              checked.showEtatNutritionnel = evt.target.checked;
              checked.showTel = evt.target.checked;
            } else {
              checked.showNbIndividus = evt.target.checked;
              checked.showChef = evt.target.checked;
              checked.showNbEnfant = evt.target.checked;
              checked.showNbFemmeAllaitante = evt.target.checked;
              checked.showNbFemmeEnceinte = evt.target.checked;
              checked.showNbPersonneAge = evt.target.checked;
              checked.showNbPersonneHandicape = evt.target.checked;
              checked.showNbMAM = evt.target.checked;
              checked.showNbMAS = evt.target.checked;
              checked.showNbDENUTRI = evt.target.checked;
              checked.showNomsMAM = evt.target.checked;
              checked.showNomsMAS = evt.target.checked;
              checked.showNomsDENUTRI = evt.target.checked;
            }
            break;
          default:
            break;
        }
      }
      checked.showAll = evt.target.checked;
    } else {
      checked[evt.target.id] = evt.target.checked;
      if (!evt.target.checked && checked.showAll)
        checked.showAll = evt.target.checked;

      if (
        checked.showRegion &&
        checked.showDistrict &&
        checked.showCommune &&
        checked.showHameau &&
        checked.showIdMenage &&
        checked.showFrequenceRepas &&
        checked.showRation
      ) {
        switch (
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.data
        ) {
          case HEALTH:
            if (
              checked.showTel &&
              checked.showSex &&
              checked.showAge &&
              checked.showHandicap &&
              checked.showTaille &&
              checked.showPoids &&
              checked.showPB &&
              checked.showEtatNutritionnel &&
              checked.showName
            )
              checked.showAll = evt.target.checked;
            break;
          case ECONOMY:
          case NUTRITION:
            if (
              checked.showFamilyChief &&
              checked.showActivity &&
              checked.showRevenus &&
              checked.showBienEtAvoir &&
              checked.showDon &&
              checked.showNbIndividu &&
              checked.showNbDeath &&
              checked.showFood
            )
              checked.showAll = evt.target.checked;
            break;
          case DEMO:
            if (this.props.location.state.search !== MENAGE) {
              if (
                checked.showTel &&
                checked.showSex &&
                checked.showAge &&
                checked.showHandicap &&
                checked.showTaille &&
                checked.showPoids &&
                checked.showPB &&
                checked.showEtatNutritionnel &&
                checked.showName
              )
                checked.showAll = evt.target.checked;
            } else {
              if (
                checked.showChef &&
                checked.showNbIndividus &&
                checked.showNbEnfant &&
                checked.showNbFemmeAllaitante &&
                checked.showNbFemmeEnceinte &&
                checked.showNbPersonneAge &&
                checked.showNbPersonneHandicape &&
                checked.showNbMAM &&
                checked.showNbMAS &&
                checked.showNbDENUTRI &&
                checked.showNomsMAM &&
                checked.showNomsMAS &&
                checked.showNomsDENUTRI
              ) {
                checked.showAll = evt.target.checked;
              }
            }
            break;
          default:
            break;
        }
      }
    }
    this.setState({
      ...this.state,
      checked,
    });
  };

  handleChangePage = (event, index) => {
    event.preventDefault();

    if (index > 0 && index < this.getTotalPage())
      this.setState(
        {
          currentPage: index,
        },
        () => this.getListIndividu()
      );
  };

  handlePage = (event) => {
    this.setState({ [event.target.id]: parseInt(event.target.value) }, () =>
      this.getListIndividu()
    );
  };

  componentDidMount() {
    const { checked } = this.state;
    this.initCheckBox(checked);
    this.getListIndividu();
  }

  toggle = () => {
    const { checked } = this.state;
    let { initChecked } = this.state;
    initChecked = {
      ...checked,
    };
    this.setState({ showModal: !this.state.showModal, initChecked });
  };

  getTitle = () => {
    let title = "";
    const menage = (this.props.location &&
      this.props.location.state &&
      this.props.location.state.menage) || {
      commune: "",
      district: "",
      region: "",
      fokotany: "",
    };
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.data
    ) {
      switch (this.props.location.state.data) {
        case HEALTH:
          title += "Liste des individus / Etat de santé : ";
          if (
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.search
          ) {
            switch (this.props.location.state.search) {
              case HEALED:
                title += "Guéris / ";
                break;
              case NEW_CASE:
                title += "Nouveaux Cas / ";
                break;
              case BEING_PROCESSED:
                title += "En cours de traitement /";
                break;
              case DEATH:
                title += "Décés /";
                break;
              case DMAS_ENFANT:
                title += "Detecté MAS (enfant)/";
                break;
              case DMAM_ENFANT:
                title += "Detecté MAM (enfant)/";
                break;
              case MASMAM_ENFANT:
                title += "MAS transformé en MAM (enfant)/";
                break;
              case MAMMAS_ENFANT:
                title += "MAM transformé en MAS (enfant)/";
                break;
              case NON_ENFANT:
                title += "Non classifié (enfant)/";
                break;
              case BON_ENFANT:
                title += "En bon état (enfant)/";
                break;
              case DMAS_ADOS:
                title += "Detecté MAS (ADOS)/";
                break;
              case DMAM_ADOS:
                title += "Detecté MAM (ADOS)/";
                break;
              case MASMAM_ADOS:
                title += "MAS transformé en MAM (ADOS)/";
                break;
              case MAMMAS_ADOS:
                title += "MAM transformé en MAS (ADOS)/";
                break;
              case NON_ADOS:
                title += "Non classifié (ADOS)/";
                break;
              case BON_ADOS:
                title += "En bon état (ADOS)/";
                break;
              case NUTRI:
                title += "En bon état (Adulte)/";
                break;
              case MAL:
                title += "Mal nutrition (Adulte)/";
                break;

              case NON_ADULTE:
                title += "Non classifié (Adulte)/";
                break;
              default:
                break;
            }
          }
          break;
        case NUTRITION:
          title += "Liste des menages / Situation alimentaire : ";
          if (
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.search
          ) {
            switch (this.props.location.state.search) {
              case NUTRITION_3FOIS:
                title += "3 fois par jour/";
                break;
              case NUTRITION_2FOIS:
                title += "2 fois par jour/";
                break;
              case NUTRITION_1FOIS:
                title += "1 fois par jour/";
                break;
              case NUTRITION_PLUS_2FOIS:
                title += "Plus de 2 fois par jour/";
                break;
              case NUTRITION_MOIN_1FOIS:
                title += "Moins 1 fois par jour/";
                break;
              case NUTRITION_1FOIS_OU_MOINS:
                title += "1 fois par jour ou moins/";
                break;
              case NOT_SPECIFIED_NUTRI:
                title += "Non renseigné/";
                break;
              case FREQUENCE:
                title += "Fréquence repas / ";
                break;
              case RATION:
                title += "Ration /";
                break;
              default:
                break;
            }
          }
          break;
        case ECONOMY:
          title += "Liste des menages / Situation économique : ";
          if (
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.search
          ) {
            switch (this.props.location.state.search) {
              case REVENU_0_100:
                title += "Revenus 0 à 100 000 Ar / ";
                break;
              case REVENU_100_200:
                title += "Revenus 100 000 à 200 000 Ar / ";
                break;
              case REVENU_200_300:
                title += "Revenus 200 000 à 300 000 Ar / ";
                break;
              case REVENU_300_400:
                title += "Revenus 300 000 à 400 000 Ar / ";
                break;
              case REVENU_PLUS_400:
                title += "Revenus plus de 400 000 Ar / ";
                break;
              case REVENU_NON:
                title += "Revenus non renseigné / ";
                break;
              case FIAVOTA:
                title += "Fiavota / ";
                break;
              case TOSIKA_FAMENO:
                title += "Tosika Fameno / ";
                break;
              case GFD:
                title += "GFD / ";
                break;
              case NOT_SPECIFIED:
                title += "Non renseigné / ";
                break;
              case NOT_SPECIFIED_SECURITY:
                title += "Sécurité non renseignée / ";
                break;
              case IN_SAFE:
                title += "En sécurité / ";
                break;
              case IN_ALERT:
                title += "En alerte / ";
                break;
              case RED_ZONE:
                title += "Zone rouge / ";
                break;
              case AUTRE:
                title += "Source d'eau : Autre / ";
                break;
              case POMPE:
                title += "Source d'eau : Pompe / ";
                break;
              case PUIT:
                title += "Source d'eau : Puits / ";
                break;
              case FLEUVE:
                title += "Source d'eau : Fleuve / ";
                break;
              case MOINS_1_KILOMETER:
                title += "Distance point d'eau : -1 km / ";
                break;
              case NOT_SPECIFIED_WATER:
                title += "Distance point d'eau : non renseignée / ";
                break;
              case BETWEEN_1_TO_5_KILOMETER:
                title += "Distance point d'eau : De 1 à 5 km / ";
                break;
              case BETWEEN_5_TO_10_KILOMETER:
                title += "Distance point d'eau : De 5 à 10 km / ";
                break;
              case PLUS_10_KILOMETER:
                title += "Distance point d'eau : +10 km / ";
                break;
              default:
                break;
            }
          }
          break;
        case DEMO:
          if (
            this.props.location &&
            this.props.location.state.search === MENAGE
          ) {
            title += "Liste des ménages / démographique : ";
          } else {
            title += "Liste des individus / démographique : ";
          }
          if (
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.search
          ) {
            switch (this.props.location.state.search) {
              case MENAGE:
                title += "nombre de menage /";
                break;
              case MOIN5ANS:
                title += "moins de 5 ans / ";
                break;
              case PLUS65ANS:
                title += "plus de 65 ans  / ";
                break;
              case ALLAITANTE:
                title += "allaitante /";
                break;
              case ENCEINTE:
                title += "enceinte /";
                break;
              case HANDICAPE:
                title += "handicape /";
                break;

              default:
                break;
            }
          }
          break;
        default:
          break;
      }
      title +=
        " Région : " +
        menage.region +
        " District : " +
        menage.district +
        " Commune : " +
        menage.commune +
        " Fokontany : " +
        menage.fokotany;
    }
    return <Label style={{ textAlign: "center" }}>{title}</Label>;
  };

  cancel = () => {
    const { initChecked } = this.state;
    this.setState({ checked: initChecked, showModal: !this.state.showModal });
  };

  render() {
    const roleName = localStorage.getItem("roleName");
    if(!user_viewer.includes(roleName)){
      this.props.history.push("/admin/gestion-menage");
    }
    const {
      checked,
      currentPage,
      pageSize,
      nutriCheck,
      ecoCheck,
      santeCheck,
      demoCheck,
    } = this.state;

    const tableData =
      this.state.listIndividu &&
      this.state.listIndividu.map((user, index) => (
        <tr key={index}>
          {checked && checked.showName && (
            <td>{user.nom !== "Null" ? user.nom : ""}</td>
          )}
          {checked && checked.showRegion && (
            <td>{user.region !== "Null" ? user.region : ""}</td>
          )}
          {checked && checked.showDistrict && (
            <td>{user.district !== "Null" ? user.district : ""}</td>
          )}
          {checked && checked.showCommune && (
            <td>{user.commune !== "Null" ? user.commune : ""}</td>
          )}
          {checked && checked.showFokontany && (
            <td>{user.fokotany !== "Null" ? user.fokotany : ""}</td>
          )}
          {checked && checked.showHameau && (
            <td>{user.hameau !== "Null" ? user.hameau : ""}</td>
          )}
          {ecoCheck && checked && checked.showIdMenage && (
            <td>{user.adresse !== "Null" ? user.adresse : ""}</td>
          )}
          {nutriCheck && checked && checked.showIdMenage && (
            <td>{user.id !== "Null" ? user.id : ""}</td>
          )}
          {santeCheck && checked && checked.showIdMenage && (
            <td>{user.menageid !== "Null" ? user.menageid : ""}</td>
          )}
          {/* {santeCheck && checked && checked.showFamilyChief && (
                        <td>{user.chef !== "Null" ? user.chef : ""}</td>
                    )} */}
          {checked && checked.showTel && (
            <td>{user.telephone !== "Null" ? user.telephone : ""}</td>
          )}
          {ecoCheck && checked && checked.showFrequenceRepas && (
            <td>{user.frequencerepo !== "Null" ? user.frequencerepo : ""}</td>
          )}
          {demoCheck && checked && checked.showIdMenage && (
            <td>{user.id !== "Null" ? user.id : ""}</td>
          )}
          {demoCheck && checked && checked.showFrequenceRepas && (
            <td>{user.frequencerepo !== "Null" ? user.frequencerepo : ""}</td>
          )}
          {nutriCheck && checked && checked.showFrequenceRepas && (
            <td>
              {user.frequencerepo !== "Null" ? user.frequencerepo : ""}
            </td>
          )}
          {santeCheck && checked && checked.showFrequenceRepas && (
            <td>{user.frequencerepo !== "Null" ? user.frequencerepo : ""}</td>
          )}
          {checked && checked.showRation && (
            <td>
              {user.ration && user.ration !== "Null"
                ? user.ration.toLowerCase().includes("ration")
                  ? user.ration.toLowerCase().replaceAll("ration", "portion")
                  : user.ration
                : ""}
            </td>
          )}
          {checked && checked.showSex && (
            <td>{user.sexe !== "Null" ? user.sexe : ""}</td>
          )}
          {checked && checked.showAge && (
            <td>{user.age !== "Null" ? user.age : ""}</td>
          )}
          {checked && checked.showHandicap && (
            <td>{user.handicap !== "Null" ? user.handicap : ""}</td>
          )}
          {checked && checked.showTaille && (
            <td>{user.taille !== "Null" ? user.taille : ""}</td>
          )}
          {checked && checked.showPoids && (
            <td>{user.poids !== "Null" ? user.poids : ""}</td>
          )}
          {checked && checked.showPB && (
            <td>{user.pb !== "Null" ? user.pb : ""}</td>
          )}
          {checked && checked.showEtatNutritionnel && (
            <td>
              {user.etat_nutritionnel !== "Null" ? user.etat_nutritionnel : ""}
            </td>
          )}
          {checked && checked.showFamilyChief && (
            <td>{user.chef !== "Null" ? user.chef : ""}</td>
          )}
          {checked && checked.showActivity && (
            <td>{user.activite !== "Null" ? user.activite : ""}</td>
          )}
          {checked && checked.showRevenus && (
            <td>{user.revenu !== "Null" ? user.revenu : ""}</td>
          )}
          {checked && checked.showBienEtAvoir && (
            <td>{user.bien_avoirs !== "Null" ? user.bien_avoirs : ""}</td>
          )}
          {checked && checked.showDon && (
            <td>{user.don !== "Null" ? user.don : ""}</td>
          )}
          {checked && checked.showNbIndividu && (
            <td>{user.nb_individu !== "Null" ? user.nb_individu : ""}</td>
          )}
          {checked && checked.showNbDeath && (
            <td>{user.nb_deces !== "Null" ? user.nb_deces : ""}</td>
          )}
          {checked && checked.showFood && (
            <td>{user.nourriture !== "Null" ? user.nourriture : ""}</td>
          )}
          {checked && checked.showWaterSource && (
            <td>{user.sourceEau !== "Null" ? user.sourceEau : ""}</td>
          )}
          {checked && checked.showWaterDistance && (
            <td>{user.distanceEau !== "Null" ? user.distanceEau : ""}</td>
          )}
          {checked && checked.showSecurity && (
            <td>
              {user.niveauInsecurite !== "Null" ? user.niveauInsecurite : ""}
            </td>
          )}
          {checked && checked.showChef && (
            <td>{user.chef !== "Null" ? user.chef : ""}</td>
          )}
          {checked && checked.showNbIndividus && (
            <td>{user.chef !== "Null" ? user.nbIndividus : ""}</td>
          )}
          {checked && checked.showNbEnfant && (
            <td>{user.nbEnfant !== "Null" ? user.nbEnfant : ""}</td>
          )}
          {checked && checked.showNbFemmeAllaitante && (
            <td>
              {user.nbFemmeAllaitante !== "Null" ? user.nbFemmeAllaitante : ""}
            </td>
          )}
          {checked && checked.showNbFemmeEnceinte && (
            <td>
              {user.nbFemmeEnceinte !== "Null" ? user.nbFemmeEnceinte : ""}
            </td>
          )}
          {checked && checked.showNbPersonneAge && (
            <td>{user.nbPersonneAge !== "Null" ? user.nbPersonneAge : ""}</td>
          )}
          {checked && checked.showNbPersonneHandicape && (
            <td>
              {user.nbPersonneHandicape !== "Null"
                ? user.nbPersonneHandicape
                : ""}
            </td>
          )}
          {checked && checked.showNbMAM && (
            <td>{(user.nbMAM !== "Null" && user.nbMAM!=='0') ? user.nbMAM : ""}</td>
          )}
          {checked && checked.showNbMAS && (
            <td>{(user.nbMAS !== "Null" && user.nbMAS!=='0')  ? user.nbMAS : ""}</td>
          )}

          {checked && checked.showNbDENUTRI && (
            <td>{(user.nbDENUTRI !== "Null" && user.nbDENUTRI!=='0') ? user.nbDENUTRI : ""}</td>
          )}

          {checked && checked.showNomsMAM && (
            <td>{user.nomsMAM !== "Null" ? user.nomsMAM.toString().replace(/"/g, '') : ""}</td>
          )}
          {checked && checked.showNomsMAS && (
            <td>{user.nomsMAS !== "Null" ? user.nomsMAS.toString().replace(/"/g, '') : ""}</td>
          )}
          {checked && checked.showNomsDENUTRI && (
            <td>{user.nomsDENUTRI !== "Null" ? user.nomsDENUTRI.toString().replace(/"/g, '') : ""}</td>
          )}
        </tr>
      ));
  
    return (
      <>
        <div className="header bg-gradient-primary postition-absolute">
        <AlertModal
        onClose={this.closeAlert}
        show={this.state.showAlert}
        title="Une erreur s'est produite. Veuillez réessayer svp">
          </AlertModal>
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row></Row>
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt-5 custom-card-container" fluid  style={{ maxHeight: "100vh" }}>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-1">
                  <Row style={{ alignItems: "center" }}>
                    <Col md={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.toggle}
                      >
                        Afficher les colonnes
                      </Button>
                    </Col>
                    <Col md={6}>{this.getTitle()}</Col>
                    <Col md={3}>
                      <Container
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center ",
                          paddingRight: 0,
                        }}
                      >
                        <Label
                          style={{ marginLeft: 10, fontSize: 16, marginTop: 6 }}
                        >
                          Nombre de lignes
                        </Label>
                        <Input
                          id="pageSize"
                          type="select"
                          width={50}
                          style={{
                            display: "inline-block",
                            width: 80,
                            marginLeft: 10,
                            height: 40,
                          }}
                          value={pageSize}
                          onChange={this.handlePage}
                        >
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={30}>30</option>
                          <option value={40}>40</option>
                          <option value={50}>50</option>
                        </Input>
                      </Container>
                    </Col>
                  </Row>
                </CardHeader>
                <Table
                  className="align-items-center table-flush table"
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      {checked && checked.showName && (
                        <th scope="col">Nom et Prénoms</th>
                      )}
                      {checked && checked.showRegion && (
                        <th scope="col">Region</th>
                      )}
                      {checked && checked.showDistrict && (
                        <th scope="col">District</th>
                      )}
                      {checked && checked.showCommune && (
                        <th scope="col">Commune</th>
                      )}
                      {checked && checked.showFokontany && (
                        <th scope="col">fokontany</th>
                      )}
                      {checked && checked.showHameau && (
                        <th scope="col">Hameau</th>
                      )}
                      {checked && checked.showIdMenage && (
                        <th scope="col">Id Menage</th>
                      )}
                      {checked && checked.showFrequenceRepas && (
                        <th scope="col">Fréquence repas</th>
                      )}
                      {checked && checked.showTel && (
                        <th scope="col">Téléphone</th>
                      )}
                      {checked && checked.showRation && (
                        <th scope="col">Ration</th>
                      )}
                      {checked && checked.showSex && <th scope="col">Genre</th>}
                      {checked && checked.showAge && <th scope="col">Age</th>}
                      {checked && checked.showHandicap && (
                        <th scope="col">Handicap</th>
                      )}
                      {checked && checked.showTaille && (
                        <th scope="col">Taille</th>
                      )}
                      {checked && checked.showPoids && (
                        <th scope="col">Poids</th>
                      )}
                      {checked && checked.showPB && <th scope="col">PB</th>}
                      {checked && checked.showEtatNutritionnel && (
                        <th scope="col">Etat nutritionnel</th>
                      )}
                      {checked && checked.showFamilyChief && (
                        <th scope="col">Chef de famille</th>
                      )}
                      {checked && checked.showActivity && (
                        <th scope="col">Activité(s)</th>
                      )}
                      {checked && checked.showRevenus && (
                        <th scope="col">Revenus</th>
                      )}
                      {checked && checked.showBienEtAvoir && (
                        <th scope="col">Biens et avoirs</th>
                      )}
                      {checked && checked.showDon && <th scope="col">Don</th>}
                      {checked && checked.showNbIndividu && (
                        <th scope="col">Nombre d'individu</th>
                      )}
                      {checked && checked.showNbDeath && (
                        <th scope="col">Nombre de décés</th>
                      )}
                      {checked && checked.showFood && (
                        <th scope="col">Nourriture</th>
                      )}
                      {checked && checked.showWaterSource && (
                        <th scope="col">Source d'eau</th>
                      )}
                      {checked && checked.showWaterDistance && (
                        <th scope="col">Distance eau</th>
                      )}
                      {checked && checked.showSecurity && (
                        <th scope="col">Niveau d'insécurité</th>
                      )}
                      {checked && checked.showChef && (
                        <th scope="col">Chef de menage</th>
                      )}
                      {checked && checked.showNbIndividus && (
                        <th scope="col">Nombre individus</th>
                      )}
                      {checked && checked.showNbEnfant && (
                        <th scope="col">Nombre enfant moins de 5 ans</th>
                      )}
                      {checked && checked.showNbFemmeAllaitante && (
                        <th scope="col">Nombre Femme allaitante</th>
                      )}
                      {checked && checked.showNbFemmeEnceinte && (
                        <th scope="col">Nombre Femme enceinte</th>
                      )}
                      {checked && checked.showNbPersonneAge && (
                        <th scope="col">Nombre personne plus de 65 ans</th>
                      )}
                      {checked && checked.showNbPersonneHandicape && (
                        <th scope="col">Nombre personnes handicapées</th>
                      )}
                       {checked && checked.showNbMAM && (
                        <th scope="col">Nombre MAM</th>
                      )}
                       {checked && checked.showNbMAS && (
                        <th scope="col">Nombre MAS</th>
                      )}
                       {checked && checked.showNbDENUTRI && (
                        <th scope="col">Nombre Dénutri</th>
                      )}
                       {checked && checked.showNomsMAM && (
                        <th scope="col">Personne MAM</th>
                      )}
                       {checked && checked.showNomsMAS && (
                        <th scope="col">Personne MAS</th>
                      )}
                       {checked && checked.showNomsDENUTRI && (
                        <th scope="col">Personne Dénutrie</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>{tableData}</tbody>
                </Table>
                <FooterTable
                  currentPage={currentPage}
                  handlePage={this.handlePage}
                  handleChangePage={this.handleChangePage}
                  getTotalPage={this.getTotalPage}
                >
                  <Button className="btn" onClick={this.exportData}>
                    Exporter
                  </Button>
                </FooterTable>
              </Card>
            </div>
          </Row>

          <PageSpinner show={this.state.isLoading} />
        </Container>
        <PopupChech
          showModal={this.state.showModal}
          handleChange={this.handleChange}
          toggle={this.toggle}
          checked={this.state.checked}
          cancel={this.cancel}
          {...this.props}
        />
      </>
    );
  }
}

export default FicheTraitementPage;
