import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import CardFooter from "reactstrap/lib/CardFooter";
import AuthService from "services/AuthService";
import "../../App.css";
import logoUGD from  "../../assets/img/brand/logoUGD.png";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      loading: false,
      message: "",
      requiredFields: {
        username: "",
        password: "",
      },
    };

    this.handleUsernameChange.bind(this);
    this.handlePasswordChange.bind(this);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.formValid() && this.handleLogin();
  };

  formValid = () => {
    let validation = true;
    let username = "";
    let password = "";
    if (this.state.username === "") {
      validation = false;
      username = "champ obligatoire";
    }
    if (this.state.password === "") {
      validation = false;
      password = "champ obligatoire";
    }

    this.setState({
      requiredFields: {
        ...this.state.requiredFields,
        username,
        password,
      },
    });
    return validation;
  };

  handleLogin = () => {
    this.setState({
      message: "",
      loading: true,
    });
    // event.preventDefault();

    //  this.props.history.push("/admin");
    AuthService.login(this.state.username, this.state.password).then(
      () => {
        this.props.history.push("/admin");
      },
      (error) => {
        const resMessage = error.message.toString();
        this.setState({
          loading: false,
          message: "Utilisateur ou mot de passe érroné",
        });
      }
    );
  };

  handleUsernameChange = (event) => {
    const username = event.target.value === "" ? "champ obligatoire" : "";
    this.setState({
      username: event.target.value,
      message:'',
      requiredFields: { ...this.state.requiredFields, username },
    });
  };

  handlePasswordChange = (event) => {
    const password = event.target.value === "" ? "champ obligatoire" : "";
    this.setState({
      password: event.target.value,
      message:'',
      requiredFields: { ...this.state.requiredFields, password },
    });
  };
  render() {
    return (
      <>
        <Col lg="6" md="8" sm="10" xs="12" className="login-block">
          <Card className="bg-light-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-2">              
              <div className="text-muted text-center m-2">
                <img
                  src={require("assets/img/theme/logo.png")}
                  alt="profile-img"
                  className="img"
                />
              </div>             
            </CardHeader>
            <h3 className="text-center login-title text-white bg-gradient-primary">
              RESCUE-DATA
            </h3>
            <CardBody className="px-lg-5 py-lg-3">
              <Form role="form">
                <FormGroup className="mb-3">
                <InputGroup className={"input-group-alternative "+ (this.state.requiredFields.username !== ""&&"border-danger" )}>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Utilisateur"
                      type="text"
                      autoComplete="new-email"
                      value={this.state.username}
                      onChange={this.handleUsernameChange}
                    />
                  </InputGroup>
                  {this.state.requiredFields.username !== "" && (
                    <h6 className="text-danger m-1">Le champ est obligatoire</h6>
                  )}
                </FormGroup>
                <FormGroup >
                  <InputGroup className={"input-group-alternative "+ (this.state.requiredFields.password !== ""&&"border-danger" )}>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Mot de passe"
                      type="password"
                      value={this.state.password}
                      onChange={this.handlePasswordChange}
                      autoComplete="new-password"
                    />
                  </InputGroup>
                  {this.state.requiredFields.password !== "" && (
                    <h6 className="text-danger m-1">Le champ est obligatoire</h6>
                  )}
                </FormGroup>
                
                <div className="text-center">
                  <Button
                    className="my-2"
                    color="primary"
                    type="submit"
                    onClick={this.handleSubmit.bind(this)}
                  >
                    {this.state.loading ? (
                      <>
                        <span className="spinner-border spinner-border-sm text-center"></span>
                        <span> Connexion ...</span>
                      </>
                    ) : (
                      <span>Se connecter</span>
                    )}
                  </Button>

                  {this.state.message && (
                    <p className="text-danger">{this.state.message} !</p>
                  )}
                </div>
                
              </Form>
              
            </CardBody>
            <CardFooter className="bg-transparent py-2 ">
              <Row>
              <div className="d-flex justify-content-start align-items-center col-4">
                <a href="#" className="text-left">
                  <small>Powered by MNDPT</small>
                </a>
              </div>
              <div className="d-flex justify-content-center align-items-center col-4">
                    <img style={{width: 100}} src={logoUGD} alt="Logo UGD" className="logo-ugd" />
              </div>
              <div className="d-flex justify-content-end align-items-center col-4">
                <small className="text-right">&#169; 2022</small>
              </div>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </>
    );
  }
}

export default LoginPage;
