import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import articleService from "services/ArticleService";
import categoryService from "services/CategoryService";
import unityService from "services/UnityService";
import ConfirmationModal from "views/components/ConfirmationModal";
import NewArticleModal from "views/components/NewArticleModal";
import PageSpinner from "views/components/PageSpinner";
import FooterTable from "components/Footer/Footer";

class ArticlesPage extends React.Component {
  state = {
    articleId: "",
    articleList: [
      {
        categorie_id: 0,
        id: 0,
        libelle: "string",
        libelle_categori: "string",
        libelle_unite: "string",
        unite_id: 0,
      },
    ],
    selectedArticle: {},
    currentPage: 0,
    totalArticle: 0,
    pageSize: 10,
    showModal: false,
    confirmationModal: false,
    categoryList: [],
    unityList: [],
    checkerField: {
      isArticleIdEmpty: false,
      isDesignationEmpty: false,
    },
  };

  componentDidMount() {
    this.getUnityListFromWS();
    this.getCategoryListFromWs();
    this.getArticleListFromWS();
  }

  getArticleListFromWS = () => {
    this.setState({ isLoading: true });
    articleService
      .getAllArticles()
      .then((response) => response.data)
      .then((result) => {
        this.setState({
          isLoading: false,
          articleList: result.data || [],
        });
      })
      .catch(() => this.setState({ isLoading: false, articleList: [] }));
  };

  getCategoryListFromWs = () => {
    this.setState({ isLoading: true });
    categoryService
      .getAllCategories()
      .then((response) => response.data)
      .then((result) => {
        this.setState({
          isLoading: false,
          categoryList: result.data || [],
        });
      })
      .catch(() => this.setState({ isLoading: false, categoryList: [] }));
  };

  getUnityListFromWS = () => {
    this.setState({ isLoading: true });
    unityService
      .getAllUnities()
      .then((response) => response.data)
      .then((result) => {
        this.setState({
          isLoading: false,
          unityList: result.data || [],
        });
      })
      .catch(() => this.setState({ isLoading: false, unityList: [] }));
  };

  onUpdateArticle = (article) => {
    const { articleList } = this.state;

    this.setState({
      selectedArticle: article,
      showModal: true,
    });
  };

  onSaveArticle = () => {
    this.setState({
      showModal: true,
      selectedArticle: {},
    });
  };

  saveNewArticle = () => {
    const { selectedArticle } = this.state;
    this.setState({ isLoading: true });
    if (!this.checkField()) {
      articleService
        .createArticle(selectedArticle)
        .then((response) => response.data)
        .then((result) => this.getArticleListFromWS())
        .then(() => this.setState({ articleList: [], isLoading: false }));
        
      return true ; // success
    }
    else {
      this.setState({ isLoading: false });
      return false ; // failure
    }
  };

  update = () => {
    this.setState({ isLoading: true });
    if (!this.checkField()) {
      articleService.updateArticle(this.state.selectedArticle).then(
        (data) => {
          this.getArticleListFromWS();
        },
        (error) => {
          console.log("erreur serveur:", error);

          this.setState({ isLoading: false });
          return false ; // failure
        }
      );
      return true ; // success
    }
    else {
      this.setState({ isLoading: false });
      return false ; // failure
    }
  };

  checkField = () => {
    const { checkerField, selectedArticle } = this.state;
    if (!selectedArticle.libelle || selectedArticle.libelle === "")
      checkerField.isDesignationEmpty = true;
    else checkerField.isDesignationEmpty = false;

    if (!selectedArticle.articleId || selectedArticle.articleId === "")
      checkerField.isArticleIdEmpty = true;
    else checkerField.isArticleIdEmpty = false;
    this.setState({ checkerField });
    return checkerField.isArticleIdEmpty || checkerField.isDesignationEmpty;
  };

  deleteArticle = () => {
    articleService.deleteArticle(this.state.selectedArticle.id).then(
      (data) => {
        this.getArticleListFromWS();
      },
      (error) => {
        console.log("erreur serveur:", error);
      }
    );
  };

  getDataByIdFromList = (id, dataList, initData) => {
    const data = dataList && dataList.filter((item) => item.id === id);
    if (data.length) return data[0];
    else return initData;
  };

  handleChange = (evt) => {
    evt.preventDefault();
    const { selectedArticle, checkerField } = this.state;
    switch (evt.target.id) {
      case "category":
        const { categoryList } = this.state;
        const categoryOutput =
          categoryList &&
          categoryList.length > 0 &&
          categoryList.filter((item) => item.id === parseInt(evt.target.value));
        selectedArticle.categorie =
          categoryOutput.length > 0 ? categoryOutput[0] : null;
        break;
      case "unity":
        const { unityList } = this.state;
        const unityOutput =
          unityList &&
          unityList.length > 0 &&
          unityList.filter((item) => item.id === parseInt(evt.target.value));
        selectedArticle.unite = unityOutput.length > 0 ? unityOutput[0] : null;
        break;
      default:
        const eventId = evt.target.id;
        const eventValue = evt.target.value;
        selectedArticle[eventId] = eventValue;
        if (eventId === "articleId" && eventValue === "")
          checkerField.isArticleIdEmpty = true;
        else checkerField.isArticleIdEmpty = false;
        if (eventId === "libelle" && eventValue === "")
          checkerField.isDesignationEmpty = true;
        else checkerField.isDesignationEmpty = false;
        break;
    }

    this.setState({ selectedArticle, checkerField });
  };

  getArticleList = () => {
    const { articleList } = this.state;
    return (
      articleList &&
      articleList.map((article, index) => (
        <tr key={index}>
          <td>{article.articleId}</td>
          <td>{article.libelle}</td>
          <td>{(article.categorie && article.categorie.libelle) || ""}</td>
          <td>{(article.unite && article.unite.libelle) || ""}</td>
          <td align="right">
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-icon-only text-light"
                href="#"
                role="button"
                size="sm"
                color=""
                outline={true}
                style={{ zIndex: 1 }}
                onClick={(e) => e.preventDefault()}>
                <i className="fas fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" container="body" positionFixed={true}  right>
                <DropdownItem
                  href="#"
                  className="clickable"
                  onClick={() => {
                    this.onUpdateArticle(article);
                  }}>
                  Modifier Article
                </DropdownItem>
                <DropdownItem
                  href="#"
                  className="clickable"
                  onClick={() => {
                    this.showConfirmationModal(article);
                  }}>
                  Supprimer Article
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>
      ))
    );
  };

  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
      selectedArticle: {},
      isLoading: false,
    });
  };

  handleChangePage = (event, index) => {
    event.preventDefault();
    if (index > 0 && index < this.getTotalPage())
      this.setState(
        {
          currentPage: index,
        },
        () => this.getArticleList()
      );
  };

  handlePage = (event) => {
    this.setState({ [event.target.id]: parseInt(event.target.value) }, () =>
      this.getArticleList()
    );
  };

  getTotalPage = () => {
    const { totalArticle, pageSize } = this.state;
    return Math.ceil(totalArticle / pageSize);
  };
  cancelConfirmation = () => {
    this.setState({
      confirmationModal: false,
      showModal: false,
    });
  };
  saveConfirmation = () => {
    this.deleteArticle();
    this.setState({
      confirmationModal: false,
    });
  };
  showConfirmationModal = (item) => {
    this.setState({
      confirmationModal: true,
      selectedArticle: item,
    });
  };

  render() {
    const roleName = localStorage.getItem("roleName");
    if (roleName !== "superadmin" && roleName !== "adminadd") {
      this.props.history.push("/admin/accueil");
    }
    const {
      currentPage,
      isLoading,
      showModal,
      selectedArticle,
      unityList,
      categoryList,
      checkerField,
    } = this.state;
    return (
      <>
        {this.state.confirmationModal && (
          <ConfirmationModal
            onCancel={this.cancelConfirmation}
            onSave={this.saveConfirmation}
          />
        )}
        {
          this.props.showHeader != false || this.props.showHeader == true ?
          <div className="header bg-gradient-primary postition-absolute">
            <Container fluid>
              <div className="header-body">
                <Row></Row>
              </div>
            </Container>
          </div>
          : ""
        }

        <Container className="mb-7" fluid>
          <Row >
            <div className="col">
              <Card className="shadow" style={{ height: 500 }}>
                <CardHeader className="border-0 d-flex justify-content-end">
                    <Button className="btn" onClick={this.onSaveArticle}>
                      Créer un article
                    </Button>
                </CardHeader>
                <div className="table-responsive">
                  <table
                    className="table table-bordered align-items-center table-flush table"
                    responsive="true">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" />
                        <th scope="col">Identifiant Articles</th>
                        <th scope="col">Désignation</th>
                        <th scope="col">Catégorie</th>
                        <th scope="col">Unité de gestion</th>
                      </tr>
                    </thead>
                    <tbody>{this.getArticleList()}</tbody>
                  </table>
                </div>
                <FooterTable
                  currentPage={currentPage}
                  handlePage={this.handleChange}
                  handleChangePage={this.handleChangePage}
                  getTotalPage={this.getTotalPage}
                />
              </Card>
            </div>
          </Row>
          {this.state.showModal && (
            <NewArticleModal
              showModal={showModal}
              toggle={this.toggle}
              selectedArticle={selectedArticle}
              unityList={unityList}
              categoryList={categoryList}
              handleChange={this.handleChange}
              save={this.saveNewArticle}
              cancel={this.cancelConfirmation}
              update={this.update}
              checkerField={checkerField}
            />
          )}
          <PageSpinner show={isLoading} />
        </Container>
      </>
    );
  }
}

export default ArticlesPage;
