import React from "react";
// reactstrap components
import { Button, Modal } from "reactstrap";

export const AlertModal = (props) => {
  const [state, setState] = React.useState();
  const toggleModal = (state) => {
    setState({ ...this.state, [state]: state[state] });
  };

  return (
    <>
      {props && props.show && (
        <Modal
          className="modal-dialog-centered"
          isOpen={true}
          toggle={() => toggleModal("exampleModal")}
        >
          <div className="modal-header text-center">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => toggleModal("exampleModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body text-center">
            <p>{props.title}</p>
          </div>
          <div className="modal-footer">
            <Button onClick={props.onClose} color="primary" type="button">
              Fermer
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};
