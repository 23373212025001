export function Download(response) {
  const { data } = response;
  const downloadUrl = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");

  const filename = response.request
    .getResponseHeader("Content-Disposition")
    .split("filename=")[1];

  link.href = downloadUrl;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

