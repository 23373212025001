import React from 'react';
import "../../App.css";

const PageSpinner = ({ show = false, color = 'secondary' }) => {
  return (show && (
  
      <div className='loading'  />
   
  )) || null
}

export default PageSpinner
