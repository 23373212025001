import AdminFooter from "components/Footers/AdminFooter.js";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
import routes from "routes.js";
import SettingsPage from "views/pages/SettingsPage";
import UtilisateurPage from "views/pages/UtilisateurPage";
import DataContext from "context/DataContext";
import ZonnageService from "services/ZonnageService";
import PageSpinner from "views/components/PageSpinner";

class Admin extends React.Component {
  state = {
    listRegion: [],
    listDistrict: [],
    listCommune: [],
    listFokotany: [],
    displayListDistrict: [],
    displayListCommune: [],
    displayListFokotany: [],
    displayListRegion: [],
    isLoading: false,
    test: "",
  };

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user || !user.token) {
      this.props.history.push({ pathname: "/auth" });
    } else {
      this.getRegion();
      this.getDistrict();
      this.getCommune();
      this.getFokotany();
      this.setState({ test: "test" });
    }
  }



  getCommune = () => {
    ZonnageService.getListCommune("", "", "")
      .then((res) => res.data)
      .then((result) => {
        this.setState({
          listCommune: result.data,
          displayListCommune: [],
        });
      });
  };

  getFokotany = () => {
    ZonnageService.getListFokotany("", "", "", "")
      .then((res) => {
        return res.data;
      })
      .then((result) => {
        this.setState({
          listFokotany: result.data,
          displayListFokotany: [],
          isLoading: false,
        });
      });
  };

  getDistrict = () => {
    ZonnageService.getListDistrict("", "")
      .then((res) => res.data)
      .then((result) => {
        this.setState({
          listDistrict: result.data,
          displayListDistrict: [],
        });
      });
  };

  getRegion = () => {
    this.setState({ isLoading: true });
    ZonnageService.getListRegion("")
      .then((res) => res.data)
      .then((result) => {
        console.log("result", result);
        this.setState({
          listRegion: result.data,
          displayListRegion: result.data,
        });
      });
  };

  initAllDiplays = () => {

    this.setState({
      displayListRegion: this.state.listRegion,
      displayListDistrict: [],
      displayListCommune: [],
      displayListFokotany: [],
    });
  }

  getLocalisationBis = (name, value, callback, options) => {
    let { displayListDistrict, displayListCommune, displayListFokotany } =
      this.state;
    const { listRegion, listDistrict, listCommune, listFokotany } = this.state;
    const situation = {
      region: -1,
      district: -1,
      commune: -1,
      fokontany: -1,
      regionDTO: null,
      districtDTO: null,
      communeDTO: null,
      fokontanyDTO: null,
    };

    if (name === "region") {
      situation.region = value === "" ? -1 : parseInt(options?.id || value);
      situation.district = -1;
      situation.commune = -1;
      situation.fokontany = -1;

      if (situation.region !== -1) {
        displayListDistrict = listDistrict?.filter((r) => r.regionid === situation.region);
        // displayListCommune = listCommune?.filter((r) => r.regionid === situation.region);
        // displayListFokotany = listFokotany?.filter((r) => r.regionid === situation.region);
      }

    } else if (name === "district") {
      situation.district = value === "" ? -1 : parseInt(options?.id || value);
      situation.commune = -1;
      situation.fokontany = -1;
      const district = situation.district !== -1 ? listDistrict?.filter((district) => district.id === situation.district) : displayListDistrict;
      situation.region = district.length > 0 ? district[0].regionid : -1;

      if (situation.district !== -1) {
        displayListCommune = listCommune?.filter((r) => r.dictricteid === situation.district);
        // displayListFokotany = listFokotany?.filter((r) => r.districteid === situation.district);
      }

    } else if (name === "commune") {
      situation.commune = value === "" ? -1 : parseInt(options?.id || value);
      situation.fokontany = -1;
      const commune = situation.commune !== -1 ? listCommune?.filter((commune) => commune.id === situation.commune) : displayListCommune;
      situation.region = commune.length > 0 ? commune[0].regionid : -1;
      situation.district = commune.length > 0 ? commune[0].dictricteid : -1;

      if (situation.commune !== -1) {
        displayListFokotany = listFokotany?.filter((r) => r.communeid === situation.commune);
      }

    } else if (name === "fokotany") {
      situation.fokontany = value === "" ? -1 : parseInt(options?.id || value);
      const fokontany = situation.fokontany !== -1 ? listFokotany?.filter((fokontany) => fokontany.id === parseInt(options?.id || value)) : displayListFokotany;
      situation.region = fokontany.length > 0 ? fokontany[0].regionid : -1;
      situation.district = fokontany.length > 0 ? fokontany[0].dictricteid : -1;
      situation.commune = fokontany.length > 0 ? fokontany[0].communeid : -1;
    }

    if (situation.region !== -1)
      situation.regionDTO = listRegion.find(item => item.id === situation.region)
    else {
      displayListDistrict = [];
      displayListCommune = [];
      displayListFokotany = [];
    }
    if (situation.district !== -1)
      situation.districtDTO = listDistrict.find(item => item.id === situation.district)
    else {
      displayListCommune = [];
      displayListFokotany = [];
    }
    if (situation.commune !== -1)
      situation.communeDTO = listCommune.find(item => item.id === situation.commune)
    else {
      displayListFokotany = [];
    }
    if (situation.fokontany !== -1)
      situation.fokontanyDTO = listFokotany.find(item => item.id === situation.fokontany) 

    callback(situation);

    this.setState({
      displayListDistrict,
      displayListCommune,
      displayListFokotany,
    });
  };

  handleChangeZonage = (event, callback, options = {}) => {
    event.preventDefault();
    let { value, name } = event.target;
    this.getLocalisationBis(name, value, callback, options);
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
      this.initAllDiplays();
    }
  }

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}>
            {prop.children && prop.children.length > 0
              ? this.getRoutes(prop.children)
              : null}
          </Route>
        );
      } else {
        return "";
      }
    });
  };

  getBrandText = (path) => {
    for (let i = 0; i < routes.routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes.routes[i].layout + routes.routes[i].path
        ) !== -1
      ) {
        if (routes.routes[i].name !== "Accueil") return routes.routes[i].name;
        else return "Carte du Sud de Madagascar";
      }
    }
    for (let i = 0; i < routes.routes2.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes.routes2[i].layout + routes.routes2[i].path
        ) !== -1
      ) {
        if (routes.routes2[i].name !== "Accueil") return routes.routes2[i].name;
        else return "Carte du Sud de Madagascar";
      }
    }

    return "";
  };

  render() {
    let routes1 = routes.routes;
    let routes2 = routes.routes2;

    return (
      <DataContext.Provider
        value={{
          listRegion: this.state.listRegion,
          listDistrict: this.state.listDistrict,
          listCommune: this.state.listCommune,
          listFokotany: this.state.listFokotany,
          displayListRegion: this.state.displayListRegion,
          displayListDistrict: this.state.displayListDistrict,
          displayListCommune: this.state.displayListCommune,
          displayListFokotany: this.state.displayListFokotany,
          handleChangeZonage: this.handleChangeZonage,
        }}>
        <>
          <Sidebar
            {...this.props}
            routes={routes1}
            routes2={routes2}
            logo={{
              innerLink: "/admin/tableau-de-bord",
              imgSrc: require("assets/img/brand/logo.png"),
              imgAlt: "...",
              width: "1000",
            }}
            restrictionView={true}
          />
          <div className="main-content" ref="mainContent">
            <AdminNavbar
              {...this.props}
              brandText={this.getBrandText(this.props.location.pathname)}
            />
            <Switch>
              {this.getRoutes(routes.routes)}
              {this.getRoutes(routes.routes2)}
              <Route path={"/admin/settings"} component={SettingsPage} />
              <Route path={"/admin/utilisateurs"} component={UtilisateurPage} />
              <Redirect from="*" to="/admin/accueil" />
            </Switch>
            <Container fluid>
              <AdminFooter />
            </Container>
          </div>
        </>
        <PageSpinner show={this.state.isLoading} />
      </DataContext.Provider>
    );
  }
}

export default Admin;
