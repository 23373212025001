import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
import routes from "routes.js";

class Auth extends React.Component {
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.token) {
      this.props.history.push({ pathname: "/admin" });
    }

    document.body.classList.add("bg-orange");
  }
  componentWillUnmount() {
    document.body.classList.remove("bg-orange");
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    return (
      <>
        <div className="login-container">
            <div className="login-bg-image"></div>
            <Container className="pb-5 login-form-container">
              <Row className="justify-content-center">
                <Switch>
                  {this.getRoutes(routes.routes)}
                  <Redirect from="*" to="/auth/login" />
                </Switch>
              </Row>
            </Container>
        </div>
      </>
    );
  }
}

export default Auth;
