import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
const NewArticleModal = (props) => {
    const save = () => {
        if (props.selectedUnity && props.selectedUnity.id && props.selectedUnity.id > 0) {
            props.update();
            props.toggle();
        } else {
            props.save();
            props.toggle();
        }

    }
    return (
        <Modal isOpen={props.showModal} toggle={props.toggle} className='modal-check' fade={true}>
            <ModalHeader toggle={props.toggle}>Ajout de nouvelle catégorie</ModalHeader>
            <ModalBody style={{ backgroundColor: 'transparent' }}>
                <FormGroup>
                    <Label for="category">Nom de catégorie</Label>
                    <Input
                        type="text"
                        name="category"
                        id="category"
                        placeholder="Nom de catégorie"
                        value={(props.selectedCategory && props.selectedCategory.libelle) || ''}
                        onChange={props.handleChange}
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={save}>Enregistrer</Button>{' '}
                <Button color="secondary" onClick={props.toggle}>Annuler</Button>
            </ModalFooter>
        </Modal >
    )
}

export default NewArticleModal;

