import axios from 'axios';
import { BACK_URL } from 'data/constant/urls';
import authHeader from './AuthHeader';

// const API_URL = process.env.REACT_APP_URL;
const API_URL = BACK_URL;

class InventoryService {

    getStockByPaginationByFilter(partenaireId, articleId, siteMedicalId, pageNo, pageSize) {
        return axios.get(API_URL + `api/stock/findStockByFilter?pageNo=${pageNo}&pageSize=${pageSize}&articleId=${articleId}&siteMedicalId=${siteMedicalId}&partenaireId=${partenaireId}`, {
            headers: authHeader(),
        });
    }

    getInventoryByPagination(data, pageNo, pageSize) {
        return axios.get(API_URL + `api/stock/findInventory?pageNo=${pageNo}&pageSize=${pageSize}&articleId=${data.articleId}&siteMedicalId=${data.siteMedicalId}`, {
            headers: authHeader(),
        });
    }

    addInventoryByList = (listStock) => {
        return axios.post(API_URL + `api/stock/addInventoryByList`, listStock, {
            headers: authHeader(),
        });
    }

    registerQuantityPlanned = (data) => {
        return axios.put(API_URL + `api/stock/registerQuantityPlanned`, data, {
            headers: authHeader(),
        });
    }

    cancelRegistrationQuantityPlanned = (data) => {
        return axios.put(API_URL + `api/stock/cancelRegistrationQuantityPlanned`, data, {
            headers: authHeader(),
        });
    }

    useQuantityRegistered = (data) => {
        return axios.post(API_URL + `api/historique/useQuantityRegistered`, data, {
            headers: authHeader(),
        });
    }
}

export default new InventoryService();