import axios from 'axios';
import { BACK_URL } from 'data/constant/urls';
import authHeader from './AuthHeader';

// const API_URL = process.env.REACT_APP_URL;
const API_URL = BACK_URL;

class MenageIndividuTraitementService {
  get = async (id) => {
    return await axios.get(API_URL + `sinistre/findmenage?id=${id}`, {
      headers: authHeader(),
    });
  }

  getById(id) {
    return new Promise(async (success, error) => {
      const result = axios
        .get(API_URL + `sinistre/${id}`, {
          headers: authHeader(),
        })
        .catch((exception) => {
          error(exception.response && exception.response.data);
        });
      success(result);
    });
  }

  delete(id) {
    return axios.delete(API_URL + `api/menage/deletemenageindividuTraitement?id=${id}`, {
      headers: authHeader(),
    });
  }

  update(data) {
    return axios.put(`${API_URL}sinistre/updatemenage`, data, {
      headers: authHeader(),
    });
  }

  post(data) {
    return axios.post(`${API_URL}sinistre/addMenageIndividu`, data, {
      headers: authHeader(),
    });
  }

}

export default new MenageIndividuTraitementService();