import moment from "moment";
import React from "react";
// reactstrap components
import {
  Card,
  CardFooter,
  CardHeader,
  Container,
  Row,
  Table,
  CardTitle,
  CardText,
  Button,
  Label,
  CardBody,
  Col,
} from "reactstrap";

import SiteService from "services/SiteService";

import PageSpinner from "views/components/PageSpinner";

class FicheTraitementPage extends React.Component {
  state = {
    isLoading: false,
    site: {},
    recentSightings: [
      {
        id: 1,
        dateObservation: "27/01/2020",
        libelle: "Création --",
      },
      {
        id: 1,
        dateObservation: "27/01/2020",
        libelle: "Changement --",
      },
      {
        id: 1,
        dateObservation: "27/01/2020",
        libelle: "Blablabla --",
      },
    ],
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({
        isLoading: true,
      });
      SiteService.getSiteTBById(this.props.match.params.id)
        .then(
          (response) => {
            console.log("response", response);
            this.setState({
              site: response.data?.data,
              title: "MODIFICATION D'UN SITE",
              isLoading: false,
              isUpdate: true,
              selectedTimeDistribution:
                response.data.data.distributionTime &&
                response.data.data.distributionTime.length > 0 &&
                this.options.filter((option) =>
                  response.data.data.distributionTime.includes(option["value"])
                ),
            });
          },
          (error) => {
            this.setState({
              isLoading: false,
            });
          }
        )
        .catch((exception) => {
          this.setState({
            isLoading: false,
            isError: true,
            errorMessage: exception.errorCode
              ? exception.errorMessage
              : "Contactez votre administrateur",
            errorTitle: "Erreur à la recherche d'un site",
          });
        });
    }
  }

  render() {
    const { site } = this.state;

    const tableRecentSightings =
      this.state.site?.observationDTOs &&
      this.state.site?.observationDTOs.map((sighting, index) => (
        <tr key={index}>
          <td style={{ textAlign: "center" }}>
            {sighting?.dateObservation &&
              moment(sighting?.dateObservation).format("DD-MM-YYYY")}
          </td>
          <td style={{ textAlign: "center" }}>{sighting.libelle}</td>
        </tr>
      ));

    const tableUrgentNeeds =
      this.state.site?.besoinInfoDTO &&
      this.state.site?.besoinInfoDTO.map((need, index) => (
        <tr key={index}>
          <td style={{ textAlign: "center" }}>{need?.article?.libelle}</td>
          <td
            style={{
              textAlign: "center",
            }}
          >{`${need.qteArticle} ${need.article?.unite?.libelle}`}</td>
          <td style={{ textAlign: "center" }}>{need?.etat}</td>
        </tr>
      ));

    return (
      <div className="main-container">
        <div className="header bg-gradient-primary postition-absolute pb-8 pt-5 pt-md-8"></div>
        {/* Page content */}
        <Container className="pt-5 pb-5" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardBody className="border-0">
                  <Card className="mb-4">
                    <CardHeader
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <b>Information du site : </b>
                        {site.nom_site || ""}
                      </div>
                      <Button
                        onClick={() =>
                          this.props.history.push(
                            `/admin/siteDetails/${site.id}`,
                            { isUpdate: true, isCheckInfo: true }
                          )
                        }
                      >
                        Tout Voir
                      </Button>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md="12" lg="5">
                          <div>
                            <b>Identification : </b>
                            {site.reference || "-"}
                          </div>
                          <div>
                            <b>Coordonnées :</b>
                            <ul>
                              <li>
                                <b>latitude :</b> {site.latitude || "-"}
                              </li>
                              <li>
                                <b>longitude :</b> {site.longitude || "-"}
                              </li>
                            </ul>
                          </div>
                        </Col>
                        <Col md="12" lg="7">
                          <div>
                            <b>Secteur :</b>
                            <ul>
                              <li>
                                <b>Région :</b>{" "}
                                {site.zonage?.region?.nom_region || "-"}
                              </li>
                              <li>
                                <b>District :</b>{" "}
                                {site.zonage?.districte?.nom_districte || "-"}
                              </li>
                              <li>
                                <b>Commune :</b>{" "}
                                {site.zonage?.commune?.nomCommune || "-"}
                              </li>
                              <li>
                                <b>Fokontany :</b>{" "}
                                {site.zonage?.fokotany?.nom_fokotany || "-"}{" "}
                              </li>
                            </ul>
                          </div>
                        </Col>
                      </Row>
                      <div>
                        <b>Voina actuel : {site?.lastVoina || "-"}</b>
                      </div>
                    </CardBody>
                    {/* <CardFooter className="text-muted">
                      <Button>Voir les dons</Button>
                    </CardFooter> */}
                  </Card>
                  <Card className="mb-4">
                    <CardHeader>
                      <div>
                        <b>Information capacité</b>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <div>
                        <b>Capacité :</b> {site.accommodationCapacity || "-"}
                      </div>
                      <div>
                        <b>Foyer présent :</b> {site.menagepresent || "-"}
                      </div>
                      <div>
                        <b>Individu présent :</b>{" "}
                        {site?.individuPresent !== undefined
                          ? site?.individuPresent
                          : 0}
                      </div>
                    </CardBody>
                  </Card>
                  <Row className="mb-4">
                    <Col>
                      <Card className="mb-4">
                        <CardHeader
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <b>Besoin urgents</b>
                          </div>
                          <Button
                            onClick={() =>
                              this.props.history.push("/admin/needs", {
                                siteId: this.state.site.id,
                              })
                            }
                          >
                            Tout Voir
                          </Button>
                        </CardHeader>
                        <Table
                          className="align-items-center table-flush table"
                          responsive
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Article
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Quantité(Unité)
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Etats
                              </th>
                            </tr>
                          </thead>
                          <tbody>{tableUrgentNeeds}</tbody>
                        </Table>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <CardHeader
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <b>Observations récentes</b>
                          </div>
                          <Button
                            onClick={() =>
                              this.props.history.push("/admin/observation")
                            }
                          >
                            Tout Voir
                          </Button>
                        </CardHeader>
                        <Table
                          className="align-items-center table-flush table"
                          responsive
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Date
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Libellé
                              </th>
                            </tr>
                          </thead>
                          <tbody>{tableRecentSightings}</tbody>
                        </Table>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        <PageSpinner show={this.state.isLoading} />
      </div>
    );
  }
}

export default FicheTraitementPage;
