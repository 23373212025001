import React from "react";
import { Col, FormGroup, Input, Row } from "reactstrap";
import MenageService from "services/MenageService";

class InformationSupplementaire extends React.Component {
  state = {
    enrolement: {
      nombreHommes: 0,
      nombreFemmes: 0,
      nombreEtudiants: 0,
      nombreEnfants0a5: 0,
      nombreFemmesEnceintes: 0,
      nombreFemmesAllaitent: 0,
      nombreHandicapes: 0,
      nombrePlus60: 0,
      nombreDiarhee: 0,
      nombreProblemeRespiratoire: 0,
      nombreGrippe: 0,
      nombrePaludisme: 0,
      nombreCovid: 0,
      nombreChronique: 0,
      kitScolaireDetruit: false,
      cuisineDetruite: false,
      sourceEauDetruite: false,
      reserveEauDetruite: false,
      accesEauPotable: false,
      accesElectricite: false,
      maisonDetruite: false,
      maisonBrulee: false,
      maisonInondee: false,
      toitDetruit: false,
    },
    nombreIndividusMax: 1,
  };

  componentWillReceiveProps(props) {
    this.getNombreIndividuMax(props);
  }

  getData(props) {
    let enrolement = {};
    enrolement = props && props.enrolement;

    this.setDefault(enrolement);
  }
  getNombreIndividuMax(props) {
    if (props.nbIndividuMax == 0) {
      const idMenage = props.match.params.idMenage;

      MenageService.getMenageById(idMenage).then(
        (result) => {
          console.log("Menage : >>>> ", result.data.data);
          this.setState({
            nombreIndividusMax: result.data.data.tailleMenage,
          });
        },
        (error) => {
          console.log("ERROR : ", error);
        }
      );
    } else {
      this.setState(
        {
          nombreIndividusMax: parseInt(props.nbIndividuMax),
        },
        () => {
          let nombreHommes =
            this.state.enrolement.nombreHommes > this.state.nombreIndividusMax
              ? this.state.nombreIndividusMax
              : this.state.enrolement.nombreHommes;
          let nombreFemmes =
            this.state.enrolement.nombreFemmes > this.state.nombreIndividusMax
              ? this.state.nombreIndividusMax
              : this.state.enrolement.nombreFemmes;
          let nombreEtudiants =
            this.state.enrolement.nombreEtudiants >
            this.state.nombreIndividusMax
              ? this.state.nombreIndividusMax
              : this.state.enrolement.nombreEtudiants;
          let nombreEnfants0a5 =
            this.state.enrolement.nombreEnfants0a5 >
            this.state.nombreIndividusMax
              ? this.state.nombreIndividusMax
              : this.state.enrolement.nombreEnfants0a5;
          let nombreFemmesEnceintes =
            this.state.enrolement.nombreFemmesEnceintes >
            this.state.nombreIndividusMax
              ? this.state.nombreIndividusMax
              : this.state.enrolement.nombreFemmesEnceintes;
          let nombreFemmesAllaitent =
            this.state.enrolement.nombreFemmesAllaitent >
            this.state.nombreIndividusMax
              ? this.state.nombreIndividusMax
              : this.state.enrolement.nombreFemmesAllaitent;
          let nombreHandicapes =
            this.state.enrolement.nombreHandicapes >
            this.state.nombreIndividusMax
              ? this.state.nombreIndividusMax
              : this.state.enrolement.nombreHandicapes;
          let nombrePlus60 =
            this.state.enrolement.nombrePlus60 > this.state.nombreIndividusMax
              ? this.state.nombreIndividusMax
              : this.state.enrolement.nombrePlus60;
          let nombreDiarhee =
            this.state.enrolement.nombreDiarhee > this.state.nombreIndividusMax
              ? this.state.nombreIndividusMax
              : this.state.enrolement.nombreDiarhee;
          let nombreProblemeRespiratoire =
            this.state.enrolement.nombreProblemeRespiratoire >
            this.state.nombreIndividusMax
              ? this.state.nombreIndividusMax
              : this.state.enrolement.nombreProblemeRespiratoire;
          let nombreGrippe =
            this.state.enrolement.nombreGrippe > this.state.nombreIndividusMax
              ? this.state.nombreIndividusMax
              : this.state.enrolement.nombreGrippe;
          let nombrePaludisme =
            this.state.enrolement.nombrePaludisme >
            this.state.nombreIndividusMax
              ? this.state.nombreIndividusMax
              : this.state.enrolement.nombrePaludisme;
          let nombreCovid =
            this.state.enrolement.nombreCovid > this.state.nombreIndividusMax
              ? this.state.nombreIndividusMax
              : this.state.enrolement.nombreCovid;
          let nombreChronique =
            this.state.enrolement.nombreChronique >
            this.state.nombreIndividusMax
              ? this.state.nombreIndividusMax
              : this.state.enrolement.nombreChronique;

          this.setState({
            enrolement: {
              ...this.state.enrolement,

              nombreHommes,
              nombreFemmes,
              nombreEtudiants,
              nombreEnfants0a5,
              nombreFemmesEnceintes,
              nombreFemmesAllaitent,
              nombreHandicapes,
              nombrePlus60,
              nombreDiarhee,
              nombreProblemeRespiratoire,
              nombreGrippe,
              nombrePaludisme,
              nombreCovid,
              nombreChronique,
            },
          });
        }
      );
    }
  }
  handleCheckBoxChange = (event) => {
    const { name } = event.target;
    this.setState(
      {
        enrolement: {
          ...this.state.enrolement,
          [name]: event.target.checked,
        },
      },
      () => {
        this.props.data(this.state.enrolement);
      }
    );
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(
      {
        enrolement: {
          ...this.state.enrolement,
          [name]: value,
        },
      },
      () => {
        this.props.data(this.state.enrolement);
      }
    );
  };
  componentDidMount() {
    this.setState(
      {
        enrolement: {
          ...this.state.enrolement,
        },
      },
      () => {
        this.props.data(this.state.enrolement);
      }
    );
  }
  render() {
    return (
      <>
        <Row>
          <Col>
            <FormGroup>
              <div className="h3 text-primary">
                Informations supplémentaires
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xl="2" lg="4" sm="6" xs="12" className="align-self-end">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-nb-homme">
                Nb hommes
              </label>
              <Input
                className="form-control-alternative"
                id="input-nb-homme"
                placeholder=""
                type="number"
                min={0}
                max={this.state.nombreIndividusMax}
                value={this.state.enrolement.nombreHommes}
                onChange={this.handleChange}
                onKeyDown={(e) => e.preventDefault()}
                name="nombreHommes"
              />
            </FormGroup>
          </Col>
          <Col xl="2" lg="4" sm="6" xs="12" className="align-self-end">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-nb-femme">
                Nb femmes
              </label>
              <Input
                className="form-control-alternative"
                id="input-nb-femme"
                placeholder=""
                type="number"
                min={0}
                max={this.state.nombreIndividusMax}
                value={this.state.enrolement.nombreFemmes}
                onChange={this.handleChange}
                onKeyDown={(e) => e.preventDefault()}
                name="nombreFemmes"
              />
            </FormGroup>
          </Col>
          <Col xl="2" lg="4" sm="6" xs="12" className="align-self-end">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-etudiants">
                Nb enfants scolarisés
              </label>
              <Input
                className="form-control-alternative"
                id="input-etudiants"
                placeholder=""
                type="number"
                min={0}
                max={this.state.nombreIndividusMax}
                value={this.state.enrolement.nombreEtudiants}
                onChange={this.handleChange}
                onKeyDown={(e) => e.preventDefault()}
                name="nombreEtudiants"
              />
            </FormGroup>
          </Col>
          <Col xl="2" lg="4" sm="6" xs="12" className="align-self-end">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-enfant">
                Nb enfants 0 à 5 ans
              </label>
              <Input
                className="form-control-alternative"
                id="input-enfant"
                placeholder=""
                type="number"
                min={0}
                max={this.state.nombreIndividusMax}
                value={this.state.enrolement.nombreEnfants0a5}
                onChange={this.handleChange}
                onKeyDown={(e) => e.preventDefault()}
                name="nombreEnfants0a5"
              />
            </FormGroup>
          </Col>
          <Col xl="2" lg="4" sm="6" xs="12" className="align-self-end">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-enceintes">
                Nb femmes enceintes
              </label>
              <Input
                className="form-control-alternative"
                id="input-enceintes"
                placeholder=""
                type="number"
                min={0}
                max={this.state.nombreIndividusMax}
                value={this.state.enrolement.nombreFemmesEnceintes}
                onChange={this.handleChange}
                onKeyDown={(e) => e.preventDefault()}
                name="nombreFemmesEnceintes"
              />
            </FormGroup>
          </Col>
          <Col xl="2" lg="4" sm="6" xs="12" className="align-self-end">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-allaitent">
                Nb femmes qui allaitent
              </label>
              <Input
                className="form-control-alternative"
                id="input-allaitent"
                placeholder=""
                type="number"
                min={0}
                max={this.state.nombreIndividusMax}
                value={this.state.enrolement.nombreFemmesAllaitent}
                onChange={this.handleChange}
                onKeyDown={(e) => e.preventDefault()}
                name="nombreFemmesAllaitent"
              />
            </FormGroup>
          </Col>
          <Col xl="2" lg="4" sm="6" xs="12" className="align-self-end">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-handicapes">
                Nb handicapés
              </label>
              <Input
                className="form-control-alternative"
                id="input-handicapes"
                placeholder=""
                type="number"
                min={0}
                max={this.state.nombreIndividusMax}
                value={this.state.enrolement.nombreHandicapes}
                onChange={this.handleChange}
                onKeyDown={(e) => e.preventDefault()}
                name="nombreHandicapes"
              />
            </FormGroup>
          </Col>
          <Col xl="2" lg="4" sm="6" xs="12" className="align-self-end">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-plus-soixante"
              >
                Nb de + 60 ans
              </label>
              <Input
                className="form-control-alternative"
                id="input-plus-soixante"
                placeholder=""
                type="number"
                min={0}
                max={this.state.nombreIndividusMax}
                value={this.state.enrolement.nombrePlus60}
                onChange={this.handleChange}
                onKeyDown={(e) => e.preventDefault()}
                name="nombrePlus60"
              />
            </FormGroup>
          </Col>
          <Col xl="2" lg="4" sm="6" xs="12" className="align-self-end">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-diarrhee">
                Nb ayant diarrhée
              </label>
              <Input
                className="form-control-alternative"
                id="input-diarrhee"
                placeholder=""
                type="number"
                min={0}
                max={this.state.nombreIndividusMax}
                value={this.state.enrolement.nombreDiarhee}
                onChange={this.handleChange}
                onKeyDown={(e) => e.preventDefault()}
                name="nombreDiarhee"
              />
            </FormGroup>
          </Col>
          <Col xl="2" lg="4" sm="6" xs="12" className="align-self-end">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-pblm-respiratoire"
              >
                Nb problèmes respiratoires
              </label>
              <Input
                className="form-control-alternative"
                id="input-pblm-respiratoire"
                placeholder=""
                type="number"
                min={0}
                max={this.state.nombreIndividusMax}
                value={this.state.enrolement.nombreProblemeRespiratoire}
                onChange={this.handleChange}
                onKeyDown={(e) => e.preventDefault()}
                name="nombreProblemeRespiratoire"
              />
            </FormGroup>
          </Col>
          <Col xl="2" lg="4" sm="6" xs="12" className="align-self-end">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-grippe">
                Nb ayant la grippe
              </label>
              <Input
                className="form-control-alternative"
                id="input-grippe"
                placeholder=""
                type="number"
                min={0}
                max={this.state.nombreIndividusMax}
                value={this.state.enrolement.nombreGrippe}
                onChange={this.handleChange}
                onKeyDown={(e) => e.preventDefault()}
                name="nombreGrippe"
              />
            </FormGroup>
          </Col>
          <Col xl="2" lg="4" sm="6" xs="12" className="align-self-end">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-paludisme">
                Nb ayant le paludisme
              </label>
              <Input
                className="form-control-alternative"
                id="input-paludisme"
                placeholder=""
                type="number"
                min={0}
                max={this.state.nombreIndividusMax}
                value={this.state.enrolement.nombrePaludisme}
                onChange={this.handleChange}
                onKeyDown={(e) => e.preventDefault()}
                name="nombrePaludisme"
              />
            </FormGroup>
          </Col>
          <Col xl="2" lg="4" sm="6" xs="12" className="align-self-end">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-covid">
                Nb ayant le Covid
              </label>
              <Input
                className="form-control-alternative"
                id="input-covid"
                placeholder=""
                type="number"
                min={0}
                max={this.state.nombreIndividusMax}
                value={this.state.enrolement.nombreCovid}
                onChange={this.handleChange}
                onKeyDown={(e) => e.preventDefault()}
                name="nombreCovid"
              />
            </FormGroup>
          </Col>
          <Col xl="2" lg="4" sm="6" xs="12" className="align-self-end">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-chronique">
                Nb ayant maladie chronique
              </label>
              <Input
                className="form-control-alternative"
                id="input-chronique"
                placeholder=""
                type="number"
                min={0}
                max={this.state.nombreIndividusMax}
                value={this.state.enrolement.nombreChronique}
                onChange={this.handleChange}
                onKeyDown={(e) => e.preventDefault()}
                name="nombreChronique"
              />
            </FormGroup>
          </Col>
        </Row>
        <hr className="mb-4 mt-1" />
        <Row>
          <Col xl="2" lg="4" sm="6" xs="12">
            <FormGroup>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  className="custom-control-input form-control"
                  id="customCheckKit"
                  type="checkbox"
                  name="kitScolaireDetruit"
                  defaultChecked={this.state.enrolement.kitScolaireDetruit}
                  value={this.state.enrolement.kitScolaireDetruit}
                  onChange={this.handleCheckBoxChange}
                />
                <label
                  className="custom-control-label text-bold"
                  htmlFor="customCheckKit"
                >
                  Kit scolaire détruit
                </label>
              </div>
            </FormGroup>
          </Col>
          <Col xl="2" lg="4" sm="6" xs="12">
            <FormGroup>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  className="custom-control-input form-control"
                  id="cuisineDetruite"
                  type="checkbox"
                  name="cuisineDetruite"
                  defaultChecked={this.state.enrolement.cuisineDetruite}
                  value={this.state.enrolement.cuisineDetruite}
                  onChange={this.handleCheckBoxChange}
                />
                <label
                  className="custom-control-label text-bold"
                  htmlFor="cuisineDetruite"
                >
                  Equipement cuisine détruit
                </label>
              </div>
            </FormGroup>
          </Col>
          <Col xl="2" lg="4" sm="6" xs="12">
            <FormGroup>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  className="custom-control-input form-control"
                  id="sourceEauDetruite"
                  type="checkbox"
                  name="sourceEauDetruite"
                  defaultChecked={this.state.enrolement.sourceEauDetruite}
                  value={this.state.enrolement.sourceEauDetruite}
                  onChange={this.handleCheckBoxChange}
                />
                <label
                  className="custom-control-label text-bold"
                  htmlFor="sourceEauDetruite"
                >
                  Source d'eau détruite
                </label>
              </div>
            </FormGroup>
          </Col>
          <Col xl="2" lg="4" sm="6" xs="12">
            <FormGroup>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  className="custom-control-input form-control"
                  id="reserveEauDetruite"
                  type="checkbox"
                  name="reserveEauDetruite"
                  defaultChecked={this.state.enrolement.reserveEauDetruite}
                  value={this.state.enrolement.reserveEauDetruite}
                  onChange={this.handleCheckBoxChange}
                />
                <label
                  className="custom-control-label text-bold"
                  htmlFor="reserveEauDetruite"
                >
                  Réserve d'eau détruite
                </label>
              </div>
            </FormGroup>
          </Col>
          <Col xl="2" lg="4" sm="6" xs="12">
            <FormGroup>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  className="custom-control-input form-control"
                  id="accesEauPotable"
                  type="checkbox"
                  name="accesEauPotable"
                  defaultChecked={this.state.enrolement.accesEauPotable}
                  value={this.state.enrolement.accesEauPotable}
                  onChange={this.handleCheckBoxChange}
                />
                <label
                  className="custom-control-label text-bold"
                  htmlFor="accesEauPotable"
                >
                  Accès eau potable
                </label>
              </div>
            </FormGroup>
          </Col>
          <Col xl="2" lg="4" sm="6" xs="12">
            <FormGroup>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  className="custom-control-input form-control"
                  id="accesElectricite"
                  type="checkbox"
                  name="accesElectricite"
                  defaultChecked={this.state.enrolement.accesElectricite}
                  value={this.state.enrolement.accesElectricite}
                  onChange={this.handleCheckBoxChange}
                />
                <label
                  className="custom-control-label text-bold"
                  htmlFor="accesElectricite"
                >
                  Maison avec électricité
                </label>
              </div>
            </FormGroup>
          </Col>
          <Col xl="2" lg="4" sm="6" xs="12">
            <FormGroup>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  className="custom-control-input form-control"
                  id="maisonDetruite"
                  type="checkbox"
                  name="maisonDetruite"
                  defaultChecked={this.state.enrolement.maisonDetruite}
                  value={this.state.enrolement.maisonDetruite}
                  onChange={this.handleCheckBoxChange}
                />
                <label
                  className="custom-control-label text-bold"
                  htmlFor="maisonDetruite"
                >
                  Maison détruite
                </label>
              </div>
            </FormGroup>
          </Col>
          <Col xl="2" lg="4" sm="6" xs="12">
            <FormGroup>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  className="custom-control-input form-control"
                  id="maisonBrulee"
                  type="checkbox"
                  name="maisonBrulee"
                  defaultChecked={this.state.enrolement.maisonBrulee}
                  value={this.state.enrolement.maisonBrulee}
                  onChange={this.handleCheckBoxChange}
                />
                <label
                  className="custom-control-label text-bold"
                  htmlFor="maisonBrulee"
                >
                  Maison brulée
                </label>
              </div>
            </FormGroup>
          </Col>
          <Col xl="2" lg="4" sm="6" xs="12">
            <FormGroup>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  className="custom-control-input form-control"
                  id="maisonInondee"
                  type="checkbox"
                  name="maisonInondee"
                  defaultChecked={this.state.enrolement.maisonInondee}
                  value={this.state.enrolement.maisonInondee}
                  onChange={this.handleCheckBoxChange}
                />
                <label
                  className="custom-control-label text-bold"
                  htmlFor="maisonInondee"
                >
                  Maison inondée
                </label>
              </div>
            </FormGroup>
          </Col>
          <Col xl="2" lg="4" sm="6" xs="12">
            <FormGroup>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  className="custom-control-input form-control"
                  id="toitDetruit"
                  type="checkbox"
                  name="toitDetruit"
                  defaultChecked={this.state.enrolement.toitDetruit}
                  value={this.state.enrolement.toitDetruit}
                  onChange={this.handleCheckBoxChange}
                />
                <label
                  className="custom-control-label text-bold"
                  htmlFor="toitDetruit"
                >
                  Toit détruit
                </label>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }
}

export default InformationSupplementaire;
