import { Checkbox, FormControlLabel } from '@material-ui/core';
import GenerateValue from "common/data";
import { stdDev } from "helper/math";
import React from "react";
import ReactDatetime from "react-datetime";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  Row,
  Table
} from "reactstrap";
import FicheService from "services/FicheService";
import SuiviService from 'services/SuiviService';
import PageSpinner from "./PageSpinner";

class CreateEditFicheTraitementModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange.bind(this);
    this.handleSubmit.bind(this);
    this.valueToTen = GenerateValue(10);
    this.valueRespiration = GenerateValue(40);
    this.title = "Création Fiche de traitement";
  }

  state = {
    isLoading: false,
    id: -1,
    etat_nutritionnel: "",
    admission: "",
    examen_clinique: "",
    poids: "",
    temp: "",
    oedemes: "",
    diarrhe: "",
    vomissement: "",
    fievre: "",
    toux: "",
    conj_pales: "",
    resultat_test_palu: "",
    test_app: "",
    choix_accompagnant: "",
    atpe: "",
    atpe_sachet: "",
    besoin_vad: "",
    taille: "",
    amoxicilline_date: "",
    amoxicilline_dose: "",
    vitamine_date: "",
    vitamine_dose: "",
    anti_palu_date: "",
    anti_palu_dose: "",
    observation: "",
    dateobs: "",
    individuid: -1,
    pb: "",
    nomindividu: "",
    csb: "",
    imc: "",
    mal_nutrition: "false",
    remarque: "",
    maladieChronique: "",
    typetraitement_id: -1,
    priseEnMain_id: -1,
    pbRespiratoir: "Non",
    avecComplication: "Non",
    bay: "Non",
    disabledButton: false
  };

  handleChangeCheckBox = (event) => {
    this.setState({ ...this.state, avecComplication: event.target.checked ? "Oui" : "Non" });
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  componentDidMount() {
    if (this.props.individu) {
      this.setState({
        individuid: this.props.individu.id,
        nomindividu: this.props.individu.nom,
      });
    } else if (this.props.data) {
      //Modification fiche à partir gestion fiche de traitement
      this.isUpdate = true;
      this.title = `Fiche de traitement de ${this.props.data.nomindividu}`;
      FicheService.getTraitementById(this.props.data.id).then((data) => {
        this.setState(data.data.data);
      });
    } else if (this.props.gestionIndividu) {
      if (this.props.gestionIndividu.traitementid !== 0) {
        // Modification fiche a partir gestion individu
        this.isUpdate = true;
        this.title = `Fiche de traitement de `;
        FicheService.getTraitementById(
          this.props.gestionIndividu.traitementid
        ).then((data) => {
          this.title = `Fiche de traitement de ${data.data.data.nomindividu}`;
          this.setState(data.data.data);
        });
      } else {
        // Création fiche a partir gestion individu
        this.title = "Création Fiche de traitement";
        this.setState({
          individuid: this.props.gestionIndividu.id,
          nomindividu: `${this.props.gestionIndividu.nom}`,
        });
      }
    }

    if (this.props.data) {
      this.title = "Modification Fiche de traitement";
      this.isUpdate = false;
      this.setState(this.props.data);
    }

    if (this.props.new) {
      if (this.props.individu) {
        SuiviService.lastFiche(this.props.individu.id)
          .then((response) => {
            this.setState({
              ...this.state,
              taille: response.data.taille,
              poids: response.data.poids,
              pb: response.data.pb
            })
          })
          .catch(e => console.log('Error on getting Suivi info', e));
      }
    }
  }

  suivreTraitement = (event) => {
    event.preventDefault();
    //process save
    this.setState({
      isLoading: true,
    });
    const DTO = { ...this.state };
    delete DTO.isLoading;

    this.processSave(DTO)
  };

  processSave = (DTO) => {
    FicheService.post(DTO).then(
      (data) => {
        this.setState({
          isLoading: false,
          disabledButton: false,
        });
        if (this.getTreatmentTypeName(this.state.typetraitement_id) !== '')
          this.props.history.push("/admin/fiche-suivi/" + data.data.data.id);
        this.props.onSuivreTraitement();
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      isLoading: true,
    });
    const DTO = { ...this.state };
    delete DTO.isLoading;
    this.processUpdate(DTO);
  };

  processUpdate = (DTO) => {
    FicheService.update(DTO).then(
      (response) => {
        this.setState({
          disabledButton: false,
          isLoading: false,
        });
        if (this.getTreatmentTypeName(this.state.typetraitement_id) !== '')
          this.props.history.push("/admin/fiche-suivi/" + response.data.data.id);
        this.props.onSuivreTraitement();
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
        this.props.onCancel();
      }
    );
  };

  onSave = (event) => {
    event.preventDefault();
    this.setState({
      isLoading: true,
      disabledButton: true,
    });
    const DTO = { ...this.state };
    delete DTO.isLoading
    delete DTO.disabledButton;
    this.processSave(DTO)
  }

  setEtatNutritionnel = () => {
    const { taille, poids } = this.state;
    const etat_nutritionnel = "";

    if (taille && poids) {
      const ecartType = stdDev(taille, poids);
      switch (ecartType) {
        case ecartType < -3:
          etat_nutritionnel = "MAS sans complication";
          break;
        case ecartType < -2:
          etat_nutritionnel = "MAM";
          break;
        case ecartType > -2:
          etat_nutritionnel = "";
          break;
        default:
          break;
      }
    }
  };

  handleChange = (evt) => {
    const { name, value } = evt.target;

    this.setState(
      {
        ...this.state,
        [name]: value,
      },
      () => {
        if (name === "taille" || name === "poids") {
          this.setEtatNutritionnel();
        }
      }
    );
  };

  handleAmoxDate = (date) => {
    let value = "";
    if (date.isValid) {
      value = date.format("DD/MM/YYYY");
    }
    this.setState({
      ...this.state,
      amoxicilline_date: value,
    });
  };

  handleVitamineDate = (date) => {
    let value = "";
    if (date.isValid) {
      value = date.format("DD/MM/YYYY");
    }
    this.setState({
      ...this.state,
      vitamine_date: value,
    });
  };

  handlePaluDate = (date) => {
    let value = "";
    if (date.isValid) {
      value = date.format("DD/MM/YYYY");
    }
    this.setState({
      ...this.state,
      anti_palu_date: value,
    });
  };

  handleObsDate = (date) => {
    let value = "";
    if (date.isValid) {
      value = date.format("DD/MM/YYYY");
    }
    this.setState({
      ...this.state,
      dateobs: value,
    });
  };

  handleObs2Date = (date) => {
    let value = "";
    if (date.isValid) {
      value = date.format("DD/MM/YYYY");
    }
    this.setState({
      ...this.state,
      dateobs2: value,
    });
  };

  handleObs3Date = (date) => {
    let value = "";
    if (date.isValid) {
      value = date.format("DD/MM/YYYY");
    }
    this.setState({
      ...this.state,
      dateobs3: value,
    });
  };

  getTreatmentTypeList = () => {
    const { treatmentTypeList } = this.props;
    return treatmentTypeList && treatmentTypeList.map(item => <option value={item.id}>{item.nomType}</option>)
  }

  getHandlingList = () => {
    const { handlingList } = this.props;
    return handlingList && handlingList.map(item => <option value={item.id}>{item.designation}</option>)
  }

  getTreatmentTypeName = (id) => {
    const { treatmentTypeList } = this.props;
    const treatment = treatmentTypeList && treatmentTypeList.length > 0 && treatmentTypeList.filter(item => item.id === parseInt(id));
    return treatment.length > 0 ? treatment.nomType : "";
  }

  render() {
    return (
      <>
        <Modal className="modal-dialog-centered" size="lg" isOpen={true}>
          <div className="modal-body p-0">
            <Card className="bg-light-secondary shadow border-0">
              <CardHeader className="bg-transparent pb-0">
                <div className="text-muted text-center mt-2 mb-3">
                  <h2>Création fiche de traitement</h2>
                </div>
              </CardHeader>
              <CardBody className="px-lg-3 py-lg-2">
                <Form>
                  <div className="pl-lg-4">

                    <div className="row">
                      <Col >
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Taille (cm)
                            </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            placeholder="Taille (cm)"
                            type="number"
                            name="taille"
                            onChange={this.handleChange}
                            value={this.state.taille}
                          />
                        </FormGroup>
                      </Col>
                      <Col >
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Poids (kg)
                            </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            placeholder="Poids (kg)"
                            type="number"
                            name="poids"
                            onChange={this.handleChange}
                            value={this.state.poids}
                          />
                        </FormGroup>
                      </Col>
                      <Col >
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            PB (mm)
                            </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            placeholder="PB (mm)"
                            type="number"
                            name="pb"
                            onChange={this.handleChange}
                            value={this.state.pb}
                          />
                        </FormGroup>
                      </Col>

                    </div>


                    <div className="row" >
                      {this.props.individu && !this.props.individu.isChild &&
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Indice de masse corporelle (IMC)
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              placeholder="IMC"
                              type="number"
                              name="imc"
                              onChange={this.handleChange}
                              value={this.state.taille && this.state.poids / Math.pow(this.state.taille / 100, 2)}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      }
                      {
                        this.isUpdate && this.props.individu && !this.props.individu.isChild &&
                        <Col >
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >Dénutri</label>
                            <div className="input-group-alternative input-group">
                              <select
                                disabled={true}
                                name="mal_nutrition"
                                onChange={this.handleChange}
                                value={this.state.mal_nutrition}
                                className="mdb-select md-form  form-control"
                              >
                                <option value={false}>Non</option>
                                <option value={true}>Oui</option>

                              </select>
                            </div>
                          </FormGroup>
                        </Col>
                      }
                      {
                        this.props.individu && this.props.individu.isChild &&
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Poids/taille
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              placeholder="Poids/Taille"
                              type="number"
                              name="taille"
                              onChange={this.handleChange}
                              value={isNaN(parseFloat(this.state.poids) / parseFloat(this.state.taille)) ?
                                '' : parseFloat(this.state.poids) / parseFloat(this.state.taille)}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      }

                    </div>
                    {
                      this.isUpdate && this.props.individu && this.props.individu.isChild &&
                      <div className="row">
                        <Col >
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Etat nutritionnel
                            </label>
                            <div className="input-group-alternative input-group">
                              <select
                                disabled={true}
                                name="typetraitement_id"
                                onChange={this.handleChange}
                                value={this.state.typetraitemet_id}
                                className="mdb-select md-form  form-control"
                              >
                                <option value={-1}></option>
                                {this.getTreatmentTypeList()}
                              </select>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col style={{ display: 'flex', alignItems: 'center', marginLeft: 10 }}>
                          <FormControlLabel
                            control={<Checkbox
                              checked={this.state.avecComplication === "Oui"}
                              name="avecComplication" onChange={this.handleChangeCheckBox}
                            />}
                            label="Avec complication"
                          />
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Oedemes
                            </label>
                            <div className="input-group-alternative input-group">
                              <select
                                value="1"
                                className="mdb-select md-form  form-control"
                                name="oedemes"
                                onChange={this.handleChange}
                                value={this.state.oedemes}
                              >
                                <option value=""></option>
                                <option value="OUI">OUI</option>
                                <option value="NON">NON</option>
                              </select>
                            </div>
                          </FormGroup>
                        </Col>

                      </div>
                    }

                  </div>

                  <div className="modal-footer">
                    <Button type="button" onClick={this.props.onCancel}>
                      Annuler
                    </Button>
                    <Button
                      color="primary"
                      type="button"
                      onClick={this.onSave}
                      disabled={this.state.disabledButton}
                    >
                      Enregistrer
                      </Button>

                    {this.props.individu && this.props.individu.isChild && (
                      <Button
                        color="primary"
                        type="button"
                        onClick={this.suivreTraitement.bind(this)}
                      >
                        Suivre traitement
                        <i className="fas fa-angle-double-right"></i>
                      </Button>
                    )}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
        <PageSpinner show={this.state.isLoading} />
      </>
    );
  }
}

export default CreateEditFicheTraitementModal;
