import {
  DISTANCE, GET_MALADIE_HERIDITAIRE,
  GET_NIVEAU_INSECURITE,
  GET_SOURCE_EAU,
  ListActivites,
  ListBienAvoir, REVENU
} from "common/data";
import React from "react";
import ReactDatetime from "react-datetime";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  Row
} from "reactstrap";
import IndividuService from "services/IndividuService";
import MenageService from "services/MenageService";
import ZonnageService from "services/ZonnageService";
import NotificationModal from "./NotificationModal";
import PageSpinner from "./PageSpinner";
import Suggestions from "./Suggestion";
import SuggestionMenage from "./SuggestionMenage";

class CreateMenageModal extends React.Component {
  constructor(props) {
    super(props);
    // this.handleSubmit.bind(this);
    this.save.bind(this);
    this.getRegion.bind(this);
    this.handleChange.bind(this);
    this.activites = ListActivites;
    this.bienAvoir = ListBienAvoir;
    this.title = "Création ménage";
    this.notificationMessage = "";
    this.notificationTitle = "";
    this.bienAvoir = ListBienAvoir.map((elt, i) => ({
      label: elt,
      value: elt,
    }));
  }

  state = {
    isLoading: false,
    showSuggestion: true,
    showSuggestionMenage: true,
    showNotification: false,
    listRegion: [],
    listDistrict: [],
    listCommune: [],
    displayRegion: [],
    displayCommune: [],
    displayDistrict: [],
    showFiche: false,
    requiredAdresse: false,
    requiredNom: false,
    requiredAge: false,
    requiredCin: false,
    menage: {
      eauNonRenseigne: true,
      revenu: "",
      activite: "",
      adresse: "Ménage_",
      bien_avoirs: "",
      region: "",
      district: "",
      commune: "",
      fokotany: "",
      hameau: "",
      tosika_fameno: "",
      fiavota: "",
      assistanceType1: "",
      assistanceDate1: "",
      assistanceType2: "",
      assistanceDate2: "",
      assistanceType3: "",
      assistanceDate3: "",
      frequencerepo: "",
      nourriture: "",
      ration: "",
      autocompletion: [],
      accesEauPotable: "",
      niveauInsecurite: "",
      isError: {
        adresse: "",
        region: "",
        district: "",
        commune: "",
        fokotany: "",
      },
      presenceEau: "",
      abscenceEau: "",
      acheterEau: "",
      sourceEau: "",
      distanceEau: "",
      prixBidon: "",
      demandeEau: "",
      presenceCampMilitaire: "",
      distanceCampMilitaire: "",
      demandeSecurite: "",
      presenceLieuSanitaire: "",
      distanceLieuSanitaire: "",
      lieuSanitaire: "",
      utilisationContraception: "",
      demandeSanitaire: "",
      nbPersonneActifDansLeMenage: "",
      nbEnfantScolarise: "",
      nbElevage: "",
      presenceTerreACultive: "",
      recolteObtenus: "",
      demandeSociale: "",
      terrain: "",
      maison: "",
      nbIndividus: "",
      nbFemmeEnceinte: "",
      nbFemmeAllaitante: "",
      nbPersonneHandicape: "",
      nbEnfant: "",
      nbPersonneAge: "",
      diminution: "",
      maladie: [],
    },
    individu: {
      nom: "",
      prenoms: "",
      handicape: "",
      cin: "",
      genre: "",
      sexe: "",
      telephone: "",
      affiliation: "CF",
      age: "",
      allaité: "",
      jumeaux: "",
      femme_enceinte: "",
      femme_allaitante: "",
      taille: "",
      menageid: 0,
      autocompletion: [],
      maladie: "",
      isError: {
        nom: "",
        cin: "",
        age: "",
        telephone: "",
        sexe: "",
      },
      marie: "",
      derniereClasse: "",
      dateNaissance: "",
    },
  };

  componentDidMount() {
    this.getRegion();
    this.getDistrict();
    this.getCommune();
    this.isUpdate = this.props.data ? true : false;
    if (this.isUpdate) {
      this.title = "Modification ménage";
      const isError = {
        adresse: "",
        region: "",
        district: "",
        commune: "",
        fokotany: "",
      };

      let data = { ...this.props.data, isError };
      IndividuService.findChefFamilleByMenageId(this.props.data.id).then(
        (res) => {
          const isError = {
            nom: "",
            cin: "",
            age: "",
            telephone: "",
            sexe: "",
          };
          if (res.data.data)
            this.setState(
              {
                individu: { ...res.data.data[0], isError },
              });
        }
      );

      this.setState({ menage: data });
    }
  }

  onFocus = () => {
    this.setState({
      showSuggestion: true,
    });
  };
  onBlur = () => {
    this.setState({
      showSuggestion: false,
    });
  };
  onFocusMenage = () => {
    this.setState({
      showSuggestionMenage: true,
    });
  };
  onBlurMenage = () => {
    this.setState({
      showSuggestionMenage: false,
    });
  };
  getRegion = () => {
    this.setState({
      isLoading: true,
    });
    ZonnageService.getListRegion(this.state.menage.region)
      .then((res) => res.data)
      .then((result) => {
        this.setState({
          listRegion: result.data,
          displayRegion: result.data,
          isLoading: false,
        });
      });
  };
  getCommune = () => {
    this.setState({
      isLoading: true,
    });
    ZonnageService.getListCommune(
      this.state.menage.commune,
      this.state.menage.district,
      this.state.menage.region
    )
      .then((res) => res.data)
      .then((result) => {
        this.setState({
          listCommune: result.data,
          displayCommune: result.data,
          isLoading: false,
        });
      });
  };

  closeNotification = () => {
    this.setState({
      showNotification: false,
    });
  };
  getDistrict = () => {
    this.setState({
      isLoading: true,
    });
    ZonnageService.getListDistrict(
      this.state.menage.district,
      this.state.menage.region
    )
      .then((res) => res.data)
      .then((result) => {
        this.setState({
          listDistrict: result.data,
          displayDistrict: result.data,
          isLoading: false,
        });
      });
  };

  getValueBiensAvoir() {
    const biens = this.state ? this.state.bien_avoirs : "";
    const aBiens = biens.split(",");
    let aResult = [{ label: "", value: "" }];
    aResult = aBiens.map((elt) => ({
      label: elt,
      value: elt,
    }));

    return aResult;
  }
  getBiensAvoir = ({ value }) => {
    if (value) {
      const data =
        value &&
        value.length > 0 &&
        value.map((elt) => elt.value).filter((d) => d !== "");
      const bien_avoirs = data && data.length > 0 && data.join(",");
      this.state.menage.bien_avoirs = bien_avoirs;
      return bien_avoirs;
    } else if (this.isUpdate) {
      return this.state.menage.bien_avoirs;
    }
    return "Choisir biens et avoirs";
  };
  /*
  processSave = (DTO) => {
    MenageService.post(DTO).then(
      (menage) => {
        if (menage.data.code && menage.data.code.toString() === "200") {
          this.saveIndividu(menage.data.data);
        } else if (menage.data.code && menage.data.code.toString() === "400") {
          this.notificationMessage = "Le ménage exite déjà";
          this.notificationTitle = "Création Ménage impossible";
          this.setState({
            showNotification: true,
            isLoading: false,
          });
        }
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
        // this.props.onCancel();
      }
    );
  };
  */

  processSave = () => {
    const menage = { ...this.state.menage };
    const individu = { ...this.state.individu };
    const DTO = { menage, individu };

    MenageService.postMenageIndividu(DTO).then(
      (menage) => {
        if (menage.data.code && menage.data.code.toString() === "200") {
          if (this.state.showFiche) {
            this.props.onShowFiche(menage.data.data);
          } else {
            this.props.onSave(menage.data.data);
          }
        } else if (
          menage.data.code &&
          menage.data.code.toString() === "400" &&
          menage.data.message.toString() === "MENAGE ALREADY EXIST"
        ) {
          this.notificationMessage = "Le ménage exite déjà";
          this.notificationTitle = "Création Ménage impossible";
          this.setState({
            showNotification: true,
            isLoading: false,
          });
        } else if (
          menage.data.code &&
          menage.data.code.toString() === "400" &&
          menage.data.message.toString() === "CIN ALREADY EXIST"
        ) {
          this.notificationMessage = "Le CIN exite déjà";
          this.notificationTitle = "Création Individu impossible";
          this.setState({
            showNotification: true,
            isLoading: false,
          });
        }
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
        // this.props.onCancel();
      }
    );
  };

  saveIndividu = (menage) => {
    const DTO = { ...this.state.individu, menageid: menage.id };
    IndividuService.post(DTO).then(
      (data) => {
        this.setState({
          isLoading: false,
        });
        if (this.state.showFiche) {
          this.props.onShowFiche(data.data.data);
        } else {
          this.props.onSave();
        }
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  };
  updateIndividu = (menage) => {
    const DTO = { ...this.state.individu, menageid: menage.id };
    IndividuService.post(DTO).then(
      (data) => {
        this.setState({
          isLoading: false,
        });
        this.props.onSave();
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  processUpdate() {
    const menage = { ...this.state.menage, id: this.props.data.id };
    const individu = { ...this.state.individu, menageid: this.props.data.id };
    const DTO = { menage, individu };
    MenageService.updateMenageIndividu(DTO).then(
      (menage) => {
        this.setState({
          isLoading: false,
        });
        this.props.onSave(menage.data.data);
        //   this.updateIndividu(menage.data.data);
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
        this.props.onCancel();
      }
    );
  }

  save = (event) => {
    event.preventDefault();
    if (this.checkValidation()) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          this.isUpdate ? this.processUpdate() : this.processSave();
        }
      );
    }
  };

  checkMenage = () => {
    let menageExists = false;
    MenageService.checkMenage(this.state.menage).then((rep) => {
      if (rep.data.data.id) {
        menageExists = true;
      } else {
        this.setState({
          showNotification: true,
        });
      }
    });
    return menageExists;
  };

  checkValidation = () => {
    const adresse =
      this.state.menage.adresse.length > 0 ? "" : "champ obligatoire";
    const region =
      this.state.menage.region.length > 0 ? "" : "champ obligatoire";
    const district =
      this.state.menage.district.length > 0 ? "" : "champ obligatoire";
    const commune =
      this.state.menage.commune.length > 0 ? "" : "champ obligatoire";
    const isError = { adresse, region, district, commune };
    const nom = this.state.individu.nom.length > 0 ? "" : "champ obligatoire";
    let cin = "";
    let telephone = "";

    if (
      this.state.individu.cin &&
      this.state.individu.cin.length !== 0 &&
      this.state.individu.cin.length !== 12
    ) {
      cin = "12 chiffres requis";
    }
    if (
      this.state.individu.telephone &&
      this.state.individu.telephone.length !== 0 &&
      this.state.individu.telephone.length !== 10
    ) {
      telephone = "10 chiffres requis";
    }
    let result = true;
    this.setState({
      menage: {
        ...this.state.menage,
        isError,
      },
      individu: {
        ...this.state.individu,
        isError: {
          nom,
          cin,
          telephone,
        },
      },
    });

    if (
      adresse.length > 0 ||
      region.length > 0 ||
      district.length > 0 ||
      commune.length > 0 ||
      nom.length > 0 ||
      cin.length > 0 ||
      telephone.length > 0
    ) {
      result = false;
    }

    return result;
  };
  handleChangeAdresse = (evt) => {
    let { value } = evt.target;
    let isError = { ...this.state.menage.isError };
    isError.adresse = value === "" ? "Champ obligatoire" : "";
    this.setState(
      {
        menage: {
          ...this.state.menage,
          isError,
          adresse: value,
        },
      },
      () => this.getListMenage()
    );
  };

  getListMenage() {
    this.setState({
      isLoading: true,
    });
    MenageService.getListByPagination(
      0,
      10,
      "",
      "",
      this.state.menage.adresse
    ).then(
      (response) => {
        this.setState({
          menage: {
            ...this.state.menage,
            autocompletion: response.data.data,
          },
          isLoading: false,
        });
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  }

  hanldeEauNonRensegne = (evt) => {
    this.setState({
      menage: {
        ...this.state.menage,
        eauNonRenseigne: !this.state.menage.eauNonRenseigne,
        presenceEau: "",
        abscenceEau: "",
        acheterEau: "",
        prixBidon: "",
        distanceEau: "",
        sourceEau: "",
        demandeEau: "",
      },
    });
  };
  handleChange = (evt) => {
    const { name, value } = evt.target;
    let isError = { ...this.state.menage.isError };
    switch (name) {
      case "region":
        isError.region = value === "" ? "Champ obligatoire" : "";
        const displayDistrict =
          value !== ""
            ? this.state.listDistrict.filter((r) => r.nom_region === value)
            : this.state.listDistrict;
        const displayCommune =
          value !== ""
            ? this.state.listCommune.filter((r) => r.nomRegion === value)
            : this.state.listCommune;
        if (!value) {
          isError.district = "Champ obligatoire";
          isError.commune = "Champ obligatoire";
        }

        this.setState({
          displayDistrict,
          displayCommune,
          menage: {
            ...this.state.menage,
            district: "",
            commune: "",
            [name]: value,
            isError,
          },
        });
        break;
      case "district":
        isError.district = value === "" ? "Champ obligatoire" : "";
        const region =
          value !== ""
            ? this.state.listDistrict.filter(
                (r) => r.nom_districte === value
              )[0].nom_region
            : "";
        const commune =
          value !== ""
            ? this.state.listCommune.filter((r) => r.nomDistricte === value)
            : this.state.listCommune;
        const district =
          value !== ""
            ? this.state.listDistrict.filter((r) => r.nom_region === region)
            : this.state.listDistrict;
        if (value) {
          isError.region = "";
        } else {
          isError.region = "Champ obligatoire";
          isError.commun = "Champ obligatoire";
        }
        this.setState({
          displayDistrict: district,
          displayRegion: this.state.listRegion,
          displayCommune: commune,
          menage: {
            ...this.state.menage,
            region: region,
            commune: "",
            [name]: value,
            isError,
          },
        });
        break;
      case "commune":
        isError.commune = value === "" ? "Champ obligatoire" : "";
        const nomRegion =
          value !== ""
            ? this.state.listCommune.filter((r) => r.nomCommune === value)[0]
                .nomRegion
            : "";
        const nomDistricte =
          value !== ""
            ? this.state.listCommune.filter((r) => r.nomCommune === value)[0]
                .nomDistricte
            : "";
        let communeList =
          value !== ""
            ? this.state.listCommune.filter(
                (r) =>
                  r.nomRegion === nomRegion && r.nomDistricte === nomDistricte
              )
            : this.state.listCommune;
        if (value) {
          isError.region = "";
          isError.district = "";
        } else {
          isError.region = "Champ obligatoire";
          isError.district = "Champ obligatoire";
        }
        this.setState({
          displayCommune: communeList,
          displayRegion: this.state.listRegion,
          displayDistrict: this.state.listDistrict,
          menage: {
            ...this.state.menage,
            region: nomRegion,
            district: nomDistricte,
            [name]: value,
            isError,
          },
        });
        break;
      case "fokotany":
        isError.fokotany = value === "" ? "" : "";
        this.setState({
          menage: {
            ...this.state.menage,
            [name]: value,
            isError,
          },
        });
        break;
      case "custom-radio-2":
        const { id } = evt.target;
        switch (id) {
          case "acheterEau":
            this.setState({
              menage: {
                ...this.state.menage,
                [id]: "oui",
                presenceEau: "non",
                abscenceEau: "non",
                eauNonRenseigne: false,
                sourceEau: "",
                distanceEau: "",
              },
            });
            break;
          case "presenceEau":
            this.setState({
              menage: {
                ...this.state.menage,
                [id]: "oui",
                acheterEau: "non",
                abscenceEau: "non",
                eauNonRenseigne: false,
                prixBidon: "",
                distanceEau: "",
              },
            });
            break;
          case "abscenceEau":
            this.setState({
              menage: {
                ...this.state.menage,
                [id]: "oui",
                presenceEau: "non",
                acheterEau: "non",
                eauNonRenseigne: false,
                sourceEau: "",
                prixBidon: "",
              },
            });
            break;
        }
        break;
      default:
        this.setState({
          menage: {
            ...this.state.menage,
            [name]: value,
          },
        });
        break;
    }
  };

  addMaladieHereditaire = (evt) => {
    evt.preventDefault();
    const id = "";
    const nomMaladie = GET_MALADIE_HERIDITAIRE[0].key;
    const connue = false;
    const nbConnue = "";
    const traitement = false;
    const nbTraitement = "";
    const newMaladie = {
      id,
      nomMaladie,
      connue,
      nbConnue,
      traitement,
      nbTraitement,
    };
   
    let maladie = [...this.state.menage.maladie];
  
    maladie.push(newMaladie);

    this.setState({
      menage: {
        ...this.state.menage,
        maladie,
      },
    });
  };

  removeMaldieHereditaire = (evt, id) => {
    evt.preventDefault();
    let maladie = [...this.state.menage.maladie];
  
    maladie = maladie.filter((elt, idx) => idx !== id);
    this.setState({
      menage: {
        ...this.state.menage,
        maladie,
      },
    });
  };

  handleChangeAssistance = (evt) => {
    const { name, value } = evt.target;
    this.setState({
      menage: {
        ...this.state.menage,
        [name]: value,
      },
    });
  };
  handleChangeAssistanceDate1 = (date) => {
    let value = "";
    if (date.isValid) {
      value = date.format("DD/MM/YYYY");
    }
    this.setState({
      menage: {
        ...this.state.menage,
        assistanceDate1: value,
      },
    });
  };
  handeleDateNaissance = (date) => {
    let value = "";
    if (date.isValid) {
      value = date.format("DD/MM/YYYY");
    }
    this.setState({
      individu: {
        ...this.state.individu,
        dateNaissance: value,
      },
    });
  };

  handleChangeAssistanceDate2 = (date) => {
    let value = "";
    if (date.isValid) {
      value = date.format("DD/MM/YYYY");
    }
    this.setState({
      menage: {
        ...this.state.menage,
        assistanceDate2: value,
      },
    });
  };
  handleChangeAssistanceDate3 = (date) => {
    let value = "";
    if (date.isValid) {
      value = date.format("DD/MM/YYYY");
    }
    this.setState({
      menage: {
        ...this.state.menage,
        assistanceDate3: value,
      },
    });
  };

  handleChangeIndividu = (evt) => {
    let { name, value } = evt.target;
    let isError = { ...this.state.individu.isError };
    switch (name) {
      case "nom":
        isError.nom = value === "" ? "Champ obligatoire" : "";
        this.setState({
          isLoading: true,
        });
        IndividuService.getListByPagination(0, 10, value, "").then((data) => {
          this.setState({
            isLoading: false,
            individu: {
              ...this.state.individu,
              autocompletion: data.data.data,
            },
          });
        });
        break;
      case "cin":
        value = value.replace(/[^\d]/, "");
        isError.cin = value.length !== 12 ? "12 chiffres réquis" : "";
        if (value.length > 12) {
          return "";
        }
        break;
      case "age":
        isError.age = value === "" ? "" : "";
        value = value.replace(/[^\d]/, "");

        if (value.length > 2) {
          return "";
        }

        break;
      case "telephone":
        value = value.replace(/[^\d]/, "");
        isError.telephone =
          value.length !== 0 && value.length !== 10 ? "10 chiffres réquis" : "";
        if (value.length > 10) {
          return "";
        }
        break;
      case "sexe":
        isError.sexe = value === "" ? "" : "";
        break;
      default:
        break;
    }

    this.setState({
      individu: {
        ...this.state.individu,
        isError,
        [evt.target.name]: value,
      },
    });
  };

  addAssistance = (e) => {
    e.preventDefault();
    let assistance = this.state.menage.assistance;
    assistance.push("");
    this.setState({
      assistance,
    });
  };

  removeAssistance = (idx) => {
    let assistance = this.state.menage.assistance;
    assistance = assistance.filter((e, i) => i !== idx);
    this.setState({
      menage: {
        ...this.state.menage,
        assistance,
      },
    });
  };
  showFiche = (event) => {
    if (this.checkValidation()) {
      this.setState(
        {
          showFiche: true,
        },
        () => {
          this.save(event);
        }
      );
    }
  };

  handleChangeMaladieHereditaire = (event, idx, num) => {
    event.preventDefault();
    let { value } = event.target;
    let maladie = [...this.state.menage.maladie];
    const selectedMaladie = maladie.filter((elt, id) => id === idx)[0];
    selectedMaladie[num] = value;
    maladie = maladie.map((elt, id) => {
      if (id === idx) {
        elt = selectedMaladie;
      }
      return elt;
    });
    this.setState({
      menage: {
        ...this.state.menage,
        maladie: maladie,
      },
    });
  };

  questionnaireEau = () => {
    if (
      typeof this.state.menage.presenceEau === "string" &&
      this.state.menage.presenceEau.toLowerCase() === "oui"
    ) {
      return (
        <>
          <FormGroup>
            <label className="form-control-label" htmlFor="input-first-name">
              Quelle est la source de l'eau ?
            </label>
            <div className="input-group-alternative input-group">
              <select
                className="mdb-select md-form  form-control"
                onChange={this.handleChange}
                name="sourceEau"
                value={this.state.menage.sourceEau}
              >
                {GET_SOURCE_EAU.map((elt, index) => (
                  <option key={index} value={elt.key}>
                    {elt.value}
                  </option>
                ))}
              </select>
            </div>
          </FormGroup>
          <FormGroup>
            <label className="form-control-label" htmlFor="input-email">
              Problème/Demande
            </label>

            <textarea
              style={{ display: "block", width: "100%" }}
              className="form-control-alternative"
              id="input-username"
              placeholder=""
              type="text"
              value={this.state.menage.demandeEau}
              onChange={this.handleChange}
              name="demandeEau"
            ></textarea>
          </FormGroup>
        </>
      );
    } else if (
      typeof this.state.menage.abscenceEau === "string" &&
      this.state.menage.abscenceEau.toLowerCase() === "oui"
    ) {
      return (
        <>
          <FormGroup>
            <label className="form-control-label" htmlFor="input-first-name">
              A combien de kilomètre se trouve l'eau ?
            </label>
            <div className="input-group-alternative input-group">
              <select
                className="mdb-select md-form  form-control"
                onChange={this.handleChange}
                name="distanceEau"
                value={this.state.menage.distanceEau}
              >
                <option value=""></option>
                {DISTANCE &&
                  DISTANCE.map((elt, index) => (
                    <option key={index} value={elt.key}>
                      {elt.value}
                    </option>
                  ))}
              </select>
            </div>
          </FormGroup>
          <FormGroup>
            <label className="form-control-label" htmlFor="input-email">
              Problème/Demande
            </label>

            <textarea
              style={{ display: "block", width: "100%" }}
              className="form-control-alternative"
              id="input-username"
              placeholder=""
              type="text"
              value={this.state.menage.demandeEau}
              onChange={this.handleChange}
              name="demandeEau"
            ></textarea>
          </FormGroup>
        </>
      );
    } else if (
      typeof this.state.menage.acheterEau === "string" &&
      this.state.menage.acheterEau.toLowerCase() === "oui"
    ) {
      return (
        <>
          <FormGroup>
            <label className="form-control-label" htmlFor="input-first-name">
              Combien coute le prix du bidon (Ariary) ?
            </label>
            <Input
              className="form-control-alternative"
              placeholder=""
              type="text"
              name="prixBidon"
              onChange={this.handleChange}
              value={this.state.menage.prixBidon}
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label" htmlFor="input-email">
              Problème/Demande
            </label>

            <textarea
              style={{ display: "block", width: "100%" }}
              className="form-control-alternative"
              id="input-username"
              placeholder=""
              type="text"
              value={this.state.menage.demandeEau}
              onChange={this.handleChange}
              name="demandeEau"
            ></textarea>
          </FormGroup>
        </>
      );
    }
  };

  render() {
    const tableData =
      this.state.menage.assistance &&
      this.state.menage.assistance.map((elt, idx) => (
        <tr key={idx}>
          <td>
            <FormGroup>
              <div className="input-group-alternative input-group">
                <select
                  className="mdb-select md-form  form-control"
                  onChange={this.handleChange}
                  name="region"
                  value={elt.type}
                >
                  <option value=""></option>
                  <option value="Tosika Fameno">Tosika Fameno</option>
                  <option value="Fiavota">Fiavota</option>
                  <option value="GFD">GFD</option>
                </select>
              </div>
            </FormGroup>
          </td>
          <td>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: "Choisir date",
                  }}
                  format={"DD/MM/YYYY"}
                  closeOnSelect={true}
                  timeFormat={false}
                />
              </InputGroup>
            </FormGroup>
          </td>
          <td>
            <FormGroup>
              <Button onClick={() => this.removeAssistance(idx)}>-</Button>
            </FormGroup>
          </td>
        </tr>
      ));
    const listeRegion =
      this.state.displayRegion &&
      this.state.displayRegion.map((elt, idx) => (
        <option key={elt.id} value={elt.nom_region}>
          {elt.nom_region}
        </option>
      ));
    const listeCommnue =
      this.state.displayCommune &&
      this.state.displayCommune.map((elt, idx) => (
        <option key={elt.id} value={elt.nomCommune}>
          {elt.nomCommune}
        </option>
      ));
    const listeDistrict =
      this.state.displayDistrict &&
      this.state.displayDistrict.map((elt, idx) => (
        <option key={elt.id} value={elt.nom_districte}>
          {elt.nom_districte}
        </option>
      ));

    const aMaladieHereditaire =
      this.state.menage.maladie &&
      this.state.menage.maladie.map((data, idx) => {
        return (
          <>
            <tr key={idx}>
              <td>
                <FormGroup>
                  <select
                    className="mdb-select md-form  form-control"
                    value={data.nomMaladie}
                    name="nomMaladie"
                    onChange={(event) =>
                      this.handleChangeMaladieHereditaire(
                        event,
                        idx,
                        "nomMaladie"
                      )
                    }
                  >
                    {GET_MALADIE_HERIDITAIRE.map((elt, id) => (
                      <option key={id}>{elt.value}</option>
                    ))}
                  </select>
                </FormGroup>
              </td>
              <td>
                <FormGroup>
                  <select
                    className="mdb-select md-form  form-control"
                    value={data.connue}
                    name="connue"
                    onChange={(event) =>
                      this.handleChangeMaladieHereditaire(event, idx, "connue")
                    }
                  >
                    <option value="false">Non</option>
                    <option value="true">Oui</option>
                  </select>
                </FormGroup>
              </td>
              <td>
                <FormGroup>
                  <Input
                    className="form-control-alternative"
                    id="input-text"
                    placeholder=""
                    type="number"
                    min={1}
                    onChange={(event) =>
                      this.handleChangeMaladieHereditaire(
                        event,
                        idx,
                        "nbConnue"
                      )
                    }
                    name="nbConnue"
                    value={data.nbConnue}
                  />
                </FormGroup>
              </td>

              <td>
                <FormGroup>
                  <select
                    className="mdb-select md-form  form-control"
                    value={data.traitement}
                    name="traitement"
                    onChange={(event) =>
                      this.handleChangeMaladieHereditaire(
                        event,
                        idx,
                        "traitement"
                      )
                    }
                  >
                    <option value="false">Non</option>
                    <option value="true">Oui</option>
                  </select>
                </FormGroup>
              </td>

              <td>
                <FormGroup>
                  <Input
                    className="form-control-alternative"
                    id="input-text"
                    placeholder=""
                    type="number"
                    min={1}
                    onChange={(event) =>
                      this.handleChangeMaladieHereditaire(
                        event,
                        idx,
                        "nbTraitement"
                      )
                    }
                    name="nbTraitement"
                    value={data.nbTraitement}
                  />
                </FormGroup>
              </td>

              <td>
                <Button
                  onClick={(event) => this.removeMaldieHereditaire(event, idx)}
                  className=""
                >
                  -
                </Button>
              </td>
            </tr>
          </>
        );
      });

    const niveauInsecurite =
      GET_NIVEAU_INSECURITE &&
      GET_NIVEAU_INSECURITE.map((elt, index) => (
        <option key={index} value={elt.key}>
          {elt.value}
        </option>
      ));

    const distance =
      DISTANCE &&
      DISTANCE.map((elt, index) => (
        <option key={index} value={elt.key}>
          {elt.value}
        </option>
      ));

    const revenu =
      REVENU &&
      REVENU.map((elt, index) => (
        <option key={index} value={elt.key}>
          {elt.value}
        </option>
      ));

    return (
      <>
        <Modal className="modal-dialog-centered" size="lg" isOpen={true}>
          <div className="modal-body p-0">
            <Card className="bg-light-secondary shadow border-0">
              <CardHeader className="bg-transparent pb-0">
                <div className="text-muted text-center mt-2 mb-3">
                  <h2 className="title-text">{this.title}</h2>
                </div>
              </CardHeader>
              <CardBody className="px-lg-3 py-lg-2">
                <Form autocomplete="off">
                  <div className="pl-lg-4">
                    <fieldset>
                      <legend>Zonage</legend>
                      <div className="row">
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Région *
                            </label>
                            <div className="input-group-alternative input-group">
                              <select
                                className="mdb-select md-form  form-control"
                                onChange={this.handleChange}
                                name="region"
                                value={this.state.menage.region}
                              >
                                <option value="">
                                  Afficher toutes les régions
                                </option>
                                {listeRegion}
                              </select>
                            </div>
                            {this.state.menage.isError.region.length > 0 && (
                              <small className="text-danger">
                                champ obligatoire
                              </small>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              District *
                            </label>
                            <div className="input-group-alternative input-group">
                              <select
                                className="mdb-select md-form  form-control"
                                onChange={this.handleChange}
                                name="district"
                                value={this.state.menage.district}
                              >
                                <option value="">
                                  Afficher tous les districts
                                </option>
                                {listeDistrict}
                              </select>
                            </div>
                            {this.state.menage.isError.district.length > 0 && (
                              <small className="text-danger">
                                champ obligatoire
                              </small>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Commune *
                            </label>
                            <div className="input-group-alternative input-group">
                              <select
                                className="mdb-select md-form  form-control"
                                onChange={this.handleChange}
                                name="commune"
                                value={this.state.menage.commune}
                              >
                                <option value="">
                                  Afficher toutes les communes
                                </option>
                                {listeCommnue}
                              </select>
                            </div>
                            {this.state.menage.isError.commune.length > 0 && (
                              <small className="text-danger">
                                champ obligatoire
                              </small>
                            )}
                          </FormGroup>
                        </Col>
                      </div>
                      <div className="row">
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Fokontany
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-text"
                              placeholder="Fokontany"
                              type="text"
                              onChange={this.handleChange}
                              name="fokotany"
                              value={this.state.menage.fokotany}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Hameau(x)
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              placeholder="Hameau(x)"
                              type="text"
                              onChange={this.handleChange}
                              name="hameau"
                              value={this.state.menage.hameau}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="identifiant"
                            >
                              Identifiant Menage *
                            </label>
                            <Input
                              autocomplete="off"
                              disabled={this.isUpdate}
                              className="form-control-alternative"
                              id="identifiant"
                              placeholder="Identifiant Menage"
                              type="text"
                              onChange={this.handleChangeAdresse}
                              value={this.state.menage.adresse}
                              onFocus={this.onFocusMenage}
                              onBlur={this.onBlurMenage}
                            />
                            <SuggestionMenage
                              results={this.state.menage.autocompletion}
                              show={this.state.showSuggestionMenage}
                            />
                            {this.state.menage.isError.adresse.length > 0 && (
                              <small className="text-danger">
                                champ obligatoire
                              </small>
                            )}
                          </FormGroup>
                        </Col>
                      </div>
                    </fieldset>
                    <fieldset>
                      <legend>Situation économique et sociale</legend>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Activité
                            </label>
                            <div className="input-group-alternative input-group">
                              <select
                                className="mdb-select md-form  form-control"
                                onChange={this.handleChange}
                                name="activite"
                                value={this.state.menage.activite}
                              >
                                <option value=""></option>
                                {this.activites.map((elt, idx) => (
                                  <option key={idx} value={elt}>
                                    {elt}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Revenus
                            </label>
                            <div className="input-group-alternative input-group">
                              <select
                                className="mdb-select md-form  form-control"
                                onChange={this.handleChange}
                                name="revenu"
                                value={this.state.menage.revenu}
                              >
                                <option value=""></option>
                                {revenu}
                              </select>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Nb personnes actives
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              placeholder=""
                              type="number"
                              min={0}
                              value={
                                this.state.menage.nbPersonneActifDansLeMenage
                              }
                              onChange={this.handleChange}
                              name="nbPersonneActifDansLeMenage"
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Nb enfants scolarisés
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              placeholder=""
                              type="number"
                              min={0}
                              value={this.state.menage.nbEnfantScolarise}
                              onChange={this.handleChange}
                              name="nbEnfantScolarise"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Nb animaux d'élévage
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              placeholder=""
                              type="number"
                              min={0}
                              value={this.state.menage.nbElevage}
                              onChange={this.handleChange}
                              name="nbElevage"
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Terrain cultivable
                            </label>

                            <select
                              className="mdb-select md-form  form-control"
                              onChange={this.handleChange}
                              name="terrain"
                              value={this.state.menage.terrain}
                            >
                              <option value=""></option>
                              <option value="oui">Oui</option>
                              <option value="non">Non</option>
                            </select>
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Récolte (nb)
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              placeholder="Ex: Haricot (10kg)"
                              type="text"
                              value={this.state.menage.recolteObtenus}
                              onChange={this.handleChange}
                              name="recolteObtenus"
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Maison
                            </label>
                            <select
                              className="mdb-select md-form  form-control"
                              onChange={this.handleChange}
                              name="maison"
                              value={this.state.menage.maison}
                            >
                              <option value=""></option>
                              <option value="Locataire">Locataire</option>
                              <option value="Propriétaire">Propriétaire</option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Ration
                            </label>
                            <div className="input-group-alternative input-group">
                              <select
                                className="mdb-select md-form  form-control"
                                onChange={this.handleChange}
                                name="ration"
                                value={this.state.menage.ration}
                              >
                                <option value=""></option>
                                <option value="Moins de 1/2 ration">
                                  Moins de 1/2 portion
                                </option>
                                <option value="1/2 ration">1/2 Portion</option>
                                <option value="Ration normale">
                                  Portion normale
                                </option>
                              </select>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Fréquence repas
                            </label>
                            <div className="input-group-alternative input-group">
                              <select
                                className="mdb-select md-form  form-control"
                                onChange={this.handleChange}
                                name="frequencerepo"
                                value={this.state.menage.frequencerepo}
                              >
                                <option value=""></option>
                                <option value="Moins d'une fois par jour">
                                  Moins de 1 jour
                                </option>
                                <option value="1 fois par jour">
                                 1 fois par jour
                                </option>
                                <option value="2 fois par jour">
                                2 fois par jour 
                                </option>
                                <option value="plus de 2 fois par jour">
                                  plus de 2 fois par jour
                                </option>
                              </select>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Nourriture
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              placeholder=""
                              type="text"
                              value={this.state.menage.nourriture}
                              onChange={this.handleChange}
                              name="nourriture"
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Problème/Demande
                            </label>
                            <textarea
                              style={{ width: "100%" }}
                              className="form-control-alternative"
                              id="input-username"
                              placeholder=""
                              type="text"
                              value={this.state.menage.demandeSociale}
                              onChange={this.handleChange}
                              name="demandeSociale"
                            ></textarea>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <table className="table table-bordered">
                            <thead className="">
                              <tr>
                                <th>Type Don</th>
                                <th>Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <FormGroup>
                                    <div className="input-group-alternative input-group">
                                      <select
                                        className="mdb-select md-form  form-control"
                                        onChange={this.handleChangeAssistance}
                                        name="assistanceType1"
                                        value={
                                          this.state.menage.assistanceType1
                                        }
                                      >
                                        <option value=""></option>
                                        <option value="Tosika Fameno">
                                          Tosika Fameno
                                        </option>
                                        <option value="Fiavota">Fiavota</option>
                                        <option value="GFD">GFD</option>
                                      </select>
                                    </div>
                                  </FormGroup>
                                </td>
                                <td>
                                  <FormGroup>
                                    <InputGroup className="input-group-alternative">
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                          <i className="ni ni-calendar-grid-58" />
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      <ReactDatetime
                                        inputProps={{
                                          placeholder: "Choisir date",
                                        }}
                                        format={"DD/MM/YYYY"}
                                        closeOnSelect={true}
                                        timeFormat={false}
                                        onChange={
                                          this.handleChangeAssistanceDate1
                                        }
                                        name="assistanceDate1"
                                        selected={
                                          this.state.menage.assistanceDate1
                                        }
                                        value={
                                          this.state.menage.assistanceDate1 !==
                                            "" &&
                                          this.state.menage.assistanceDate1
                                        }
                                      />
                                    </InputGroup>
                                  </FormGroup>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <FormGroup>
                                    <div className="input-group-alternative input-group">
                                      <select
                                        className="mdb-select md-form  form-control"
                                        onChange={this.handleChangeAssistance}
                                        name="assistanceType2"
                                        value={
                                          this.state.menage.assistanceType2
                                        }
                                      >
                                        <option value=""></option>
                                        <option value="Tosika Fameno">
                                          Tosika Fameno
                                        </option>
                                        <option value="Fiavota">Fiavota</option>
                                        <option value="GFD">GFD</option>
                                      </select>
                                    </div>
                                  </FormGroup>
                                </td>
                                <td>
                                  <FormGroup>
                                    <InputGroup className="input-group-alternative">
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                          <i className="ni ni-calendar-grid-58" />
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      <ReactDatetime
                                        inputProps={{
                                          placeholder: "Choisir date",
                                        }}
                                        format={"DD/MM/YYYY"}
                                        closeOnSelect={true}
                                        timeFormat={false}
                                        onChange={
                                          this.handleChangeAssistanceDate2
                                        }
                                        name="assistanceDate2"
                                        selected={
                                          this.state.menage.assistanceDate2
                                        }
                                        value={
                                          this.state.menage.assistanceDate2 !==
                                            "" &&
                                          this.state.menage.assistanceDate2
                                        }
                                      />
                                    </InputGroup>
                                  </FormGroup>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <FormGroup>
                                    <div className="input-group-alternative input-group">
                                      <select
                                        className="mdb-select md-form  form-control"
                                        onChange={this.handleChangeAssistance}
                                        name="assistanceType3"
                                        value={
                                          this.state.menage.assistanceType3
                                        }
                                      >
                                        <option value=""></option>
                                        <option value="Tosika Fameno">
                                          Tosika Fameno
                                        </option>
                                        <option value="Fiavota">Fiavota</option>
                                        <option value="GFD">GFD</option>
                                      </select>
                                    </div>
                                  </FormGroup>
                                </td>
                                <td>
                                  <FormGroup
                                    id="form-group-date"
                                    style={{ marginBottom: 0 }}
                                  >
                                    <InputGroup className="input-group-alternative">
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                          <i className="ni ni-calendar-grid-58" />
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      <ReactDatetime
                                        inputProps={{
                                          placeholder: "Choisir date",
                                        }}
                                        format={"DD/MM/YYYY"}
                                        id="date"
                                        closeOnSelect={true}
                                        timeFormat={false}
                                        onChange={
                                          this.handleChangeAssistanceDate3
                                        }
                                        name="assistanceDate3"
                                        selected={
                                          this.state.menage.assistanceDate3
                                        }
                                        value={
                                          this.state.menage.assistanceDate3 !==
                                            "" &&
                                          this.state.menage.assistanceDate3
                                        }
                                      />
                                    </InputGroup>
                                  </FormGroup>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                    </fieldset>

                    <fieldset>
                      <legend>Accès à l'eau </legend>
                      <div className="row">
                        <Col lg="6">
                          <FormGroup>
                            <div className="custom-control custom-radio mb-3">
                              <input
                                className="custom-control-input"
                                id="eauNonRenseigne"
                                type="radio"
                                name="custom-radio-2"
                                value={this.state.menage.eauNonRenseigne}
                                onChange={this.hanldeEauNonRensegne}
                                checked={this.state.menage.eauNonRenseigne}
                              />
                              <label
                                className="custom-control-label text-bold"
                                htmlFor="eauNonRenseigne"
                              >
                                Non renseigné
                              </label>
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <div className="custom-control custom-radio mb-3">
                              <input
                                className="custom-control-input"
                                id="presenceEau"
                                type="radio"
                                name="custom-radio-2"
                                value={
                                  typeof this.state.menage.presenceEau ===
                                    "string" &&
                                  this.state.menage.presenceEau.toLowerCase()
                                }
                                onChange={this.handleChange}
                                checked={
                                  typeof this.state.menage.presenceEau ===
                                    "string" &&
                                  this.state.menage.presenceEau.toLowerCase() ===
                                    "oui"
                                }
                              />
                              <label
                                className="custom-control-label text-bold"
                                htmlFor="presenceEau"
                              >
                                Présence d'eau à domicile
                              </label>
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <div className="custom-control custom-radio mb-3">
                              <input
                                className="custom-control-input"
                                id="abscenceEau"
                                type="radio"
                                name="custom-radio-2"
                                value={
                                  typeof this.state.menage.abscenceEau ===
                                    "string" &&
                                  this.state.menage.abscenceEau.toLowerCase()
                                }
                                onChange={this.handleChange}
                                checked={
                                  typeof this.state.menage.abscenceEau ===
                                    "string" &&
                                  this.state.menage.abscenceEau.toLowerCase() ===
                                    "oui"
                                }
                              />
                              <label
                                className="custom-control-label text-bold"
                                htmlFor="abscenceEau"
                              >
                                Absence d'eau à domicile
                              </label>
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <div className="custom-control custom-radio mb-3">
                              <input
                                className="custom-control-input"
                                id="acheterEau"
                                type="radio"
                                name="custom-radio-2"
                                value={
                                  typeof this.state.menage.acheterEau ===
                                    "string" &&
                                  this.state.menage.acheterEau.toLowerCase()
                                }
                                onChange={this.handleChange}
                                checked={
                                  typeof this.state.menage.acheterEau ===
                                    "string" &&
                                  this.state.menage.acheterEau.toLowerCase() ===
                                    "oui"
                                }
                              />
                              <label
                                className="custom-control-label text-bold"
                                htmlFor="acheterEau"
                              >
                                Acheter eau
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="6">{this.questionnaireEau()}</Col>
                      </div>
                    </fieldset>
                    <fieldset>
                      <legend>Sécurité dans le village</legend>
                      <div className="row">
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Niveau d'insécurité
                            </label>
                            <select
                              className="mdb-select md-form  form-control"
                              onChange={this.handleChange}
                              name="niveauInsecurite"
                              value={this.state.menage.niveauInsecurite}
                            >
                              <option value=""></option>
                              {niveauInsecurite}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Camp militaire le plus proche
                            </label>
                            <select
                              className="mdb-select md-form  form-control"
                              onChange={this.handleChange}
                              name="distanceCampMilitaire"
                              value={this.state.menage.distanceCampMilitaire}
                            >
                              <option value=""></option>
                              {distance}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Problème/Demande
                            </label>

                            <textarea
                              style={{ display: "block", width: "100%" }}
                              className="form-control-alternative"
                              id="input-username"
                              placeholder=""
                              type="text"
                              value={this.state.menage.demandeSecurite}
                              onChange={this.handleChange}
                              name="demandeSecurite"
                            ></textarea>
                          </FormGroup>
                        </Col>
                      </div>
                    </fieldset>
                    <fieldset>
                      <legend>Sanitaire</legend>
                      <div className="row">
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Distance de CSB le plus proche ?
                            </label>
                            <div className="input-group-alternative input-group">
                              <select
                                className="mdb-select md-form  form-control"
                                onChange={this.handleChange}
                                name="distanceLieuSanitaire"
                                value={this.state.menage.distanceLieuSanitaire}
                              >
                                <option value=""></option>
                                {distance}
                              </select>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Premier endroit à aller en cas de maladie
                            </label>
                            <div className="input-group-alternative input-group">
                              <select
                                className="mdb-select md-form  form-control"
                                onChange={this.handleChange}
                                name="lieuSanitaire"
                                value={this.state.menage.lieuSanitaire}
                              >
                                <option value=""></option>
                                <option value="CSB">CSB</option>
                                <option value="Medecine traditionnelle">
                                  Medecine traditionnelle
                                </option>
                              </select>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Utilisation de moyen de contraception
                            </label>
                            <div className="input-group-alternative input-group">
                              <select
                                className="mdb-select md-form  form-control"
                                onChange={this.handleChange}
                                name="utilisationContraception"
                                value={
                                  this.state.menage.utilisationContraception
                                }
                              >
                                <option value=""></option>
                                <option value="oui">Oui</option>
                                <option value="non">Non</option>
                              </select>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Problème/Demande
                            </label>

                            <textarea
                              style={{ display: "block", width: "100%" }}
                              className="form-control-alternative"
                              id="input-username"
                              placeholder=""
                              type="text"
                              value={this.state.menage.demandeSanitaire}
                              onChange={this.handleChange}
                              name="demandeSanitaire"
                            ></textarea>
                          </FormGroup>
                        </Col>
                      </div>
                      <Row>
                        <Col lg="12">
                          <label className="form-control-label">
                            Maladie héréditaire{" "}
                          </label>
                          <table className="table">
                            <thead>
                              <tr>
                                <th className="w-19">Maladie</th>
                                <th className="w-19">Connue</th>
                                <th className="w-19">Nombre</th>
                                <th className="w-19">Traitée</th>
                                <th className="w-19">Nombre</th>
                                <th className="w-5"></th>
                              </tr>
                            </thead>
                            <tbody>{aMaladieHereditaire}</tbody>
                          </table>
                          <div>
                            <Button
                              onClick={this.addMaladieHereditaire}
                              className="add-button"
                            >
                              <i
                                style={{ fontSize: "20px", fontWeight: "bold" }}
                              >
                                +
                              </i>
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </fieldset>
                    <fieldset>
                      <legend>Membres de famille</legend>
                      <Row>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Nb individus
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              placeholder=""
                              type="number"
                              min={0}
                              value={this.state.menage.nbIndividus}
                              onChange={this.handleChange}
                              name="nbIndividus"
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Nb enfant moins de 5 ans
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              placeholder=""
                              type="number"
                              min={0}
                              value={this.state.menage.nbEnfant}
                              onChange={this.handleChange}
                              name="nbEnfant"
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Nb Femme allaitante
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              placeholder=""
                              type="number"
                              min={0}
                              value={this.state.menage.nbFemmeAllaitante}
                              onChange={this.handleChange}
                              name="nbFemmeAllaitante"
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Nb Femme enceinte
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              placeholder=""
                              type="number"
                              min={0}
                              value={this.state.menage.nbFemmeEnceinte}
                              onChange={this.handleChange}
                              name="nbFemmeEnceinte"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col-md-6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Nb Personne agée ( Plus de 65ans)
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              placeholder=""
                              type="number"
                              min={0}
                              value={this.state.menage.nbPersonneAge}
                              onChange={this.handleChange}
                              name="nbPersonneAge"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="col-md-6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Diminution regime alimentaire constaté ces 6
                              derniers mois ?
                            </label>
                            <div className="input-group-alternative input-group">
                              <select
                                className="mdb-select md-form  form-control"
                                onChange={this.handleChange}
                                name="diminution"
                                value={this.state.menage.diminution}
                              >
                                <option value=""></option>
                                <option value="oui">Oui</option>
                                <option value="non">Non</option>
                              </select>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </fieldset>
                  </div>
                </Form>

                <div>
                  <Form autocomplete="off">
                    <div className="pl-lg-4">
                      <fieldset>
                        <legend>Chef de famille</legend>
                        <div>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-username"
                                >
                                  Nom et Prénoms*
                                </label>

                                <Input
                                  className="form-control-alternative"
                                  id="input-username"
                                  placeholder="Nom et Prénoms"
                                  type="text"
                                  name="nom"
                                  value={this.state.individu.nom}
                                  onChange={this.handleChangeIndividu}
                                  onFocus={this.onFocus}
                                  onBlur={this.onBlur}
                                />
                                <Suggestions
                                  results={this.state.individu.autocompletion}
                                  show={this.state.showSuggestion}
                                />
                                {this.state.individu.isError.nom.length > 0 && (
                                  <small className="text-danger">
                                    champ obligatoire
                                  </small>
                                )}
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-username"
                                >
                                  Age
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-username"
                                  placeholder="Age"
                                  type="text"
                                  value={this.state.individu.age}
                                  onChange={this.handleChangeIndividu}
                                  name="age"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Genre
                                </label>
                                <div className="input-group-alternative input-group">
                                  <select
                                    className="mdb-select md-form  form-control"
                                    onChange={this.handleChangeIndividu}
                                    name="sexe"
                                    value={this.state.individu.genre}
                                  >
                                    <option value=""></option>
                                    <option value="Masculin">Masculin</option>
                                    <option value="Feminin">Feminin</option>
                                  </select>
                                </div>
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  CIN
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-first-name"
                                  placeholder="CIN"
                                  type="text"
                                  value={this.state.individu.cin}
                                  onChange={this.handleChangeIndividu}
                                  name="cin"
                                />
                                {this.state.individu.isError.cin.length > 0 && (
                                  <small className="text-danger">
                                    {this.state.individu.isError.cin}
                                  </small>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Date de naissance
                                </label>
                                <FormGroup>
                                  <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="ni ni-calendar-grid-58" />
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <ReactDatetime
                                      inputProps={{
                                        placeholder: "Choisir date",
                                      }}
                                      format={"DD/MM/YYYY"}
                                      closeOnSelect={true}
                                      timeFormat={false}
                                      onChange={this.handeleDateNaissance}
                                      name="dateNaissance"
                                      selected={
                                        this.state.individu.dateNaissance
                                      }
                                      value={
                                        this.state.individu.dateNaissance !==
                                          "" &&
                                        this.state.individu.dateNaissance
                                      }
                                    />
                                  </InputGroup>
                                </FormGroup>
                              </FormGroup>
                            </Col>

                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Marié
                                </label>
                                <div className="input-group-alternative input-group">
                                  <select
                                    className="mdb-select md-form  form-control"
                                    onChange={this.handleChangeIndividu}
                                    name="marie"
                                    value={this.state.individu.marie}
                                  >
                                    <option value=""></option>
                                    <option value="oui">Oui</option>
                                    <option value="non">Non</option>
                                  </select>
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Femme allaitante
                                </label>
                                <div className="input-group-alternative input-group">
                                  <select
                                    className="mdb-select md-form  form-control"
                                    name="femme_allaitante"
                                    disabled={
                                      this.state.individu &&
                                      this.state.individu.sexe &&
                                      this.state.individu.sexe.toLocaleLowerCase() ===
                                        "masculin"
                                    }
                                    value={this.state.individu.femme_allaitante || ""}
                                    onChange={this.handleChangeIndividu}
                                  >
                                    <option value=""></option>
                                    <option value="Oui">Oui</option>
                                    <option value="Non">Non</option>
                                  </select>
                                </div>
                              </FormGroup>
                            </Col>

                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Femme enceinte
                                </label>
                                <div className="input-group-alternative input-group">
                                  <select
                                    className="mdb-select md-form  form-control"
                                    name="femme_enceinte"
                                    disabled={
                                      this.state.individu &&
                                      this.state.individu.sexe &&
                                      this.state.individu.sexe.toLocaleLowerCase() ===
                                        "masculin"
                                    }
                                    value={this.state.individu.femme_enceinte || ""}
                                    onChange={this.handleChangeIndividu}
                                  >
                                    <option value=""></option>
                                    <option value="Oui">Oui</option>
                                    <option value="Non">Non</option>
                                  </select>
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Handicap/maladie chronique
                                </label>
                                <div className="input-group-alternative input-group">
                                  <select
                                    className="mdb-select md-form  form-control"
                                    name="handicape"
                                    value={this.state.individu.handicape}
                                    onChange={this.handleChangeIndividu}
                                  >
                                    <option value=""></option>
                                    <option value="Oui">Oui</option>
                                    <option value="Non">Non</option>
                                  </select>
                                </div>
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Maladie
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-first-name"
                                  placeholder=""
                                  type="text"
                                  value={this.state.individu.maladie}
                                  onChange={this.handleChangeIndividu}
                                  name="maladie"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Niveau d'étude
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-first-name"
                                  placeholder="dernière classe"
                                  type="text"
                                  value={this.state.individu.derniereClasse}
                                  onChange={this.handleChangeIndividu}
                                  name="derniereClasse"
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-username"
                                >
                                  Téléphone
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-username"
                                  placeholder="Téléphone"
                                  type="text"
                                  value={this.state.individu.telephone}
                                  onChange={this.handleChangeIndividu}
                                  name="telephone"
                                />
                                {this.state.individu.isError.telephone.length >
                                  0 && (
                                  <small className="text-danger">
                                    {this.state.individu.isError.telephone}
                                  </small>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </fieldset>
                    </div>
                    <div className="pl-lg-4"></div>
                  </Form>
                </div>

                <div className="modal-footer">
                  <Button type="button" onClick={this.props.onCancel}>
                    Annuler
                  </Button>
                  <Button
                    type="button"
                    onClick={(event) => {
                      this.save(event);
                    }}
                  >
                    <span>Enregistrer</span>
                  </Button>
                  {!this.isUpdate && (
                    <Button
                      type="button"
                      color="primary"
                      onClick={this.showFiche}
                    >
                      Créer Fiche de traitement
                      <i className="fas fa-angle-double-right"></i>
                    </Button>
                  )}
                </div>
              </CardBody>
            </Card>
          </div>

          <PageSpinner show={this.state.isLoading} />
        </Modal>
        <NotificationModal
          show={this.state.showNotification}
          message={this.notificationMessage}
          title={this.notificationTitle}
          close={this.closeNotification}
        />
      </>
    );
  }
}

export default CreateMenageModal;
