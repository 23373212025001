import axios from 'axios';
import { BACK_URL } from 'data/constant/urls';
import authHeader from './AuthHeader';

// const API_URL = process.env.REACT_APP_URL;
const API_URL = BACK_URL;

class HistoriqueService {

    addHistorique(data) {
        return axios.post(API_URL + `api/historique/addHistorique`, data, {
            headers: authHeader(),
        });
    }

    findHistorique(pageNo, pageSize, idSite, idPartenaire, idMouvement) {
        return axios.get(API_URL + `api/historique/findHistorique?pageNo=${pageNo}&pageSize=${pageSize}&idSite=${idSite}&idPartenaire=${idPartenaire}&idMouvement=${idMouvement}`, {
            headers: authHeader(),
        });
    }

    deleteHistorique(id) {
        return new Promise(async (success, error) => {
            const result = await axios.put(API_URL + `api/historique/cancel?historiqueId=${id}`, null, {
                headers: authHeader(),
            }).catch(exception => {
                error(exception.response && exception.response.data);
            });
            success(result);
        });
    }
}

export default new HistoriqueService();