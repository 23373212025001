export function average(data) {
  var sum = data.reduce(function (sum, value) {
    return sum + value;
  }, 0);

  var avg = sum / data.length;
  return avg;
}

export function stdDev(...data) {
  const avgSquareDiff = average(data);
  return Math.sqrt(avgSquareDiff);
}
