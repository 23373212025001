import { getFiliation } from "common/data";
import { Download } from 'helper/ImpressionHelper';
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,

  Modal,
  Row
} from "reactstrap";
import MenageService from "services/MenageService";
import PageSpinner from "./PageSpinner";

class FicheMenageModal extends React.Component {
  state = {
    exampleModal: false,
    isLoading: false,
    aIndividus: [],
    aMenages: [],
    data: null,
    id:null,
  };

  componentDidMount() {
    const id = this.props.menage ? this.props.menage.id : "";
    this.setState({
      id
    })
    this.getFicheMenage(id);
  }

  getFicheMenage = (id) => {
    this.setState({
      isLoading: true,
    });
    MenageService.getFicheMenageById(id).then((response) => {
      this.formatDTO(response.data.data);
    },error=>{
      this.setState({
        isLoading:false
      })
    });
  };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  formatDTO = (data) => {
    const keyIndividu = [
      { key: "nom", value: "Nom et prénoms" },
      { key: "age", value: "Age" },
      ,
      { key: "sexe", value: "Sexe" },
      { key: "telephone", value: "Téléphone" },
      { key: "affiliation", value: "Filiation" },
    ];
    const keyMenage = [
      { key: "adresse", value: "ID Menage" },
      { key: "revenu", value: "Revenu" },
      { key: "fokotany", value: "Fokontany" },
      { key: "district", value: "District" },
      { key: "commune", value: "Commune" },
      { key: "fokotany", value: "Fokontany" },
      { key: "region", value: "Region" },
      { key: "nb_deces", value: "Nombre de décès" },
      { key: "nb_individu", value: "Nombre individus" },
    ];
    const allData = Object.entries(data).map(([key, value]) => ({
      key,
      value,
    }));
    let dataIndivu = [];
    let dataMenage = [];

    keyMenage.forEach((menage) => {
      let selected = allData.filter((elt) => elt.key == menage.key)[0];
      if(selected){
      selected.key = menage.value;
      dataMenage.push(selected);
      }
    });

    this.setState({
      data,
      aIndividus: dataIndivu,
      aMenages: dataMenage,
      isLoading: false,
    });
  };

  handleSubmit = () => {
    this.setState({
      isLoading: true,
    });

    MenageService.impression(this.state.id).then((response) => {
      Download(response)
      this.setState({
        isLoading: false,
      });
      this.props.onCancel();
    });
  };
 
  render() {
    const { aMenages } = this.state;
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={true}
          size="lg"
          toggle={() => this.toggleModal("exampleModal")}
        >
          <div className="modal-body p-0">
            <Card className=" border-0">
              <CardHeader className="bg-light-secondary pb-0">
                <div className="text-muted text-center mt-2 mb-3 ">
                  <h2>Fiche Menage</h2>
                </div>
              </CardHeader>
              <CardBody className="px-lg-4 py-lg-2">
                <Form>
                  <div className="pl-lg-4">
                    <h4 className="text-underline">
                      <i className="ni ni-single-02 text-yellow" /> Information
                      sur le menage
                    </h4>
                    <div>
                      <div className="row">
                        <div className="col-md-6">
                          <Row>
                            <Col lg="4">
                              <label
                                className="form-control-label  "
                                htmlFor="input-username"
                              >
                                ID menage:
                              </label>
                            </Col>
                            <Col lg="8">
                              <label
                                className="form-control-label text-primary"
                                htmlFor="input-username"
                              >
                                &nbsp;
                                {this.state.data && this.state.data.adresse}
                              </label>
                            </Col>
                          </Row>
                        </div>
                        <div className="col-md-6">
                          <Row>
                            <Col lg="4">
                              <label
                                className="form-control-label  "
                                htmlFor="input-username"
                              >
                                Fokontany:
                              </label>
                            </Col>
                            <Col lg="8">
                              <label
                                className="form-control-label text-primary"
                                htmlFor="input-username"
                              >
                                &nbsp;
                                {this.state.data && this.state.data.fokotany}
                              </label>
                            </Col>
                          </Row>
                         
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                        <Row>
                            <Col lg="4">
                              <label
                                className="form-control-label  "
                                htmlFor="input-username"
                              >
                                Commune:
                              </label>
                            </Col>
                            <Col lg="8">
                              <label
                                className="form-control-label text-primary"
                                htmlFor="input-username"
                              >
                                &nbsp;
                                {this.state.data && this.state.data.commune}
                              </label>
                            </Col>
                          </Row>
                       
                        </div>
                        <div className="col-md-6">
                        <Row>
                            <Col lg="4">
                              <label
                                className="form-control-label  "
                                htmlFor="input-username"
                              >
                                District:
                              </label>
                            </Col>
                            <Col lg="8">
                              <label
                                className="form-control-label text-primary"
                                htmlFor="input-username"
                              >
                                &nbsp;
                                {this.state.data && this.state.data.district}
                              </label>
                            </Col>
                          </Row>
                         
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                        <Row>
                            <Col lg="4">
                              <label
                                className="form-control-label  "
                                htmlFor="input-username"
                              >
                                Region:
                              </label>
                            </Col>
                            <Col lg="8">
                              <label
                                className="form-control-label text-primary"
                                htmlFor="input-username"
                              >
                                &nbsp;
                                {this.state.data && this.state.data.region}
                              </label>
                            </Col>
                          </Row>
                          
                        </div>
                        <div className="col-md-6">
                        <Row>
                            <Col lg="4">
                              <label
                                className="form-control-label  "
                                htmlFor="input-username"
                              >
                                Activite:
                              </label>
                            </Col>
                            <Col lg="8">
                              <label
                                className="form-control-label text-primary"
                                htmlFor="input-username"
                              >
                                &nbsp;
                                {this.state.data && this.state.data.activite}
                              </label>
                            </Col>
                          </Row>
                        
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                        <Row>
                            <Col lg="4">
                              <label
                                className="form-control-label  "
                                htmlFor="input-username"
                              >
                                Freq. repas:
                              </label>
                            </Col>
                            <Col lg="8">
                              <label
                                className="form-control-label text-primary"
                                htmlFor="input-username"
                              >
                                &nbsp;
                                {this.state.data &&
                                  this.state.data.frequencerepo}
                              </label>
                            </Col>
                          </Row>
                         
                        </div>
                        <div className="col-md-6">
                        <Row>
                            <Col lg="4">
                              <label
                                className="form-control-label  "
                                htmlFor="input-username"
                              >
                                Ration:
                              </label>
                            </Col>
                            <Col lg="8">
                              <label
                                className="form-control-label text-primary"
                                htmlFor="input-username"
                              >
                                &nbsp;
                                {this.state.data && this.state.data.ration}
                              </label>
                            </Col>
                          </Row>
                         
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                        <Row>
                            <Col lg="4">
                              <label
                                className="form-control-label  "
                                htmlFor="input-username"
                              >
                                Biens et avoirs:
                              </label>
                            </Col>
                            <Col lg="8">
                              <label
                                className="form-control-label text-primary"
                                htmlFor="input-username"
                              >
                                &nbsp;
                                {this.state.data && this.state.data.bien_avoirs}
                              </label>
                            </Col>
                          </Row>
                         
                        </div>
                        <div className="col-md-6">
                        <Row>
                            <Col lg="4">
                              <label
                                className="form-control-label  "
                                htmlFor="input-username"
                              >
                                Hameau(x):
                              </label>
                            </Col>
                            <Col lg="8">
                              <label
                                className="form-control-label text-primary"
                                htmlFor="input-username"
                              >
                                &nbsp;
                                {this.state.data && this.state.data.hameau}
                              </label>
                            </Col>
                          </Row>
                          
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                        <Row>
                            <Col lg="4">
                              <label
                                className="form-control-label  "
                                htmlFor="input-username"
                              >
                                Revenu:
                              </label>
                            </Col>
                            <Col lg="8">
                              <label
                                className="form-control-label text-primary"
                                htmlFor="input-username"
                              >
                                &nbsp;
                                {this.state.data && this.state.data.revenu}
                              </label>
                            </Col>
                          </Row>
                          
                        </div>
                      
                        <div className="col-md-6">
                          <Row>
                            <Col lg="4">
                              <label
                                className="form-control-label  "
                                htmlFor="input-username"
                              >
                                Nombre de décès:
                              </label>
                            </Col>
                            <Col lg="8">
                              <label
                                className="form-control-label text-primary"
                                htmlFor="input-username"
                              >
                                &nbsp;
                                {this.state.data && this.state.data.nb_deces}
                              </label>
                            </Col>
                          </Row>
                        </div>
                      
                      </div> 
                       
                    </div>
                    <div className="row">
                      <h4 className="text-underline">
                        <i className="ni ni-books text-black" /> Information sur
                        les individus
                      </h4>
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-primary">Nom</th>
                            <th className="text-primary">Age</th>
                            <th className="text-primary">Sexe</th>
                            <th className="text-primary">Filiation</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.data &&
                            this.state.data.individuDTOS &&
                            this.state.data.individuDTOS.map((elt, idx) => (
                              <tr
                                className={
                                  elt.affiliation === "CF" ? "bg-light-secondary" : ""
                                }
                                key={idx}
                              >
                                <td>{elt.nom}</td>
                                <td>{elt.age}</td>
                                <td>{elt.sexe}</td>
                                <td>{getFiliation(elt.affiliation)}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr className="m-0" />
                  <div className="modal-footer">
                    <Button type="button" onClick={this.props.onCancel}>
                      Fermer
                    </Button>
                    <Button
                      type="button"
                 
                      onClick={this.handleSubmit}
                    >
                      <span>Imprimer</span>
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
          <PageSpinner show={this.state.isLoading} />
        </Modal>
      </>
    );
  }
}

export default FicheMenageModal;
