export const HEALTH = "HEALTH";
export const ECONOMY = "ECONOMY";
export const NUTRITION = "NUTRITION";
export const HEALED = "HEALED";
export const NEW_CASE = "NEW_CASE";
export const BEING_PROCESSED = "BEING_PROCESSED";
export const DEATH = "DEATH";
export const GFD = "GFD";
export const TOSIKA_FAMENO = "Tosika fameno";
export const FIAVOTA = "Fiavota";
export const FREQUENCE = "FREQUENCE";
export const RATION = "RATION";
export const DEMO = "DEMO";
export const MOIN5ANS = "MOINS5ANS";
export const PLUS65ANS = "PLUS65ANS";
export const ALLAITANTE = "ALLAITANTE";
export const ENCEINTE = "ENCEINTE";
export const HANDICAPE = "HANDICAPE";
export const NOT_SPECIFIED = "NOT_SPECIFIED";
export const DMAS_ENFANT = "DMAS_ENFANT";
export const DMAM_ENFANT = "DMAM_ENFANT";
export const MAMMAS_ENFANT = "MAMMAS_ENFANT";
export const MASMAM_ENFANT = "MASMAM_ENFANT";
export const BON_ENFANT = "BON_ENFANT";
export const NON_ENFANT = "NON_ENFANT";
export const DMAS_ADOS = "DMAS_ADOS";
export const DMAM_ADOS = "DMAM_ADOS";
export const MAMMAS_ADOS = "MAMMAS_ADOS";
export const MASMAM_ADOS = "MASMAM_ADOS";
export const BON_ADOS = "BON_ADOS";
export const NON_ADOS = "NON_ADOS";
export const NUTRI = "NUTRI";
export const MAL = "MAL";
export const NON_ADULTE = "NON_ADULTE";
export const TSY_MANDRY = "Tsy mandry";
export const MILAMINA = "Milamina";
export const MAMPIAHIAHY = "Misy mampiahiahy";
export const OUI = "OUI";
export const NON = "NON";
export const POMPE = "Pompe";
export const PUIT = "Puit";
export const AUTRE = "Autre";
export const FLEUVE = "Fleuve";
export const MOINS_1_KILOMETER = "-1 km";
export const NOT_SPECIFIED_WATER = "Distance non renseigner";
export const BETWEEN_1_TO_5_KILOMETER = "1 à 5 km";
export const BETWEEN_5_TO_10_KILOMETER = "5 à 10 km";
export const PLUS_10_KILOMETER = "+10 km";
export const IN_SAFE = "En sécurité";
export const IN_ALERT = "En alerte";
export const RED_ZONE = "Zone rouge";
export const NOT_SPECIFIED_SECURITY = "Sécurité non renseigner";
export const ENTREE = "ENTREE";
export const SORTIE = "SORTIE";
export const TRANSFERT = "TRANSFERT";
export const NUTRITION_3FOIS = "3 fois par jour";
export const NUTRITION_2FOIS = "2 fois par jour";
export const NUTRITION_1FOIS = "1 fois par jour";
export const NUTRITION_PLUS_2FOIS = "plus de 2 fois par jour";
export const NUTRITION_1FOIS_OU_MOINS = "1 fois par jour ou moins";
export const NUTRITION_MOIN_1FOIS = "Moins de 1 fois par jour";
export const NOT_SPECIFIED_NUTRI = "non renseigné";
export const REVENU_0_100 = "0 à 100 000 Ar";
export const REVENU_100_200 = "100 000 à 200 000 Ar";
export const REVENU_200_300 = "200 000 à 300 000 Ar";
export const REVENU_300_400 = "300 000 à 400 000 Ar";
export const REVENU_PLUS_400 = "plus de 400 000 Ar";
export const REVENU_NON = "Non renseigné";
export const MENAGE = "NBMENAGE";
export const ADULTENONCLASSIFIE = "ADULTENONCLASSIFIE";


export const OUVERT = "OUVERT";
export const PEUT_ENCORE_ACCUEILLIR = "PEUT ENCORE ACCUEILLIR";
export const PLEIN = "PLEIN";
export const DEPASSE_LA_CAPACITE_D_ACCUEIL = "DEPASSE LA CAPACITE D'ACCUEIL";
export const DEPLACE = "DEPLACE";
export const FERME = "FERME";
