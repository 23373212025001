import React from 'react'
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
    Row
} from 'reactstrap';

const NewArticleModal = (props) => {


    const getArticleList = () => {
        const { articleList } = props;
        return articleList && articleList.map((article, index) =>
            <option key={index} value={article.id}>{`${article.libelle}(${article?.libelle})`}</option>
        );
    }

    return (
        <Modal isOpen={props.showModal} toggle={props.toggle} size="lg" fade={true}>
            <ModalHeader toggle={props.toggle}>Inventaire</ModalHeader>
            <ModalBody>
                <Row>
                    <FormGroup className="col">
                        <Label for="article">Article</Label>
                        <Input
                            type="select"
                            name="article"
                            id="article"
                            value={(props.selectedStock && props.selectedStock.id) || -1}
                            onChange={props.handleChange}
                        >
                            {getArticleList()}
                        </Input>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup className="col">
                        <Label for="idArticle">Identifiant</Label>
                        <Input
                            type="text"
                            name="idArticle"
                            id="idArticle"
                            placeholder="Identifiant de l'article"
                            value={(props.selectedArticle && props.selectedArticle.article && props.selectedArticle.article.idArticle) || ''}
                            onChange={props.handleChange}
                        />
                    </FormGroup>
                    <FormGroup className="col">
                        <Label for="article">Article</Label>
                        <Input
                            type="text"
                            name="article"
                            id="article"
                            placeholder="Désignation de l'article"
                            value={(props.selectedArticle && props.selectedArticle.article && props.selectedArticle.article.article) || ''}
                            onChange={props.handleChange}
                        />
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup className="col">
                        <Label for="id">Id site</Label>
                        <Input
                            type="text"
                            name="idArticle"
                            id="idArticle"
                            placeholder="Id du site"
                            value={(props.selectedArticle && props.selectedArticle.site && props.selectedArticle.site.idSite) || ''}
                            onChange={props.handleChange}
                        />
                    </FormGroup>
                    <FormGroup className="col">
                        <Label for="site">Site médical</Label>
                        <Input
                            type="text"
                            name="site"
                            id="site"
                            placeholder="Site médical"
                            value={(props.selectedArticle && props.selectedArticle.site && props.selectedArticle.site.site) || ''}
                            onChange={props.handleChange}
                        />
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup className="col">
                        <Label for="id">Quantité théorique</Label>
                        <Input
                            type="number"
                            name="theoricQuantity"
                            id="theoricQuantity"
                            placeholder="Quantité théorique"
                            value={(props.selectedArticle && props.selectedArticle.theoricQuantity) || ''}
                            onChange={props.handleChange}
                        />
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup className="col">
                        <Label for="designation">Quantité physique</Label>
                        <Input
                            type="number"
                            name="realQuantity"
                            id="realQuantity"
                            placeholder="Quantité physique"
                            value={(props.selectedArticle && props.selectedArticle.realQuantity) || ''}
                            onChange={props.handleChange}
                        />
                    </FormGroup>
                </Row>
            </ModalBody>
            <ModalFooter style={{ borderTopColor: '#cad1d7', borderTopStyle: 'solid', borderTopWidth: 0.5 }}>
                <Button color="primary" onClick={props.toggle}>Enregistrer</Button>{' '}
                <Button color="secondary" onClick={props.cancel}>Annuler</Button>
            </ModalFooter>
        </Modal >
    )
}

export default NewArticleModal;

