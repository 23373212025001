import { user_editor } from "data/constant/user.rights";
import { user_gestionnaire } from "data/constant/user.rights";
import moment from "moment";
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  UncontrolledDropdown,
  Badge,
} from "reactstrap";
import ArticleService from "services/ArticleService";
import NeedService from "services/NeedService";
import SiteService from "services/SiteService";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";
import ConfirmationModal from "views/components/ConfirmationModal";
import CreateEditNeedModal from "views/components/CreateEditNeedModal";
import PageSpinner from "views/components/PageSpinner";
import NotificationModal from "../components/NotificationModal";

class BesoinPage extends React.Component {
  priorities = [
    {
      id: 1,
      name: "Urgent",
    },
    {
      id: 2,
      name: "Critique",
    },
    {
      id: 3,
      name: "Mineur",
    },
  ];

  states = ["NOUVEAU", "EN COURS DE TRAITEMENT", "TRAITÉ", "IMPOSSIBLE"];
  state = {
    needs: [],
    siteList: [],
    currentPage: 0,
    totalPages: 1,
    totalItems: 0,
    pageSize: 10,
    siteId: -1,
    articleId: -1,
    priority: "",
    state: "",
    showNeed: false,
    needToUpdate: -1,
    selectedLineIndex: null,
  };

  componentDidMount() {
    this.getSiteListFromWs();
    this.getArticleListFromWs();
    if (this.props.location.state?.siteId)
      this.setState({ siteId: this.props.location.state?.siteId }, () =>
        this.getNeedList()
      );
    else this.getNeedList();
  }

  nextPage = () => {
    this.setState(
      {
        currentPage: this.state.currentPage + 1,
      },
      () => this.getNeedList()
    );
  };

  previousPage = () => {
    this.setState(
      {
        currentPage: this.state.currentPage - 1,
      },
      () => {
        this.getNeedList();
      }
    );
  };

  onSave = () => {
    this.getNeedList();
    this.setState({
      showNeed: false,
    });
  };

  getNeedList = () => {
    this.setState({
      isLoading: true,
    });

    NeedService.getList(
      this.state.currentPage,
      this.state.pageSize,
      this.state.siteId,
      this.state.articleId,
      this.state.priority,
      this.state.state
    ).then(
      (response) => {
        this.setState({
          needs: response.data.data,
          isLoading: false,
          totalPages: response.data?.totalPage,
          totalItems: response.data?.totalitems,
        });
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  getSiteListFromWs = () => {
    SiteService.getAllSite()
      .then((response) => response.data)
      .then((result) => {
        this.setState({
          siteList: result.data || [],
          isLoading: false,
        });
      })
      .catch(() =>
        this.setState({
          isLoading: false,
        })
      );
  };

  getArticleListFromWs = () => {
    this.setState({
      isLoading: true,
    });

    ArticleService.getAllArticles()
      .then((response) => response.data)
      .then((result) => {
        console.log("resultat", result.data);
        this.setState({
          articleList: result.data || [],
          isLoading: false,
        });
      })
      .catch(() =>
        this.setState({
          isLoading: false,
        })
      );
  };

  onSearch = (e) => {
    this.getNeedList();
  };

  onShowNeed = (need, isUpdate) => {
    this.setState({
      showNeed: true,
      isUpdate: isUpdate,
      needToUpdate: need,
    });
  };

  onShowConfirmation = (id) => {
    this.setState({ siteIdtoDelete: id, showConfirmation: true });
  };

  onHideFormModal = () => {
    this.setState({
      showNeed: false,
    });
  };

  cancelConfirmation = () => {
    this.setState({
      showConfirmation: false,
    });
  };

  handlePage = (event) => {
    this.setState({ [event.target.id]: event.target.value }, () =>
      this.getNeedList()
    );
  };

  handleChangeInput = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.getNeedList();
    });
  };

  deleteSite = () => {
    const { siteIdtoDelete } = this.state;
    NeedService.delete(siteIdtoDelete)
      .then((response) => response.data)
      .then(() => this.setState({ isLoading: false }, () => this.getNeedList()))
      .catch((exception) =>
        this.setState({
          isLoading: false,
          isError: true,
          errorMessage: exception.errorCode
            ? exception.errorMessage
            : "Contactez votre administrateur",
          errorTitle: "Erreur à la suppression d'un site",
        })
      );
  };

  handleInput = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  saveConfirmation = () => {
    this.deleteSite();
    this.setState(
      {
        showConfirmation: false,
      },
      () => this.getNeedList()
    );
  };

  getPriorityValue = (number) => {
    switch (number) {
      case "1":
        return "Urgent";
      case "2":
        return "Critique";
      case "3":
        return "Mineur";
      default:
        return "";
    }
  };

  getPriorityType = (number) => {
    switch (number) {
      case "1":
        return "danger";
      case "2":
        return "warning";
      case "3":
        return "success";
      default:
        return "";
    }
  };

  getPriorityColor = (number) => {
    switch (number) {
      case "1":
        return "orange";
      case "2":
        return "red";
      case "3":
        return "green";
      default:
        return "black";
    }
  };

  render() {
    const pageList = Array.from(Array(this.state.totalPages).keys()).map(
      (number, key) => (
        <option value={number} key={key}>
          {number + 1}
        </option>
      )
    );

    const articleOptions =
      this.state.articleList &&
      this.state.articleList.map((elt, idx) => (
        <option key={elt.id} value={elt.id}>
          {elt.libelle}
        </option>
      ));

    const siteOptions =
      this.state.siteList &&
      this.state.siteList.map((elt, idx) => (
        <option key={elt.id} value={elt.id}>
          {elt.nom_site}
        </option>
      ));

    const priorityOptions = this.priorities.map((elt, idx) => (
      <option key={elt.id} value={elt.id}>
        {elt.name}
      </option>
    ));

    const stateOptions = this.states.map((elt, idx) => (
      <option key={idx} value={elt}>
        {elt}
      </option>
    ));

    const tableData =
      this.state.needs &&
      this.state.needs
        .sort(function (a, b) {
          if (a.priorite < b.priorite) return -1;
          if (a.priorite > b.priorite) return 1;
          if (moment(a.dateSave) < moment(b.dateSave)) return -1;
          if (moment(a.dateSave) > moment(b.dateSave)) return 1;
          return 0;
        })
        .map((need, index) => (
          <tr
            key={index}
            className={this.state.selectedLineIndex == index ? "selected" : ""}
          >
            {(user_gestionnaire.includes(
              localStorage.getItem("roleName") || ""
            ) ||
              user_editor.includes(localStorage.getItem("roleName") || "")) && (
              <td className="sticky-col sticky-left p-1">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light m-0"
                    href="#"
                    role="button"
                    size="sm"
                    color=""
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ selectedLineIndex: index });
                    }}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu
                    className="dropdown-menu-arrow"
                    container="body"
                    positionFixed={true}
                    right
                  >
                    {(user_gestionnaire.includes(
                      localStorage.getItem("roleName") || ""
                    ) ||
                      user_editor.includes(
                        localStorage.getItem("roleName") || ""
                      )) && (
                      <DropdownItem
                        href="#"
                        className="clickable"
                        onClick={() => this.onShowNeed(need.id, true)}
                      >
                        Modifier besoin
                      </DropdownItem>
                    )}
                    {user_gestionnaire.includes(
                      localStorage.getItem("roleName") || ""
                    ) &&
                      need.etat === "NOUVEAU" && (
                        <DropdownItem
                          href="#"
                          className="clickable"
                          onClick={() => this.onShowConfirmation(need.id)}
                        >
                          Supprimer besoin
                        </DropdownItem>
                      )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            )}
            <td>{need.sitemedical?.nom_site}</td>
            <td>{need.article?.libelle}</td>
            <td>{need.article?.unite?.libelle}</td>
            <td>{need?.qteArticle}</td>
            <td>
              {moment(new Date(need?.dateSave)).format("DD/MM/YYYY HH:mm")}
            </td>
            <td style={{ color: this.getPriorityColor(need?.priorite) }}>
              {" "}
              <Badge color={this.getPriorityType(need?.priorite)}>
                {this.getPriorityValue(need?.priorite)}
              </Badge>
            </td>
            <td>{need?.etat}</td>
          </tr>
        ));

    const { showConfirmation } = this.state;

    console.log(
      "permission ===>",
      user_gestionnaire.includes(localStorage.getItem("roleName") || "") ||
        user_editor.includes(localStorage.getItem("roleName") || "")
    );
    return (
      <>
        {showConfirmation && (
          <ConfirmationModal
            onCancel={this.cancelConfirmation}
            onSave={this.saveConfirmation}
          />
        )}
        {this.state.showNeed && (
          <CreateEditNeedModal
            need={this.state.needToUpdate}
            onCancel={this.onHideFormModal}
            onSave={this.onSave}
            isUpdate={this.state.isUpdate}
            articleList={this.state.articleList}
            siteList={this.state.siteList}
          />
        )}
        {this.state.isError && (
          <NotificationModal
            show={this.state.isError}
            message={this.state.errorMessage}
            title={this.state.errorTitle}
            close={this.closeErrorNotification}
          />
        )}

        <div className="header bg-gradient-primary postition-absolute">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row></Row>
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt-5 custom-card-container" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                    <Col xl="2" lg="4" sm="6" xs="12" className="mb-3 mb-xl-0">
                      <FormGroup>
                        <div className="input-group-alternative input-group">
                          <Input
                            placeholder="Site concerné *"
                            type="select"
                            className={
                              this.state.isEmptyType
                                ? "form-control-alternative-error mdb-select md-form form-control"
                                : "mdb-select md-form form-control"
                            }
                            onChange={this.handleChangeInput}
                            name="siteId"
                            value={this.state.siteId}
                          >
                            <option value={-1}>Tous les sites</option>
                            {siteOptions}
                          </Input>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xl="2" lg="4" sm="6" xs="12" className="mb-3 mb-xl-0">
                      <FormGroup>
                        <div className="input-group-alternative input-group">
                          <Input
                            placeholder="Article *"
                            type="select"
                            className={
                              this.state.isEmptyType
                                ? "form-control-alternative-error mdb-select md-form form-control"
                                : "mdb-select md-form form-control"
                            }
                            onChange={this.handleChangeInput}
                            value={this.state.articleId}
                            name="articleId"
                          >
                            <option value={-1}>Tous les articles</option>
                            {articleOptions}
                          </Input>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xl="2" lg="4" sm="6" xs="12" className="mb-3 mb-xl-0">
                      <FormGroup>
                        <div className="input-group-alternative input-group">
                          <Input
                            placeholder="Priorité *"
                            type="select"
                            className={
                              this.state.isEmptyType
                                ? "form-control-alternative-error mdb-select md-form form-control"
                                : "mdb-select md-form form-control"
                            }
                            onChange={this.handleChangeInput}
                            name="priority"
                            value={this.state.priority}
                          >
                            <option value="">Toutes les priorités </option>
                            {priorityOptions}
                          </Input>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xl="2" lg="4" sm="6" xs="12" className="mb-3 mb-xl-0">
                      <FormGroup>
                        <div className="input-group-alternative input-group">
                          <Input
                            placeholder="Etat *"
                            type="select"
                            className={
                              this.state.isEmptyType
                                ? "form-control-alternative-error mdb-select md-form form-control"
                                : "mdb-select md-form form-control"
                            }
                            onChange={this.handleChangeInput}
                            name="state"
                            value={this.state.state}
                          >
                            <option value="">Tous les états</option>
                            {stateOptions}
                          </Input>
                        </div>
                      </FormGroup>
                    </Col>

                    <Col>
                      {user_gestionnaire.includes(
                        localStorage.getItem("roleName") || ""
                      ) && (
                        <FormGroup className="d-flex justify-content-end">
                          <Button
                            className="btn"
                            onClick={() => this.onShowNeed(null, false)}
                          >
                            Ajouter un besoin
                          </Button>
                        </FormGroup>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <Table
                  className="align-items-center table-flush table table-sticky"
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" className="p-1" />
                      <th scope="col">Site concerné</th>
                      <th scope="col">Article</th>
                      <th scope="col">Unité</th>
                      <th scope="col">Quantité</th>
                      <th scope="col">Date d'enregistrement</th>
                      <th scope="col">Priorité</th>
                      <th scope="col">Etat</th>
                    </tr>
                  </thead>
                  <tbody>{tableData}</tbody>
                </Table>
                <CardFooter className="py-2 footer-pagination-container">
                  <div className="pagination-navigation-container">
                    <Label className="form-control-label">
                      <b>Nombre de lignes</b>
                    </Label>
                    <Input
                      id="pageSize"
                      type="select"
                      width={50}
                      style={{
                        display: "inline-block",
                        width: 80,
                        marginLeft: 10,
                        height: 40,
                      }}
                      value={this.state.pageSize}
                      onChange={this.handlePage}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                      <option value={50}>50</option>
                    </Input>
                  </div>
                  <Label className="form-control-label">
                    {this.state.pageSize > this.state.totalItems
                      ? this.state.totalItems
                      : this.state.pageSize}{" "}
                    <b>besoins sur </b> {this.state.totalItems} /
                    {parseInt(this.state.currentPage) + 1} <b>Page sur</b>{" "}
                    {this.state.totalPages}
                  </Label>

                  <div className="pagination-navigation-container">
                    <Label className="form-control-label">
                      <b>Numéro de page</b>
                    </Label>
                    <Input
                      id="currentPage"
                      type="select"
                      width={50}
                      style={{
                        display: "inline-block",
                        width: 80,
                        marginRight: 10,
                        marginLeft: 10,
                        height: 40,
                      }}
                      value={this.state.currentPage}
                      onChange={this.handlePage}
                    >
                      {pageList}
                    </Input>
                    <nav aria-label="...">
                      <Pagination
                        className="pagination pagination-lg justify-content-end mb-0"
                        listClassName="justify-content-end mb-0 pagination-lg"
                      >
                        <PaginationItem>
                          {this.state.currentPage > 0 && (
                            <PaginationLink
                              href="#pablo"
                              onClick={this.previousPage}
                              tabIndex="-1"
                            >
                              <i className="fas fa-angle-left" />
                              <span className="sr-only">Previous</span>
                            </PaginationLink>
                          )}
                        </PaginationItem>
                        <PaginationItem
                          className={
                            this.state.currentPage + 1 < this.state.totalPages
                              ? "active"
                              : "disabled"
                          }
                        >
                          <PaginationLink href="#" onClick={this.nextPage}>
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </nav>
                  </div>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
        <PageSpinner show={this.state.isLoading} />
      </>
    );
  }
}

export default BesoinPage;
